import Immutable from 'immutable'
import * as React from 'react'

import { AutoCompleteMulti } from 'components/form'

import { requiredStringList } from 'com.batch.common/validators'

import { useSearchOptions, optionFormatter, type InputProps } from './helper'

import { MAX_ITEMS_IN_ARRAY } from '../query.const'
import { InputPlatform } from './input-platform'

const identity = (text: string) => text
const optionToString = (option?: string | null) => option ?? ''

export const InputStringList = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const attributeId = React.useMemo(
    () => condition?.attribute?.api ?? '',
    [condition?.attribute?.api]
  )
  const isTag = condition?.attribute?.type === 'TAG'
  const loadOptions = useSearchOptions(attributeId, isTag)

  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const onChangeLocal = React.useCallback(
    value => {
      setInvalid(!requiredStringList(value) || value.size > MAX_ITEMS_IN_ARRAY)
      updateCondition(
        condition.set('value', condition.value.set('stringList', !value ? Immutable.List() : value))
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])
  if (condition.attribute?.api === 'bt.push_optin')
    return (
      <InputPlatform
        condition={condition}
        updateCondition={updateCondition}
        isInvalid={isInvalid}
      />
    )
  return (
    <AutoCompleteMulti
      key={attributeId}
      optionFormatter={optionFormatter}
      style={{ minWidth: 150 }}
      onBlur={onBlur}
      isClearable
      loadOptions={loadOptions}
      invalid={(invalid && isTouched) || isInvalid}
      value={condition.get('value').stringList}
      optionCreator={identity}
      optionToString={optionToString}
      onChange={onChangeLocal}
    />
  )
}
