/* eslint-disable react/jsx-no-bind */
import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Select } from 'components/form'

import { OperatorAndNegateFormatter, type operatorAndNegate } from './left-helper'

import { allOperators, type ConditionRecord } from 'com.batch.redux/query/query.records'
import { CountFunction } from 'com.batch.redux/query/query.records.functions'
import {
  ContainsAllOperator,
  ContainsOperator,
  DoesNotContainSomeOperator,
  EqualOperator,
  ExistsOperator,
} from 'com.batch.redux/query/query.records.operators'
import { setInputType } from 'com.batch.redux/query/query.api'
import { PushOptinArray } from 'com.batch.redux/attribute.api'

type LeftTagProps = {
  condition: ConditionRecord
  updateCondition: (arg1: ConditionRecord) => void
  negated: boolean
  negate: () => void
  unNegate: () => void
}

type option = {
  label: string
  action: (arg1: ConditionRecord) => ConditionRecord
  valueMatcher: (arg1: ConditionRecord) => boolean
}
const OPTIONS: List<option> = Immutable.List([
  {
    label: 'values',
    valueMatcher: (condition: ConditionRecord) => condition.functions.size === 0,
    action: (condition: ConditionRecord) =>
      setInputType(condition.set('functions', Immutable.List()).set('operator', ContainsOperator)),
  },
  {
    label: 'count',
    valueMatcher: (condition: ConditionRecord) => condition.functions.size === 1,
    action: (condition: ConditionRecord) =>
      setInputType(
        condition.set('functions', Immutable.List([CountFunction])).set('operator', EqualOperator)
      ),
  },
])

export const LeftArray = ({
  condition,
  updateCondition,
  negated,
  negate,
  unNegate,
}: LeftTagProps): React.ReactElement => {
  const availableOperators = React.useMemo(() => {
    const producedType = condition.functions.reduce(
      (type, func) => func.produce,
      condition.attribute?.type ?? 'STRING'
    )

    // always hide exists operator, and hide doesNotContainSome operator only when doesNotContainSome is not selected
    return (
      allOperators
        .filter(
          operator =>
            operator !== ExistsOperator &&
            (operator !== DoesNotContainSomeOperator ||
              condition.operator.value === DoesNotContainSomeOperator.value) &&
            operator.accept.has(producedType)
        )
        // hide ContainsAllOperator when the attribute is channel
        .filter(
          operator => operator !== ContainsAllOperator || condition.attribute?.api !== 'b.source'
        )
    )
  }, [condition.attribute, condition.functions, condition.operator])

  const operatorWithNegate: List<operatorAndNegate> = React.useMemo(() => {
    const tmp: Array<operatorAndNegate> = []
    availableOperators.forEach(operator => {
      tmp.push({ operator, negate: false })
      if (operator.negate !== '') {
        tmp.push({ operator, negate: true })
      }
    })
    return Immutable.List(tmp)
  }, [availableOperators])
  return (
    <FlexLine>
      {condition.attribute?.api !== PushOptinArray.id &&
        condition.attribute?.api !== 'b.source' && (
          <FlexLineItem>
            <Select
              style={{ width: 120 }}
              options={OPTIONS}
              optionToString={option => option?.label ?? ''}
              value={OPTIONS.find(option => option.valueMatcher(condition))}
              onChange={option => {
                if (option) updateCondition(option.action(condition))
              }}
            />
          </FlexLineItem>
        )}
      <FlexLineItem>
        <Select
          style={{ width: 220 }}
          optionFormatter={OperatorAndNegateFormatter}
          optionToString={option =>
            option ? option.operator.value + (option.operator.negate ? 'yes' : 'no') : ''
          }
          options={operatorWithNegate}
          value={operatorWithNegate.find(
            ({ operator, negate }) =>
              operator.value === condition.operator.value && negated === negate
          )}
          onChange={opt => {
            if (opt) {
              updateCondition(
                setInputType(condition.set('operator', opt.operator)).set(
                  'isEventFilterNegated',
                  opt.negate
                )
              )
              if (opt.negate !== negated) {
                if (opt.negate) {
                  negate()
                } else {
                  unNegate()
                }
              }
            }
          }}
        />
      </FlexLineItem>
    </FlexLine>
  )
}
