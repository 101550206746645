import { ReactFlowProvider } from '@xyflow/react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from 'components/common/button/button.styles'
import { Wrapper } from 'components/common/empty-states'
import { Loader } from 'components/common/loader/loader'

import { type ChannelInUrl, generateOrchestrationUrl } from 'com.batch.common/router'
import { generateTypedId } from 'com.batch.common/typed-id'

import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { type pushCampaignSendType } from 'com.batch.redux/_records'
import { fetchProjectApps } from 'com.batch.redux/app.action'
import { senderIdentityLoadingStateSelector } from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { fetchSenderIdentities } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { fetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetTarget } from 'com.batch.redux/target/target'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { attributesLoadedSelector } from 'com.batch.redux/target/target.selector'

import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { OrchestrationHeader } from 'com.batch/orchestration/ui/components/header'
import { fetchOrchestration } from 'com.batch/orchestration/usecases/fetch-orchestration'
import { initForm } from 'com.batch/orchestration/usecases/init-form'
import { triggerEstimate } from 'com.batch/orchestration/usecases/trigger-estimate'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { initDefaultPushPlatformsForCampaign } from 'com.batch/push/usecases/update-push-platforms'
import { initDefaultAdvancedSettingsForCampaign } from 'com.batch/push/usecases/update-push-settings'
import { fetchSspConfiguration } from 'com.batch/sms/usecases/fetch-ssp-configuration'
import { addCondition } from 'com.batch.redux/query/query'
import {
  ConditionFactory,
  ConditionInputFactory,
  QueryAttributeFactory,
  RetargetedOrchestrationFactory,
} from 'com.batch.redux/query/query.records'
import { profileTargetingAttributesSelector } from 'com.batch.redux/query/query.selector'
import { buildDefaultCondition } from 'com.batch.redux/query/query.api'
import { LoadingStatus } from 'constants/common'

type OrchestrationLoaderProps = {
  children: React.ReactNode
  defaultSendType?: pushCampaignSendType
  isAnalytics?: boolean
}
const refresh = () => window.location.reload()
export const OrchestrationLoader: React.ComponentType<OrchestrationLoaderProps> = React.memo(
  ({
    children,
    isAnalytics,
    defaultSendType,
  }: OrchestrationLoaderProps): React.ReactElement | null => {
    const dispatch = useDispatch()

    const { token, channel } = useParams()

    // channel is not present on trigger, and could be changed, so we can't trust it

    const coercedChannelForUrl: ChannelInUrl = React.useMemo(() => {
      if (channel === 'email' || channel === 'sms' || channel === 'push') {
        return channel
      }
      return null
    }, [channel])

    const coercedChannelUntilChange: ChannelUntilCleanup = React.useMemo(() => {
      if (channel === 'email' || channel === 'sms' || channel === 'push') {
        return channel
      }
      return 'email'
    }, [channel])
    const currentProject = useSelector(currentProjectSelector)
    const { loadingState, sendType, name, channels } = useSelector(orchestrationMetaSelector)
    const isLoading = React.useMemo(() => {
      return isAnalytics
        ? LoadingStatus.INIT === loadingState || LoadingStatus.LOADING === loadingState
        : loadingState === LoadingStatus.LOADING
    }, [loadingState, isAnalytics])
    const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()
    const senderIdState = useSelector(senderIdentityLoadingStateSelector)
    const attributesLoaded = useSelector(attributesLoadedSelector)
    const attributes = useSelector(profileTargetingAttributesSelector)

    const initPushConfig = React.useCallback(() => {
      dispatch(initDefaultPushPlatformsForCampaign())
      dispatch(fetchProjectApps(currentProject)).then(() => {
        dispatch(initDefaultAdvancedSettingsForCampaign())
      })
    }, [dispatch, currentProject])

    React.useEffect(() => {
      if (!attributesLoaded) {
        dispatch(fetchUnifiedCustomerDataSummary()).catch(() => {})
      }
    }, [dispatch, attributesLoaded])
    React.useEffect(() => {
      if (
        ![LoadingStatus.LOADING, LoadingStatus.LOADED, LoadingStatus.ERROR].includes(senderIdState)
      ) {
        dispatch(fetchSenderIdentities()).catch(console.error)
      }
    })
    React.useEffect(() => {
      dispatch(resetTarget(TargetStateFactory(), true))
      dispatch(
        initForm({
          schedulingType: schedulingTypeFromUrl,
          sendType: defaultSendType ?? 'now',
          // type is used for legacy reasons
          type: 'email',
          channel: coercedChannelUntilChange,
          messageTypedId: generateTypedId('message'),
        })
      )
      if (token) {
        dispatch(fetchOrchestration(token))
          // we depend on orchestration.state for settings & platforms initialization to avoid version bump on completed campaigns
          .then(() => {
            initPushConfig()
          })
          .catch(() => {})
      }
      if (!token) {
        dispatch(triggerEstimate())
        initPushConfig()
      }
      /*
        TODO => fetchSspConfiguration updates project which triggers a re-fetch of the orchestration
        because of fetchProjectApps(currentProject) in initPushConfig which is called in the same useEffect
      */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token, schedulingTypeFromUrl, defaultSendType, coercedChannelUntilChange])

    React.useEffect(() => {
      if (loadingState !== LoadingStatus.LOADED && loadingState !== LoadingStatus.LOADING) {
        dispatch(fetchLabelsAndCappingRules())
        dispatch(fetchSspConfiguration())
      }
    }, [dispatch, loadingState])

    // Retargeting - Add condition if retargeting params are present
    React.useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search)

      const retargetingEvent = urlParams.get('retargetingEvent')
      const retargetedOrchestrationToken = urlParams.get('retargetingOrchestrationToken')
      const hasRetargetingParams = retargetingEvent && retargetedOrchestrationToken

      const hasRetargetingEvent = attributes.some(attr => attr.api === retargetingEvent)

      if (!hasRetargetingParams || !hasRetargetingEvent || !attributesLoaded) return

      const baseCondition = buildDefaultCondition(
        attributes.find(attr => attr.api === retargetingEvent) ?? QueryAttributeFactory()
      )
      const baseEventFilter = baseCondition.eventFilters.get(0, ConditionFactory())
      const retargetingCondition = baseCondition.set(
        'eventFilters',
        baseCondition.eventFilters.set(
          0,
          baseEventFilter.set(
            'value',
            ConditionInputFactory({
              mode: 'InputOrchestration',
              retargetedOrchestration: RetargetedOrchestrationFactory({
                orchestrationToken: retargetedOrchestrationToken,
              }),
            })
          )
        )
      )

      dispatch(
        addCondition({
          queryId: 'targeting',
          parentId: 'root',
          condition: retargetingCondition,
        })
      )
    }, [attributes, attributesLoaded, dispatch])
    const fixedChannel: undefined | ChannelUntilCleanup = React.useMemo(() => {
      if (channels.size === 1) {
        return channels.first()
      }
      return undefined
    }, [channels])
    const sendTypeOrChannelMismatch = React.useMemo(() => {
      if (loadingState !== 'LOADED') return false
      if (schedulingTypeFromUrl === 'campaigns')
        if (sendType !== 'scheduled' && sendType !== 'now') {
          return true
        }
      if (schedulingTypeFromUrl === 'campaigns' || sendType === 'recurring')
        if (fixedChannel) {
          return fixedChannel !== coercedChannelForUrl
        }
      return sendType !== defaultSendType
    }, [
      coercedChannelForUrl,
      defaultSendType,
      fixedChannel,
      loadingState,
      schedulingTypeFromUrl,
      sendType,
    ])
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {schedulingTypeFromUrl === 'automations' ? 'Automation' : 'Campaign'} —{' '}
            {isAnalytics ? 'Analytics' : token ? 'Edit' : 'New'} – {currentProject.name} – Batch
          </title>
        </Helmet>
        <Loader loading={isLoading} padding={100} style={isLoading ? { marginTop: 20 } : {}}>
          {loadingState === LoadingStatus.ERROR || sendTypeOrChannelMismatch ? (
            <Wrapper
              isOverlayShown
              isLoading={false}
              isEmpty={false}
              overlayProps={{
                title: sendTypeOrChannelMismatch
                  ? 'Incorrect orchestration type'
                  : 'Unable to load your orchestration',
                description: sendTypeOrChannelMismatch ? (
                  <div style={{ maxWidth: 320 }}>
                    <p style={{ marginBottom: 20 }}>
                      Current URL is not valid. If a link in our dashboard led you there, please
                      contact us.
                    </p>
                    <p style={{ marginBottom: 20 }}>
                      The orchestration you seem to be trying to open should be available here :{' '}
                    </p>
                    <div>
                      <ButtonNavLink
                        intent="action"
                        kind="secondary"
                        to={generateOrchestrationUrl({
                          sendType,
                          channel: fixedChannel ?? coercedChannelForUrl,
                          projectId: currentProject.id,
                          companyId: currentProject.companyId,
                          page: 'form',
                          token: token ?? '',
                        })}
                      >
                        <span
                          style={{
                            maxWidth: 280,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {name}
                        </span>
                      </ButtonNavLink>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    An unexpected error occurred while loading orchestration <code>{token}</code>
                  </React.Fragment>
                ),
                status: 'error-page',
                refresh: !sendTypeOrChannelMismatch ? refresh : undefined,
              }}
            >
              &nbsp;
            </Wrapper>
          ) : (
            <React.Fragment>
              <OrchestrationHeader forceDisabled={isAnalytics} channel={coercedChannelForUrl} />
              {defaultSendType === 'trigger' ? (
                <ReactFlowProvider>{children}</ReactFlowProvider>
              ) : (
                children
              )}
            </React.Fragment>
          )}
        </Loader>
      </React.Fragment>
    )
  }
)
OrchestrationLoader.displayName = 'OrchestrationLoader'
