import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { PermissionButton } from 'components/common/button/permission-button'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table/table.styles'
import { Ellipsis } from 'components/styled/text'

import { generateOrchestrationListUrl } from 'com.batch.common/router/url-generator-helper'
import { kformat } from 'com.batch.common/utils'

import { DropdownSegment } from './dropdown-segment'

import { fetchEstimateForSegmentName } from '../../usecases/estimate-segment'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { estimateProfileGetterSelector } from 'com.batch.redux/target/target.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { EstimateProfileChannelToolip } from 'com.batch/orchestration/ui/components/estimate-profile-channel-tooltip'
import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { LoadingStatus } from 'constants/common'

type RowProps = {
  segment: SegmentRecord
  editUrl: string
  index: number
}

export const Row = ({ segment, editUrl }: RowProps): React.ReactElement => {
  const dispatch = useDispatch()

  const project = useSelector(currentProjectSelector)
  const estimateGetter = useSelector(estimateProfileGetterSelector)
  const estimate = React.useMemo(
    () => estimateGetter(`segment-${segment.name}`),
    [estimateGetter, segment.name]
  )
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'custom-audiences:write'])

  const user = useSelector(currentUserSelector)
  const userIsAdmin = user.permissionsForCurrentCompany.has('administrate')

  React.useEffect(() => {
    if (estimate.loading === LoadingStatus.INIT) {
      dispatch(fetchEstimateForSegmentName({ segment }))
    }
  }, [dispatch, estimate.loading, segment, segment.name])
  const automationsUrl = React.useMemo(() => {
    const url = generateOrchestrationListUrl({
      companyId: project.companyId,
      projectId: project.id,
      scheduling: 'automations',
      channel: 'email',
    })
    return `${url}?segments=${segment.name}`
  }, [project, segment])

  const campaignsUrl = React.useMemo(() => {
    const url = generateOrchestrationListUrl({
      companyId: project.companyId,
      projectId: project.id,
      scheduling: 'campaigns',
      channel: 'email',
    })
    return `${url}?segments=${segment.name}`
  }, [project, segment])

  const orchestrationCount = React.useMemo(
    () => segment.campaignCount + segment.automationCount,
    [segment]
  )

  const runningOrchestrationCount = React.useMemo(
    () => segment.campaignRunningCount + segment.automationRunningCount,
    [segment]
  )

  return (
    <TableRow>
      <TableCell>
        <Ellipsis style={{ whiteSpace: 'nowrap' }} title={segment.displayName}>
          {segment.displayName}
        </Ellipsis>
      </TableCell>
      <TableCell>
        <EstimateProfileChannelToolip estimate={estimate} channel={undefined} forceNonOptin>
          <span className="styled-matching">
            {estimate.matching.reachableProfileCount === 0 ? (
              <React.Fragment>-</React.Fragment>
            ) : (
              kformat(estimate.matching.reachableProfileCount)
            )}
          </span>
        </EstimateProfileChannelToolip>
      </TableCell>
      <TableCell>
        <Ellipsis style={{ whiteSpace: 'pre' }} title={segment.displayName}>
          {orchestrationCount === 0 ? (
            <React.Fragment>-</React.Fragment>
          ) : (
            kformat(orchestrationCount)
          )}
        </Ellipsis>
      </TableCell>

      <TableCellActions>
        <PermissionButton
          kind="inline"
          intent="neutral"
          to={editUrl}
          isAllowed={userHasWritePermission}
        >
          <Icon icon="edit" />
        </PermissionButton>
        <DropdownSegment
          segment={segment}
          hasDeletePermission={userHasWritePermission}
          automationsUrl={automationsUrl}
          campaignsUrl={campaignsUrl}
          runningOrchestrationCount={runningOrchestrationCount}
          userIsAdmin={userIsAdmin}
        />
      </TableCellActions>
    </TableRow>
  )
}
