import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { Wrapper, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Content } from 'components/styled/blocs'
import { Title } from 'components/styled/text'

import { SenderIdentityList } from './sender-identity/sender-identity-list'
import { SenderIdentitySelectedItem } from './sender-identity/sender-identity-selected-item'
import {
  SenderIdentityGrid,
  SenderIdentityContainer,
} from './sender-identity/sender-identity.styles'

import {
  senderIdentitiesSelector,
  senderIdentityLoadingStateSelector,
  creatingSenderIdentitySelector,
} from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { fetchEspConfiguration } from 'com.batch.redux/corelogic/usecases/project/esp-configuration/fetch-esp-configuration'
import { countBySenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/count-orchestrations-using-sender-identity'
import { fetchSenderIdentities } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { initCreatingSenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/select-sender-identities'

import { LoadingStatus } from 'constants/common'

type SettingsEmailProps = Record<any, any>

export const SettingsEmail: React.ComponentType<SettingsEmailProps> = React.memo(() => {
  const dispatch = useDispatch()
  const senderIdentities = useSelector(senderIdentitiesSelector)
  const senderIdentityLoadingState = useSelector(senderIdentityLoadingStateSelector)
  const creating = useSelector(creatingSenderIdentitySelector)

  const onCreate = React.useCallback(() => {
    dispatch(initCreatingSenderIdentity())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(fetchSenderIdentities())
      .then(identities => {
        const first = identities.first()
        if (first) {
          dispatch(countBySenderIdentity(first.id)).catch(e => console.error(e))
        }
      })
      .catch(e => console.error(e))
    dispatch(fetchEspConfiguration()).catch(e => console.error(e))
  }, [dispatch])

  const isLoading = React.useMemo(
    () => senderIdentityLoadingState === LoadingStatus.LOADING,
    [senderIdentityLoadingState]
  )

  const isError = React.useMemo(
    () => senderIdentityLoadingState === LoadingStatus.ERROR,
    [senderIdentityLoadingState]
  )

  const isEmptyAfterLoading = React.useMemo(
    () => senderIdentities.size === 0 && senderIdentityLoadingState === LoadingStatus.LOADED,
    [senderIdentities, senderIdentityLoadingState]
  )

  return (
    <Content top>
      <Title overEmptyState>Email Settings</Title>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Sender information" />
          <HeaderBoxActions>
            <Button kind="inline" addOn="suffix" onClick={onCreate} disabled={creating}>
              <Icon icon="add" />
              Create sender
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Wrapper
            isLoading={isLoading}
            isEmpty={(isEmptyAfterLoading && !creating) || isError}
            isOverlayShown={(isEmptyAfterLoading && !creating) || isError}
            overlayProps={
              isError
                ? GlobalErrorOverlayProps
                : {
                    status: 'empty',
                    title: 'No sender configured yet.',
                    content: (
                      <Button kind="inline" addOn="suffix" onClick={onCreate}>
                        <Icon icon="add" />
                        Add my first sender now !
                      </Button>
                    ),
                  }
            }
          >
            <SenderIdentityGrid template="1fr 1fr" alignItems="stretch">
              <SenderIdentityList items={senderIdentities} />
              <SenderIdentityContainer>
                <SenderIdentitySelectedItem />
              </SenderIdentityContainer>
            </SenderIdentityGrid>
          </Wrapper>
        </BoxBody>
      </Box>
    </Content>
  )
})
