import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import Highlight from 'components/common/highlight'
import Loader from 'components/common/loader-legacy'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { generateUrl } from 'com.batch.common/router'

import Condition from './conditions/rsr-condition'
import Logical from './conditions/rsr-logical'
import Estimate from './estimate-legacy'
import { TargetingSeparator } from './rsr-targeting.styles'
import { Item } from './rsr.styles'

import {
  CampaignFactory,
  type AppRecord,
  type CampaignRecord,
  type State,
} from 'com.batch.redux/_records'
import { fetchAttributesAndValues } from 'com.batch.redux/attribute'
import { fetchCampaign } from 'com.batch.redux/campaign.action'
import { rawEstimateSelector, fullTreeAsRecordSelector } from 'com.batch.redux/targeting'
import { type QbConditionRecord, type QbLogicalRecord } from 'com.batch.redux/targeting.records'
import {
  clustersTargeting,
  reviewLanguagesSelector,
  reviewRegionsSelector,
  customAudiencesSelector,
} from 'com.batch.redux/targeting.selector.composed'
import { LoadingStatus } from 'constants/common'

type RSRTargetingProps = {
  campaign: CampaignRecord
  app: AppRecord
}

const attributeSelector = (state: State) => state.attribute.config.attributeLoadingState
const campaignsSelector = (state: State) => state.campaign.entities
const targetingSelector = (state: State) => state.targeting
const doNothing = () => {}
export const RSRTargeting = ({ campaign, app }: RSRTargetingProps): React.ReactElement => {
  const [jsonView, setJsonView] = React.useState(false)

  //  --- REDUX ------
  const dispatch = useDispatch()
  const attributeLoadingState = useSelector(attributeSelector)
  const clusters = useSelector(clustersTargeting)
  const audiences = useSelector(customAudiencesSelector)
  const languages = useSelector(reviewLanguagesSelector)
  const regions = useSelector(reviewRegionsSelector)
  const estimate = useSelector(rawEstimateSelector)
  const root = useSelector(fullTreeAsRecordSelector)
  const campaigns = useSelector(campaignsSelector)
  const targeting = useSelector(targetingSelector)

  const loading = targeting.get('awaitingParse', false) || targeting.get('loading', false)
  const query = targeting.get('query') ? JSON.stringify(targeting.get('query', ''), null, 2) : ''

  React.useEffect(() => {
    if (attributeLoadingState === LoadingStatus.INIT) {
      dispatch(
        fetchAttributesAndValues({
          app: app,
          withEventDays: false,
          devMode: false,
        })
      )
    }
  }, [dispatch, app, attributeLoadingState])

  const getCampaignName = React.useCallback(
    (token: string): string => {
      if (!token) {
        return ''
      }
      const campaign = campaigns.get(token, CampaignFactory())
      if (campaign._id === 'new' && !campaign.loading) {
        dispatch(
          fetchCampaign({
            token,
            withMacro: false,
            withEstimate: false,
            usedByRetargeting: true,
            redirectOnFail: false,
          })
        )
      }
      return campaign.name !== '' ? campaign.name : token
    },
    [campaigns, dispatch]
  )
  const condition: QbConditionRecord | null | undefined = root && !root.isLogical ? root : null
  const logical: QbLogicalRecord | null | undefined = root && root.isLogical ? root : null
  const completed = campaign.state === 'COMPLETED'

  const closeJsonView = React.useCallback(() => {
    setJsonView(false)
  }, [])
  const openJsonView = React.useCallback(() => {
    setJsonView(true)
  }, [])

  return (
    <React.Fragment>
      <Popin opened={jsonView} close={closeJsonView}>
        <Box style={{ width: 680 }}>
          <BoxHeader>
            <HeaderBoxTitle title="Targeting query" />
            <HeaderBoxActions>
              <Button onClick={closeJsonView}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody style={{ overflow: 'auto', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Highlight language="js" style={{ maxHeight: 'auto' }}>
              {query}
            </Highlight>
          </BoxBody>
        </Box>
      </Popin>

      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Targeting" />
          <HeaderBoxActions>
            {query ? (
              <Button onClick={openJsonView}>
                <Icon icon="code" />
              </Button>
            ) : null}
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Loader overlay loading={loading}>
            <TargetingSeparator>
              {(completed ? 'Has been sent' : 'Will be sent') + ' to users who ...'}
            </TargetingSeparator>
            <Item name={completed ? 'Were' : 'Are'} icon="smart-segments">
              <div style={{ margin: '0 0 0 -2px' }}>
                {clusters
                  .filter(c => c.active)
                  .map(cluster => {
                    const className = `targeting targeting--cluster targeting--cluster--${cluster.code}`
                    return (
                      <span className={className} key={cluster.code}>
                        {cluster.name}
                      </span>
                    )
                  })}
              </div>
            </Item>
            <Item
              icon="location"
              name={` ${
                regions.invert
                  ? completed
                    ? 'Did not live in'
                    : 'Does not live in'
                  : completed
                    ? 'Lived in'
                    : 'Lives in'
              }`}
              borderTop
            >
              {regions.data.size === 0
                ? 'Any country'
                : regions.data.map(region => {
                    return (
                      <span className="targeting" key={region.value}>
                        <img
                          src={`/medias/img/flags/${region.value.toLowerCase()}.png`}
                          alt={`Flag of ${region.get('label')}`}
                        />
                        &nbsp;{region.label}
                      </span>
                    )
                  })}
            </Item>
            <Item
              icon="message"
              name={` ${
                languages.invert
                  ? completed
                    ? 'Did not speak'
                    : 'Does not speak'
                  : completed
                    ? 'Spoke'
                    : 'Speak'
              }`}
              borderTop
            >
              {languages.data.size === 0
                ? 'Any language'
                : languages.data.map(lang => {
                    return (
                      <span className="targeting" key={lang.value}>
                        {lang.label}
                      </span>
                    )
                  })}
            </Item>
            {audiences.size > 0 && (
              <Item icon="team" name="Audiences" borderTop>
                <ul>
                  {audiences.map(audience => {
                    return (
                      <Tooltip
                        tooltip={
                          <div>
                            <strong>
                              {audience.type === 'custom_ids'
                                ? 'Custom IDs'
                                : audience.type === 'install_ids'
                                  ? 'Install IDs'
                                  : 'Advertising IDs'}
                            </strong>{' '}
                            {audience.name}
                            {audience.deleted && (
                              <span
                                style={{
                                  background: 'tomato',
                                  display: 'inline-block',
                                  borderRadius: '4px',
                                  padding: '2px 5px',
                                }}
                              >
                                DELETED
                              </span>
                            )}
                          </div>
                        }
                        key={audience.name}
                      >
                        <li>
                          <a
                            href={generateUrl('app_settings', {
                              companyId: app.companyId,
                              appId: app.id,
                              activeTab: 'audiences',
                            })}
                            target="_blank"
                            style={{
                              textDecoration: audience.deleted ? 'line-through' : 'none',
                            }}
                          >
                            {audience.description || audience.name}
                          </a>
                        </li>
                      </Tooltip>
                    )
                  })}
                </ul>
              </Item>
            )}

            {!loading && ((logical && logical.children.size > 0) || condition) && (
              <div>
                <TargetingSeparator borderTop>
                  {completed
                    ? 'And matched the following conditions:'
                    : 'And match the following conditions:'}
                </TargetingSeparator>
                {logical && <Logical logical={logical} root getCampaignName={getCampaignName} />}
                {condition && (
                  <Condition condition={condition} getCampaignName={getCampaignName} last={true} />
                )}
              </div>
            )}
            {campaign.inappJustInTime && (
              <Item name="eligibility" icon="check" borderTop>
                Re-evaluate campaign eligibility
              </Item>
            )}
          </Loader>
        </BoxBody>
        {campaign.state === 'RUNNING' ? (
          <BoxFooter isEditable style={{ height: 76, padding: '0 20px' }}>
            <Estimate
              hideLoader={loading}
              estimate={estimate}
              tokenMode={campaign.type.toLowerCase() === 'push'}
              refreshEstimate={doNothing}
            />
          </BoxFooter>
        ) : null}
      </Box>
    </React.Fragment>
  )
}
