import * as React from 'react'

import { type InputProps } from './helper'

import { SelectMulti } from 'components/form'
import Immutable from 'immutable'
import { useSelector } from 'react-redux'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { ClickedMessage, OpenedMessage, SentMessage } from 'com.batch.redux/attribute.api'

type ChannelOption = { label: string; value: ChannelUntilCleanup; visible: boolean }

const optionToString = (option?: ChannelOption) => option?.label ?? ''

export const InputChannel = ({
  condition,
  updateCondition,
  parentCondition,
}: InputProps): React.ReactElement => {
  const project = useSelector(currentProjectSelector)

  const CHANNEL_OPTIONS: Immutable.List<ChannelOption> = React.useMemo(
    () =>
      Immutable.List([
        {
          label: 'Email',
          value: 'email',
          visible:
            project.emailConfigured &&
            [ClickedMessage.id, SentMessage.id, OpenedMessage.id].includes(
              parentCondition?.attribute?.api ?? ''
            ),
        },
        {
          label: 'SMS',
          value: 'sms',
          visible:
            project.smsConfigured &&
            [SentMessage.id].includes(parentCondition?.attribute?.api ?? ''),
        },
      ]),
    [parentCondition, project]
  )

  const handleOnChange = React.useCallback(
    (selectedChannels: Immutable.List<ChannelOption>) => {
      if (selectedChannels) {
        updateCondition(
          condition.setIn(
            ['value', 'retargetedOrchestration', 'channels'],
            selectedChannels.map(channelOption => channelOption.value)
          )
        )
      }
    },
    [condition, updateCondition]
  )

  return (
    <SelectMulti
      options={CHANNEL_OPTIONS.filter(option => option.visible)}
      placeholder="Select channels"
      value={CHANNEL_OPTIONS.filter(option =>
        condition.value.retargetedOrchestration.channels.includes(option.value)
      )}
      onChange={handleOnChange}
      optionToString={optionToString}
      noOptionText="No channels available."
      noResultText="No channels correspond to this search."
      style={{ width: '300px' }}
    />
  )
}
