import Immutable from 'immutable'

import {
  type ProfileStateRecord,
  ProfileStateFactory,
  ProfileDataFactory,
  type InstallRecord,
  type ProfileDataRecord,
} from 'com.batch/profile/models/profile.records'
import { type refreshRecipientAction } from 'com.batch/profile/usecases/refresh-platform'
import { type ProfileResetAction } from 'com.batch/profile/usecases/reset-store-profile'
import { type searchProfileIdByIdentifierAction } from 'com.batch/profile/usecases/search-profile-by-identifier'
import { type switchProfileAction } from 'com.batch/profile/usecases/switch-profile'
import { LoadingStatus } from 'constants/common'

const initialState = ProfileStateFactory()

type ProfileAction =
  | searchProfileIdByIdentifierAction
  | ProfileResetAction
  | switchProfileAction
  | refreshRecipientAction

export function profileReducer(
  state: ProfileStateRecord = initialState,
  action: ProfileAction
): ProfileStateRecord {
  switch (action.type) {
    case 'REFRESH_RECIPIENT':
      return state
    case 'REFRESH_RECIPIENT_SUCCESS': {
      const profile = state.data.get(state.profileId)

      if (profile) {
        return state.set(
          'data',
          state.data.set(
            state.profileId,
            profile?.set(
              'platforms',
              profile?.platforms.set(
                'mobile',
                profile?.platforms.mobile.map((install: InstallRecord) => {
                  if (install.installID === action.payload.installId) {
                    return install
                      .set('subscriptionStatus', action.payload.subscriptionStatus)
                      .set('notifType', action.payload.notifType)
                  }
                  return install
                })
              )
            )
          )
        )
      }
      return state
    }
    case 'REFRESH_RECIPIENT_FAILURE':
      return state
    case 'SEARCH_PROFILE_ID_BY_IDENTIFIER':
      return state.set('loadingState', LoadingStatus.LOADING)
    case 'SEARCH_PROFILE_ID_BY_IDENTIFIER_SUCCESS':
      return state
        .set('loadingState', LoadingStatus.LOADED)
        .set('profileId', action.payload.profileId ?? '')
        .set('data', action.payload.data)
    case 'SEARCH_PROFILE_ID_BY_IDENTIFIER_FAILURE':
      return state.set('loadingState', LoadingStatus.ERROR)
    case 'PROFILE_RESET':
      return state
        .set(
          'data',
          Immutable.OrderedMap<string, ProfileDataRecord>().set('', ProfileDataFactory())
        )
        .set('profileId', '')
        .set('loadingState', LoadingStatus.INIT)
    case 'SWITCH_PROFILE':
      return state.set('profileId', action.payload.profileId ?? '').set('data', action.payload.data)
    case 'SWITCH_PROFILE_SUCCESS':
      return state.set('data', action.payload.data)
    case 'SWITCH_PROFILE_FAILURE':
      return state.set('data', action.payload.data)
    default:
      return state
  }
}
