import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useSearchParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, UserbaseEmptyIcon, Wrapper } from 'components/common/empty-states'
import { LinkDoc } from 'components/styled/text'

import { ProfilebasMetricsGrid } from './profilebase.syles'

import { channelDistributionSelector } from 'com.batch/profilebase/store/channels-distribution.selector'
import { channelLastMonthReachSelector } from 'com.batch/profilebase/store/channels-reachability.selector'
import {
  profilebaseIsLoadedSelector,
  profilebaseHasErrorSelector,
} from 'com.batch/profilebase/store/profilebase.selector'
import { profileDistributionSelector } from 'com.batch/profilebase/store/profiles-distribution.selector'
import { importedTokensFeatureSelector } from 'com.batch.redux/company.selector'

import { mergeLoadingState } from 'com.batch/profilebase/infra/formats/merge-loading-states'
import { ChannelOverview } from 'com.batch/profilebase/ui/components/channel-overview/channel-overview'
import { ChannelsMetrics } from 'com.batch/profilebase/ui/components/channels-metrics/channels-metrics'
import { ProfilesMetrics } from 'com.batch/profilebase/ui/components/profiles-metrics/profiles-metrics'
import { fetchProfilesDistribution } from 'com.batch/profilebase/usecases/fetch-profiles-distribution'
import { fetchReachabilityTimeserie } from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import {
  fetchEmailSubDistribution,
  fetchSmsSubDistribution,
  fetchPushSubDistribution,
  fetchWebpushSubDistribution,
} from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { PUSH_PLATFORM_FILTER } from 'com.batch/shared/constants/push-platform-filter.constants'
import { PushPlatformFilter } from 'com.batch/shared/ui/component/filter/push-platform-filter/push-platform-filter'
import { LoadingStatus } from 'constants/common'

export const Profilebase = (): React.ReactElement => {
  const dispatch = useDispatch()

  const [search] = useSearchParams()
  const platformFilter = React.useMemo(
    () =>
      PUSH_PLATFORM_FILTER.find(f => f.filter === search.get('push')) ?? PUSH_PLATFORM_FILTER[0],
    [search]
  )

  const importedTokensFeature = useSelector(importedTokensFeatureSelector)

  const profilebaseIsLoaded = useSelector(profilebaseIsLoadedSelector)
  const hasErrors = useSelector(profilebaseHasErrorSelector)

  const profileDistribution = useSelector(profileDistributionSelector)
  const emailDistribution = useSelector(channelDistributionSelector('email'))
  const emailReach = useSelector(channelLastMonthReachSelector('email'))
  const smsDistribution = useSelector(channelDistributionSelector('sms'))
  const smsReach = useSelector(channelLastMonthReachSelector('sms'))
  const pushDistribution = useSelector(
    channelDistributionSelector(platformFilter.filter === 'webpush' ? 'webpush' : 'push')
  )
  const pushReach = useSelector(
    channelLastMonthReachSelector(platformFilter.filter === 'webpush' ? 'webpush' : 'push')
  )

  const noProfiles = React.useMemo(
    () =>
      profileDistribution.loadingState === LoadingStatus.LOADED &&
      profileDistribution.distribution.total === 0,
    [profileDistribution.loadingState, profileDistribution.distribution.total]
  )

  const [pageLoadingState, setPageLoadingState] = React.useState<LoadingStatus>(LoadingStatus.INIT)

  React.useEffect(() => {
    setPageLoadingState(LoadingStatus.LOADING)
    dispatch(fetchProfilesDistribution()).catch(error => {
      console.error('Error while fetching profiles distribution:', error)
    })
    dispatch(fetchEmailSubDistribution('marketing')).catch(error => {
      console.error('Error while fetching subscribed email distribution:', error)
    })
    dispatch(fetchSmsSubDistribution('marketing')).catch(error => {
      console.error('Error while fetching subscribed email distribution:', error)
    })
    dispatch(fetchReachabilityTimeserie('EMAIL')).catch(error => {
      console.error('Error while fetching email reachability timeserie:', error)
    })
    dispatch(fetchReachabilityTimeserie('SMS')).catch(error => {
      console.error('Error while fetching sms reachability timeserie:', error)
    })
  }, [dispatch])

  React.useEffect(() => {
    if (platformFilter.filter !== 'webpush') {
      dispatch(fetchPushSubDistribution(platformFilter.value)).catch(error => {
        console.error('Error while fetching optin push distribution:', error)
      })
      dispatch(fetchReachabilityTimeserie('PUSH', platformFilter.value)).catch(error => {
        console.error('Error while fetching push reachability timeserie:', error)
      })
    }
    if (platformFilter.filter === 'webpush') {
      dispatch(fetchWebpushSubDistribution()).catch(error => {
        console.error('Error while fetching optin webpush distribution:', error)
      })
      dispatch(fetchReachabilityTimeserie('WEBPUSH')).catch(error => {
        console.error('Error while fetching push reachability timeserie:', error)
      })
    }
  }, [dispatch, platformFilter.value, platformFilter.filter])

  React.useEffect(() => {
    if (hasErrors) setPageLoadingState(LoadingStatus.ERROR)
    else if (pageLoadingState === LoadingStatus.LOADING && profilebaseIsLoaded)
      setPageLoadingState(LoadingStatus.LOADED)
  }, [hasErrors, profilebaseIsLoaded, pageLoadingState])

  const pushLoadingState = React.useMemo(
    () =>
      mergeLoadingState([pageLoadingState, pushReach.loadingState, pushDistribution.loadingState]),
    [pageLoadingState, pushReach.loadingState, pushDistribution.loadingState]
  )
  const emailLoadingState = React.useMemo(
    () =>
      mergeLoadingState([
        pageLoadingState,
        emailReach.loadingState,
        emailDistribution.loadingState,
      ]),
    [pageLoadingState, emailReach.loadingState, emailDistribution.loadingState]
  )
  const smsLoadingState = React.useMemo(
    () =>
      mergeLoadingState([pageLoadingState, smsReach.loadingState, smsDistribution.loadingState]),
    [pageLoadingState, smsReach.loadingState, smsDistribution.loadingState]
  )

  return (
    <Wrapper
      isEmpty={hasErrors || noProfiles}
      isLoading={pageLoadingState === LoadingStatus.LOADING}
      isOverlayShown={hasErrors || (pageLoadingState === LoadingStatus.LOADED && noProfiles)}
      overlayProps={
        hasErrors
          ? GlobalErrorOverlayProps
          : {
              status: 'empty-page',
              title: 'No data available',
              description:
                'We haven’t been able to find any data yet. Please check if your SDK is up and running to fill your userbase.',
              content: <UserbaseEmptyIcon />,
              links: [
                {
                  name: 'Troubleshooting',
                  href: 'https://doc.batch.com/dashboard/analytics/troubleshooting',
                },
                {
                  name: 'Install the SDK',
                  href: 'https://doc.batch.com/download/',
                },
              ],
            }
      }
    >
      <ProfilebasMetricsGrid gap={16} template="330px minmax(457px, 1fr)" alignItems="start">
        <ProfilesMetrics
          total={profileDistribution.distribution.total}
          identified={profileDistribution.distribution.customId}
        />
        <ChannelsMetrics
          email={profileDistribution.distribution.email}
          push={profileDistribution.distribution.push}
          sms={profileDistribution.distribution.phoneNumber}
          webpush={profileDistribution.distribution.webpush}
        />
      </ProfilebasMetricsGrid>
      <ChannelOverview
        channel="push"
        filter={<PushPlatformFilter />}
        importedTokens={
          importedTokensFeature && platformFilter.filter !== 'webpush'
            ? profileDistribution.distribution.importedTokens
            : null
        }
        loadingState={pushLoadingState}
        platformFilter={platformFilter}
        subsByDay={pushReach.subsReach}
        subsCounter={pushReach.subsCounter}
        subsDistribution={pushDistribution.distribution}
      />
      <ChannelOverview
        channel="email"
        link={
          <LinkDoc
            intent="action"
            href="https://help.batch.com/en/articles/8390553-understanding-your-email-reach"
            target="_blank"
          >
            Understand Email on profile
          </LinkDoc>
        }
        loadingState={emailLoadingState}
        subsByDay={emailReach.subsReach}
        subsCounter={emailReach.subsCounter}
        subsDistribution={emailDistribution.distribution}
      />
      <ChannelOverview
        channel="sms"
        loadingState={smsLoadingState}
        subsByDay={smsReach.subsReach}
        subsCounter={smsReach.subsCounter}
        subsDistribution={smsDistribution.distribution}
      />
    </Wrapper>
  )
}
