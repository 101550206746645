/**
 * Goal : take a conditon with an attribute defined, then allow to define function / operator
 *        and offer an adequate InputType
 */

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Select } from 'components/form'
import { colors } from 'components/styled/tokens'

import { ConditionEventFilter } from './condition-event-filter'
import * as Inputs from './input'
import { LeftBase } from './left/left-base'
import { QueryBuilderContext, type QueryBuilderContextProps } from './query-builder.context'
import {
  ConditionContainer,
  ConditionPart,
  ConditionPartAction,
  ConditionPartAttribute,
} from './query.styles'

import { actions, api } from 'com.batch.redux/query/query'
import {
  ConditionFactory,
  type ConditionRecord,
  type QueryAttributeRecord,
} from 'com.batch.redux/query/query.records'
import { LeftArray } from './left/left-array'

const attributeToString = (attr: any) => attr?.label ?? ''

const attributeFormatter = (attribute?: QueryAttributeRecord | null) =>
  !attribute ? null : (
    <Grid template="auto 1fr">
      <Icon
        icon={attribute.icon}
        thickness={1.2}
        style={{ color: colors.textLight, marginTop: -1, marginLeft: 3 }}
      />
      <span style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {attribute.label}
      </span>
    </Grid>
  )

type ConditionProps = {
  condition: ConditionRecord
  setTouched: () => void
  withSeparator?: boolean
  attributes: List<QueryAttributeRecord>
  id: string
  queryId: string
  parentIsLogicalNot: boolean
  disabledMode?: boolean
}

const REFINE_ATTRIBUTE_MARKETING_ONLY = 'Marketing'
const REFINE_ATTRIBUTE_ALL = 'Marketing & transactional'

const refineAttributeSelectOptions = Immutable.List().push(
  ...[REFINE_ATTRIBUTE_MARKETING_ONLY, REFINE_ATTRIBUTE_ALL]
)
const optionToString = (opt?: string | null) => opt ?? ''

export const Condition = ({
  condition,
  setTouched,
  withSeparator,
  attributes,
  disabledMode,
  id,
  queryId,
  parentIsLogicalNot,
}: ConditionProps): React.ReactElement => {
  const dispatch = useDispatch()
  const context = React.useContext(QueryBuilderContext)
  const eventFilterContext: QueryBuilderContextProps = React.useMemo(
    () => ({ ...context, context: 'event_filter', eventId: condition.attribute?.api ?? '' }),
    [context, condition.attribute]
  )
  const removeCondition = React.useCallback(() => {
    setTouched()
    dispatch(actions.removeCondition({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])

  const updateCondition = React.useCallback(
    (condition: ConditionRecord) => {
      setTouched()
      dispatch(actions.updateCondition({ id, queryId, condition }))
    },
    [dispatch, id, queryId, setTouched]
  )
  const negate = React.useCallback(() => {
    setTouched()
    dispatch(actions.negate({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])

  const unNegate = React.useCallback(() => {
    setTouched()
    dispatch(actions.unNegate({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])
  const onAttributeChange = React.useCallback(
    attr => {
      if (attr) updateCondition(api.buildDefaultCondition(attr))
    },
    [updateCondition]
  )

  const onAddEventFilter = React.useCallback(() => {
    updateCondition(condition.set('eventFilters', condition.eventFilters.push(ConditionFactory())))
  }, [condition, updateCondition])

  const isInvalid = React.useMemo(() => context.errors.has(id), [context.errors, id])

  const baseProps = React.useMemo(
    () => ({
      condition,
      updateCondition: updateCondition,
      isInvalid,
      negated: parentIsLogicalNot,
      removeSelf: removeCondition,
      negate,
      unNegate,
      isDisabled: disabledMode,
    }),
    [
      condition,
      updateCondition,
      isInvalid,
      parentIsLogicalNot,
      removeCondition,
      negate,
      unNegate,
      disabledMode,
    ]
  )
  const ConditionOperandInput = Inputs[condition.value.mode]

  const refineMode = React.useMemo(() => {
    return condition.attribute?.api.includes('marketing')
      ? REFINE_ATTRIBUTE_MARKETING_ONLY
      : REFINE_ATTRIBUTE_ALL
  }, [condition.attribute?.api])

  const handleAttributeRefinementChange = React.useCallback(
    refineMode => {
      if (condition.attribute?.api) {
        const currentAttributeId = condition.attribute?.api
        const refinedAttributeId =
          refineMode === REFINE_ATTRIBUTE_ALL
            ? currentAttributeId?.replace('_marketing', '')
            : currentAttributeId === 'b.last_email_click'
              ? 'b.last_email_click_marketing'
              : 'b.last_email_marketing_open'
        const attr = attributes.find(a => a.api === refinedAttributeId)
        if (attr) {
          updateCondition(api.buildDefaultCondition(attr))
        }
      }
    },
    [condition.attribute?.api, updateCondition, attributes]
  )

  const showRefineAttributeSelect = React.useMemo(() => {
    if (!condition.attribute?.api) return false
    return [
      'b.last_email_open',
      'b.last_email_marketing_open',
      'b.last_email_click',
      'b.last_email_click_marketing',
    ].includes(condition.attribute?.api)
  }, [condition.attribute?.api])

  const attributesExcluded = React.useMemo(() => {
    return attributes
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
      .filter(
        attr =>
          !['b.last_email_open', 'b.last_email_click'].includes(attr.api) &&
          (!['b.is_email_optin', 'b.is_sms_optin'].includes(attr.api) ||
            queryId.startsWith('YESNO__'))
      )
  }, [attributes, queryId])

  const shouldShowAddEventFilterButton = React.useMemo(() => {
    return (
      condition.attribute?.type === 'EVENT' && condition.eventFilters.size === 0 && !disabledMode
    )
  }, [condition, disabledMode])

  const shouldDisableEventButton = React.useMemo(() => {
    return condition.attribute?.eventAttributes.size === 0
  }, [condition])

  return (
    <React.Fragment>
      <ConditionContainer
        withSeparator={withSeparator}
        hasEventFilter={condition.eventFilters.size > 0}
      >
        <ConditionPartAttribute style={{ flexGrow: !condition.attribute ? 1 : 0 }}>
          <Tooltip
            tooltip={
              <div
                style={{
                  fontSize: '14px',
                  textAlign: 'left',
                }}
              >
                {condition.attribute?.label}
              </div>
            }
            isTooltipEmpty={!condition.attribute || condition?.attribute?.label.length < 18}
            placement="top"
            arrow={false}
            delay={500}
          >
            <div>
              <Select
                isSearchable
                invalid={
                  (!condition.attribute || condition.attribute.api === '__UNSET__') && isInvalid
                }
                options={attributesExcluded}
                value={condition.attribute}
                placeholder="Pick an attribute"
                optionFormatter={attributeFormatter}
                menuOffset={40}
                optionToString={attributeToString}
                onChange={onAttributeChange}
              />
            </div>
          </Tooltip>
        </ConditionPartAttribute>
        {showRefineAttributeSelect && (
          <Select
            style={{ width: 190, minWidth: 190, marginRight: 10 }}
            value={refineMode}
            optionToString={optionToString}
            options={refineAttributeSelectOptions}
            onChange={handleAttributeRefinementChange}
          />
        )}
        {!!condition.attribute && (
          <React.Fragment>
            <ConditionPart>
              {condition.attribute.type === 'TAG' &&
              !['bt.custom_audiences', 'bt.segments'].includes(condition.attribute?.api) ? (
                <LeftArray {...baseProps} />
              ) : (
                <LeftBase {...baseProps} />
              )}
            </ConditionPart>
            <ConditionPart
              style={{
                flexGrow: 1,
                marginRight: !['bt.custom_audiences', 'bt.segments'].includes(
                  condition.attribute?.api
                )
                  ? 17
                  : -10,
              }}
            >
              <ConditionOperandInput {...baseProps} />
            </ConditionPart>
          </React.Fragment>
        )}
        {shouldShowAddEventFilterButton && (
          <Tooltip
            tooltip={
              shouldDisableEventButton ? (
                <div style={{ fontSize: 14 }}>No attributes exist for this event</div>
              ) : (
                <div style={{ fontSize: 14 }}>Add filter</div>
              )
            }
            minWidth={shouldDisableEventButton ? 220 : 100}
          >
            <ConditionPartAction>
              <Button
                kind="inline"
                intent="neutral"
                onClick={onAddEventFilter}
                disabled={shouldDisableEventButton}
                style={{ width: 36, marginRight: 4 }}
              >
                <Icon
                  icon="add-list"
                  size={14}
                  color={shouldDisableEventButton ? colors.textDisabled : colors.textLight}
                />
              </Button>
            </ConditionPartAction>
          </Tooltip>
        )}
        <ConditionPartAction>
          {!disabledMode &&
            !['bt.custom_audiences', 'bt.segments'].includes(condition.attribute?.api ?? '') && (
              <Button onClick={removeCondition} disabled={disabledMode} style={{ width: 36 }}>
                <Icon icon="remove" color={colors.textLight} size={16} />
              </Button>
            )}
        </ConditionPartAction>
      </ConditionContainer>
      {condition.eventFilters.size > 0 ? (
        <QueryBuilderContext.Provider value={eventFilterContext}>
          {condition.eventFilters.map((_, index) => (
            <ConditionEventFilter
              key={condition?.attribute?.api ?? '' + index}
              condition={condition}
              updateCondition={updateCondition}
              disabledMode={!!disabledMode}
              eventFilterIndex={index}
            />
          ))}
        </QueryBuilderContext.Provider>
      ) : null}
    </React.Fragment>
  )
}
