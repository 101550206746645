import { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Switch } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Input, InputWrapper, Radio } from 'components/form'

import { updatePushSettings } from '../usecases/update-push-settings'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { getPushSettingsSelector } from 'com.batch/push/store/push.selector'
import { type AgeRecord } from 'com.batch.redux/_records'

import {
  type PushSettingsAndroidOverrideRecord,
  type PushPriority,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'
import { formatPlatformList } from 'com.batch/orchestration/ui/components/platform-picker'
import { PushPayloadField } from 'com.batch/push/ui/push-payload-field'

type PushAdvancedSettingsProps = {
  stepMessageNodeId: string | null | undefined
}

export const PushAdvancedSettings: React.ComponentType<PushAdvancedSettingsProps> = React.memo(
  ({ stepMessageNodeId }) => {
    const dispatch = useDispatch()
    const getConfig = useSelector(getMessageConfigSelector)
    const config = React.useMemo(() => {
      return getConfig({ stepMessageNodeId })
    }, [getConfig, stepMessageNodeId])
    const getSettings = useSelector(getPushSettingsSelector)

    const [hasAndroid, hasIos] = React.useMemo(() => {
      return [config.platforms.has('android'), config.platforms.has('ios')]
    }, [config.platforms])

    const settings = React.useMemo(
      () => getSettings({ stepMessageNodeId }),
      [getSettings, stepMessageNodeId]
    )

    const onUpdatePushSettings = React.useCallback(
      (settings: PushSettingsRecord) => {
        dispatch(updatePushSettings({ stepMessageNodeId, settings }))
      },
      [dispatch, stepMessageNodeId]
    )

    const handlePriorityChange = React.useCallback(
      (value: PushPriority) => () => {
        onUpdatePushSettings(settings.set('priority', value))
      },
      [onUpdatePushSettings, settings]
    )

    const handleTtlToggle = React.useCallback(
      (bool: boolean) => {
        onUpdatePushSettings(settings.set('ttlEnabled', bool))
      },
      [onUpdatePushSettings, settings]
    )

    const handleTtlChange = React.useCallback(
      (ttl: AgeRecord) => {
        onUpdatePushSettings(settings.set('ttl', ttl))
      },
      [onUpdatePushSettings, settings]
    )

    const handleAndroidSettingsChange = React.useCallback(
      (field: keyof PushSettingsAndroidOverrideRecord) =>
        (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onUpdatePushSettings(settings.setIn(['androidOverride', field], e.currentTarget.value))
        },
      [onUpdatePushSettings, settings]
    )

    return (
      <div style={{ padding: 20 }}>
        <Grid template="1fr 1fr" alignItems="start" gap={40}>
          <div>
            <PrioritySection priority={settings.priority} onPriorityChange={handlePriorityChange} />
            {hasAndroid && (
              <AndroidSection
                androidOverride={settings.androidOverride}
                onAndroidSettingsChange={handleAndroidSettingsChange}
                platforms={config.platforms.delete('android')}
              />
            )}
            <ExpirationSection
              ttl={settings.ttl}
              hasTtl={settings.ttlEnabled}
              onTtlToggle={handleTtlToggle}
              onTtlChange={handleTtlChange}
            />
          </div>
          {(hasIos || hasAndroid) && (
            <PushPayloadField
              stepMessageNodeId={stepMessageNodeId}
              key={`push-payload-field-${stepMessageNodeId}`}
            />
          )}
        </Grid>
      </div>
    )
  }
)

const PrioritySection = ({
  priority,
  onPriorityChange,
}: {
  priority: PushPriority
  onPriorityChange: (p: PushPriority) => any
}) => (
  <InputWrapper
    label="Priority"
    style={{ marginBottom: 24 }}
    hintMinSize={400}
    hint={
      <div style={{ textAlign: 'left' }}>
        Default is high. Normal priority send the push message at a time that takes into account
        power considerations for the device. Notifications with this priority might be grouped and
        delivered in bursts. They are throttled, and in some cases are not delivered.
      </div>
    }
    id="radiogroup-label-priority"
  >
    <div role="radiogroup" aria-labelledby="radiogroup-label-priority">
      <Radio
        label="Normal"
        style={{ marginRight: 20 }}
        checked={priority === 'PUSH_PRIORITY_NORMAL'}
        onChange={onPriorityChange('PUSH_PRIORITY_NORMAL')}
      />
      <Radio
        label="High"
        checked={priority === 'PUSH_PRIORITY_HIGH'}
        onChange={onPriorityChange('PUSH_PRIORITY_HIGH')}
      />
    </div>
  </InputWrapper>
)

const AndroidSection = ({
  androidOverride,
  onAndroidSettingsChange,
  platforms,
}: {
  androidOverride: PushSettingsAndroidOverrideRecord
  onAndroidSettingsChange: (field: keyof PushSettingsAndroidOverrideRecord) => any
  platforms: Set<ProjectPlatforms>
}) => (
  <InputWrapper
    style={{ marginBottom: 24 }}
    hint={
      <div style={{ textAlign: 'left' }}>
        <p style={{ marginBottom: 8 }}>
          Defines how notifications are managed when an offline device goes online. If you specify
          keys, the device will only show the most recent notification.
        </p>
        <p style={{ marginBottom: 8 }}>
          If you don't specify keys, it will show all the notifications received when the device was
          offline.
        </p>
        <p style={{ marginBottom: 8 }}>
          You should not fill this field if all your notifications matter (E.g. messages, etc).
        </p>
        <p>
          You can use up to 3 different collapse keys if you want users to get only one notification
          of each kind when coming online (E.g. marketing, alert, etc).
        </p>
        {platforms.size > 0 && (
          <p style={{ marginTop: 8 }}>
            This setting does not apply to {formatPlatformList(platforms)}.
          </p>
        )}
      </div>
    }
    hintMinSize={400}
    label="FCM Collapse key"
  >
    <Input
      value={androidOverride.collapseKey}
      onChange={onAndroidSettingsChange('collapseKey')}
      placeholder="Default, marketing, ... (optional)"
      aria-label="FCM Collapse key"
    />
  </InputWrapper>
)

const ExpirationSection = ({
  ttl,
  hasTtl,
  onTtlToggle,
  onTtlChange,
}: {
  ttl: AgeRecord
  hasTtl: boolean
  onTtlToggle: (bool: boolean) => void
  onTtlChange: (bool: AgeRecord) => void
}) => (
  <InputWrapper
    hintMinSize={400}
    hint={
      <div style={{ textAlign: 'left' }}>
        By default, Batch will not set notifications to expire, which means that if the user's
        device is offline for 2 weeks and then goes back online, it will receive all notifications
        sent during this period. You can change this behavior here.
      </div>
    }
    label={
      <Switch isActive={hasTtl} onChange={onTtlToggle}>
        Expiration (TTL)
      </Switch>
    }
  >
    {hasTtl && (
      <TimeIntervalInput
        age={ttl}
        onChange={onTtlChange}
        style={{ width: 200 }}
        allowedUnits={['m', 'h', 'd']}
        min={60}
        max={3600 * 24 * 28}
      />
    )}
  </InputWrapper>
)

PushAdvancedSettings.displayName = 'PushAdvancedSettings'
