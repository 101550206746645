import { type List } from 'immutable'
import * as React from 'react'

import { type CommonSelectProps } from 'components/form/fields/select/select.helper'

import { FilterSelectMultiNoSearch } from './filter-select-multi-no-search'
import { FilterSelectMultiSearch } from './filter-select-multi-search'

export type BaseFilterMultiProps<T> = CommonSelectProps<T> & {
  onChange: (arg1: List<T>) => void
  options: List<T>
  showCheckbox?: boolean
  placeholder?: string
  style?: {
    [key: string]: string | number
  }
  toggleButtonWidth?: string | number
  term?: (arg1: number) => string
  value: List<T>
}
export type FilterSelectMultiProps<T> = BaseFilterMultiProps<T> & {
  isSearchable?: boolean
  searchMatchFunction?: (arg1: string, arg2: T) => boolean
}

export function FilterSelectMulti<T>({
  isSearchable = true,
  ...rest
}: FilterSelectMultiProps<T>): React.ReactElement {
  return isSearchable ? (
    <FilterSelectMultiSearch {...rest} />
  ) : (
    <FilterSelectMultiNoSearch {...rest} />
  )
}
