/* eslint-disable react/jsx-no-bind */
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useCurrentCompanyHasOneFeatureAmongst } from 'components/_hooks'
import { Banner } from 'components/common/banner'
import { Box, HeaderBoxActions, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Wrapper, GlobalErrorOverlayProps } from 'components/common/empty-states'
import Loader from 'components/common/loader-legacy'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableTemplateCell,
} from 'components/common/table'
import { LinkDoc } from 'components/styled/text'

import SettingsAndroid from './settings-android'
import { SettingsIOS } from './settings-ios'
import { SettingsWebPush } from './settings-webpush'
import SettingsWindows from './settings-windows'
import TestDevice from './test-device'

import {
  TestDeviceFactory,
  type PushConfigRecord,
  type TestDeviceRecord,
} from 'com.batch.redux/_records'
import { currentAppCanPush, currentAppSelector } from 'com.batch.redux/app'
import { checkWns, openIntegrate, savePushConfig, fetchApp } from 'com.batch.redux/app.action'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { deleteDevice, devicesSelector, saveDevice, sendTest } from 'com.batch.redux/testDevice'
import { showToast } from 'com.batch.redux/toaster'

import { LoadingStatus } from 'constants/common'

type PushSettingsProps = {
  readonly?: boolean
  advanced?: boolean
}

export const PushSettings: React.ComponentType<PushSettingsProps> = React.memo(
  ({ readonly, advanced }: PushSettingsProps) => {
    // ====================== STATE
    const [editLine, setEditLine] = React.useState<number | null>(null)
    const [sortField, setSortField] = React.useState<'name' | 'kind'>('name')
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'dsc'>('asc')

    // ====================== REDUX
    const dispatch = useDispatch()
    const app = useSelector(currentAppSelector)
    const devices = useSelector(devicesSelector)
    const canPush = useSelector(currentAppCanPush)
    const companyId = app.companyId
    const maybeProject = useSelector(optionalCurrentProjectSelector)
    const cepCompanyCanUseLegacyPush = useCurrentCompanyHasOneFeatureAmongst([
      'cep-show-legacy-trigger',
      'cep-show-legacy-recurring',
      'cep-show-legacy-campaign',
    ])

    const hasLegacyPush = React.useMemo(() => {
      return cepCompanyCanUseLegacyPush || !maybeProject
    }, [cepCompanyCanUseLegacyPush, maybeProject])

    // ====================== EMPTY STATE
    const isLoading =
      app.loadingState === LoadingStatus.LOADING || app.loadingState === LoadingStatus.INIT
    const hasError = app.loadingState === LoadingStatus.ERROR

    // ====================== DERIVED STATE
    const adv = React.useMemo(
      () => advanced && canPush && hasLegacyPush,
      [advanced, canPush, hasLegacyPush]
    )

    const isEmpty = devices.count() <= 0
    const allDevices = React.useMemo<List<TestDeviceRecord>>(
      () =>
        isEmpty
          ? Immutable.List<TestDeviceRecord>().push(
              TestDeviceFactory({ id: 0 }),
              TestDeviceFactory({ id: 1 })
            )
          : devices,
      [devices, isEmpty]
    )

    const devicesList = React.useMemo(
      () =>
        sortOrder === 'asc'
          ? allDevices.sortBy(f => f.get(sortField))
          : allDevices.sortBy(f => f.get(sortField)).reverse(),
      [sortOrder, sortField, allDevices]
    )

    React.useEffect(() => {
      if (app.id !== 0) dispatch(fetchApp(app.id))
    }, [app.id, dispatch])

    const sort = (field: 'name' | 'kind') => {
      if (field === sortField) {
        setSortOrder(sortOrder === 'asc' ? 'dsc' : 'asc')
      } else {
        setSortOrder('dsc')
        setSortField(field)
      }
    }

    const _savePushConfig = React.useCallback(
      (
        config: PushConfigRecord,
        file: File | null = null,
        password: string | null = null
      ): Promise<void> => {
        return dispatch(savePushConfig(app, config, file, password))
      },
      [app, dispatch]
    )

    const _sendTest = React.useCallback(
      (device: TestDeviceRecord) => {
        return dispatch(sendTest({ app: app, device: device, context: 'push settings' }))
      },
      [app, dispatch]
    )

    const _checkWns = React.useCallback(
      (packageId: string, secret: string) => {
        return dispatch(checkWns({ app: app, packageId, secret }))
      },
      [app, dispatch]
    )

    const renderPushSettings = React.useCallback(() => {
      if (readonly) {
        return app.validPushConfig ? (
          <Banner
            intent="limited"
            icon="check"
            content="This app has a valid push configuration. Your current credential level
            does not allow you to configure this app. Contact your manager if needed."
          />
        ) : (
          <Banner
            intent="danger"
            icon="danger"
            content="This app has an invalid push configuration. Your current credential
            level does not allow you to configure this app. Contact your manager if needed."
          />
        )
      }

      if (typeof app.pushConfig === 'undefined' || app.pushConfig === null) {
        return <h2>None</h2>
      }
      switch (app.platform) {
        case 'ios':
          return (
            <SettingsIOS
              advanced={!!advanced}
              app={app}
              config={app.pushConfig}
              savePushConfig={_savePushConfig}
              showToast={msg => dispatch(showToast(msg))}
            />
          )
        case 'android':
          return (
            <SettingsAndroid
              advanced={!!advanced}
              app={app}
              config={app.pushConfig}
              savePushConfig={_savePushConfig}
              showToast={msg => dispatch(showToast(msg))}
            />
          )
        case 'windows':
          return (
            <SettingsWindows
              checkWns={_checkWns}
              app={app}
              config={app.pushConfig}
              savePushConfig={_savePushConfig}
            />
          )
        case 'webpush':
          return (
            <SettingsWebPush
              advanced={!!advanced}
              app={app}
              openIntegrate={() => dispatch(openIntegrate())}
              config={app.pushConfig}
              webpushAuth={app.webpushAuth ? app.webpushAuth : ''}
              savePushConfig={_savePushConfig}
              showToast={msg => dispatch(showToast(msg))}
            />
          )
        default:
          return <Loader loading={true} />
      }
    }, [_savePushConfig, _checkWns, advanced, app, dispatch, readonly])

    return (
      <Wrapper
        overlayProps={GlobalErrorOverlayProps}
        isEmpty={hasError}
        isLoading={isLoading}
        isOverlayShown={hasError && !isLoading}
      >
        {renderPushSettings()}
        {adv && (
          <Box>
            <BoxHeader>
              <HeaderBoxTitle title="Test devices" />
              <HeaderBoxActions>
                {!isEmpty && (
                  <LinkDoc
                    href="https://doc.batch.com/dashboard/push/message-edition.html#_sending-a-test"
                    intent="action"
                    target="_blank"
                  >
                    Help
                  </LinkDoc>
                )}
              </HeaderBoxActions>
            </BoxHeader>
            <BoxBody>
              <Wrapper
                isEmpty={isEmpty}
                isLoading={isLoading}
                isOverlayShown={isEmpty && !isLoading}
                overlayProps={{
                  status: 'empty',
                  title: 'You don’t have any registered devices for now',
                  links: [
                    {
                      name: 'Learn how to add a new device',
                      href: 'https://doc.batch.com/dashboard/push/message-edition#sending-a-test',
                      isDocLink: true,
                    },
                  ],
                }}
                style={{ minHeight: '269px' }}
              >
                <Table template="minmax(280px, 1fr) 170px 1fr 129px" rowHeight={52}>
                  <TableHeader>
                    <TableCellOrder
                      sort={sortField === 'name' ? sortOrder : undefined}
                      onClick={() => sort('name')}
                    >
                      Name
                    </TableCellOrder>

                    <TableCellOrder
                      sort={sortField === 'kind' ? sortOrder : undefined}
                      onClick={() => sort('kind')}
                    >
                      Type
                    </TableCellOrder>
                    <TableCellHeader>Identifier</TableCellHeader>
                    <div />
                  </TableHeader>
                  <TableBody emptyTemplate={<TableEmptyTemplate />} templateSize={5}>
                    {companyId &&
                      devicesList.map((d: TestDeviceRecord, index: number) => (
                        <TestDevice
                          app={app}
                          companyId={companyId}
                          device={d}
                          isEditing={index === editLine}
                          onSave={device => dispatch(saveDevice(device))}
                          onSend={() => _sendTest(d)}
                          onDelete={device => dispatch(deleteDevice(device))}
                          key={`${d.id}-${d.updated ? d.updated.format('X') : '0'}`}
                          editLine={() => setEditLine(index)}
                          closeEditLine={() => setEditLine(null)}
                          showToast={msg => dispatch(showToast(msg))}
                        />
                      ))}
                  </TableBody>
                </Table>
              </Wrapper>
            </BoxBody>
          </Box>
        )}
      </Wrapper>
    )
  }
)

const TableEmptyTemplate = () => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
