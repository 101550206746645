import { useDispatch } from 'com.batch.common/react-redux'
import { authorizedChannelsForRetargetingEvent } from 'com.batch.redux/attribute.api'
import { type ConditionRecord } from 'com.batch.redux/query/query.records'
import { searchOrchestrationsList } from 'com.batch/orchestration-list/usecases/search-orchestration-list'
import Immutable from 'immutable'
import React from 'react'

export const useLoadAndFilterOrchestrationOptions = ({
  parentCondition,
  schedulingMode,
}: {
  parentCondition?: ConditionRecord
  schedulingMode?: schedulingType
}) => {
  const dispatch = useDispatch()

  const loadOrchestrationOptions = React.useCallback(
    async (query: string) => {
      try {
        const resp = await dispatch(
          searchOrchestrationsList({
            search: query,
            mode: schedulingMode ?? 'campaigns',
          })
        )
        const orchestrations = resp.entities
        if (parentCondition) {
          return orchestrations.filter(orchestration => {
            if (!parentCondition.attribute?.api) {
              return false
            }
            const authorizedChannelsForEvent = authorizedChannelsForRetargetingEvent.get(
              parentCondition.attribute.api
            )
            return (
              authorizedChannelsForEvent &&
              authorizedChannelsForEvent.filter(channel => orchestration.channels.includes(channel))
                .size > 0
            )
          })
        } else {
          return orchestrations
        }
      } catch {
        return Immutable.List()
      }
    },
    [dispatch, schedulingMode, parentCondition]
  )

  return loadOrchestrationOptions
}
