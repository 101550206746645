import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { buildAgeFromDuration } from 'com.batch.common/utils'

import { AgeFactory, TriggerConfigFactory } from 'com.batch.redux/_records'

import { type ListOrchestrationType } from 'com.batch/orchestration/infra/types/orchestration.types'
import {
  OneTimeFactory,
  type PartialOrchestrationRecord,
  PartialOrchestrationFactory,
  RecurrentFactory,
} from 'com.batch/orchestration-list/models/partial-orchestration.records'

/*
  ❌ attention : sur le listing (donc ici), les dates sont des timestamps (date string iso)
  sur le CRUD campaign et recurring, on a des dates au format 20231207110600
*/

export const parseOrchestrationToPartial = (
  orchestration: ListOrchestrationType
): PartialOrchestrationRecord => {
  const lockedJourney = orchestration.journey
  return PartialOrchestrationFactory({
    name: orchestration.name,
    state: orchestration.orchestrationState ?? 'DRAFT',
    createdByApi: orchestration.orchestrationSource !== 'DASHBOARD',
    token: orchestration.id,
    channels: Immutable.Set(
      Array.isArray(orchestration.orchestrationChannels)
        ? orchestration.orchestrationChannels.map(osChannel =>
            osChannel === 'EMAIL' ? 'email' : osChannel === 'PUSH' ? 'push' : 'sms'
          )
        : []
    ),
    incomplete: orchestration.incomplete,
    sendType: orchestration.recurrent
      ? 'recurring'
      : orchestration.journey
        ? 'trigger'
        : 'scheduled',
    pushPlatforms: Immutable.Set(
      (orchestration.pushPlatforms ?? [])
        .filter(backendPlatform => backendPlatform !== 'PUSH_PLATFORM_UNSPECIFIED')
        .map(backendPlatform => {
          switch (backendPlatform) {
            case 'PUSH_PLATFORM_IOS':
              return 'ios'
            case 'PUSH_PLATFORM_ANDROID':
              return 'android'
            case 'PUSH_PLATFORM_WEB':
              return 'webpush'
          }
        })
    ),
    routingMode: orchestration.recurrent || orchestration.journey ? 'automations' : 'campaigns',
    oneTime: orchestration.onetime
      ? OneTimeFactory({
          sendDate: dayjs.utc(orchestration.onetime.sendTime),
          localTimezoneDependant: !orchestration.onetime?.utc,
        })
      : undefined,
    recurring: orchestration.recurrent
      ? RecurrentFactory({
          start: orchestration.recurrent.firstSendTime
            ? dayjs.utc(orchestration.recurrent.firstSendTime)
            : null,
          end: orchestration.recurrent?.endTime ? dayjs.utc(orchestration.recurrent.endTime) : null,
          localTimezoneDependant: !orchestration?.recurrent?.utc,
          repeatUnit: orchestration.recurrent?.recurrencePeriod,
          repeatFrequency: orchestration.recurrent?.recurrenceFrequency,
        })
      : undefined,
    triggerConfig: lockedJourney
      ? TriggerConfigFactory({
          isHackForMultiStep: true,
          hasStart: Boolean(lockedJourney.startTime?.substr(0, 4) !== '1970'),
          start: dayjs.utc(lockedJourney.startTime),
          hasEnd: Boolean(lockedJourney.endTime?.substr(0, 4) !== '1970'),
          end: dayjs.utc(lockedJourney.endTime),
          enterEvent: lockedJourney.entryEvent,
          capping: lockedJourney.capping ?? 0,
          hasCapping: !!lockedJourney.capping,
          hasGrace: lockedJourney.gracePeriod !== '0s',
          hasExitEvent: false,
          grace: lockedJourney.gracePeriod
            ? buildAgeFromDuration(lockedJourney.gracePeriod, ['h', 'd'])
            : AgeFactory(),
        })
      : undefined,
  })
}
