import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf, type List, type Map } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

import * as Functions from './query.records.functions'
import { type FunctionRecord } from './query.records.functions'
import * as Operators from './query.records.operators'
import { type OperatorRecord } from './query.records.operators'
import { type OursQLType } from './query.types'

import { type AgeRecord, AgeFactory } from 'com.batch.redux/_records'

export type { FunctionRecord } from './query.records.functions'
export type { OperatorRecord } from './query.records.operators'
/* 
  we create a subtype of AttributeRecord, because we need both data and event data :
  - event data for trigger campaign event filtering
  - native / custom / user data for base query trageting
*/

export const allFunctions: List<FunctionRecord> = Immutable.List([
  Functions.AgeFunction,
  Functions.DueFunction,
  Functions.DateFunction,
  Functions.NextBirthdayFunction,
  Functions.LastBirthdayFunction,
  Functions.IsNearFunction,
  Functions.CountFunction,
  Functions.CountSinceFunction,
])

export const allOperators: List<OperatorRecord> = Immutable.List([
  Operators.ExistsOperator,
  Operators.LowerOperator,
  Operators.LowerOrEqualOperator,
  Operators.EqualOperator,
  Operators.GreaterOrEqualOperator,
  Operators.GreaterOperator,
  Operators.AgeLowerOperator,
  Operators.AgeLowerOrEqualOperator,
  Operators.AgeEqualOperator,
  Operators.AgeGreaterOrEqualOperator,
  Operators.AgeGreaterOperator,
  Operators.DateLowerOperator,
  Operators.DateLowerOrEqualOperator,
  Operators.DateGreaterOrEqualOperator,
  Operators.DateGreaterOperator,
  Operators.StartsWithOperator,
  Operators.EndsWithOperator,
  Operators.InOperator,
  Operators.NotInOperator,
  Operators.ContainsOperator,
  Operators.ContainsAllOperator,
  Operators.DoesNotContainSomeOperator,
  Operators.ContainsNoneOfOperator,
])

type QueryAttributeProps = {
  api: string
  label: string
  icon: availableIcons
  type: OursQLType
  eventAttributes: List<QueryAttributeRecord>
}
export const QueryAttributeFactory = Immutable.Record<QueryAttributeProps>({
  api: '__UNSET__',
  label: 'string',
  icon: 'default',
  type: 'STRING',
  eventAttributes: Immutable.List(),
} as QueryAttributeProps)

export type QueryAttributeRecord = RecordOf<QueryAttributeProps>

export type InputType =
  | 'InputAge'
  | 'InputBoolean'
  | 'InputDate'
  | 'InputGoogleMap'
  | 'InputFloat'
  | 'InputInteger'
  | 'InputString'
  | 'InputDeviceList'
  | 'InputStringList'
  | 'InputPrettyList'
  | 'InputAudience'
  | 'InputSegment'
  | 'InputOrchestration'
  | 'InputChannel'

export type DateInputProps = {
  inputValue: string
  hourValue: string
  minuteValue: string
  value: Dayjs | null
  valid: boolean
}

export const DateInputFactory = Immutable.Record<DateInputProps>({
  inputValue: '',
  hourValue: '',
  minuteValue: '',
  value: null,
  valid: true,
} as DateInputProps)

export type DateInputRecord = RecordOf<DateInputProps>

export const RetargetedOrchestrationFactory = Immutable.Record<RetargatedOrchestrationProps>({
  orchestrationToken: '',
  stepId: undefined,
  channels: Immutable.Set<ChannelUntilCleanup>(),
} as RetargatedOrchestrationProps)

export type RetargetedOrchestrationRecord = RecordOf<RetargatedOrchestrationProps>

export type RetargatedOrchestrationProps = {
  orchestrationToken: string
  stepId?: string
  channels: Immutable.Set<ChannelUntilCleanup>
}

type ConditionInputProps = {
  number: number
  string: string
  boolean: boolean
  stringList: List<string>
  numberList: List<number>
  age: AgeRecord
  date: DateInputRecord
  mode: InputType
  retargetedOrchestration: RetargetedOrchestrationRecord
}
export const ConditionInputFactory = Immutable.Record<ConditionInputProps>({
  number: NaN,
  string: '',
  boolean: true,
  stringList: Immutable.List(),
  numberList: Immutable.List(),
  age: AgeFactory({ unit: 'h' }),
  date: DateInputFactory(),
  mode: 'InputString',
  retargetedOrchestration: RetargetedOrchestrationFactory(),
} as ConditionInputProps)
export type ConditionInputRecord = RecordOf<ConditionInputProps>

/* je fais le choix de figer pour typer ici
  on aurait aussi pu avoir une Map<string, any> rattaché à chque function
  mais vu l'usage actuel / envisagé sur le dashboard, je pense que ça suffit & que ça simplifiera 
  certains trucs
*/

type FunctionParamsProps = {
  lat: number
  lng: number
  radius: number
  age: AgeRecord
  campaignToken: string
}
export const FunctionParamsFactory = Immutable.Record<FunctionParamsProps>({
  lat: NaN,
  lng: NaN,
  radius: 500,
  age: AgeFactory({ unit: 'h' }),
  campaignToken: '',
} as FunctionParamsProps)

export type FunctionParamsRecord = RecordOf<FunctionParamsProps>

type ConditionProps = {
  operator: OperatorRecord
  functions: List<FunctionRecord>
  value: ConditionInputRecord
  functionParams: FunctionParamsRecord | null | undefined
  attribute: QueryAttributeRecord | null | undefined
  eventFilters: List<ConditionRecord>
  isEventFilterNegated: boolean | null | undefined
}
export const ConditionFactory = Immutable.Record<ConditionProps>({
  operator: Operators.ExistsOperator,
  functions: Immutable.List(),
  value: ConditionInputFactory(),
  functionParams: null,
  attribute: null,
  eventFilters: Immutable.List(),
  isEventFilterNegated: null,
} as ConditionProps)

export type ConditionRecord = RecordOf<ConditionProps>

type LogicalNodeProps = {
  id: string
  value: 'and' | 'or' | 'not'
  descendants: List<LogicalNodeRecord | string>
}
export const LogicalNodeFactory = Immutable.Record<LogicalNodeProps>({
  id: 'root',
  value: 'and',
  descendants: Immutable.List(),
} as LogicalNodeProps)

export type LogicalNodeRecord = RecordOf<LogicalNodeProps>

export type QueryProps = {
  conditions: Map<string, ConditionRecord>
  tree: LogicalNodeRecord
  eventId: string
  error: string
}
export const QueryFactory = Immutable.Record<QueryProps>({
  conditions: Immutable.Map(),
  tree: LogicalNodeFactory(),
  eventId: '',
  error: '',
} as QueryProps)

export type QueryRecord = RecordOf<QueryProps>
