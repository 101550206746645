import {
  type OrchestrationCampaign,
  type EmailContentForCampaign,
} from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import { type OrchestrationJourney } from 'com.batch/orchestration-journey/infra/types/orchestration-journey.types'
import {
  type PushPlatforms,
  type OrchestrationPush,
} from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

export type OrchestrationChannels = Array<'EMAIL' | 'SMS' | 'PUSH'>
export type OrchestrationType = 'ONETIME' | 'RECURRENT' | 'TRIGGER'
type OrchestrationCommons = {
  projectKey: {
    textual: {
      text: string
    }
  }
  id: string | null | undefined
  name: string
  orchestrationType: OrchestrationType
  orchestrationState?: campaignStateType
  orchestrationSource: 'DASHBOARD' | 'API'
  orchestrationChannels: OrchestrationChannels | null | undefined
  dashboardConfig: DashboardConfig
}

export type OrchestrationWithJourney = OrchestrationCommons & {
  journey: OrchestrationJourney
}
export type OrchestrationCampaignOnly = {
  campaign: OrchestrationCampaign
  messages?: {
    [key: string]:
      | {
          emailChannel: EmailContentForCampaign
        }
      | {
          smsMessage: SmsContent
        }
      | {
          pushMessage: OrchestrationPush
        }
  }
}
export type OrchestrationWithCampaign = OrchestrationCommons & OrchestrationCampaignOnly
export type Orchestration = OrchestrationWithJourney | OrchestrationWithCampaign
export const orchestrationIsCampaign = (orc: Orchestration): orc is OrchestrationWithCampaign => {
  return Boolean((orc as OrchestrationWithCampaign).campaign)
}
export type DashboardEmailConfigByLanguage = {
  htmlEditorConfig: {
    type?: 'STRIPO' | 'CODE'
    templateId?: string
    html?: string
    css?: string
  }
}
export type DashboardConfigByMessageId = {
  langConfigs: {
    [lang: string]: DashboardEmailConfigByLanguage
  }
}
export type DashboardConfig = {
  emailAdditionnalConfig?: {
    [messageId: string]: DashboardConfigByMessageId
  }
  incomplete?: boolean
  winningVariantSelectionDate?: string
}

export type ListOrchestrationType = {
  id: string
  name: string
  orchestrationChannels?: Array<'EMAIL' | 'SMS' | 'PUSH'>
  incomplete?: boolean
  orchestrationSource?: 'DASHBOARD' | 'API'
  orchestrationState?: campaignStateType
  journey?: ListJourneyType
  recurrent?: ListRecurrentType
  onetime?: ListOneTimeType
  pushPlatforms?: Array<PushPlatforms>
}

export type ListJourneyType = {
  entryEvent?: string
  startTime?: string
  endTime?: string
  capping?: number
  gracePeriod?: string
}

export type ListRecurrentType = {
  firstSendTime: string
  endTime: string
  recurrencePeriod: 'DAILY' | 'WEEKLY' | 'MONTHLY'
  recurrenceFrequency: number
  utc: boolean
  capping?: number
}
export type ListOneTimeType = {
  sendTime?: string
  utc?: boolean
}
