import Immutable, { type Set } from 'immutable'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { type AppRecord, type CampaignRecord, type CompanyRecord } from './_records'
import { type ApiPushCampaign, type targetingType, type msgTrad } from './project.api.model'
import { type UserRecord } from './user.records'

import { type ContentStateRecord } from 'com.batch.redux/content.records'
import {
  ProjectFactory,
  type ProjectRecord,
  ProjectStateFactory,
  type ProjectStateRecord,
} from 'com.batch.redux/project.records'
import { getLangAndRegionArray } from 'com.batch.redux/target/target.helper'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { LoadingStatus } from 'constants/common'

const segmentMap = {
  N: 'NEW',
  Du: 'ONE_TIME',
  E: 'ENGAGED',
  D: 'DORMANT',
  I: 'IMPORTED',
}
export const formatFakeMultiPushCampaignForAPI = ({
  campaign,
  apps,
  content,
  targeting,
  live,
  query,
  user,
}: {
  campaign: CampaignRecord
  apps: Set<AppRecord>
  content: ContentStateRecord
  targeting: TargetStateRecord
  live: boolean
  query: Record<any, any> | null | undefined
  user: UserRecord
}): ApiPushCampaign => {
  const settings = content.pushSettings
  const push = content.push

  // --- targeting ---
  const targetingObject: targetingType = {
    segments: targeting.segments.toArray().map(segment => segmentMap[segment]),
    query,
  }
  const { languages, regions } = getLangAndRegionArray({ targetingState: targeting, user })

  if (languages.length > 0) targetingObject.languages = languages
  if (regions.length > 0) targetingObject.regions = regions

  // --- base campaign object ---
  const apiCampaign: ApiPushCampaign = {
    apps: apps.toArray().map(app => app.id),
    name: content.campaignName,
    isTrigger: campaign.sendType === 'trigger',
    live,
    custom_payload: settings.payload ? settings.payload : null,
    targeting: targetingObject,
  }

  // ================================================> PUSH

  if (campaign.type === 'push') {
    // --- when ---
    switch (campaign.sendType) {
      case 'trigger':
        throw new Error('Trigger campaigns are not supported yet')
      case 'now': {
        apiCampaign.push_time = 'now'
        break
      }
      case 'scheduled': {
        apiCampaign[campaign.tzAware ? 'local_push_time' : 'push_time'] = campaign.start
          ? campaign.start.format('YYYY-MM-DDTHH:mm:00')
          : null
        break
      }
      case 'recurring': {
        apiCampaign[campaign.tzAware ? 'local_push_time' : 'push_time'] = campaign.start
          ? campaign.start.format('YYYY-MM-DDTHH:mm:00')
          : null
        apiCampaign.recurrence = {
          end_date: campaign.end ? campaign.end.format('YYYY-MM-DDTHH:mm:00') : null,
          repeat_unit: campaign.repeatUnit,
          repeat_frequency: campaign.repeatFrequency,
        }
        if (campaign.capping) {
          apiCampaign.capping = campaign.capping
        } else {
          apiCampaign.capping = null
        }
        break
      }
    }

    // --- advanced ---
    apiCampaign.priority = settings.priority === 'HIGH' ? 'high' : 'normal'
    apiCampaign.time_to_live = settings.expiration ? settings.expiration * 3600 : null
    apiCampaign.deeplink = settings.deeplink ? settings.deeplink : null
    if (settings.hasCollapseKey) {
      apiCampaign.gcm_collapse_key = {
        enabled: true,
        key: settings.collapseKey,
      }
    } else {
      apiCampaign.gcm_collapse_key = {
        enabled: false,
      }
    }

    // --- media ---
    const media: {
      icon?: string
      picture: string
      audio: string
      video: string
    } = {
      picture: '',
      audio: '',
      video: '',
    }
    if (settings.attachmentUrl) {
      media[settings.attachmentKind === 'image' ? 'picture' : settings.attachmentKind] =
        settings.attachmentUrl
    }
    if (settings.iconUrl) {
      media.icon = settings.iconUrl
    }
    if (Object.keys(media).length > 0) {
      apiCampaign.media = media
    }

    // --- message ---
    const aMessages: Array<msgTrad> = []
    const bMessages: Array<msgTrad> = []
    push.forEach((abTested, language) => {
      aMessages.push({
        language: language === 'default' ? null : language,
        title: abTested.a.title.value,
        body: abTested.a.message.value,
      })
      if (campaign.abtesting.enabled) {
        bMessages.push({
          language: language === 'default' ? null : language,
          title: abTested.b.title.value,
          body: abTested.b.message.value,
        })
      }
    })
    if (campaign.abtesting.enabled) {
      apiCampaign.messages = {
        a: aMessages,
        b: bMessages,
        enabled_variants: campaign.abtesting.activeVariants.toArray(),
      }
    } else {
      apiCampaign.messages = aMessages
    }
  }

  return apiCampaign
}

export const normalizeProject = (raw: {
  id: string
  name: string
  iconUrl: string
  appCount: number
  smsConfigured: boolean
  pushConfigured: boolean
  webPushConfigured: boolean
  projectKey?: string
  companyId: number
  emailConfigured: boolean
  createdAt: string
}): ProjectRecord => {
  const apps: Array<{
    projectKey?: string
    icon?: string
    id: number
  }> = window?.initialData?.apps ?? []
  const appIconUrl = apps
    .filter(app => app.projectKey === raw.projectKey)
    .reduce((acc, curr) => {
      if (acc === '') return curr.icon ?? ''
      return acc
    }, '')
  const companyId = parseInt(window?.initialData?.routeParams?.companyId ?? '0')
  const appIds = Immutable.Set(
    apps.filter(app => app.projectKey === raw.projectKey).map(app => app.id)
  )
  return ProjectFactory({
    id: raw.id,
    companyId: raw?.companyId ?? companyId,
    loadingState: LoadingStatus.LOADED,
    smsConfigured: raw.smsConfigured,
    pushConfigured: raw.pushConfigured,
    webPushConfigured: raw.webPushConfigured,
    anyPushConfigured: raw.pushConfigured || raw.webPushConfigured,
    name: raw.name,
    projectKey: raw.projectKey,
    iconUrl: raw.iconUrl ? raw.iconUrl : appIconUrl,
    appCount: appIds.size,
    appIds,
    emailConfigured: raw.emailConfigured,
    createdAt: dayjs.utc(raw.createdAt ?? '', 'YYYY-MM-DD HH:mm'),
  })
}

//  Edit name Project.
export const updateNameProject = (
  company: CompanyRecord,
  project: ProjectRecord
): Promise<ProjectRecord> => {
  return request
    .put(
      generateUrl('api_project_update_name', { companyId: company.id, projectId: project.id }),
      project
    )
    .then(
      () => {
        return project.set('loadingState', LoadingStatus.LOADED)
      },
      response => {
        throw {
          project,
          errors: response.body.errors,
        }
      }
    )
}

export const buildInitState = (): ProjectStateRecord => {
  const projects = window?.initialData?.projects ?? []
  const currentProjectId: string = window?.initialData?.projectId ?? null
  return ProjectStateFactory({
    currentProjectId,
    entities: Immutable.Map(projects.map(p => [p.id, normalizeProject(p as any)])).sort((a, b) =>
      a.name < b.name ? -1 : 1
    ),
  })
}
