import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import {
  type OrchestrationAnalyticsFilters,
  type OrchestrationAnalyticsDateRangeRecord,
  OrchestrationAnalyticsFactory,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { fetchGlobalDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import {
  channelConfig,
  createFactory,
} from '../cross-orchestrations-analytics/cross-orchestrations-analytics'
import {
  type FetchDynamicStatsConfig,
  getStatsConfig,
} from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { LoadingStatus } from 'constants/common'
import { StatsBlocksRow } from '../orchestration-analytics/orchestration-analytics.styles'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { KeyMetricsCharts } from './key-metrics-charts'

type PeriodicKeyMetricsProps = {
  channel: ChannelUntilCleanup
  filters: OrchestrationAnalyticsFilters
  dateRange: OrchestrationAnalyticsDateRangeRecord
  projectKey: string
}
export const PeriodicKeyMetrics = React.memo(
  ({ channel, filters, dateRange, projectKey }: PeriodicKeyMetricsProps): React.ReactNode => {
    const dispatch = useDispatch()
    const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
    const [data, stats] = React.useMemo(() => {
      const channelAnalytics = analyticsMap
        .get(projectKey, OrchestrationAnalyticsFactory())
        .get('channels')
        .get(channel, createFactory(channel))

      return [channelAnalytics.periodic.data, channelAnalytics.stats]
    }, [channel, analyticsMap, projectKey])
    const [loadingState, channelLoadingState] = React.useMemo(
      () => [
        analyticsMap.get(projectKey)?.channelAndPeriodLoadingState ?? 'INIT',
        analyticsMap.get(projectKey)?.get(channelConfig[channel].loadingState) ?? 'INIT',
      ],
      [analyticsMap, projectKey, channel]
    )
    const isLoading = React.useMemo(
      () =>
        loadingState === LoadingStatus.LOADING ||
        loadingState === LoadingStatus.INIT ||
        channelLoadingState === LoadingStatus.LOADING ||
        channelLoadingState === LoadingStatus.INIT,
      [loadingState, channelLoadingState]
    )
    const isOverlayShown = React.useMemo(
      () => loadingState === LoadingStatus.ERROR || channelLoadingState === LoadingStatus.ERROR,
      [loadingState, channelLoadingState]
    )
    const [dynamicStatsConfig, periodicity]: [FetchDynamicStatsConfig, 'day' | 'week' | 'month'] =
      React.useMemo(() => {
        if (dateRange) {
          const from = dateRange.get('from')
          const to = dateRange.get('to')
          const diff = to.diff(from, 'day')
          if (diff <= 60) {
            return [
              getStatsConfig({
                name: 'orchestrationStatsByChannelAndPeriod',
                periodicity: 'day',
              }),
              'day',
            ]
          }
          if (diff <= 363) {
            return [
              getStatsConfig({
                name: 'orchestrationStatsByChannelAndPeriod',
                periodicity: 'week',
              }),
              'week',
            ]
          }
          return [
            getStatsConfig({
              name: 'orchestrationStatsByChannelAndPeriod',
              periodicity: 'month',
            }),
            'month',
          ]
        }
        return [
          getStatsConfig({
            name: 'orchestrationStatsByChannelAndPeriod',
          }),
          'day',
        ]
      }, [dateRange])

    React.useEffect(() => {
      dispatch(
        fetchGlobalDynamicStats({
          dateRange,
          config: dynamicStatsConfig,
          filters,
        })
      ).catch(() => {})
    }, [dispatch, dynamicStatsConfig, channel, filters, dateRange])

    return (
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        style={isOverlayShown ? { overflow: 'hidden' } : {}}
        boxed
      >
        <StatsBlocksRow>
          <KeyMetricsCharts
            data={data.toArray()}
            periodicity={periodicity}
            stats={stats}
            channel={channel}
            isLoading={isLoading}
            dateRangeFilter={dateRange}
          />
        </StatsBlocksRow>
      </Wrapper>
    )
  }
)
