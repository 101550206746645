import * as Immutable from 'immutable'
import { type Location as RouterLocation } from 'react-router-dom'
import URLSearchParams from 'url-search-params'

import { legacyPromiseActionCreator } from './actionCreator'

import {
  type CustomAudienceRecord,
  type CustomAudienceStateRecord,
  type DispatchOnlyBoundFn,
  CustomAudienceStateFactory,
} from 'com.batch.redux/_records'
import * as api from 'com.batch.redux/audience.api'
import { LoadingStatus } from 'constants/common'

// ========================================================
// INITIAL STATE
// ========================================================

const initialState = CustomAudienceStateFactory()

// ========================================================
// ACTION TYPES
// ========================================================

type updateCustomAudienceEstimateAction = {
  type: 'UPDATE_CUSTOM_AUDIENCE_ESTIMATE'
  payload: {
    name: string
    estimate: number | 'error'
  }
}

type fetchCustomAudiencesAction = {
  type: 'FETCH_CUSTOM_AUDIENCES'
}

type fetchCustomAudiencesSuccessAction = {
  type: 'FETCH_CUSTOM_AUDIENCES_SUCCESS'
  payload: {
    customAudiences: Immutable.List<CustomAudienceRecord>
    count: number
    page: number
  }
}

type fetchCustomAudiencesFailureAction = {
  type: 'FETCH_CUSTOM_AUDIENCES_FAILURE'
}

type resetCustomAudiencesPageIdsAction = {
  type: 'RESET_CUSTOM_AUDIENCES_PAGE_IDS'
  payload: {
    side: 'both' | 'left' | 'right'
  }
}

type createCustomAudienceAction = {
  type: 'CREATE_CUSTOM_AUDIENCE'
}

export type createCustomAudienceSuccessAction = {
  type: 'CREATE_CUSTOM_AUDIENCE_SUCCESS'
}

export type createCustomAudienceFailureAction = {
  type: 'CREATE_CUSTOM_AUDIENCE_FAILURE'
}

type saveCustomAudienceAction = {
  type: 'SAVE_CUSTOM_AUDIENCE'
}

export type saveCustomAudienceSuccessAction = {
  type: 'SAVE_CUSTOM_AUDIENCE_SUCCESS'
  payload: {
    customAudience: CustomAudienceRecord
  }
}

export type saveCustomAudienceFailureAction = {
  type: 'SAVE_CUSTOM_AUDIENCE_FAILURE'
  payload: {
    error: any
  }
}

type deleteCustomAudienceAction = {
  type: 'DELETE_CUSTOM_AUDIENCE'
}

export type deleteCustomAudienceSuccessAction = {
  type: 'DELETE_CUSTOM_AUDIENCE_SUCCESS'
  payload: {
    customAudience: CustomAudienceRecord
  }
}

export type deleteCustomAudienceFailureAction = {
  type: 'DELETE_CUSTOM_AUDIENCE_FAILURE'
  payload: {
    error: any
  }
}

type CustomAudienceActions =
  | updateCustomAudienceEstimateAction
  | fetchCustomAudiencesAction
  | fetchCustomAudiencesSuccessAction
  | fetchCustomAudiencesFailureAction
  | resetCustomAudiencesPageIdsAction
  | createCustomAudienceAction
  | createCustomAudienceSuccessAction
  | createCustomAudienceFailureAction
  | saveCustomAudienceAction
  | saveCustomAudienceSuccessAction
  | saveCustomAudienceFailureAction
  | deleteCustomAudienceAction
  | deleteCustomAudienceSuccessAction
  | deleteCustomAudienceFailureAction

// ========================================================
// ACTIONS
// ========================================================

export const fetchCustomAudiences = (
  appId: number,
  options: {
    page?: number
    sortBy?: string
    sortOrder?: 'dsc' | 'asc'
    forceRefresh?: boolean
    search?: string
  } = { forceRefresh: false }
): ((dispatch, getState) => Promise<any>) => {
  return (dispatch, getState) => {
    const config = getRouteConfig()
    const page = options.page || config.page
    const sortBy = options.sortBy || config.sortBy
    const sortOrder = options.sortOrder || config.sortOrder
    const search = options.search || config.search
    const state: any = getState()

    if (!options.forceRefresh && state.audience.idsPerPage.has(page)) {
      dispatch({ type: 'UPDATE_CUSTOM_AUDIENCES_PAGE', payload: { page } })
      return Promise.resolve()
    }

    return legacyPromiseActionCreator({
      dispatch,
      promise: api.fetchCustomAudiences(appId, { page, sortBy, sortOrder, search }),
      actionName: 'FETCH_CUSTOM_AUDIENCES',
    })
  }
}

export const resetPageIds = (
  side: 'both' | 'left' | 'right' = 'both'
): resetCustomAudiencesPageIdsAction => ({
  type: 'RESET_CUSTOM_AUDIENCES_PAGE_IDS',
  payload: { side },
})

export interface CreateCustomAudienceType {
  appId: number
  name: string
  type: 'custom_ids' | 'advertising_ids' | 'install_ids'
  description: string
  file: File
  onProgress?: (p: number) => any
}

export const createCustomAudience = ({
  appId,
  name,
  type,
  description,
  file,
  onProgress,
}: CreateCustomAudienceType): DispatchOnlyBoundFn<any> => {
  return dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      promise: api.createCustomAudience(appId, name, type, description, file, onProgress),
      actionName: 'CREATE_CUSTOM_AUDIENCE',
      successCallback: () => window.location.reload(),
      errorCallback: () => {},
    })
  }
}

export const saveCustomAudience = (
  appId: number,
  audience: CustomAudienceRecord
): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      promise: api.saveCustomAudience(
        appId,
        audience.set('description', audience.description ? audience.description : audience.name)
      ),
      actionName: 'SAVE_CUSTOM_AUDIENCE',
    })
  }
}

export const deleteCustomAudience = (
  appId: number,
  audience: CustomAudienceRecord
): DispatchOnlyBoundFn<Promise<void>> => {
  return dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      promise: api.deleteCustomAudience(appId, audience),
      actionName: 'DELETE_CUSTOM_AUDIENCE',
    }).then(() => {
      dispatch(resetPageIds('right'))
      dispatch(fetchCustomAudiences(appId, { forceRefresh: true }))
    })
  }
}

// ========================================================
// REDUCERS
// ========================================================

export default function customAudienceReducer(
  state: CustomAudienceStateRecord = initialState,
  action: CustomAudienceActions
): CustomAudienceStateRecord {
  switch (action.type) {
    case 'UPDATE_CUSTOM_AUDIENCE_ESTIMATE':
      return state.setIn(['entities', action.payload.name, 'estimate'], action.payload.estimate)
    case 'FETCH_CUSTOM_AUDIENCES':
      return state.merge({ customAudienceLoadingState: LoadingStatus.LOADING })
    case 'FETCH_CUSTOM_AUDIENCES_SUCCESS': {
      return state
        .merge({
          customAudienceLoadingState: LoadingStatus.LOADED,
          count: action.payload.count,
        })
        .set(
          'entities',
          state.entities.mergeWith(
            // Merge only the new entities to keep the estimates
            (oldAud, newAud) => (oldAud === null ? newAud : oldAud),
            Immutable.Map(action.payload.customAudiences.map(audience => [audience.name, audience]))
          )
        )
        .set(
          'idsPerPage',
          state.idsPerPage.set(
            action.payload.page,
            action.payload.customAudiences.map(aud => aud.name)
          )
        )
    }
    case 'FETCH_CUSTOM_AUDIENCES_FAILURE':
      return state.set('customAudienceLoadingState', LoadingStatus.ERROR)
    case 'RESET_CUSTOM_AUDIENCES_PAGE_IDS':
      switch (action.payload.side) {
        case 'both':
          return state.set('idsPerPage', state.idsPerPage.clear())
        case 'left':
          return state.set(
            'idsPerPage',
            state.idsPerPage.filterNot((ids, page) => page < getRouteConfig().page)
          )
        case 'right':
          return state.set(
            'idsPerPage',
            state.idsPerPage.filterNot((ids, page) => page > getRouteConfig().page)
          )
        default:
          return state
      }
    case 'CREATE_CUSTOM_AUDIENCE':
      return state.set('creating', true)
    case 'CREATE_CUSTOM_AUDIENCE_FAILURE':
      return state.set('creating', false)
    case 'SAVE_CUSTOM_AUDIENCE_SUCCESS': {
      const { customAudience } = action.payload
      return state.setIn(['entities', customAudience.name], customAudience)
    }
    case 'SAVE_CUSTOM_AUDIENCE_FAILURE':
      return state.set('customAudienceLoadingState', LoadingStatus.ERROR)
    case 'DELETE_CUSTOM_AUDIENCE':
      return state.set('customAudienceLoadingState', LoadingStatus.LOADING)
    default:
      return state
  }
}

// ========================================================
// ROUTER
// ========================================================

export const getRouteConfig = (
  location: Location | RouterLocation = window.location
): {
  page: number
  search: any
  sortBy: any
  sortOrder: any
} => {
  const params = new URLSearchParams(location.search)

  return {
    page: Number(params.get('page') || 1),
    sortBy: params.get('sortBy') || 'date',
    sortOrder: params.get('sortOrder') || 'dsc',
    search: params.get('search') || '',
  }
}
