import Immutable, { type RecordOf, type List } from 'immutable'

import { type LabelRecord } from '../models/labels.records'
import { LoadingStatus } from 'constants/common'

type LabelStateProps = {
  labels: List<LabelRecord>
  loadingState: LoadingStatus
}

export const LabelStateFactory = Immutable.Record<LabelStateProps>({
  labels: Immutable.List(),
  loadingState: LoadingStatus.INIT,
} as LabelStateProps)

export type LabelStateRecord = RecordOf<LabelStateProps>
