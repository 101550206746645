import Immutable, { type RecordOf } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Wrapper, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { TableFooter } from 'components/common/table/table.styles'
import { FilterSearch } from 'components/filter'
import { Title } from 'components/styled/text'

import {
  CustomDataEmptyBox,
  CustomDataEmptyTable,
  getNoCustomDataProps,
} from './custom-data-empty-states'
import { CustomDataList } from './custom-data-list'
import { Separator, ButtonDevLive, CounterItemsContainer } from './custom-data.styles'

import { type AppRecord, type AttributeRecord, type State } from 'com.batch.redux/_records'
import {
  attributeForCustomDataSelector,
  fetchAttributes,
  saveAttribute,
} from 'com.batch.redux/attribute'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { LoadingStatus } from 'constants/common'

type CustomDataProps = {
  app: AppRecord
}

type EditCatType =
  | 'attribute'
  | 'tag'
  | 'event'
  | 'user_attribute'
  | 'user_tag'
  | 'user_event'
  | null

const categories = [
  {
    label: 'Attributes',
    value: 'attribute',
    link: [
      'https://doc.batch.com/android/custom-data/custom-attributes',
      'https://doc.batch.com/ios/custom-data/custom-attributes',
    ],
  },
  {
    label: 'Tags',
    value: 'tag',
    link: [
      'https://doc.batch.com/android/custom-data/custom-attributes#managing-tag-collections',
      'https://doc.batch.com/ios/custom-data/custom-attributes#managing-tag-collections',
    ],
  },
  {
    label: 'Events',
    value: 'event',
    link: [
      'https://doc.batch.com/android/custom-data/custom-events',
      'https://doc.batch.com/ios/custom-data/custom-events',
    ],
  },
  {
    label: 'User attributes',
    value: 'user_attribute',
    link: 'https://doc.batch.com/api/custom-data-api/set-update',
  },
  {
    label: 'User tags collections',
    value: 'user_tag',
    link: 'https://doc.batch.com/api/custom-data-api/set-update',
  },
  {
    label: 'User events',
    value: 'user_event',
    link: 'https://doc.batch.com/api/custom-data-api/set-update',
  },
]

type PageByCategoryProps = {
  attribute: number
  tag: number
  event: number
  user_attribute: number
  user_tag: number
  user_event: number
}
type PageByCategoryRecord = RecordOf<PageByCategoryProps>
const PageByCategoryFactory = Immutable.Record<PageByCategoryProps>({
  attribute: 1,
  tag: 1,
  event: 1,
  user_attribute: 1,
  user_tag: 1,
  user_event: 1,
} as PageByCategoryProps)

const NB_PER_PAGE = 8

export const CustomData: React.ComponentType<CustomDataProps> = React.memo<CustomDataProps>(
  ({ app }: CustomDataProps) => {
    const [pages, setPages] = React.useState<PageByCategoryRecord>(PageByCategoryFactory())
    const [query, setQuery] = React.useState<string>('')

    const [editCat, setEditCat] = React.useState<EditCatType>(null)
    const [editLine, setEditLine] = React.useState<number | null>(null)

    // ====================== REDUX
    const dispatch = useDispatch()
    const { attributeLoadingState, devMode } = useSelector((state: State) => state.attribute.config)
    const attributes = useSelector(attributeForCustomDataSelector)
    const user = useSelector(currentUserSelector)
    const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)

    // ====================== Component constants
    const emptyAttributes = attributes.size === 0

    // empty state constants
    const hasError = React.useMemo(
      () => attributeLoadingState === LoadingStatus.ERROR,
      [attributeLoadingState]
    )
    const isLoading = React.useMemo(
      () =>
        attributeLoadingState === LoadingStatus.LOADING ||
        attributeLoadingState === LoadingStatus.INIT,
      [attributeLoadingState]
    )
    const noDataInit = React.useMemo(
      () =>
        emptyAttributes &&
        attributeLoadingState !== LoadingStatus.LOADED &&
        !hasError &&
        !isLoading,
      [attributeLoadingState, hasError, isLoading, emptyAttributes]
    )
    const isEmpty = React.useMemo(
      () =>
        hasError || (emptyAttributes && attributeLoadingState === LoadingStatus.LOADED && !query),
      [hasError, emptyAttributes, attributeLoadingState, query]
    )

    // ====================== use effect
    React.useEffect(() => {
      if (app.id > 0) dispatch(fetchAttributes({ app, devMode: false, refresh: true }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.id])

    // ====================== Callback
    const save = React.useCallback(
      (attribute: AttributeRecord): Promise<{ attribute: AttributeRecord }> => {
        return dispatch(saveAttribute({ app, attribute }))
      },
      [app, dispatch]
    )

    const handlePageAndQuery = React.useCallback((e: string) => {
      setQuery(e)
      setPages(PageByCategoryFactory())
    }, [])

    const createFetchAttributeCallback = React.useCallback(
      (devMode: boolean, refresh: boolean) => () => {
        setPages(PageByCategoryFactory())
        dispatch(
          fetchAttributes({
            app,
            devMode,
            refresh,
          })
        )
      },
      [app, dispatch]
    )

    const createEditLineHandlerForCat = React.useCallback(
      cat => (line: undefined | number) => {
        if (line) {
          setEditLine(line)
        } else {
          setEditLine(null)
        }
        setEditCat(cat)
      },
      []
    )

    const createOnSelectPage = React.useCallback(
      (cat: keyof PageByCategoryProps) => (page: number) => {
        setPages(pages.set(cat, page))
      },
      [pages]
    )

    // ====================== Render
    return (
      <React.Fragment>
        <Grid
          gap={!isEmpty && !isLoading && app.platform !== 'webpush' ? 8 : 0}
          template={app.platform !== 'webpush' ? '1fr auto 9px 86px' : '1fr auto 1px'}
          margin={[0, 0, 38, 0]}
        >
          <Title overEmptyState mb={0}>
            Custom data
          </Title>
          {!isEmpty && !isLoading && (
            <FilterSearch
              disabled={attributes.size === 0}
              identifier="custom data"
              value={query}
              onChange={handlePageAndQuery}
              expandedMaxWidth={280}
            />
          )}

          {app.platform !== 'webpush' && hasDevApiKeyFeature && (
            <React.Fragment>
              <Separator />
              <div style={{ display: 'flex' }}>
                <ButtonDevLive isFocus={devMode} onClick={createFetchAttributeCallback(true, true)}>
                  Dev
                </ButtonDevLive>
                <ButtonDevLive
                  style={{ marginLeft: 6 }}
                  isFocus={!devMode}
                  onClick={createFetchAttributeCallback(false, true)}
                >
                  Live
                </ButtonDevLive>
              </div>
            </React.Fragment>
          )}
        </Grid>
        <Wrapper
          isLoading={isLoading || noDataInit}
          isEmpty={isEmpty}
          isOverlayShown={isEmpty || hasError}
          overlayProps={hasError ? GlobalErrorOverlayProps : getNoCustomDataProps(app.platform)}
        >
          {!emptyAttributes && attributeLoadingState === LoadingStatus.LOADED && !hasError ? (
            <React.Fragment>
              {categories.map(currentCategorie => {
                const catListAttr = attributes.filter(
                  attr =>
                    attr.category === currentCategorie.value &&
                    (query === '' ||
                      attr.cleanId.indexOf(query.toLowerCase()) !== -1 ||
                      (!!attr.name && attr.name.indexOf(query.toLowerCase()) !== -1))
                )

                const boxMinHeight =
                  catListAttr.size > NB_PER_PAGE ? { minHeight: (NB_PER_PAGE + 1) * 48.5 } : {}

                const currentPage: number = pages.get(
                  currentCategorie.value as keyof PageByCategoryProps
                )
                  ? pages.get(currentCategorie.value, 1)
                  : 1

                return (
                  <Box
                    key={currentCategorie.value}
                    style={{
                      marginBottom: 32,
                    }}
                  >
                    <BoxHeader>
                      <HeaderBoxTitle
                        title={currentCategorie.label}
                        suffix={
                          (catListAttr.size > 0 || !!query) && (
                            <CounterItemsContainer>
                              {catListAttr.size} item{catListAttr.size > 1 && 's'}{' '}
                              {!!query && 'match your search'}
                            </CounterItemsContainer>
                          )
                        }
                      />
                    </BoxHeader>
                    <BoxBody style={{ ...boxMinHeight }}>
                      <Wrapper
                        isEmpty={catListAttr.size === 0 && !query}
                        isOverlayShown={catListAttr.size === 0 && !query}
                        isLoading={isLoading}
                        style={{ minHeight: 100 }}
                        overlayProps={{
                          status: 'empty',
                          title: `No ${currentCategorie.label.toLowerCase()} to display`,
                        }}
                      >
                        {catListAttr.size > 0 && (
                          <CustomDataList
                            attributes={catListAttr}
                            currentCategory={currentCategorie.value}
                            save={save}
                            editable={!devMode}
                            page={currentPage}
                            nbPerPage={NB_PER_PAGE}
                            editingLine={editCat === currentCategorie.value ? editLine : null}
                            editLine={createEditLineHandlerForCat(currentCategorie.value)}
                            stopEditLine={createEditLineHandlerForCat(null)}
                            user={user}
                          />
                        )}
                        {isLoading && <CustomDataEmptyTable />}
                      </Wrapper>
                    </BoxBody>
                    {catListAttr.size / NB_PER_PAGE > 1 && (
                      <TableFooter>
                        <Pager
                          page={currentPage}
                          total={catListAttr.size}
                          nbPerPage={NB_PER_PAGE}
                          selectPage={createOnSelectPage(
                            currentCategorie.value as keyof PageByCategoryProps
                          )}
                        />
                      </TableFooter>
                    )}
                  </Box>
                )
              })}
            </React.Fragment>
          ) : (
            <CustomDataEmptyBox />
          )}
        </Wrapper>
      </React.Fragment>
    )
  }
)
