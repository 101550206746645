import { generateAnalyticsUrl } from 'com.batch.common/router'
import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { currentCompanySelector } from 'com.batch.redux/company'
import { formatPlatformName } from 'com.batch/shared/ui/component/select-platform/select-platform'
import { Button, ButtonLink, DropdownMenu, useDropdown } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

type AppsAnalyticsDropdownProps = {
  apps: List<AppRecord>
}
export const AppsAnalyticsDropdown = ({ apps }: AppsAnalyticsDropdownProps): React.ReactElement => {
  const { id: appId } = useSelector(currentAppSelector)
  const { id: companyId } = useSelector(currentCompanySelector)
  const { dropdownProps, triggerProps } = useDropdown({
    offset: [-48, 6],
    forcedWidth: 128,
  })
  return (
    <React.Fragment>
      <Button
        kind="inline"
        addOn="prefix"
        addOnGap={8}
        style={{ marginLeft: 'auto' }}
        {...triggerProps}
        isActive={apps.some(({ id }) => id === appId)}
      >
        More
        <Icon icon="chevron-down" />
      </Button>
      <DropdownMenu {...dropdownProps}>
        {apps.map(({ id, platform }) => (
          <ButtonLink
            key={`dropdown-app-${id}`}
            href={generateAnalyticsUrl({
              companyId,
              appId: id,
            })}
            kind="inline"
            addOn="prefix"
            addOnGap={8}
            isActive={id === appId}
          >
            <Icon icon={platform as ProjectPlatforms} />
            {formatPlatformName(platform as ProjectPlatforms)}
          </ButtonLink>
        ))}
      </DropdownMenu>
    </React.Fragment>
  )
}
