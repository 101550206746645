import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import {
  type VolumesRecord,
  type PlatformSentVolumeRecord,
  type ProfileVolumeRecord,
} from 'com.batch/account/model/volumes.records'
import { LoadingStatus } from 'constants/common'

type Extract<T> = (arg1: State) => T
export const companyVolumesStateSelector: Extract<VolumesRecord> = state => state.company.volumes

export const billingPageIsLoadingSelector: Extract<boolean> = createSelector(
  companyVolumesStateSelector,
  companyVolumes =>
    companyVolumes.profile.loadingState === LoadingStatus.LOADING ||
    companyVolumes.sent.loadingState === LoadingStatus.LOADING
)

export const profileVolumeSelector: Extract<ProfileVolumeRecord> = createSelector(
  companyVolumesStateSelector,
  companyVolumes => companyVolumes.profile
)

export const sentVolumeSeclector: Extract<PlatformSentVolumeRecord> = createSelector(
  companyVolumesStateSelector,
  companyVolumes => companyVolumes.sent
)
