import Immutable, { type List } from 'immutable'

import { type FetchDynamicStatsConfig, type DynamicDimension } from './fetch-dynamic-stats.helper'

import {
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from '../store/orchestration-analytics.state'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

const enhanceConfig = ({
  config: { groupBy },
  filters,
  dateRange,
}: {
  config: FetchDynamicStatsConfig
  filters: OrchestrationAnalyticsFilters
  dateRange: OrchestrationAnalyticsDateRangeRecord | null
}) => {
  const { groupDimensionNames, includeFilterDimensions } = groupBy
  const includeFilterDimensionsOverride = [
    ...(includeFilterDimensions || []),
    ...filters.reduce<Array<{ dimensionName: DynamicDimension; dimensionValues: Array<string> }>>(
      (acc, values, dimensionName) => [
        ...acc,
        { dimensionName, dimensionValues: values.toArray() },
      ],
      []
    ),
  ]
  const dateRangeFilter = dateRange
    ? {
        dateFrom: dateRange.from,
        dateTo: dateRange.to,
      }
    : undefined

  return {
    groupDimensionNames,
    includeFilterDimensions: includeFilterDimensionsOverride,
    ...dateRangeFilter,
  }
}

const abortFetchDynamicStatsController: {
  [key: string]: AbortController
} = {}

export const fetchOrchestrationDynamicStats = ({
  tokens,
  filters = Immutable.Map(),
  dateRange = null,
  config,
  includePreviousPeriod = false,
}: {
  tokens: List<string>
  filters?: OrchestrationAnalyticsFilters
  dateRange?: OrchestrationAnalyticsDateRangeRecord | null
  config: FetchDynamicStatsConfig
  includePreviousPeriod?: boolean
}): DispatchExtraBoundFn<Promise<unknown>> => {
  const { actionName, parser } = config

  if (!abortFetchDynamicStatsController[actionName])
    abortFetchDynamicStatsController[actionName] = new AbortController()

  return async (dispatch, getState, { dataService }) => {
    abortFetchDynamicStatsController[actionName].abort()
    abortFetchDynamicStatsController[actionName] = new AbortController()

    const state = getState()
    const project = currentProjectSelector(state)

    const enhancedConfig = enhanceConfig({ config, filters, dateRange })

    dispatch({ type: actionName, payload: { tokens } })
    return dataService
      .fetchOrchestrationDynamicStats({
        project,
        tokens,
        ...enhancedConfig,
        parser,
        onlyIncludeMetricNames: config.onlyIncludeMetricNames,
        abortSignal: abortFetchDynamicStatsController[actionName].signal,
        includePreviousPeriod,
      })
      .then(parsed => {
        dispatch({
          type: `${actionName}_SUCCESS`,
          payload: {
            parsed,
            tokens,
          },
        })
      })
      .catch(e => {
        if (!e?.aborted)
          dispatch({
            type: `${actionName}_FAILURE`,
            payload: { ...e, tokens },
          })
      })
  }
}

export const fetchGlobalDynamicStats = ({
  filters = Immutable.Map(),
  dateRange = null,
  config,
  includePreviousPeriod = false,
}: {
  filters?: OrchestrationAnalyticsFilters
  dateRange?: OrchestrationAnalyticsDateRangeRecord | null
  config: FetchDynamicStatsConfig
  includePreviousPeriod?: boolean
}): DispatchExtraBoundFn<Promise<unknown>> => {
  const { actionName, parser } = config

  if (!abortFetchDynamicStatsController[actionName])
    abortFetchDynamicStatsController[actionName] = new AbortController()

  return async (dispatch, getState, { dataService }) => {
    abortFetchDynamicStatsController[actionName].abort()
    abortFetchDynamicStatsController[actionName] = new AbortController()

    const state = getState()
    const project = currentProjectSelector(state)

    const enhancedConfig = enhanceConfig({ config, filters, dateRange })

    dispatch({ type: actionName, payload: { projectKey: project.projectKey } })
    return dataService
      .fetchGlobalDynamicStats({
        project,
        ...enhancedConfig,
        parser,
        abortSignal: abortFetchDynamicStatsController[actionName].signal,
        includePreviousPeriod,
      })
      .then(parsed => {
        dispatch({
          type: `${actionName}_SUCCESS`,
          payload: {
            parsed,
            projectKey: project.projectKey,
          },
        })
      })
      .catch(e => {
        if (!e?.aborted)
          dispatch({
            type: `${actionName}_FAILURE`,
            payload: { ...e, projectKey: project.projectKey },
          })
      })
  }
}
