import { type Map, type Set } from 'immutable'

import { config } from 'com.batch.common/config'
import { slugify } from 'com.batch.common/utils'

import { legacyPromiseActionCreator, promiseActionCreator } from './actionCreator'

import { generateSettingsUrl } from 'com.batch.common/router'
import {
  type Variant,
  type CampaignRecord,
  type DispatchBoundFn,
  type DispatchOnlyBoundFn,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import api from 'com.batch.redux/theme.api'
import {
  type FieldKind,
  type FieldRecord,
  type PayloadProps,
  type StyleProperty,
  ThemeFactory,
  type ThemeProps,
  type ThemeRecord,
  ThemeStateFactory,
  type ThemeStateRecord,
} from 'com.batch.redux/theme.records'

import { LoadingStatus } from 'constants/common'

export const themeConfigParser = (
  theme: Map<string, any>
): {
  isLight: boolean
  computedClasses: string
} => {
  if (typeof theme === 'undefined') {
    return {
      isLight: false,
      computedClasses: '',
    }
  }
  const classes: Array<string> = []
  classes.push(theme.getIn(['payloadVars', 'common', 'flip_hero_v']) ? 'heroDown' : 'heroUp')
  classes.push(
    theme.getIn(['payloadVars', 'common', 'attach_cta_bottom']) ? 'ctaBottom' : 'ctaBellow'
  )
  classes.push(theme.getIn(['payloadVars', 'common', 'stack_cta_h']) ? 'ctaH' : 'ctaV')
  classes.push(theme.getIn(['payloadVars', 'common', 'stretch_cta_h']) ? 'ctaStretch' : '')
  return {
    isLight: theme.getIn(['styleVars', 'common', '--mode'], 'light') === 'light',
    computedClasses: classes
      .filter(v => !!v)
      .map(v => `landingPreview--${v}`)
      .join(' '),
  }
}

// ========================================================
// ACTIONS
// ========================================================
export const FetchThemes: () => DispatchBoundFn<any> = () => {
  return (dispatch, getState) => {
    const state = getState()
    const app = currentAppSelector(state)
    if (state.theme.loadingState === LoadingStatus.INIT)
      return legacyPromiseActionCreator({
        dispatch,
        payload: null,
        promise: api.fetchThemes(app),
        actionName: 'FETCH_THEMES',
      })
  }
}

type FetchThemeAction = {
  type: 'FETCH_THEMES'
  payload: null
}
type FetchThemeSuccessAction = {
  type: 'FETCH_THEMES_SUCCESS'
  payload: {
    themes: ThemeRecord[]
  }
}

type FetchThemeFailureAction = {
  type: 'FETCH_THEMES_FAILURE'
  payload: {
    error: string
  }
}

// SET ACTIVE THE%E
type SetActiveThemeAction = {
  type: 'THEME_SET_ACTIVE'
  payload: number | 'new'
}
export const SetActiveTheme: (id: number | 'new') => SetActiveThemeAction = id => {
  return {
    type: 'THEME_SET_ACTIVE',
    payload: id,
  }
}

// ATTACH / DETACH theme
type LinkThemeAction = {
  type: 'THEME_APP_LINK'
  payload: {
    appId: number
    theme: ThemeRecord
    link: boolean
  }
}
export type LinkThemeActionSuccess = {
  type: 'THEME_APP_LINK_SUCCESS'
  payload: {
    appId: number
    theme: ThemeRecord
    link: boolean
  }
}
export type LinkThemeActionFailure = {
  type: 'THEME_APP_LINK_FAILURE'
  payload: {
    error: number
  }
}
export const LinkTheme: (
  theme: ThemeRecord,
  link: boolean,
  appId: number
) => DispatchOnlyBoundFn<any> = (theme, link, appId) => {
  return dispatch => {
    return promiseActionCreator({
      dispatch,
      payload: {
        appId,
        theme: theme,
        link,
      },
      promise: api.linkTheme(appId, theme, link),
      actionName: 'THEME_APP_LINK',
    })
  }
}
// LINK THEMES
type AttachThemesAction = {
  type: 'ATTACH_THEMES_APP'
}
export type AttachThemesActionSuccess = {
  type: 'ATTACH_THEMES_APP_SUCCESS'
  payload: {
    themes: Map<number | 'new', ThemeRecord>
  }
}
type AttachThemesActionFailure = {
  type: 'ATTACH_THEMES_APP_FAILURE'
  payload: {
    error: number
  }
}

export const attachThemes: (arg1: {
  themes: Map<number | 'new', ThemeRecord>
  appId: number
}) => DispatchOnlyBoundFn<any> =
  ({ themes, appId }) =>
  dispatch => {
    return promiseActionCreator({
      dispatch,
      payload: {
        appId,
        theme: themes,
      },
      promise: api.linkThemes(appId, themes, true),
      actionName: 'ATTACH_THEMES_APP',
    })
  }

// ATTACH / DETACH theme
type ArchiveThemeAction = {
  type: 'THEME_ARCHIVE'
  payload: {
    theme: ThemeRecord
  }
}
export type ArchiveThemeActionSuccess = {
  type: 'THEME_ARCHIVE_SUCCESS'
  payload: {
    theme: ThemeRecord
  }
}
type ArchiveThemeActionFailure = {
  type: 'THEME_ARCHIVE_FAILURE'
  payload: {
    error: string
  }
}
export const ArchiveTheme: (theme: ThemeRecord) => DispatchBoundFn<any> = theme => {
  return (dispatch, getState) => {
    const state = getState()
    const app = state.app.current
    return legacyPromiseActionCreator({
      dispatch,
      payload: { theme },
      promise: api.archiveTheme(app, theme),
      actionName: 'THEME_ARCHIVE',
    })
  }
}

// DUPPLICATE THEME
type duplicateThemeAction = {
  type: 'DUPPLICATE_THEME'
  payload: ThemeRecord
}
export const duplicateTheme: (theme: ThemeRecord) => duplicateThemeAction = theme => {
  const newName = `${theme.name} – Copy`
  return {
    type: 'DUPPLICATE_THEME',
    payload: ThemeFactory()
      .set('color', theme.color)
      .set('dark', theme.dark)
      .set('name', newName)
      .set('code', slugify(newName))
      .set('fields', theme.fields)
      .set('statusBarMode', theme.statusBarMode)
      .set('payloadVars', theme.payloadVars)
      .set('fromDuplication', true),
  }
}

// NEW THEME INIT
type newThemeInitAction = {
  type: 'NEW_THEME_INIT'
  payload: ThemeRecord
}
export const newThemeInit: () => newThemeInitAction = () => ({
  type: 'NEW_THEME_INIT',
  payload: ThemeFactory(),
})

// UPDATE THEME
type ThemeUpdateAction = {
  type: 'THEME_UPDATE'
  payload: {
    what: keyof ThemeProps
    value: string | boolean | number
  }
}
export const UpdateTheme: (arg1: {
  what: keyof ThemeProps
  value: string | boolean | number
}) => ThemeUpdateAction = ({ what, value }) => {
  return {
    type: 'THEME_UPDATE',
    payload: { what, value },
  }
}

// UPDATE SELECTED FIELD
type SelectThemeFieldAction = {
  type: 'THEME_SELECT_FIELD'
  payload: {
    field: FieldKind | null | undefined
    variant: Variant | null | undefined
  }
}
export const SelectThemeField: (arg1: {
  field: FieldKind | null | undefined
  variant: Variant | null | undefined
}) => SelectThemeFieldAction = ({ field, variant }) => ({
  type: 'THEME_SELECT_FIELD',
  payload: { field, variant },
})

// UPDATE THEME PAYLOAD

type ThemeUpdatePayloadAction = {
  type: 'THEME_UPDATE_PAYLOAD'
  payload: {
    what: keyof PayloadProps
    value: string | boolean | number
  }
}
export const UpdateThemePayload: (arg1: {
  what: keyof PayloadProps
  value: string | boolean | number
}) => ThemeUpdatePayloadAction = ({ what, value }) => {
  return {
    type: 'THEME_UPDATE_PAYLOAD',
    payload: { what, value },
  }
}
// UPDATE A FIELD

type UpdateThemeFieldAction = {
  type: 'UPDATE_THEME_FIELD'
  payload: {
    field: FieldRecord
  }
}
export const UpdateThemeField: (field: FieldRecord) => UpdateThemeFieldAction = field => {
  return {
    type: 'UPDATE_THEME_FIELD',
    payload: { field },
  }
}
// UPDATE A FIELD STYLE

type UpdateThemeFieldStyleAction = {
  type: 'UPDATE_THEME_FIELD_STYLE'
  payload: {
    field: FieldRecord
    property: StyleProperty
    value: any
  }
}
export const UpdateThemeFieldStyle: (arg1: {
  field: FieldRecord
  property: StyleProperty
  value: any
}) => UpdateThemeFieldStyleAction = ({ field, property, value }) => {
  return {
    type: 'UPDATE_THEME_FIELD_STYLE',
    payload: { field, property, value },
  }
}

// FETCH USED LIVE CPG
type ThemeFetchRunningCampignsAction = {
  type: 'THEME_FETCH_RUNNING_CAMPAIGNS'
  payload: {
    theme: ThemeRecord
  }
}

type ThemeFetchRunningCampignsSuccessAction = {
  type: 'THEME_FETCH_RUNNING_CAMPAIGNS_SUCCESS'
  payload: {
    theme: ThemeRecord
    campaigns: Set<CampaignRecord>
  }
}
type ThemeFetchRunningCampignsFailureAction = {
  type: 'THEME_FETCH_RUNNING_CAMPAIGNS_FAILURE'
  payload: string
}
export const FetchRunningCampaignForTheme = (theme: ThemeRecord): DispatchBoundFn<Promise<any>> => {
  return dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      payload: { theme },
      actionName: 'THEME_FETCH_RUNNING_CAMPAIGNS',
      promise: api.fetchLinkedCampaigns(theme),
    })
  }
}

// SAVE A THEME
type ThemeSaveAction = {
  type: 'THEME_SAVE'
  payload: null
}

export type ThemeSaveSuccessAction = {
  type: 'THEME_SAVE_SUCCESS'
  payload: {
    theme: ThemeRecord
    isDuplication: boolean
  }
}
export type ThemeSaveFailureAction = {
  type: 'THEME_SAVE_FAILURE'
  payload: string
}

export const SaveTheme = (
  theme: ThemeRecord,
  isDuplication: boolean
): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const app = state.app.current
    const { companyId, id: appId } = app
    const projectId = state.project.currentProjectId
    const redirectTo =
      companyId && projectId
        ? generateSettingsUrl({
            companyId,
            projectId,
            activeTab: 'themes',
            channel: { appId },
          })
        : config.common.urls.appSettings
            .replace('{companyId}', companyId?.toString() ?? '')
            .replace('{appId}', appId?.toString() ?? '')
            .replace('{activeTab}', 'themes')

    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: api.saveTheme(app, theme, isDuplication),
      actionName: 'THEME_SAVE',
      successCallback: () => {
        window.location.href = redirectTo
      },
    })
  }
}

type ThemeAction =
  | ThemeUpdateAction
  | ThemeSaveAction
  | ThemeUpdatePayloadAction
  | UpdateThemeFieldAction
  | SelectThemeFieldAction
  | SetActiveThemeAction // | ThemeSetSample
  | UpdateThemeFieldStyleAction
  | FetchThemeAction
  | FetchThemeSuccessAction
  | FetchThemeFailureAction
  | ThemeSaveFailureAction
  | ThemeSaveSuccessAction
  | ThemeFetchRunningCampignsAction
  | ThemeFetchRunningCampignsSuccessAction
  | ThemeFetchRunningCampignsFailureAction
  | LinkThemeAction
  | LinkThemeActionSuccess
  | LinkThemeActionFailure
  | AttachThemesAction
  | AttachThemesActionSuccess
  | AttachThemesActionFailure
  | ArchiveThemeAction
  | ArchiveThemeActionSuccess
  | ArchiveThemeActionFailure
  | duplicateThemeAction
  | newThemeInitAction
// ========================================================
// REDUCER
// ========================================================
function singleThemeReducer(state: ThemeRecord = ThemeFactory(), action: ThemeAction) {
  switch (action.type) {
    case 'THEME_FETCH_RUNNING_CAMPAIGNS':
      return state.set('loading', true)

    case 'THEME_FETCH_RUNNING_CAMPAIGNS_SUCCESS':
      return state
        .set('campaigns', action.payload.campaigns)
        .set('loading', false)
        .set('linkedCampaignsLoaded', true)

    case 'UPDATE_THEME_FIELD_STYLE':
      return state.set(
        'fields',
        state.fields.set(
          action.payload.field.id,
          action.payload.field.set(
            'style',
            action.payload.field.style.set(action.payload.property, action.payload.value)
          )
        )
      )

    case 'THEME_APP_LINK_SUCCESS':
      if (action.payload.link) {
        return state.set('apps', state.apps.add(action.payload.appId))
      } else {
        return state.set('apps', state.apps.delete(action.payload.appId))
      }

    case 'UPDATE_THEME_FIELD': {
      const field: FieldRecord = action.payload.field
      return api.ensureClosability(state.set('fields', state.fields.set(field.id, field)))
    }
    case 'THEME_UPDATE_PAYLOAD': {
      const varName = action.payload.what
      let newState = state
      if (varName === 'kind') {
        if (
          action.payload.value === 'banner' ||
          action.payload.value === 'universal' ||
          action.payload.value === 'modal' ||
          action.payload.value === 'image' ||
          action.payload.value === 'webview' ||
          action.payload.value === ''
        ) {
          newState = state.set(
            'payloadVars',
            newState.payloadVars.set('kind', action.payload.value)
          )
        }
      } else if (varName !== 'hero_split_ratio') {
        newState = newState.set(
          'payloadVars', // $FlowExpectedError we need to coerce here
          newState.payloadVars.set(varName, !!action.payload.value)
        )
      }
      if (action.payload.what === 'kind') {
        newState = api.buildFieldsForTheme(newState)
      }
      return newState
    }
    case 'THEME_UPDATE': {
      const what = action.payload.what
      switch (what) {
        case 'name':
        case 'code':
          return state.set(what, action.payload.value.toString())
        case 'color':
          return api.buildFieldsForTheme(state.set(what, action.payload.value.toString()))
        case 'dark':
          return api.buildFieldsForTheme(state.set(what, !!action.payload.value))
        case 'statusBarMode':
          return state.set(
            what,
            action.payload.value === 'light'
              ? 'light'
              : action.payload.value === 'dark'
                ? 'dark'
                : 'auto'
          )
        default:
          return state
      }
    }
    default:
      return state
  }
}

function themeReducer(
  state: ThemeStateRecord = ThemeStateFactory(),
  action: ThemeAction
): ThemeStateRecord {
  switch (action.type) {
    case 'FETCH_THEMES':
    case 'THEME_ARCHIVE':
    case 'THEME_APP_LINK':
      return state
        .set('loadingState', LoadingStatus.LOADING)
        .setIn(['entities', 'new'], ThemeFactory())

    case 'ATTACH_THEMES_APP':
      return state.set('loadingState', LoadingStatus.LOADING)

    case 'FETCH_THEMES_FAILURE':
      return state.set('loadingState', LoadingStatus.ERROR)

    case 'THEME_ARCHIVE_FAILURE':
    case 'THEME_APP_LINK_FAILURE':
    case 'ATTACH_THEMES_APP_FAILURE':
      return state.set('loadingState', LoadingStatus.ERROR)

    case 'FETCH_THEMES_SUCCESS': {
      let entities = state.entities
      action.payload.themes.forEach(theme => {
        entities = entities.set(theme.id, theme)
      })
      entities = entities.set('new', ThemeFactory())
      return state.set('loadingState', LoadingStatus.LOADED).set('entities', entities)
    }
    case 'THEME_SET_ACTIVE':
      return state.set('editing', action.payload)

    case 'THEME_SELECT_FIELD':
      return state.set(
        'selectedField',
        action.payload.field
          ? `${action.payload.field}${action.payload.variant ? '-' : ''}${
              action.payload.variant ?? ''
            }`
          : ''
      )

    case 'THEME_ARCHIVE_SUCCESS':
      return state
        .set('loadingState', LoadingStatus.LOADED)
        .set('entities', state.entities.delete(action.payload.theme.id))

    case 'THEME_APP_LINK_SUCCESS':
      return state
        .set('loadingState', LoadingStatus.LOADED)
        .set(
          'entities',
          state.entities.set(
            action.payload.theme.id,
            singleThemeReducer(state.entities.get(action.payload.theme.id, ThemeFactory()), action)
          )
        )

    case 'ATTACH_THEMES_APP_SUCCESS':
      return state
        .set('loadingState', LoadingStatus.LOADED)
        .set('entities', state.entities.merge(action.payload.themes))

    case 'THEME_FETCH_RUNNING_CAMPAIGNS':
    case 'THEME_FETCH_RUNNING_CAMPAIGNS_SUCCESS':
      return state.set(
        'entities',
        state.entities.set(
          action.payload.theme.id,
          singleThemeReducer(state.entities.get(action.payload.theme.id, ThemeFactory()), action)
        )
      )
    case 'THEME_UPDATE':
    case 'THEME_UPDATE_PAYLOAD':
    case 'UPDATE_THEME_FIELD':
    case 'UPDATE_THEME_FIELD_STYLE':
      return state.set(
        'entities',
        state.entities.set(
          state.editing,
          singleThemeReducer(state.entities.get(state.editing, ThemeFactory()), action)
        )
      )

    case 'DUPPLICATE_THEME':
    case 'NEW_THEME_INIT':
      return state.set('entities', state.entities.set('new', action.payload))

    default:
      return state
  }
}

export default themeReducer
