/* eslint-disable react/jsx-no-bind */
import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import styled, { css } from 'styled-components'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { PermissionButton } from 'components/common/button/permission-button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import { Checkbox } from 'components/form'

import { CappingCategoryFactory, type CappingCategoryRecord } from 'com.batch.redux/_records'
import { currentAppLoadingState, labelsSelector } from 'com.batch.redux/app'
import { saveCappingCategory, updatePressureCategory } from 'com.batch.redux/app.action'
import { actions } from 'com.batch.redux/campaign'
import { toggleLabel } from 'com.batch.redux/campaign.action'

import { LoadingStatus } from 'constants/common'

const LabelHead = styled.h5`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: #9fa5b5;
  margin: 32px 32px 5px 32px;
`

const LabelCount = styled.span`
  padding: 0 0 0 10px;
  color: #9fa5b5;
  font-size: 12px;
`
const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 200px;
  max-width: 640px;
  @media (min-width: 750px) {
    min-width: 600px;
  }
  label {
    display: flex;
    margin-bottom: 6px;
  }
  padding: 10px 32px 25px 32px;
  ${(props: { sep?: boolean }) =>
    props.sep &&
    css`
      border-bottom: 1px solid #f2f3f6;
    `};
`

const Footer = styled.div`
  padding: 10px 32px 25px 32px;
`

type Props = {
  onClose: () => void
  appId: number
  schedulingType: schedulingType
  pickedLabels: Set<CappingCategoryRecord>
}

const hasLabelInSet = (label: CappingCategoryRecord, set: Set<CappingCategoryRecord>): boolean => {
  return set.map(label => label.id).contains(label.id)
}

export const LabelPicker = ({
  onClose,
  appId,
  schedulingType,
  pickedLabels,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(actions.fetchCampaignsLabelsCount(appId))
  }, [appId, dispatch])

  const [toToggle, setToToggle] = React.useState(Immutable.Set<CappingCategoryRecord>())
  const [toRemove, setToRemove] = React.useState(Immutable.Set<CappingCategoryRecord>())
  const currentLabelCount = React.useMemo(
    () => pickedLabels.size - toRemove.size + toToggle.size,
    [pickedLabels.size, toRemove.size, toToggle.size]
  )
  const isLabelUsed = React.useCallback(
    (label: CappingCategoryRecord): boolean => {
      const isInProps = hasLabelInSet(label, pickedLabels)
      const isInToRemove = hasLabelInSet(label, toRemove)
      const isInToToggle = hasLabelInSet(label, toToggle)
      return (isInProps && !isInToRemove) || isInToToggle
    },
    [pickedLabels, toRemove, toToggle]
  )
  const onToggle = React.useCallback(
    (label: CappingCategoryRecord) => {
      if (currentLabelCount >= 3 && !isLabelUsed(label)) {
        return
      }
      if (hasLabelInSet(label, pickedLabels)) {
        setToRemove(toRemove =>
          hasLabelInSet(label, toRemove) ? toRemove.remove(label) : toRemove.add(label)
        )
      } else {
        setToToggle(toToggle =>
          hasLabelInSet(label, toToggle) ? toToggle.remove(label) : toToggle.add(label)
        )
      }
    },
    [currentLabelCount, isLabelUsed, pickedLabels]
  )

  const onDismiss = React.useCallback(() => {
    setToToggle(Immutable.Set())
    setToRemove(Immutable.Set())
    onClose()
  }, [onClose])
  const onConfirm = React.useCallback(() => {
    toToggle.forEach(label => {
      dispatch(toggleLabel(label))
    })
    toRemove.forEach(label => {
      dispatch(toggleLabel(label))
    })
    onDismiss()
  }, [dispatch, onDismiss, toRemove, toToggle])
  const labels = useSelector(labelsSelector)
  const loadingState = useSelector(currentAppLoadingState)
  const newLabel = React.useMemo(
    () => labels.find(cat => cat.id === 'new', null, CappingCategoryFactory()),
    [labels]
  )
  const onUpdateLabel = React.useCallback(
    evt => {
      if (!newLabel) {
        return
      }
      dispatch(
        updatePressureCategory({
          categorieRecord: newLabel,
          updated: newLabel.set('name', evt.target.value.toString()),
        })
      )
    },
    [dispatch, newLabel]
  )

  const onSaveNewLabel = React.useCallback(() => {
    if (!newLabel) {
      return
    }
    dispatch(saveCappingCategory(newLabel, 'campaign'))
  }, [dispatch, newLabel])
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle
          title={`Associate labels to ${
            schedulingType === 'campaigns' ? 'campaign' : 'automation'
          }`}
        />
      </BoxHeader>
      <BoxBody>
        <Loader
          overlay
          loading={loadingState === LoadingStatus.LOADING || loadingState === LoadingStatus.INIT}
        >
          <LabelHead>Select existing labels</LabelHead>
          <Scrollable sep>
            {labels.size === 0 && (
              <p className="text-muted">
                No label found for this app. Use the form below to create one.
              </p>
            )}
            {labels
              .filter(l => l.id !== 'new')
              .map(label => (
                <Checkbox
                  key={label.id}
                  size={16}
                  disabled={currentLabelCount >= 3 && !isLabelUsed(label)}
                  onChange={() => onToggle(label)}
                  checked={isLabelUsed(label)}
                  label={
                    <React.Fragment>
                      {label.name}
                      <LabelCount>
                        {!label.count ? 'No campaign' : `${label.count} campaigns`}
                      </LabelCount>
                    </React.Fragment>
                  }
                />
              ))}
          </Scrollable>
          <LabelHead>Create a new label</LabelHead>
          <Footer>
            <FlexLine>
              <FlexLineItem grow={1}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={newLabel?.name}
                  onChange={onUpdateLabel}
                />
              </FlexLineItem>
              <FlexLineItem grow={1}>
                <PermissionButton
                  kind="primary"
                  intent="action"
                  isAllowed={labels.size < 500}
                  notAllowedMessage="You can't create more than 500 labels"
                  disabled={
                    !newLabel || !newLabel.name || !!(newLabel.code && newLabel.code.length < 4)
                  }
                  onClick={onSaveNewLabel}
                >
                  Create
                </PermissionButton>
              </FlexLineItem>
            </FlexLine>
          </Footer>
        </Loader>
      </BoxBody>
      <BoxFooter isEditable>
        <Button kind="inline" intent="neutral" onClick={onDismiss}>
          Cancel
        </Button>
        <FooterBoxActions>
          <Button kind="primary" intent="action" onClick={onConfirm}>
            Associate labels ({currentLabelCount})
          </Button>
        </FooterBoxActions>
      </BoxFooter>
    </Box>
  )
}
