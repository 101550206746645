import dayjs, { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf } from 'immutable'

import { LoadingStatus } from 'constants/common'

export type AllowedAudienceType = 'custom_ids' | 'advertising_ids' | 'install_ids'

type ProfileAudienceProps = {
  name: string
  displayName: string
  type: AllowedAudienceType
  updated: Dayjs
  created: Dayjs
  nbIdentifier: number
  loading: LoadingStatus
}

export const ProfileAudienceFactory = Immutable.Record<ProfileAudienceProps>({
  name: '',
  displayName: '',
  type: 'custom_ids',
  created: dayjs(),
  updated: dayjs(),
  nbIdentifier: 0,
  loading: LoadingStatus.LOADED,
} as ProfileAudienceProps)
export type ProfileAudienceRecord = RecordOf<ProfileAudienceProps>
