import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { type OrderedMap } from 'immutable'
import { $getRoot, LineBreakNode, ParagraphNode, TextNode, type EditorState } from 'lexical'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { LexicalFocusPlugin } from 'components/editor/focus-plugin'
import { LogicalNode } from 'components/editor/logical-node'
import { LogicalPlugin } from 'components/editor/logical-plugin'
import { TagNode } from 'components/editor/tag-node'
import { TagPlugin } from 'components/editor/tag-plugin'
import { lexicalInitState } from 'components/form/fields/editor/lexical-init-state'
import { RemoveLineBreaksOnPastePlugin } from 'components/form/fields/editor/paste-override-lexical-plugin'
import { SyncValuePlugin } from 'components/form/fields/editor/sync-value-lexical-plugin'

import { type EditorConnectedProps } from './editor-connected'
import { EditorContainer } from './editor.styles'
import { ModalTemplatePlugin } from './modal-template'

import { type AttributeRecord } from 'com.batch.redux/_records'
import { colors } from 'components/styled/tokens'
import { Icon } from 'components/common/svg-icon'

export type EditorProps = EditorConnectedProps & {
  attributes: OrderedMap<string, AttributeRecord>
  contextEventId: string
}

const AutoFocusPlugin = () => {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    editor.focus()
  }, [editor])
  return null
}

// -------- handle on writing

export const Editor = ({
  id,
  placeholder,
  value,
  onChange,
  contextEventId,
  attributes,
  style,
  forcedFocus,
  onFocus,
  disabled,
  prefix,
}: EditorProps): React.ReactElement => {
  const theme = useTheme()
  disabled = disabled ?? theme?.disabledMode ?? false

  // Theme
  const lexicalTheme = {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
  }

  // Create config
  const conf = {
    theme: lexicalTheme,
    namespace: 'BatchLexicalEditor',
    editorState: lexicalInitState(value),
    onError: (e: Error) => {
      console.log(e.message)
    },
    editable: !disabled,
    nodes: [LineBreakNode, ParagraphNode, TextNode, LogicalNode, TagNode],
  }

  // --------- Manage State Functions -------------

  const onTemplateChange = React.useCallback(
    (State: EditorState) => {
      let computedText = ''
      State.read(() => {
        computedText = $getRoot().getTextContent()
      })

      if (computedText !== value && onChange) {
        onChange(computedText)
      }
    },
    [onChange, value]
  )

  return (
    <LexicalComposer initialConfig={conf}>
      <EditorContainer style={style} id={id}>
        {!!prefix && (
          <div className="editor-prefix">
            <Icon
              icon={prefix.value}
              color={disabled ? colors.textDisabled : prefix.color}
              style={prefix.style}
            />
          </div>
        )}
        <ModalTemplatePlugin
          onChange={onChange}
          attributes={attributes}
          contextEventId={contextEventId}
          id={id}
        />
        <PlainTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={
            <ContentEditable
              spellCheck={false}
              className={`editor-input ${disabled ? 'disabled' : ''} ${prefix ? 'prefixed' : ''}`}
              ariaLabel={id}
            />
          }
          placeholder={
            <div className={`editor-placeholder ${prefix ? 'prefixed' : ''}`}>{placeholder}</div>
          }
        />
        <LogicalPlugin />
        <TagPlugin />
        <OnChangePlugin onChange={onTemplateChange} />
        <LexicalFocusPlugin onFocus={onFocus} />
        <HistoryPlugin />
        {forcedFocus && <AutoFocusPlugin />}
        <RemoveLineBreaksOnPastePlugin endOfLineOnly />
        <SyncValuePlugin value={value} />
      </EditorContainer>
    </LexicalComposer>
  )
}
