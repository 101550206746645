import Immutable, { type Map } from 'immutable'

import { legacyPromiseActionCreator, promiseActionCreator } from './actionCreator'
import * as api from './user.api'

import {
  type CompanyRecord,
  type DispatchOnlyBoundFn,
  type DispatchBoundFn,
} from 'com.batch.redux/_records'
import { type updateAllowedUsersSuccessAction } from 'com.batch.redux/app.action'
import { normalizeUser } from 'com.batch.redux/user.api'
import {
  UserStateFactory,
  UserFactory,
  type UserStateRecord,
  type UserRecord,
} from 'com.batch.redux/user.records'

import { LoadingStatus } from 'constants/common'

const user = window.user ? normalizeUser(window.user) : UserFactory()

const initialState: UserStateRecord = UserStateFactory({
  entities: Immutable.Map(user.id ? [[user.id, user]] : []),
  currentUserId: user.id,
})

export const listUsersByCompany = (
  company: CompanyRecord
): DispatchOnlyBoundFn<Promise<Map<number, UserRecord>>> => {
  return dispatch =>
    promiseActionCreator<Map<number, UserRecord>>({
      dispatch,
      promise: api.listByCompany(company),
      actionName: 'LIST_USERS_BY_COMPANY',
    })
}

type InviteUserAction = {
  type: 'INVITE_USER'
  payload: null
}
export type InviteUserSuccessAction = {
  type: 'INVITE_USER_SUCCESS'
  payload: {
    user: UserRecord
    company: CompanyRecord
  }
}
export type InviteUserFailureAction = {
  type: 'INVITE_USER_FAILURE'
  payload: null
}
export const inviteUser =
  ({
    user,
    company,
  }: {
    user: UserRecord
    company: CompanyRecord
  }): DispatchOnlyBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'INVITE_USER',
      dispatch,
      promise: api.inviteUser({ user, company }),
      payload: null,
    })

type ResendInviteAction = {
  type: 'RESEND_INVITE'
  payload: UserRecord
}
export type ResendInviteSuccessAction = {
  type: 'RESEND_INVITE_SUCCESS'
  payload: UserRecord
}
type ResendInviteFailureAction = {
  type: 'RESEND_INVITE_FAILURE'
  payload: UserRecord
}
export const resendInvite =
  ({
    user,
    company,
  }: {
    user: UserRecord
    company: CompanyRecord
  }): DispatchBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'RESEND_INVITE',
      dispatch,
      promise: api.resendInvite(company, user),
      payload: user,
    })

type deleteUserAction = {
  type: 'DELETE_USER_FOR_COMPANY'
  payload: UserRecord
}
export type deleteUserSuccessAction = {
  type: 'DELETE_USER_FOR_COMPANY_SUCCESS'
  payload: UserRecord
}
export type deleteUserFailureAction = {
  type: 'DELETE_USER_FOR_COMPANY_FAILURE'
  payload: UserRecord
}
export const deleteUser =
  (company: CompanyRecord, user: UserRecord): DispatchBoundFn<Promise<any>> =>
  dispatch =>
    legacyPromiseActionCreator({
      actionName: 'DELETE_USER_FOR_COMPANY',
      dispatch,
      promise: api.deleteUserForCompany(company, user),
      payload: user,
    })

export const updateUserPermissions = (
  company: CompanyRecord,
  user: UserRecord
): DispatchOnlyBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: user,
      promise: api.updatePermissions(company, user),
      actionName: 'UPDATE_USER_PERMISSIONS',
    })
}
export const updateUser = (user: UserRecord): DispatchOnlyBoundFn<any> => {
  return dispatch =>
    promiseActionCreator<UserRecord>({
      dispatch,
      payload: user,
      promise: api.updateUser(user),
      actionName: 'UPDATE_USER',
    })
}
export const disable2FA = (user: UserRecord): DispatchOnlyBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: user,
      promise: api.disable2FA(user),
      actionName: 'DISABLE_2FA',
    })
}
export const enable2FA = ({
  user,
  code,
  totpUri,
}: {
  user: UserRecord
  code: string
  totpUri: string
}): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: user,
      promise: api.enable2FA({ user, code, totpUri }),
      actionName: 'ENABLE_2FA',
    })
}

export const updateUserPassword = ({
  user,
  currentPassword,
  newPassword,
}: {
  user: UserRecord
  currentPassword: string
  newPassword: string
}): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: user,
      promise: api.updatePassword({ user, currentPassword, newPassword }),
      actionName: 'UPDATE_USER_PASSWORD',
    })
}

type listByCompany = {
  type: 'LIST_USERS_BY_COMPANY'
  payload: null
}

export type listByCompanySuccess = {
  type: 'LIST_USERS_BY_COMPANY_SUCCESS'
  payload: Map<number, UserRecord>
}

type listByCompanyFailure = {
  type: 'LIST_USERS_BY_COMPANY_FAILURE'
  payload: null
}

type updatePermissions = {
  type: 'UPDATE_USER_PERMISSIONS'
  payload: UserRecord
}

export type updatePermissionsSuccess = {
  type: 'UPDATE_USER_PERMISSIONS_SUCCESS'
  payload: UserRecord
}

export type updatePermissionsFailure = {
  type: 'UPDATE_USER_PERMISSIONS_FAILURE'
  payload: {
    user: UserRecord
    errors: [
      {
        message: string
      },
    ]
  }
}

type updateUserAction = {
  type: 'UPDATE_USER'
  payload: UserRecord
}

export type updateUserSuccessAction = {
  type: 'UPDATE_USER_SUCCESS'
  payload: UserRecord
}

export type updateUserFailureAction = {
  type: 'UPDATE_USER_FAILURE'
  payload: {
    user: UserRecord
    errors: Array<string>
  }
}
type updateUserPasswordAction = {
  type: 'UPDATE_USER_PASSWORD'
  payload: UserRecord
}

export type updateUserPasswordSuccessAction = {
  type: 'UPDATE_USER_PASSWORD_SUCCESS'
  payload: UserRecord
}

type updateUserPasswordFailureAction = {
  type: 'UPDATE_USER_PASSWORD_FAILURE'
  payload: {
    user: UserRecord
    errors: Array<string>
  }
}

type disable2FAAction = {
  type: 'DISABLE_2FA'
  payload: UserRecord
}

export type disable2FASuccessAction = {
  type: 'DISABLE_2FA_SUCCESS'
  payload: UserRecord
}

export type disable2FAFailureAction = {
  type: 'DISABLE_2FA_FAILURE'
  payload: {
    user: UserRecord
    errors: Array<string>
  }
}

type enable2FAAction = {
  type: 'ENABLE_2FA'
  payload: UserRecord
}

export type enable2FASuccessAction = {
  type: 'ENABLE_2FA_SUCCESS'
  payload: {
    user: UserRecord
    codes: Array<string>
  }
}

type enable2FAFailureAction = {
  type: 'ENABLE_2FA_FAILURE'
  payload: {
    user: UserRecord
    errors: Array<string>
  }
}

export type UserActions =
  | listByCompany
  | listByCompanySuccess
  | listByCompanyFailure
  | updatePermissions
  | updatePermissionsSuccess
  | updatePermissionsFailure
  | updateUserAction
  | updateUserSuccessAction
  | updateUserFailureAction
  | updateUserPasswordAction
  | updateUserPasswordSuccessAction
  | updateUserPasswordFailureAction
  | disable2FAAction
  | disable2FASuccessAction
  | disable2FAFailureAction
  | enable2FAAction
  | enable2FASuccessAction
  | enable2FAFailureAction
  | deleteUserAction
  | deleteUserSuccessAction
  | deleteUserFailureAction
  | InviteUserAction
  | InviteUserSuccessAction
  | InviteUserFailureAction
  | ResendInviteAction
  | ResendInviteSuccessAction
  | ResendInviteFailureAction
  | updateAllowedUsersSuccessAction

export default function userReducer(
  state: UserStateRecord = initialState,
  action: UserActions
): UserStateRecord {
  switch (action.type) {
    case 'DELETE_USER_FOR_COMPANY_SUCCESS':
      return state
        .set('entities', state.entities.remove(action.payload.id || -1))
        .set('loadingState', LoadingStatus.LOADED)
    case 'DELETE_USER_FOR_COMPANY':
      return state.set('loadingState', LoadingStatus.LOADING)
    case 'LIST_USERS_BY_COMPANY':
      return state.set('loadingState', LoadingStatus.LOADING)
    case 'UPDATE_ALLOWED_USERS_SUCCESS':
    case 'LIST_USERS_BY_COMPANY_SUCCESS':
      return state
        .set('entities', state.entities.merge(action.payload)) // pour le vis ma vie, mais ça marche bof
        .set('loadingState', LoadingStatus.LOADED)
    case 'INVITE_USER_SUCCESS':
      return state.set(
        'entities',
        state.entities.set(action.payload.user.id || 0, action.payload.user)
      )
    case 'DELETE_USER_FOR_COMPANY_FAILURE':
    case 'LIST_USERS_BY_COMPANY_FAILURE':
      return state.set('loadingState', LoadingStatus.ERROR)
    case 'UPDATE_USER':
    case 'UPDATE_USER_PASSWORD':
    case 'ENABLE_2FA':
    case 'RESEND_INVITE':
    case 'DISABLE_2FA':
    case 'UPDATE_USER_PERMISSIONS':
      return state.setIn(['entities', Number(action.payload.id), 'loading'], true)
    case 'ENABLE_2FA_SUCCESS':
      return state.setIn(['entities', Number(action.payload.user.id)], action.payload.user)
    case 'UPDATE_USER_SUCCESS':
    case 'UPDATE_USER_PASSWORD_SUCCESS':
    case 'DISABLE_2FA_SUCCESS':
    case 'UPDATE_USER_PERMISSIONS_SUCCESS':
      return state.setIn(['entities', Number(action.payload.id)], action.payload)
    case 'ENABLE_2FA_FAILURE':
      return state.setIn(['entities', Number(action.payload.user.id)], action.payload.user)
    case 'UPDATE_USER_FAILURE':
    case 'DISABLE_2FA_FAILURE':
    case 'UPDATE_USER_PASSWORD_FAILURE':
    case 'UPDATE_USER_PERMISSIONS_FAILURE':
      return state.setIn(['entities', Number(action.payload.user.id), 'loading'], false)
    case 'RESEND_INVITE_SUCCESS':
      return state.setIn(['entities', Number(action.payload.id)], action.payload)
    case 'RESEND_INVITE_FAILURE':
      return state.setIn(['entities', Number(action.payload.id), 'loading'], false)
    default:
      return state
  }
}
