import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { Button, IconDropdown } from 'components/common/button/'
import { Grid } from 'components/common/grid'
import { Loader } from 'components/common/loader/loader'
import { SelectMulti } from 'components/form'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type InputProps } from './helper'

import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { LoadingStatus } from 'constants/common'

type device = {
  value: string
  label: string
  platform: 'ios' | 'android'
}

const optionToString = (option?: device | null) => option?.label ?? '' + (option?.value ?? '')

// global cache, this is not app dependent and we only need to load once
let cache: List<device> = Immutable.List()
let loadingState: LoadingStatus = LoadingStatus.INIT

export const InputDeviceList = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const targetedApps = useSelector(currentCampaignAppsSelector)
  const platforms = React.useMemo(() => {
    return Immutable.Set(targetedApps.map(a => a.platform))
  }, [targetedApps])

  // state local to trigger a re-render when our fetch resolves
  const [loading, setLoading] = React.useState(loadingState !== LoadingStatus.LOADED)
  const [options, setOptions] = React.useState(cache)

  React.useEffect(() => {
    if (loadingState === LoadingStatus.INIT) {
      setLoading(true)
      loadingState = LoadingStatus.LOADING
      request.get<Array<device>>(generateUrl('api_native_attribute_value_devices')).then(
        response => {
          const alldevices = Immutable.List(response)
          cache = alldevices
          loadingState = LoadingStatus.LOADED
          setLoading(false)
          setOptions(alldevices.filter(d => platforms.has(d.platform)))
        },
        () => {
          setLoading(false)
          loadingState = LoadingStatus.ERROR
        }
      )
    } else {
      setOptions(cache.filter(d => platforms.has(d.platform)))
    }
  }, [loading, platforms])

  const mappedValues = React.useMemo<List<device>>(() => {
    return condition.value.stringList
      .map(v => {
        return cache.find(o => o.value === v, null, {
          label: 'not found - ' + v,
          value: v,
          platform: 'android',
        })
      })
      .filter(v => !!v)
  }, [condition.value.stringList])

  const onChangeLocal = React.useCallback(
    (value: List<device>) => {
      setInvalid(value.size < 1)
      updateCondition(
        condition.set(
          'value',
          condition.value.set('stringList', !value ? Immutable.List() : value.map(v => v.value))
        )
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])

  const onShortcutClick = React.useCallback(
    (search: string) => () => {
      const newValues = mappedValues.concat(
        options.filter(o => o.platform === 'ios' && o.label.toLowerCase().includes(search))
      )
      onChangeLocal(newValues)
    },
    [mappedValues, onChangeLocal, options]
  )

  return (
    <Loader loading={loading} overlay size="small">
      <Grid template={platforms.has('ios') ? '1fr 36px' : '1fr'}>
        <SelectMulti
          style={{ minWidth: 150 }}
          options={options}
          isLoading={loading}
          onBlur={onBlur}
          isClearable
          invalid={(invalid && isTouched) || isInvalid}
          value={mappedValues}
          optionToString={optionToString}
          onChange={onChangeLocal}
        />
        {platforms.has('ios') && (
          <IconDropdown>
            <Button onClick={onShortcutClick('iphone')}>All iPhones</Button>
            <Button onClick={onShortcutClick('ipad')}>All iPads</Button>
            <Button onClick={onShortcutClick('ipod')}>All iPods</Button>
          </IconDropdown>
        )}
      </Grid>
    </Loader>
  )
}
