import Immutable from 'immutable'

import { buildAgeFromDuration } from 'com.batch.common/utils'

import { AgeFactory } from 'com.batch.redux/_records'

import {
  PushSettingsAndroidOverrideFactory,
  AndroidPushMessageRawFactory,
  PushContentFactory,
  PushMessageRawFactory,
  PushContentTemplatesFactory,
  type PushContentRecord,
  type PushMessageRecord,
  type PushSettingsRecord,
  PushSettingsFactory,
  PushMessageFactory,
  IosPushMessageRawFactory,
  WebPushMessageRawFactory,
  PushSettingsIosOverrideFactory,
  AndroidPushContentTemplatesFactory,
  IosPushContentTemplatesFactory,
  WebPushContentTemplatesFactory,
} from 'com.batch/message/models/message.records'
import { parseFilterPushTokens } from 'com.batch/orchestration/infra/parses/push-token-target.parse'
import {
  type PushPlatforms,
  type OrchestrationPush,
} from 'com.batch/push/infra/types/push.message.types'

export const parsePush = (
  raw: OrchestrationPush
): {
  message: PushMessageRecord
  settings: PushSettingsRecord
  platforms: Immutable.Set<ProjectPlatforms>
  contentFullyEmpty: boolean
  contentInvalid: boolean
} => {
  const contentInvalid = false
  const contentFullyEmpty = false
  const tuplesLangValueArray: Array<[string, PushContentRecord]> | null | undefined =
    raw.localizedPush?.map(({ language, region, pushMessageContentTemplate }) => {
      const lang = language && region ? `${language}-${region}` : language ? language : 'default'
      // proto duration format is like "1s" for 1 second
      const p = pushMessageContentTemplate
      const pushContentRecord = PushContentFactory({
        content: PushMessageRawFactory({
          pushTitle: p.content.title,
          pushBody: p.content.body,
          pushPicture: p.content.picture ? p.content.picture.url : '',
          pushIcon: p.androidOverride?.icon?.url ?? p.webPushOverride?.icon?.url ?? '',
          deeplink: p.content.deepLink ?? '',
          templates: PushContentTemplatesFactory({
            pushTitle: p.content.title,
            pushBody: p.content.body,
            pushPicture: p.content.picture ? p.content.picture.url : '',
            pushIcon: p.androidOverride?.icon?.url ?? p.webPushOverride?.icon?.url ?? '',
            deeplink: p.content.deepLink ?? '',
          }),
        }),
        androidContent: AndroidPushMessageRawFactory({
          deeplink: p?.androidOverride?.deepLink ?? '',
          templates: AndroidPushContentTemplatesFactory({
            deeplink: p?.androidOverride?.deepLink ?? '',
          }),
        }),
        iosContent: IosPushMessageRawFactory({
          deeplink: p?.iosOverride?.deepLink ?? '',
          video: p?.iosOverride?.iosVideo?.url ?? '',
          audio: p?.iosOverride?.iosAudio?.url ?? '',
          templates: IosPushContentTemplatesFactory({
            deeplink: p?.iosOverride?.deepLink ?? '',
          }),
        }),
        webContent: WebPushMessageRawFactory({
          deeplink: p?.webPushOverride?.deepLink ?? '',
          templates: WebPushContentTemplatesFactory({
            deeplink: p?.webPushOverride?.deepLink ?? '',
          }),
        }),
      })
      return [lang, pushContentRecord]
    })
  const platforms: Array<ProjectPlatforms> =
    raw.pushPlatforms
      ?.filter((platform: PushPlatforms) =>
        ['PUSH_PLATFORM_ANDROID', 'PUSH_PLATFORM_IOS', 'PUSH_PLATFORM_WEB'].includes(platform)
      )
      .map((platform: PushPlatforms) => {
        switch (platform) {
          case 'PUSH_PLATFORM_ANDROID':
            return 'android'
          case 'PUSH_PLATFORM_IOS':
            return 'ios'
          default:
            return 'webpush'
        }
      }) ?? []

  let customPayload: Maybe<string> = undefined
  let androidCustomPayload: string = ''
  let iosCustomPayload: string = ''
  let androidCollapseKey: string = ''
  if (raw.localizedPush && tuplesLangValueArray) {
    const { pushMessageContentTemplate: first } = raw.localizedPush[0]
    customPayload = first.content.customPayload
    androidCollapseKey = first.androidOverride?.collapseKey ?? ''
    androidCustomPayload = first.androidOverride?.customPayload ?? ''
    iosCustomPayload = first.iosOverride?.customPayload ?? ''
  }
  return {
    message: PushMessageFactory({
      localizedContent: Immutable.OrderedMap(tuplesLangValueArray),
    }),
    settings: PushSettingsFactory({
      ttl: raw.ttl ? buildAgeFromDuration(raw.ttl) : AgeFactory(),
      ttlEnabled: Boolean(raw.ttl),
      priority: raw.priority,
      customPayload,
      filterPushTokens: parseFilterPushTokens(raw.filterPushTokens),
      androidOverride: PushSettingsAndroidOverrideFactory({
        collapseKey: androidCollapseKey,
        customPayload: androidCustomPayload,
      }),
      iosOverride: PushSettingsIosOverrideFactory({
        customPayload: iosCustomPayload,
      }),
    }),
    platforms: Immutable.Set(platforms),
    contentFullyEmpty,
    contentInvalid,
  }
}
