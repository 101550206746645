import { type OrderedMap } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import {
  type ProfileDataRecord,
  ProfileDataFactory,
} from 'com.batch/profile/models/profile.records'
import { LoadingStatus } from 'constants/common'

type SwitchProfileAction = {
  type: 'SWITCH_PROFILE'
  payload: {
    profileId: string
    data: OrderedMap<string, ProfileDataRecord>
  }
}

type SwitchProfileSuccessAction = {
  type: 'SWITCH_PROFILE_SUCCESS'
  payload: {
    data: OrderedMap<string, ProfileDataRecord>
  }
}

type SwitchProfileFailureAction = {
  type: 'SWITCH_PROFILE_FAILURE'
  payload: {
    data: OrderedMap<string, ProfileDataRecord>
    error: {
      errors: {
        message: string
      }[]
    }
  }
}

export type switchProfileAction =
  | SwitchProfileAction
  | SwitchProfileSuccessAction
  | SwitchProfileFailureAction

let abortFetchEventsController: AbortController = new AbortController()

export const switchProfile = (profileIndex: number): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { debugGateway }) => {
    const project = currentProjectSelector(getState())
    const profilesData = getState().profile.data
    const profileId = profilesData.keySeq().get(profileIndex) ?? ''
    const data = profilesData.get(profileId) ?? ProfileDataFactory()

    dispatch({
      type: 'SWITCH_PROFILE',
      payload: {
        profileId,
        data: profilesData.set(profileId, data?.set('eventsLoadingState', LoadingStatus.LOADING)),
      },
    })

    abortFetchEventsController.abort()
    abortFetchEventsController = new AbortController()

    try {
      const eventsData = await debugGateway.fetchEvents({
        project,
        profileId,
        abortSignal: abortFetchEventsController.signal,
      })
      dispatch({
        type: 'SWITCH_PROFILE_SUCCESS',
        payload: {
          data: profilesData.set(
            profileId,
            data?.set('events', eventsData)?.set('eventsLoadingState', LoadingStatus.LOADED)
          ),
        },
      })
    } catch (error: any) {
      if (!error.aborted) {
        console.log(error)
        dispatch({
          type: 'SWITCH_PROFILE_FAILURE',
          payload: {
            error,
            data: profilesData.set(profileId, data?.set('eventsLoadingState', LoadingStatus.ERROR)),
          },
        })
      }
    }
  }
}
