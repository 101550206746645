import { type Map } from 'immutable'

import { removeInvalidConditions } from './query.api'
import {
  ConditionFactory,
  FunctionParamsFactory,
  LogicalNodeFactory,
  type QueryRecord,
  type ConditionInputRecord,
  type ConditionRecord,
  type LogicalNodeRecord,
  type FunctionRecord,
} from './query.records'
import { ClickedMessage, OpenedMessage, SentMessage } from 'com.batch.redux/attribute.api'
import Immutable from 'immutable'

function getValue(value: ConditionInputRecord): any {
  switch (value.mode) {
    case 'InputInteger':
    case 'InputFloat':
      return value.number
    case 'InputBoolean':
      return value.boolean
    case 'InputAge':
      return `${value.age.value}${value.age.unit}`
    case 'InputDate':
      return value.date.value !== null ? value.date.value.unix() : null
    case 'InputSegment':
    case 'InputAudience':
    case 'InputStringList':
    case 'InputDeviceList':
      return value.stringList.toArray()
    case 'InputPrettyList':
      return value.numberList.toArray()
    case 'InputGoogleMap':
      return true
    case 'InputChannel':
      return value.retargetedOrchestration.channels.toArray()
    default:
      return value.string
  }
}

function formatLeft(condition: ConditionRecord): string {
  if (condition.value.mode === 'InputGoogleMap') {
    const fp = condition.functionParams ?? FunctionParamsFactory()
    return `isNear(lat:${fp.lat}, lng:${fp.lng}, radius:${fp.radius}m, expiration:12h)`
  }
  let left = condition.attribute?.api ?? ''
  const funcs = condition.functions.toArray()
  while (funcs.length > 0) {
    const func = funcs.pop() as FunctionRecord
    left = `${func.value}(${left})`
  }
  return left
}

function formatCondition(
  condition: ConditionRecord,
  isEventFilterContext: boolean = false
): Record<any, any> | null | undefined {
  if (!condition.attribute) throw new Error('Attribute is required')
  if (condition.attribute.type === 'EVENT') {
    // we will only have one func here, count or age
    if (condition.functions.size === 0) {
      throw new Error('Event attribute must have a function')
    }
    const func = condition.functions.first() as FunctionRecord
    if (!condition.attribute) throw new Error('Attribute is required')
    switch (func.value) {
      case 'count':
        return {
          $count: {
            [condition.attribute.api]: generateEventFilterBlock(condition),
            [`$${condition.operator.value}`]: getValue(condition.value),
          },
        }
      // identical to count, but with a period
      case 'countSince':
        return {
          $count: {
            [condition.attribute.api]: generateEventFilterBlock(condition),
            [`$${condition.operator.value}`]: getValue(condition.value),
            ['$period']: `${condition.functionParams?.age.inputValue ?? ''}${
              condition.functionParams?.age.unit ?? ''
            }`,
          },
        }
      case 'age': {
        return {
          $age: {
            [condition.attribute.api]: generateEventFilterBlock(condition),
            [`$${condition.operator.value}`]: getValue(condition.value),
          },
        }
      }
    }
  }
  const baseCond = {
    [formatLeft(condition)]: {
      [`$${condition.operator.value}`]: getValue(condition.value),
    },
  }
  if (condition.isEventFilterNegated && isEventFilterContext) return { $not: baseCond }
  return baseCond
}

const generateEventFilterBlock = (condition: ConditionRecord): Record<any, any> => {
  return {
    $match: condition.eventFilters
      .map(filter => {
        // to handle multiple conditions on the same line for orchestration targeting, we need to expand the single condition into multiple
        if (
          [ClickedMessage.id, SentMessage.id, OpenedMessage.id].includes(
            condition.attribute?.api ?? ''
          )
        ) {
          if (
            filter.value.retargetedOrchestration.stepId ||
            filter.value.retargetedOrchestration.orchestrationToken
          ) {
            const orchestrationIdFilter = filter
              .setIn(['attribute', 'api'], 'b.orchestration_id')
              .setIn(['value', 'string'], filter.value.retargetedOrchestration.orchestrationToken)

            const stepIdFilter = filter
              .setIn(['attribute', 'api'], 'b.step_id')
              .setIn(['value', 'string'], filter.value.retargetedOrchestration.stepId)

            return filter.value.retargetedOrchestration.stepId
              ? Immutable.List([
                  formatCondition(orchestrationIdFilter, true),
                  formatCondition(stepIdFilter, true),
                ])
              : formatCondition(orchestrationIdFilter, true)
          } else {
            // channel attribute
            return formatCondition(filter, true)
          }
        }
        // else we just format the condition
        return formatCondition(filter, true)
      })
      .flatten()
      .toArray(),
  }
}

function formatNodeOrCondition(
  nodeOrConditionId: LogicalNodeRecord | string,
  conditions: Map<string, ConditionRecord>
): Record<any, any> | null | undefined {
  return typeof nodeOrConditionId === 'string'
    ? formatCondition(conditions.get(nodeOrConditionId, ConditionFactory()))
    : formatTree(nodeOrConditionId, conditions)
}

function formatTree(
  node: LogicalNodeRecord,
  conditions: Map<string, ConditionRecord>
): Record<any, any> | null | undefined {
  if (node.descendants.size === 1) {
    if (node.value === 'not') {
      return {
        $not: formatNodeOrCondition(node.descendants.get(0, LogicalNodeFactory()), conditions),
      }
    }
    // we can't have an array with only 1 element for $or / $and
    return formatNodeOrCondition(node.descendants.get(0, LogicalNodeFactory()), conditions)
  }
  if (node.descendants.size === 0) return
  return {
    [`$${node.value}`]: node.descendants
      .map(nodeOrId => formatNodeOrCondition(nodeOrId, conditions))
      .toArray(),
  }
}

export function formatQueryForAPI(query: QueryRecord): Record<any, any> | null | undefined {
  const clean = removeInvalidConditions(query)
  return formatTree(clean.tree, clean.conditions)
}
