import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import {
  type ClickDetail,
  type FetchMonthlySentResponse,
  type GetOrchestrationEmailClicksByUrlBulkResponse,
  type GetOrchestrationStatsDynamicDimensionBulkResponse,
} from './types/grpc-stats-service'
import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'

import { type ProjectRecord } from 'com.batch.redux/project.records'

import { type FetchMonthlySentResult } from 'com.batch/account/infra/parses/parse-monthly-sent'
import { type ClickLinkRecord } from 'com.batch/orchestration-analytics/models/click-links-detail.record'
import {
  type FilterDimensions,
  type DynamicDimension,
} from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

import { parseMonthlySentResponse } from 'com.batch/account/infra/parses/parse-monthly-sent'
import { parseClickLinks } from 'com.batch/orchestration-analytics/infra/parses/parse-click-links'
import { parseFilterValues } from 'com.batch/orchestration-analytics/infra/parses/parse-filter-values'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { type DiscoveryFilterRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

export type DataService = {
  fetchDimensionValues(args: {
    project: ProjectRecord
    dimension: DynamicDimension
    channel: ChannelUntilCleanup
  }): Promise<List<DiscoveryFilterRecord>>
  fetchOrchestrationDynamicStats(args: {
    project: ProjectRecord
    tokens: List<string>
    groupDimensionNames: Array<DynamicDimension>
    includeFilterDimensions?: FilterDimensions
    onlyIncludeMetricNames?: Array<string>
    dateFrom?: Dayjs
    dateTo?: Dayjs
    includePreviousPeriod?: boolean
    parser: (stats?: Array<OrchestrationStatsDynamicDimension>) => unknown
    abortSignal?: AbortSignal
  }): Promise<unknown>
  fetchGlobalDynamicStats(args: {
    project: ProjectRecord
    groupDimensionNames: Array<DynamicDimension>
    includeFilterDimensions?: FilterDimensions
    dateFrom?: Dayjs
    dateTo?: Dayjs
    includePreviousPeriod?: boolean
    parser: (stats?: Array<OrchestrationStatsDynamicDimension>) => unknown
    abortSignal?: AbortSignal
  }): Promise<unknown>
  fetchClicksByUrl(args: {
    project: ProjectRecord
    token: string
    abortSignal?: AbortSignal
    includeFilterDimensions?: Array<{
      dimensionName: string
      dimensionValues: Array<string>
    }>
    dateFrom?: Dayjs
    dateTo?: Dayjs
  }): Promise<List<ClickLinkRecord>>
  getMonthlySentForBilling(args: {
    projectKeys: Array<string>
    month: string
  }): Promise<FetchMonthlySentResult>
}

export const dataService: DataService = {
  fetchDimensionValues: async ({ project, dimension, channel }) => {
    const response = await request.post<GetOrchestrationStatsDynamicDimensionBulkResponse>(
      generateUrl('api_grpc_messaging_data_service', {
        methodName: 'GetOrchestrationStatsDynamicDimension',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        groupDimensionNames: [dimension],
        includeFilterDimensions: [
          {
            dimensionName: 'channel',
            dimensionValues: [channel],
          },
        ],
        onlyIncludeMetricNames: ['sent'],
        dateFrom: dayjs.utc().subtract(364, 'day').format('YYYY-MM-DD'),
        dateTo: dayjs.utc().format('YYYY-MM-DD'),
      }
    )
    return parseFilterValues({ stats: response.stats, dimension })
  },
  fetchOrchestrationDynamicStats: async ({
    project,
    tokens,
    groupDimensionNames,
    includeFilterDimensions,
    dateFrom,
    onlyIncludeMetricNames,
    dateTo,
    includePreviousPeriod = false,
    parser,
    abortSignal,
  }) => {
    const response = await request.post<GetOrchestrationStatsDynamicDimensionBulkResponse>(
      generateUrl('api_grpc_messaging_data_service', {
        methodName: 'GetOrchestrationStatsDynamicDimension',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        tokens,
        groupDimensionNames,
        onlyIncludeMetricNames,
        includeFilterDimensions,
        includePreviousPeriod,
        dateFrom: dateFrom?.format('YYYY-MM-DD'),
        dateTo: dateTo?.format('YYYY-MM-DD'),
      },
      abortSignal
    )
    return parser(response.stats)
  },
  fetchGlobalDynamicStats: async ({
    project: { projectKey },
    groupDimensionNames,
    includeFilterDimensions,
    dateFrom,
    dateTo,
    includePreviousPeriod = false,
    parser,
    abortSignal,
  }) => {
    const response = await request.post<GetOrchestrationStatsDynamicDimensionBulkResponse>(
      generateUrl('api_grpc_messaging_data_service', {
        methodName: 'GetOrchestrationStatsDynamicDimension',
      }),
      {
        projectKey: {
          textual: {
            text: projectKey,
          },
        },
        groupDimensionNames,
        includeFilterDimensions,
        includePreviousPeriod,
        dateFrom: dateFrom?.format('YYYY-MM-DD'),
        dateTo: dateTo?.format('YYYY-MM-DD'),
      },
      abortSignal
    )
    return parser(response.stats)
  },
  fetchClicksByUrl: async ({
    project,
    token,
    includeFilterDimensions,
    dateFrom,
    dateTo,
    abortSignal,
  }) => {
    const response = await request.post<GetOrchestrationEmailClicksByUrlBulkResponse>(
      generateUrl('api_grpc_messaging_data_service', {
        methodName: 'GetOrchestrationEmailClicksByUrl',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        tokens: [token],
        urlGroupingMode: 'SMART_WITH_LINK_NAME',
        groupLimit: 50,
        includeFilterDimensions,
        dateFrom: dateFrom?.format('YYYY-MM-DD'),
        dateTo: dateTo?.format('YYYY-MM-DD'),
      },
      abortSignal
    )
    const match = response.orchestrationEmailClickDetails?.find(
      (raw: { clickDetail: ClickDetail; token: string }) => raw.token === token
    )
    return parseClickLinks({ clickDetails: match?.clickDetail })
  },
  getMonthlySentForBilling: async ({ projectKeys, month }) => {
    try {
      const response = await request.post<FetchMonthlySentResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetMonthlySentForBilling',
        }),
        {
          projectKeys: projectKeys.map(p => ({
            textual: {
              text: p,
            },
          })),
          month,
        }
      )
      return parseMonthlySentResponse(response)
    } catch (error: any) {
      throw new Error(`Error : ${error.message}`)
    }
  },
}
