import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { fetchApp } from 'com.batch.redux/app.action'
import { LoadingStatus } from 'constants/common'

export const useFetchAppFromRouter = (): AppRecord | null => {
  const { appId } = useParams()
  const app = useSelector(currentAppSelector)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (appId && app.id !== parseInt(appId) && app.loadingState !== LoadingStatus.LOADING)
      dispatch(fetchApp(parseInt(appId)))
  }, [app.id, appId, dispatch, app.loadingState])
  return appId && app.id !== parseInt(appId) ? null : app
}
