import { type RecordOf, Record } from 'immutable'

import { LoadingStatus } from 'constants/common'

type ProfilesDistributionProps = {
  total: number
  email: number
  phoneNumber: number
  push: number
  importedTokens: number
  webpush: number
  install: number
  customId: number
  orphans: number
}
export const ProfilesDistributionFactory = Record<ProfilesDistributionProps>({
  total: 0,
  email: 0,
  phoneNumber: 0,
  push: 0,
  importedTokens: 0,
  webpush: 0,
  install: 0,
  customId: 0,
  orphans: 0,
})
export type ProfilesDistributionRecord = RecordOf<ProfilesDistributionProps>

type ProfilesDistributionStateProps = {
  loadingState: LoadingStatus
  distribution: ProfilesDistributionRecord
}

export const ProfilesDistributionStateFactory = Record<ProfilesDistributionStateProps>({
  loadingState: LoadingStatus.INIT,
  distribution: ProfilesDistributionFactory(),
} as ProfilesDistributionStateProps)
export type ProfilesDistributionStateRecord = RecordOf<ProfilesDistributionStateProps>
