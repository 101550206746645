import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { Loader } from 'components/common/loader/loader'
import { Icon } from 'components/common/svg-icon'

import { pluralizeAndKformat } from 'com.batch.common/utils'

import { EstimateProfileChannelToolip } from './estimate-profile-channel-tooltip'
import { EstimateContainer, EstimateTitle, EstimateCount } from './estimate-profile.styles'

import { orchestrationCampaignSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'
import { LoadingStatus } from 'constants/common'

type Props = {
  estimate: EstimateProfileRecord
  channel: ChannelUntilCleanup
}

export const EstimateProfileMono = ({ estimate, channel }: Props): React.ReactElement => {
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  const orchestration = useSelector(orchestrationCampaignSelector)

  const filterPushTokens = React.useMemo(
    () => orchestration.messageConfig.pushSettings.filterPushTokens,
    [orchestration.messageConfig.pushSettings.filterPushTokens]
  )

  const matchingField = React.useMemo(
    () => (subscriptionStatus === 'marketing' && channel !== 'push' ? 'matchingOptin' : 'matching'),
    [subscriptionStatus, channel]
  )

  return (
    <EstimateContainer $withSeparator={channel === null}>
      <EstimateTitle>Estimated reach </EstimateTitle>
      {estimate.loading === LoadingStatus.LOADING ? (
        <Loader size="small" loading style={{ width: 36, height: 30, minHeight: 32 }} />
      ) : (
        <EstimateProfileChannelToolip
          estimate={estimate}
          channel={channel}
          filterPushTokens={filterPushTokens}
        >
          <EstimateCount $warn={subscriptionStatus === 'fullbase'}>
            <div
              className="styled-main"
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr auto',
                alignItems: 'center',
                padding: '0',
              }}
            >
              <Icon
                icon={channel === 'email' ? 'mail' : channel}
                style={{ display: 'block', marginTop: -3 }}
              />
              <span className="styled-matching">
                {pluralizeAndKformat('profile', estimate[matchingField][channel].uniqueCount)}{' '}
              </span>
              <span className="styled-total">
                {subscriptionStatus === 'marketing' ? (
                  ''
                ) : (
                  <React.Fragment>&nbsp;&mdash; counting opted out users</React.Fragment>
                )}
              </span>
            </div>
          </EstimateCount>
        </EstimateProfileChannelToolip>
      )}
    </EstimateContainer>
  )
}
