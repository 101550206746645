import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { type OrchestrationParserResult } from 'com.batch/orchestration/infra/parses/orchestration.parse'

export const fetchOrchestrationWithoutStoring: (
  arg1: string
) => DispatchExtraBoundFn<Promise<OrchestrationParserResult | undefined>> = token => {
  return async (_, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    const user = currentUserSelector(state)
    try {
      const orchestration: OrchestrationParserResult = await orchestrationService.get({
        token,
        project,
        user,
      })
      return orchestration
    } catch (e) {
      console.log(e)
    }
  }
}
