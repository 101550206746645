import * as React from 'react'
import styled from 'styled-components'

import { Grid } from 'components/common/grid'
import { type availableIcons, Icon } from 'components/common/svg-icon'
import { schemes } from 'components/styled/tokens'

type NodeIconWrapperProps = {
  children?: React.ReactNode
  addon?: React.ReactNode
  kind: 'email' | 'sms' | 'random' | 'yesno' | 'delay' | 'entry' | 'push'
  size?: number
}

type IconWrapperProps = {
  size: number
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
`

export const NodeIconWrapper = ({
  children,
  kind,
  addon,
  size,
}: NodeIconWrapperProps): React.ReactElement => {
  const { background, fill, icon, style } = React.useMemo((): {
    background: string
    fill: string
    icon: availableIcons
    style: any
  } => {
    switch (kind) {
      case 'email':
        return {
          background: schemes.blue['00'],
          fill: schemes.blue['50'],
          icon: 'mail',
          style: { marginBottom: 4, marginTop: 0 },
        }
      case 'sms':
        return {
          background: schemes.blue['00'],
          fill: schemes.blue['50'],
          icon: 'sms',
          style: { marginBottom: 4, marginTop: 0 },
        }
      case 'random':
        return {
          background: schemes.orange['00'],
          fill: schemes.orange['40'],
          icon: 'split',
          style: { marginBottom: 4, marginTop: 0 },
        }
      case 'yesno':
        return {
          background: schemes.orange['00'],
          fill: schemes.orange['40'],
          icon: 'yesno',
          style: { marginBottom: 4, marginTop: 0 },
        }
      case 'delay':
        return {
          background: schemes.purple['00'],
          fill: schemes.purple['50'],
          icon: 'wait',
          style: { marginTop: 0 },
        }
      case 'entry':
        return {
          background: schemes.grayscale['10'],
          fill: schemes.grayscale[10],
          icon: 'door',
          style: { marginTop: 3 },
        }
      case 'push':
        return {
          background: schemes.blue['00'],
          fill: schemes.blue['50'],
          icon: 'push',
          style: { marginBottom: 4, marginTop: 0 },
        }
    }
  }, [kind])
  return (
    <Grid
      template={addon ? 'auto 1fr auto' : 'auto 1fr'}
      style={{ flexGrow: 1, columnGap: !children && !addon ? '0px' : '10px' }}
    >
      <IconWrapper style={{ background }} size={size ?? 28}>
        <Icon icon={icon} size={16} color={fill} style={style} />
      </IconWrapper>
      {children}
      {addon}
    </Grid>
  )
}
