import Immutable, { type List, type Set, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { type DateRange } from 'com.batch.common/dayjs.custom'

import {
  type OrchestrationListSortableBy,
  type OrchestrationListStateRecord,
} from 'com.batch/orchestration-list/store/orchestration-list.state'
import { type State } from 'com.batch.redux/_records'

import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import {
  type PartialOrchestrationRecord,
  PartialOrchestrationFactory,
} from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { type LoadingStatus } from 'constants/common'

type Extract<T> = (arg1: State) => T

export type OrchestrationListMetadata = {
  total: number
  matching: number
  nbPerPage: number
  sortBy: OrchestrationListSortableBy
  sortDirection: 'asc' | 'dsc'
  page: number
  statuses: Set<campaignStateType>
  channels: Set<ChannelUntilCleanup>
  labels: Set<string>
  segments: Set<string>
  platforms: Set<ProjectPlatforms>
  search: string
  dateRange: DateRange | null | undefined
  loadingState: LoadingStatus
}

const orchestrationListStateSelector = (state: State): OrchestrationListStateRecord =>
  state.orchestrationList

export const campaignListMetaSelector: Extract<OrchestrationListMetadata> = createSelector(
  orchestrationListStateSelector,
  cls => ({
    matching: cls.count ?? 0,
    total: cls.countTotal ?? 0,
    nbPerPage: cls.nbPerPage,
    page: cls.page,
    sortBy: cls.sortBy,
    statuses: cls.statuses,
    channels: cls.channels,
    labels: cls.labels,
    segments: cls.segments,
    platforms: cls.platforms,
    sortDirection: cls.sortDirection,
    search: cls.search,
    loadingState: cls.loadingState,
    dateRange: cls.dateRange,
  })
)

export const fetchedPagesSelector: Extract<Set<number>> = createSelector(
  orchestrationListStateSelector,
  cls => {
    return Immutable.Set(cls.tokensPerPage.keys())
  }
)

export const pagedPartialCampaignsSelector: Extract<List<PartialOrchestrationRecord>> =
  createSelector(orchestrationListStateSelector, cls =>
    cls.tokensPerPage
      .get(cls.page, Immutable.List())
      .map(token => cls.entities.get(token, PartialOrchestrationFactory()))
  )

export const campaignsFetchingStateSelector: Extract<LoadingStatus> = createSelector(
  orchestrationListStateSelector,
  cls => cls.loadingState
)

export const campaignsDataSelector: Extract<Map<string, OrchestrationListStatsStateRecord>> =
  createSelector(orchestrationListStateSelector, cls => cls.data)
