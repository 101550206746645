import Immutable, { type Set, type Map } from 'immutable'

import { TargetStateFactory, type TargetStateRecord } from './target.records'

import { type ReduxAction, type EstimateRecord, EstimateFactory } from 'com.batch.redux/_records'
import { type fetchAttributesActionSuccessAction } from 'com.batch.redux/attribute'
import { type toggleChannelAction } from 'com.batch.redux/campaign.action'
import { type FetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'

import { type FilterPushTokensType } from 'com.batch/message/models/message.records'
import {
  EstimateProfileFactory,
  type EstimateProfileRecord,
} from 'com.batch/orchestration/models/profile-estimate.records'
import { LoadingStatus } from 'constants/common'

export type SetHashAction = ReduxAction<
  'T_ESTIMATE_SET_HASH',
  {
    id: string
    hash: number
  }
>
export type FetchHashAction = ReduxAction<
  'T_ESTIMATE_FETCH_HASH',
  {
    id: string
    hash: number
    retries: number
    apps: Array<number>
    languages: Array<string>
    regions: Array<string>
    segments: Array<string>
    query: string
    filterPushTokens: FilterPushTokensType
  }
>
export type FetchProfileHashAction = ReduxAction<
  'T_ESTIMATE_FETCH_PROFILE_HASH',
  {
    id: string
    hash: number
    retries: number
    apps: Array<number>
    languages: Array<string>
    regions: Array<string>
    segments: Array<string>
    query: string
    filterPushTokens?: FilterPushTokensType
  }
>
export type FetchHashActionFailure = ReduxAction<
  'T_ESTIMATE_FETCH_HASH_FAILURE',
  {
    hash: number
    error: string
    id: string
  }
>
export type FetchProfileHashActionFailure = ReduxAction<
  'T_ESTIMATE_FETCH_PROFILE_HASH_FAILURE',
  {
    hash: number
    error: string
    id: string
  }
>
export type FetchHashActionSuccess = ReduxAction<
  'T_ESTIMATE_FETCH_HASH_SUCCESS',
  {
    hash: number
    estimate: EstimateRecord
    id: string
  }
>
export type FetchProfileHashActionSuccess = ReduxAction<
  'T_ESTIMATE_FETCH_PROFILE_HASH_SUCCESS',
  {
    hash: number
    estimate: EstimateProfileRecord
    id: string
  }
>
export type updateSegmentAction = ReduxAction<
  'TARGET_SEGMENT',
  {
    code: string
    add: boolean
    id: string
  }
>
type resetTargetAction = ReduxAction<
  'RESET_TARGET',
  {
    preserveAttributesLoaded: boolean
    target: TargetStateRecord
    id: string
  }
>
export const resetTarget = (
  target: TargetStateRecord,
  preserveAttributesLoaded: boolean = false,
  id: string = 'default'
): resetTargetAction => {
  return {
    type: 'RESET_TARGET',
    payload: { target, preserveAttributesLoaded, id },
  }
}

type resetAllTargetAction = ReduxAction<'RESET_ALL_TARGET', Map<string, TargetStateRecord>>
export const resetAllTarget = (payload: Map<string, TargetStateRecord>): resetAllTargetAction => {
  return {
    type: 'RESET_ALL_TARGET',
    payload,
  }
}

export const updateSegment = ({
  code,
  add,
  id,
}: {
  code: string
  add: boolean
  id: string
}): updateSegmentAction => {
  return {
    type: 'TARGET_SEGMENT',
    payload: {
      code,
      add,
      id,
    },
  }
}
export type updateLangRegionAction = ReduxAction<
  'TARGET_LANG_REGION',
  {
    what: 'languages' | 'regions'
    values: Set<string>
    id: string
    inverted: boolean
  }
>
export const updateLangRegion = ({
  what,
  values,
  inverted,
  id,
}: {
  what: 'languages' | 'regions'
  values: Set<string>
  inverted: boolean
  id: string
}): updateLangRegionAction => {
  return {
    type: 'TARGET_LANG_REGION',
    payload: {
      what,
      values,
      inverted,
      id,
    },
  }
}

export type updateTargetKindAction = ReduxAction<
  'TARGET_KIND',
  {
    value: 'marketing' | 'fullbase'
  }
>

export const updateTargetKind = ({
  value,
}: {
  value: 'marketing' | 'fullbase'
}): updateTargetKindAction => {
  return {
    type: 'TARGET_KIND',
    payload: {
      value,
    },
  }
}

export type TargetReducerActions =
  | updateSegmentAction
  | updateLangRegionAction
  | SetHashAction
  | FetchHashAction
  | FetchHashActionFailure
  | FetchHashActionSuccess
  | FetchProfileHashActionSuccess
  | FetchProfileHashActionFailure
  | FetchProfileHashAction
  | toggleChannelAction
  | fetchAttributesActionSuccessAction
  | resetTargetAction
  | resetAllTargetAction
  | updateTargetKindAction
  | FetchUnifiedCustomerDataSummary

function singleTargetReducer(
  state: TargetStateRecord = TargetStateFactory(),
  action: TargetReducerActions
): TargetStateRecord {
  switch (action.type) {
    case 'TARGET_KIND':
      return state.set('subscriptionStatus', action.payload.value)
    case 'RESET_TARGET':
      return action.payload.preserveAttributesLoaded
        ? action.payload.target.set('attributesLoaded', state.attributesLoaded)
        : action.payload.target
    case 'CAMPAIGN_TOGGLE_CHANNELS':
      return state.set('attributesLoaded', false)
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS':
    case 'FETCH_ATTRIBUTES_SUCCESS':
      return state.set('attributesLoaded', true)
    case 'TARGET_SEGMENT':
      return state.set(
        'segments',
        action.payload.add
          ? state.segments.add(action.payload.code)
          : state.segments.remove(action.payload.code)
      )
    case 'TARGET_LANG_REGION':
      return state
        .set(action.payload.what, action.payload.values)
        .set(
          action.payload.what === 'languages' ? 'languagesInverted' : 'regionsInverted',
          action.payload.inverted
        )
    case 'T_ESTIMATE_SET_HASH':
      return state.set('currentEstimateHash', action.payload.hash)
    case 'T_ESTIMATE_FETCH_HASH':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set(
          'estimates',
          state.estimates.set(action.payload.hash, EstimateFactory({ loading: true }))
        )
    case 'T_ESTIMATE_FETCH_PROFILE_HASH':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set(
          'profileEstimates',
          state.profileEstimates.set(
            action.payload.hash,
            EstimateProfileFactory({ loading: LoadingStatus.LOADING })
          )
        )
    case 'T_ESTIMATE_FETCH_PROFILE_HASH_SUCCESS':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set(
          'profileEstimates',
          state.profileEstimates.set(action.payload.hash, action.payload.estimate)
        )
    case 'T_ESTIMATE_FETCH_PROFILE_HASH_FAILURE':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set(
          'profileEstimates',
          state.profileEstimates.set(
            action.payload.hash,
            EstimateProfileFactory({ loading: LoadingStatus.ERROR })
          )
        )
    case 'T_ESTIMATE_FETCH_HASH_SUCCESS':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set('estimates', state.estimates.set(action.payload.hash, action.payload.estimate))
    case 'T_ESTIMATE_FETCH_HASH_FAILURE':
      return state
        .set('currentEstimateHash', action.payload.hash)
        .set(
          'estimates',
          state.estimates.set(action.payload.hash, EstimateFactory({ loading: false, error: true }))
        )
    default:
      return state
  }
}

export function targetReducer(
  state: Map<string, TargetStateRecord> = Immutable.Map([['default', TargetStateFactory()]]),
  action: TargetReducerActions
): Map<string, TargetStateRecord> {
  switch (action.type) {
    case 'CAMPAIGN_TOGGLE_CHANNELS':
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS':
    case 'FETCH_ATTRIBUTES_SUCCESS':
    case 'TARGET_KIND':
      return state.set('default', singleTargetReducer(state.get('default'), action))
    case 'T_ESTIMATE_FETCH_HASH_SUCCESS':
    case 'RESET_TARGET':
    case 'TARGET_SEGMENT':
    case 'TARGET_LANG_REGION':
    case 'T_ESTIMATE_SET_HASH':
    case 'T_ESTIMATE_FETCH_PROFILE_HASH_SUCCESS':
    case 'T_ESTIMATE_FETCH_PROFILE_HASH_FAILURE':
    case 'T_ESTIMATE_FETCH_PROFILE_HASH':
    case 'T_ESTIMATE_FETCH_HASH_FAILURE':
      return state.set(action.payload.id, singleTargetReducer(state.get(action.payload.id), action))
    case 'RESET_ALL_TARGET':
      return action.payload
    default:
      return state
  }
}
