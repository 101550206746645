import { LoadingStatus } from 'constants/common'
import Immutable, { type RecordOf } from 'immutable'

import { type Dayjs } from 'com.batch.common/dayjs.custom'

type OrchestrationListStatsStateProps = {
  loadingState: LoadingStatus
  sent: number
  trend: number
  lastSent: Dayjs | null
}

export const OrchestrationListStatsStateFactory =
  Immutable.Record<OrchestrationListStatsStateProps>({
    loadingState: LoadingStatus.INIT,
    sent: 0,
    trend: 0,
    lastSent: null,
  } as OrchestrationListStatsStateProps)

export type OrchestrationListStatsStateRecord = RecordOf<OrchestrationListStatsStateProps>
