import * as React from 'react'
import { useTheme } from 'styled-components'

import { useCombinedRefs } from 'components/_hooks'
import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Hint } from 'components/common/hint'
import { type availableIcons, Icon } from 'components/common/svg-icon'
import { type AccessNotAllowedProps, TooltipPermission } from 'components/common/tooltip-permission'
import { colors } from 'components/styled/tokens'

import { randomSize } from 'com.batch.common/utils'

import { InputContainer } from '../styles'

type SuffixProps =
  | {
      kind: 'icon'
      value: availableIcons
      handler?: () => void
    }
  | {
      kind: 'text'
      value: string
      handler?: () => void
    }
export type PrefixProps = {
  value: availableIcons
  color?: string
  style?: {
    [key: string]: string | number
  }
}

type InputProps = AccessNotAllowedProps & {
  disabled?: boolean
  invalid?: boolean
  rows?: number
  invalidMessage?: string
  readOnly?: boolean
  allowedCharsRegex?: RegExp
  style?: {
    [key: string]: string | number
  }
  inputStyle?: {
    [key: string]: string | number
  }
  prefix?: PrefixProps
  suffix?: SuffixProps
  forcedFocus?: boolean
  onKeyPress?: (arg1: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  autoComplete?: boolean | 'one-time-code'
} & Omit<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'prefix' | 'autoComplete'
  >

const Input = React.forwardRef<HTMLInputElement & HTMLTextAreaElement, InputProps>(
  (
    {
      prefix,
      suffix,
      style,
      rows,
      allowedCharsRegex,
      disabled,
      invalid,
      inputStyle,
      invalidMessage,
      accessNotAllowed,
      forcedFocus,
      accessNotAllowedMsg,
      onKeyPress,
      autoComplete,
      ...props
    }: InputProps,
    ref
  ): React.ReactElement => {
    const isTextArea = React.useMemo(() => typeof rows === 'number', [rows])
    const inputElemRef = React.useRef<HTMLInputElement>(null)
    const textAreaElemRef = React.useRef<HTMLTextAreaElement>(null)
    const innerRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const inputRef = useCombinedRefs(ref, innerRef)

    // const inputReference = React.useRef(null)
    // did not manage to use default props with fowardRef & flow type
    const disabledwithDefault = React.useMemo(
      () => (typeof disabled === 'boolean' ? disabled : false),
      [disabled]
    )
    const invalidWithDefault = React.useMemo(
      () => (typeof invalid === 'boolean' ? invalid : false),
      [invalid]
    )
    const accessNotAllowedDefault = React.useMemo(
      () => (typeof accessNotAllowed === 'boolean' ? accessNotAllowed : false),
      [accessNotAllowed]
    )
    // const theme = React.useContext(ThemeContext)
    const [emptyFieldSize] = React.useState(`${randomSize(20, 60)}%`)

    const finalProps = {
      ...props,
      onKeyPress: allowedCharsRegex
        ? (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!evt.key.match(allowedCharsRegex) && !evt.metaKey) {
              evt.preventDefault()
            } else {
              if (typeof onKeyPress === 'function') {
                onKeyPress(evt)
              }
            }
          }
        : onKeyPress,
    }

    React.useEffect(() => {
      if (forcedFocus) {
        inputRef?.current?.focus()
      }
    }, [inputRef, ref, forcedFocus])

    const theme = useTheme()

    const render = (
      <InputContainer
        invalid={invalidWithDefault}
        disabled={disabledwithDefault || accessNotAllowedDefault || theme?.disabledMode}
        style={style}
      >
        {!!prefix && (
          <div className="styled-input-addons styled-addon-pre">
            <Icon
              icon={prefix.value}
              size={13}
              color={disabled ? colors.textDisabled : prefix.color}
              style={prefix.style}
            />
          </div>
        )}

        {!!theme && (theme.isEmpty || theme.isLoading) ? (
          <div style={{ width: '100%' }}>
            {!disabledwithDefault && <EmptyField _width={emptyFieldSize} />}
          </div>
        ) : isTextArea ? (
          <textarea
            {...finalProps}
            disabled={disabledwithDefault || accessNotAllowedDefault || theme?.disabledMode}
            ref={textAreaElemRef}
            rows={rows}
            style={inputStyle}
          />
        ) : (
          <input
            {...finalProps}
            disabled={disabledwithDefault || accessNotAllowedDefault || theme?.disabledMode}
            ref={inputElemRef}
            style={inputStyle}
            autoComplete={
              autoComplete ? (autoComplete === 'one-time-code' ? 'one-time-code' : 'on') : 'off'
            }
            {...(!autoComplete ? { 'data-1p-ignore': true, 'data-lpignore': true } : {})}
          />
        )}

        {!!suffix && !invalidMessage && (
          <div className="styled-input-addons styled-addon-suf">
            {suffix.kind === 'icon' &&
              (suffix.handler ? (
                <Button
                  as={'div'}
                  type="button"
                  style={{ height: 28, marginRight: 4, width: 28 }}
                  onClick={suffix.handler}
                >
                  <Icon icon={suffix.value} />
                </Button>
              ) : (
                <Icon
                  icon={suffix.value ? suffix.value : 'arrow-long-left'}
                  size={12}
                  color={colors.textLight}
                />
              ))}

            {suffix.kind === 'text' &&
              (suffix.handler ? (
                <Button
                  type="button"
                  kind="discreet"
                  style={{ height: 28 }}
                  onClick={suffix.handler}
                >
                  {suffix.value}
                </Button>
              ) : (
                <div
                  style={{
                    color: colors.textLight,
                    fontSize: '13px',
                  }}
                >
                  {suffix.value}
                </div>
              ))}
          </div>
        )}

        {invalidWithDefault && !!invalidMessage && (
          <Hint style={{ color: colors.textDanger, marginRight: 1 }}>{invalidMessage}</Hint>
        )}
      </InputContainer>
    )
    return accessNotAllowedDefault ? (
      <TooltipPermission
        accessNotAllowed={accessNotAllowedDefault}
        accessNotAllowedMsg={accessNotAllowedMsg}
      >
        {render}
      </TooltipPermission>
    ) : (
      render
    )
  }
)

Input.displayName = 'Input'
export { Input }
