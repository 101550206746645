import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type RecordOf } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type TestDeviceRecord } from 'com.batch.redux/_records'

import { LoadingStatus } from 'constants/common'

type DebugInstallProps = {
  apiKey: string
  loading: boolean
  customId: string | null | undefined
  timezone: string
  platform: string
  installationId: string
  notificationStatus: string
  device: TestDeviceRecord | null | undefined
  attributes: Immutable.OrderedMap<string, any>
  prettifiedValues: Immutable.Map<string, any>
}

export const DebugInstallFactory = Immutable.Record<DebugInstallProps>({
  apiKey: '',
  loading: false,
  customId: null,
  installationId: '',
  platform: 'ios',
  timezone: 'unknown',
  notificationStatus: 'unknown',
  device: null,
  attributes: Immutable.OrderedMap(),
  prettifiedValues: Immutable.Map(),
})

export type DebugInstallRecord = RecordOf<DebugInstallProps>

type ErrorProps = {
  code: string
  count: number
}

export const ErrorFactory = Immutable.Record<ErrorProps>({
  code: '',
  count: 0,
})

export type ErrorRecord = RecordOf<ErrorProps>

export type AnalyticDataProp = {
  daus: number
  maus: number
  starts: number
  installs: number
  pushes: number
  transactions: number
  tokens: number
  deletedTokens: number
  optOut: number
}

export const AnalyticDataFactory = Immutable.Record<AnalyticDataProp>({
  daus: 0,
  maus: 0,
  starts: 0,
  pushes: 0,
  installs: 0,
  transactions: 0,
  deletedTokens: 0,
  tokens: 0,
  optOut: 0,
})

export type AnalyticDataRecord = RecordOf<AnalyticDataProp>

export type AnalyticRegionDataProp = {
  daus: number[]
  maus: number[]
  starts: number[]
  installs: number[]
  pushes: number[]
  transactions: number[]
  tokens: number[]
  deletedTokens: number[]
  optOut: number[]
}

export const AnalyticRegionDataFactory = Immutable.Record<AnalyticRegionDataProp>({
  daus: [],
  maus: [],
  starts: [],
  pushes: [],
  installs: [],
  transactions: [],
  deletedTokens: [],
  tokens: [],
  optOut: [],
})

export type AnalyticRegionDataRecord = RecordOf<AnalyticRegionDataProp>

type AnalyticByPeriodProp = {
  period: Dayjs
  events: Map<string, number>
  data: AnalyticDataRecord
}
export const AnalyticByPeriodFactory = Immutable.Record<AnalyticByPeriodProp>({
  period: dayjs(),
  events: Immutable.Map(),
  data: AnalyticDataFactory(),
})

export type AnalyticByPeriodRecord = RecordOf<AnalyticByPeriodProp>

type AnalyticByRegionProp = {
  region: string
  data: AnalyticRegionDataRecord
}
export const AnalyticByRegionFactory = Immutable.Record<AnalyticByRegionProp>({
  region: '',
  data: AnalyticRegionDataFactory(),
})

export type AnalyticByRegionRecord = RecordOf<AnalyticByRegionProp>

type AnalyticsConfigProps = {
  loadingOverview: LoadingStatus
  loadingRegion: LoadingStatus
  loadingPushOverview: LoadingStatus
  loadingReach: LoadingStatus
  granularity: 'day' | 'hour'
  activeTab: 'analytics' | 'push' | 'reach'
  from: Dayjs
  to: Dayjs
  secret: boolean
}

export const AnalyticsConfigFactory = Immutable.Record<AnalyticsConfigProps>({
  loadingOverview: LoadingStatus.INIT,
  loadingRegion: LoadingStatus.INIT,
  loadingPushOverview: LoadingStatus.INIT,
  loadingReach: LoadingStatus.INIT,
  granularity: 'day',
  activeTab: 'analytics',
  from: dayjs.utc().subtract(1, 'month').subtract(1, 'day').hour(0).minute(0).second(0),
  to: dayjs.utc().subtract(1, 'day').hour(0).minute(0).second(0),
  secret: false,
})

export type AnalyticsConfigRecord = RecordOf<AnalyticsConfigProps>

// TRANSAC DEBUG
type TransacDebugDetails = Array<{
  token: string
  date: number
  sent_date: number
}>

export type TransacResponseProps = Readonly<{
  error: boolean
  recipientsCount: number
  recipientsSample: Array<string>
  recipientsType: Array<string>
  failureMessage: string
  failureCount: number
  successCount: number
  notFoundCount: number
  unregistredCount: number
  success: TransacDebugDetails
  failure: TransacDebugDetails
  feedback: TransacDebugDetails
  groupId: string
  pushDate: number
  apiKey: string
  labels: Array<string>
}>

export const TransacResponseFactory = Immutable.Record<TransacResponseProps>({
  error: false,
  recipientsCount: 0,
  recipientsSample: [],
  recipientsType: [],
  failureMessage: '',
  failureCount: 0,
  successCount: 0,
  notFoundCount: 0,
  unregistredCount: 0,
  success: [],
  failure: [],
  feedback: [],
  groupId: '',
  pushDate: 0,
  apiKey: '',
  labels: [],
})

export type TransacResponseRecord = RecordOf<TransacResponseProps>

type DebugTransacProps = {
  loading: boolean
  error: false | string
  query: string
  results: TransacResponseProps | null | undefined
}
export const DebugTransacFactory = Immutable.Record<DebugTransacProps>({
  loading: false,
  query: '',
  error: false,
  results: null,
})

export type DebugTransacRecord = RecordOf<DebugTransacProps>

// DEBUG USER
type DebugUserProps = {
  loading: boolean
  query: string
  mode: 'advertising_id' | 'custom_id' | 'installation_id'
  results: List<DebugInstallRecord>
  error: string | false
  recentCustomIds: Array<string>
  recentInstallationIds: Array<string>
}
export const DebugUserFactory = Immutable.Record<DebugUserProps>({
  loading: false,
  query: '',
  mode: 'advertising_id',
  results: Immutable.List(),
  error: false,
  recentCustomIds: [],
  recentInstallationIds: [],
})

export type DebugUserRecord = RecordOf<DebugUserProps>

type ReachCountProps = {
  tokens: number
  tokensNotifOn: number
}
export const ReachCountFactory = Immutable.Record<ReachCountProps>({
  tokens: 0,
  tokensNotifOn: 0,
})

export type ReachCountRecord = RecordOf<ReachCountProps>

type ReachChangesProps = {
  toNotifOn: number
  toNotifOff: number
  newTokens: number
  newTokensNotifOn: number
  deletedTokens: number
  deletedTokensNotifOn: number
}
export const ReachChangesFactory = Immutable.Record<ReachChangesProps>({
  toNotifOn: 0,
  toNotifOff: 0,
  newTokens: 0,
  newTokensNotifOn: 0,
  deletedTokens: 0,
  deletedTokensNotifOn: 0,
})

export type ReachChangesRecord = RecordOf<ReachChangesProps>

type ReachDataProps = {
  count: ReachCountRecord
  changes: ReachChangesRecord
}
export const ReachDataFactory = Immutable.Record<ReachDataProps>({
  count: ReachCountFactory(),
  changes: ReachChangesFactory(),
})

export type ReachDataRecord = RecordOf<ReachDataProps>

type ReachByCountryProps = {
  country: string
  data: ReachDataRecord
}
export const ReachByCountryFactory = Immutable.Record<ReachByCountryProps>({
  country: '',
  data: ReachDataFactory(),
})

export type ReachByCountryRecord = RecordOf<ReachByCountryProps>

type ReachByDayProps = {
  date: Dayjs
  data: ReachDataRecord
  byCountry: List<ReachByCountryRecord>
}
export const ReachByDayFactory = Immutable.Record<ReachByDayProps>({
  date: dayjs(),
  data: ReachDataFactory(),
  byCountry: Immutable.List(),
})

export type ReachByDayRecord = RecordOf<ReachByDayProps>

type StatStateProps = {
  analyticsByDate: List<AnalyticByPeriodRecord>
  analyticsByRegion: List<AnalyticByRegionRecord>
  config: AnalyticsConfigRecord
  // debug: DebugStateRecord,
  reach: List<ReachByDayRecord>
  userDebug: DebugUserRecord
}
export const StatStateFactory = Immutable.Record<StatStateProps>({
  reach: Immutable.List(),
  analyticsByDate: Immutable.List(),
  analyticsByRegion: Immutable.List(),
  config: AnalyticsConfigFactory(),
  userDebug: DebugUserFactory(),
  // debug: DebugStateFactory()
})

export type StatStateRecord = RecordOf<StatStateProps>

type GDPRByDayProps = {
  date: Dayjs
  dashboard: number
  api: number
  mobile: number
}
export const GDPRByDayFactory = Immutable.Record<GDPRByDayProps>({
  date: dayjs(),
  dashboard: 0,
  api: 0,
  mobile: 0,
})

export type GDPRByDayRecord = RecordOf<GDPRByDayProps>
