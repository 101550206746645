import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { SelectMulti } from 'components/form'

import { requiredStringList } from 'com.batch.common/validators'

import { type InputProps } from './helper'
import { useSelector } from 'react-redux'
import { currentProjectPlatformsSelector } from 'com.batch.redux/app'
type OptionPlatform = { label: string; value: 'ios' | 'android' | 'web' }
const optionFormatter = (opt: OptionPlatform) => opt.label
const optionToString = (option?: OptionPlatform) => option?.value ?? ''
const platforms = Immutable.List<OptionPlatform>([
  { label: 'iOS', value: 'ios' },
  { label: 'Android', value: 'android' },
  { label: 'Web', value: 'web' },
])
export const InputPlatform = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const attributeId = React.useMemo(
    () => condition?.attribute?.api ?? '',
    [condition?.attribute?.api]
  )
  const projectPlatforms = useSelector(currentProjectPlatformsSelector)
  const shownPlatforms = React.useMemo(() => {
    return platforms.filter(
      p =>
        // ugly, cause backend uses web and we use webpush
        (p.value === 'web' && projectPlatforms.includes('webpush')) ||
        (p.value !== 'web' && projectPlatforms.includes(p.value)) ||
        condition.value.stringList.includes(p.value)
    )
  }, [condition.value.stringList, projectPlatforms])
  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const onChangeLocal = React.useCallback(
    (value: List<OptionPlatform>) => {
      setInvalid(!requiredStringList(value.map(optionToString)))
      updateCondition(
        condition.set('value', condition.value.set('stringList', value.map(optionToString)))
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])

  return (
    <SelectMulti
      options={shownPlatforms}
      key={attributeId}
      style={{ minWidth: 150 }}
      onBlur={onBlur}
      optionFormatter={optionFormatter}
      isClearable
      invalid={(invalid && isTouched) || isInvalid}
      value={shownPlatforms.filter(p => condition.value.stringList.includes(p.value))}
      optionToString={optionToString}
      onChange={onChangeLocal}
    />
  )
}
