import { type Set, type List } from 'immutable'

import { type DateRange } from 'com.batch.common/dayjs.custom'

import { type PartialOrchestrationRecord } from '../models/partial-orchestration.records'
import { type OrchestrationListSortableBy } from 'com.batch/orchestration-list/store/orchestration-list.state'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type fetchCampaignsResponse = {
  count: number
  countTotal: number
  entities: List<PartialOrchestrationRecord>
}

export type SetPageAction = ReduxAction<'SET_PAGE_ORCHESTRATIONS_LIST', number>
export const setPage = (page: number): DispatchExtraBoundFn<void> => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PAGE_ORCHESTRATIONS_LIST', payload: page } as SetPageAction)
    if (!getState().orchestrationList.tokensPerPage.has(page)) {
      dispatch(fetchOrchestrationsList({ cacheMode: 'KEEP' })).catch(err => {
        if (!err.aborted) console.log('unable to fetch campaigns', err)
      })
    }
  }
}

let scheduleFetchTimeout: ReturnType<typeof setTimeout> | null = null
export type UpdateSearchAction = ReduxAction<'UPDATE_SEARCH_ORCHESTRATIONS_LIST', string>
export const updateSearch = (search: string): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_SEARCH_ORCHESTRATIONS_LIST',
      payload: search,
    })
  }
}

export type UpdateFilterStatesAction = ReduxAction<
  'UPDATE_FILTER_STATES_ORCHESTRATIONS_LIST',
  Set<campaignStateType>
>
export const updateFilterStates = (states: Set<campaignStateType>): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_STATES_ORCHESTRATIONS_LIST',
      payload: states,
    })
  }
}

export type UpdateFilterChannelAction = ReduxAction<
  'UPDATE_FILTER_CHANNELS_ORCHESTRATIONS_LIST',
  Set<ChannelUntilCleanup>
>
export const updateFilterChannels = (
  channels: Set<ChannelUntilCleanup>
): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_CHANNELS_ORCHESTRATIONS_LIST',
      payload: channels,
    })
  }
}

export type UpdateFilterPlatformAction = ReduxAction<
  'UPDATE_FILTER_PLATFORMS_ORCHESTRATIONS_LIST',
  Set<ProjectPlatforms>
>
export const updateFilterPlatforms = (
  platforms: Set<ProjectPlatforms>
): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_PLATFORMS_ORCHESTRATIONS_LIST',
      payload: platforms,
    })
  }
}

export type UpdateFilterLabelsAction = ReduxAction<
  'UPDATE_FILTER_LABELS_ORCHESTRATIONS_LIST',
  Set<string>
>
export const updateFilterLabels = (labels: Set<string>): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_LABELS_ORCHESTRATIONS_LIST',
      payload: labels,
    })
  }
}

export type UpdateSegmentsLabelsAction = ReduxAction<
  'UPDATE_FILTER_SEGMENTS_ORCHESTRATIONS_LIST',
  Set<string>
>
export const updateFilterSegments = (segments: Set<string>): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_SEGMENTS_ORCHESTRATIONS_LIST',
      payload: segments,
    })
  }
}

export type UpdateFilterDatesAction = ReduxAction<
  'UPDATE_FILTER_DATE_RANGE_ORCHESTRATIONS_LIST',
  DateRange
>
export const updateFilterDateRangeAction = (
  dateRange?: DateRange | null
): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_DATE_RANGE_ORCHESTRATIONS_LIST',
      payload: dateRange,
    })
  }
}

type sortDirections = 'asc' | 'dsc'
export type UpdateSortAction = ReduxAction<
  'UPDATE_SORT_ORCHESTRATIONS_LIST',
  {
    sortBy: OrchestrationListSortableBy
    sortDirection: sortDirections
  }
>
export const updateSort = (payload: {
  sortBy: OrchestrationListSortableBy
  sortDirection: sortDirections
}): DispatchExtraBoundFn<void> => {
  if (scheduleFetchTimeout) clearTimeout(scheduleFetchTimeout)
  return (dispatch, getState) => {
    const mode = getState().orchestrationList.mode
    const projectId = currentProjectSelector(getState()).id
    localStorage.setItem(`${projectId}-${mode}-sortBy`, payload.sortBy)
    localStorage.setItem(`${projectId}-${mode}-sortDirection`, payload.sortDirection)
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_SORT_ORCHESTRATIONS_LIST',
      payload,
    })
  }
}

type FetchOrchestrationsListAction = ReduxAction<'FETCH_ORCHESTRATIONS_LIST', null>

export type FetchOrchestrationsListSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATIONS_LIST_SUCCESS',
  fetchCampaignsResponse & {
    cacheMode: CacheMode
    page: number
  }
>

export type FetchOrchestrationsListFailureAction = ReduxAction<
  'FETCH_ORCHESTRATIONS_LIST_FAILURE',
  string
>

export type FetchCampaignsListActions =
  | FetchOrchestrationsListAction
  | FetchOrchestrationsListSuccessAction
  | FetchOrchestrationsListFailureAction
  | SetPageAction
  | UpdateSearchAction
  | UpdateSortAction
  | UpdateFilterStatesAction
  | UpdateFilterChannelAction
  | UpdateFilterPlatformAction
  | UpdateFilterLabelsAction
  | UpdateFilterDatesAction
  | UpdateSegmentsLabelsAction

let abortFetchCampaignsController: AbortController = new AbortController()

type CacheMode = 'KEEP' | 'TRASH' | 'TRASH_AFTER_CURRENT_PAGE'

export const fetchOrchestrationsList = ({
  cacheMode = 'TRASH',
}: {
  cacheMode?: CacheMode
  search?: string
  mode?: schedulingType
}): DispatchExtraBoundFn<
  Promise<
    fetchCampaignsResponse & {
      page: number
      cacheMode: CacheMode
    }
  >
> => {
  return (dispatch, getState, { orchestrationService }) => {
    abortFetchCampaignsController.abort()
    abortFetchCampaignsController = new AbortController()
    const state = getState()
    const project = currentProjectSelector(state)
    const cls = state.orchestrationList
    const targetPage = cacheMode === 'TRASH' ? 1 : cls.page

    const trashCache =
      (cls.search !== '' ||
        cls.statuses.size !== 0 ||
        cls.channels.size !== 0 ||
        cls.labels.size !== 0 ||
        cls.dateRange !== null ||
        cls.segments.size !== 0 ||
        cls.platforms.size !== 0 ||
        cacheMode === 'TRASH') &&
      state.orchestrationList.countTotal === null

    const promise = orchestrationService
      .fetchOrchestrations({
        project,
        mode: cls.mode,
        search: cls.search,
        orderDirection: cls.sortDirection,
        orderBy: cls.sortBy,
        statuses: cls.statuses.toArray(),
        filterType: [],
        channels: cls.channels.toArray(),
        labels: cls.labels.toArray(),
        dateRange: cls.dateRange,
        offset: (targetPage - 1) * cls.nbPerPage,
        pageSize: cls.nbPerPage,
        count: state.orchestrationList.count,
        countTotal: state.orchestrationList.countTotal,
        abortSignal: abortFetchCampaignsController.signal,
        pushPlatforms: cls.platforms.toArray().map(frontPlatform => {
          switch (frontPlatform) {
            case 'ios':
              return 'PUSH_PLATFORM_IOS'
            case 'android':
              return 'PUSH_PLATFORM_ANDROID'
            case 'webpush':
              return 'PUSH_PLATFORM_WEB'
          }
        }),
        segments: cls.segments.toArray(),
        trashCache,
      })
      .then(response => {
        const qs = new URLSearchParams(window.location.search)
        qs.set('page', String(targetPage))
        qs.set('search', cls.search)
        qs.set('orderBy', cls.sortBy)
        qs.set('orderDirection', cls.sortDirection)
        qs.set(
          'statuses',
          cls.statuses
            .toArray()
            .map(status => status.toLowerCase())
            .join(',')
        )
        qs.set('channels', cls.channels.toArray().join(','))
        qs.set('labels', cls.labels.toArray().join(','))
        qs.set('segments', cls.segments.toArray().join(','))
        qs.set('platforms', cls.platforms.toArray().join(','))
        qs.set('from', cls.dateRange?.from.toISOString() ?? '')
        qs.set('to', cls.dateRange?.to.toISOString() ?? '')
        history.pushState(null, '', '?' + qs.toString())

        return {
          ...response,
          page: targetPage,
          cacheMode,
        }
      })
    return promiseActionCreator<
      fetchCampaignsResponse & {
        page: number
        cacheMode: CacheMode
      }
    >({
      dispatch,
      promise,
      actionName: 'FETCH_ORCHESTRATIONS_LIST',
    })
  }
}

function scheduleFetchAndDispatch<T extends string, P>(dispatch, action: ReduxAction<T, P>) {
  if (scheduleFetchTimeout) clearTimeout(scheduleFetchTimeout)
  dispatch(action)
  scheduleFetchTimeout = setTimeout(() => {
    dispatch(fetchOrchestrationsList({ cacheMode: 'TRASH' })).catch(err => {
      if (!err.aborted) console.log('unable to fetch campaigns', err)
    })
  }, 800)
}
