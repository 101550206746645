import Immutable, { type List, type OrderedSet } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'components/console/react-redux'
import request from 'superagent-interface-promise'

import { useToggle } from 'components/_hooks'
import { Avatar } from 'components/common/avatar'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'
import { CompanySSO } from 'components/console/company/company-sso'
import { DeleteCompanyModal } from 'components/console/company/delete-company-modal/delete-company-modal'
import { SubNav, SubNavRouterLink } from 'components/styled/subnav'

import { generateUrl } from 'com.batch.common/router'

import { fetchDataByCompany } from './../redux/analytics.api'
import { AnalyticByCompanyFactory, type AnalyticByCompanyRecord } from './../redux/console.records'
import { CompanyData } from './company-data'
import { CompanyPlan } from './company-plan'

import { Subtitle } from '../console.style'
import {
  setSalesForceId,
  setSeats,
  toggleEdito,
  toggleGdprExternalFlag,
  updateCompanyFeatures,
  updatePlan,
  updateSaml,
} from '../redux/company'
import { type CompanyRecord, type SAMLRecord } from 'com.batch.redux/_records'
import { type BillingRecord } from 'com.batch.redux/billing.records'
import { type CSSProperties } from 'styled-components'

type CompanyViewProps = {
  company: CompanyRecord
  tab: 'general' | 'features' | 'sso'
}
type UpdatePlanParams = {
  company: CompanyRecord
  billing: BillingRecord
}
type UpdateSamlParams = {
  company: CompanyRecord
  saml: SAMLRecord
}
type updateCompanyFeaturesParams = {
  company: CompanyRecord
  disabled: OrderedSet<string>
  additionals: OrderedSet<string>
}

export const CompanyView = ({ company, tab }: CompanyViewProps): React.ReactElement => {
  const dispatch = useDispatch()

  const updatePlanBound = React.useCallback(
    (p: UpdatePlanParams) => dispatch(updatePlan(p)),
    [dispatch]
  )
  const updateSamlBound = React.useCallback(
    (p: UpdateSamlParams) => dispatch(updateSaml(p)),
    [dispatch]
  )
  const setSalesForceIdBound = React.useCallback(
    (c: CompanyRecord) => dispatch(setSalesForceId(c)),
    [dispatch]
  )
  const toggleGdprExternalFlagBound = React.useCallback(
    (c: CompanyRecord) => dispatch(toggleGdprExternalFlag(c)),
    [dispatch]
  )
  const setSeatsBound = React.useCallback(
    (c: CompanyRecord, seats?: number | null) => dispatch(setSeats(c, seats)),
    [dispatch]
  )
  const toggleEditoBound = React.useCallback(
    (c: CompanyRecord) => dispatch(toggleEdito(c)),
    [dispatch]
  )
  const updateCompanyFeaturesBound = React.useCallback(
    (p: updateCompanyFeaturesParams) => dispatch(updateCompanyFeatures(p)),
    [dispatch]
  )

  const deleteCompanyModalState = useToggle()
  const [featureList, setFeatureList] = React.useState<
    List<{
      code: FeatureCode
      description: string
      platform: string
    }>
  >(Immutable.List())
  const [analytics, setAnalytics] = React.useState<AnalyticByCompanyRecord>(
    AnalyticByCompanyFactory()
  )
  const [dataLoading, setDataLoading] = React.useState(false)

  React.useEffect(() => {
    request.get(generateUrl('console_api_features')).then(
      res => {
        setFeatureList(Immutable.List(res.body))
      },
      error => console.log(error)
    )
    setDataLoading(true)
    fetchDataByCompany({ company }).then(
      res => {
        setAnalytics(res)
        setDataLoading(false)
      },
      () => setDataLoading(false)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company.id])

  const deletedStyle: CSSProperties = { position: 'relative', pointerEvents: 'none' }

  return (
    <div style={company.deletedAt ? deletedStyle : {}}>
      {company.deletedAt ? (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 998,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          />
          <div
            style={{
              background: 'crimson',
              color: '#FFF',
              borderRadius: 7,
              padding: 20,
              position: 'absolute',
              fontSize: 30,
              left: '50%',
              top: '50%',
              marginLeft: -100,
              marginTop: -100,
              zIndex: 999,
            }}
          >
            DELETED {company.deletedAt.format('DD/MM/YYYY')}
          </div>
        </React.Fragment>
      ) : null}
      <Grid template="38px 1fr" alignItems="center" style={{ marginBottom: 25 }}>
        <Avatar url={company.avatarUrl} placeholder={company.name} color={'#1c2e43'} />
        <Subtitle>
          {company.name}&nbsp;
          <Tag type="label">
            {company.billing.trialIsActive && company.billing.trial
              ? company.billing.trial.name
              : company.billing.plan.name}{' '}
            plan
          </Tag>
          <Tag>
            {company.usedSeats} / {company.seats}
          </Tag>
          {company.billing.overcharge && (
            <Tag style={{ background: 'crimson', color: '#FFF' }}>LOCKED</Tag>
          )}
          {company.sfid && (
            <span
              style={{
                fontSize: 11,
                display: 'inline-block',
                padding: '2px 0 0 6px',
              }}
            >
              <a
                href={`https://batch.lightning.force.com/lightning/r/Account/${company.sfid}/view`}
              >
                Salesforce
              </a>
            </span>
          )}
        </Subtitle>
      </Grid>

      <SubNav style={{ display: 'flex' }}>
        <SubNavRouterLink to={`/console/company/${company.id}`} end>
          General information
        </SubNavRouterLink>
        <SubNavRouterLink to={`/console/company/${company.id}/features`}>
          Plans & features
        </SubNavRouterLink>
        <SubNavRouterLink to={`/console/company/${company.id}/sso`}>SSO</SubNavRouterLink>
        {window.roles?.includes('ROLE_SUPER_ADMIN') && (
          <React.Fragment>
            <Button
              style={{ marginLeft: 'auto' }}
              kind="primary"
              intent="danger"
              onClick={deleteCompanyModalState.open}
            >
              Delete company
            </Button>
            <DeleteCompanyModal company={company} state={deleteCompanyModalState} />
          </React.Fragment>
        )}
      </SubNav>

      {tab === 'general' && (
        <CompanyData
          company={company}
          analytics={analytics}
          analyticsLoading={dataLoading}
          toggleGdprExternalFlag={toggleGdprExternalFlagBound}
          saveSeats={setSeatsBound}
          setSalesForceId={setSalesForceIdBound}
          toggleEdito={toggleEditoBound}
        />
      )}

      {tab === 'features' && (
        <CompanyPlan
          company={company}
          featureList={featureList}
          updatePlan={updatePlanBound}
          updateCompanyFeatures={updateCompanyFeaturesBound}
        />
      )}

      {tab === 'sso' && <CompanySSO company={company} updateSaml={updateSamlBound} />}
    </div>
  )
}
