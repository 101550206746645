import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { Outlet } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { Box } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import { Table, TableFooter, TableTemplateCell } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { Title } from 'components/styled/text'
import { Separator } from 'components/styled/utils'

import { generateUrl } from 'com.batch.common/router/router'

import { MAXIMUM_SEGMENT_LIMIT, NUMBER_OF_ENTITIES_PER_PAGE } from '../../models/segment.constant'
import {
  currentPageSegmentsSelector,
  segmentsSelector,
  segmentLoadingStateSelector,
} from 'com.batch/segments/store/segments.selector'
import { fetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { attributesLoadedSelector } from 'com.batch.redux/target/target.selector'

import { Body } from 'com.batch/segments/ui/components/body'
import { Header } from 'com.batch/segments/ui/components/header'
import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'
import { usePaginationParams } from 'com.batch/shared/hooks/use-pagination-params'
import { useSortParams } from 'com.batch/shared/hooks/use-sort-params'
import { type OverlayEmptyProps } from 'components/common/empty-states/overlay'
import { LoadingStatus } from 'constants/common'

// TODO: extract this to a shared component
export const EmptyListTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
    </React.Fragment>
  )
}

export const SegmentsList = (): React.ReactElement => {
  // hooks
  const dispatch = useDispatch()
  const project = useSelector(currentProjectSelector)
  const { currentPage, setPaginationParam } = usePaginationParams()
  const { sortOrderByParam, sortDirectionParam } = useSortParams()

  const [search, setSearch] = React.useState('')
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'custom-audiences:write'])

  // state
  const { total: segmentsTotal, totalMatching: segmentsTotalMatching } =
    useSelector(segmentsSelector)
  const segments = useSelector(currentPageSegmentsSelector)
  const { fetchingLoadingState } = useSelector(segmentLoadingStateSelector)

  const attributesLoaded = useSelector(attributesLoadedSelector)

  const hasNoContent = React.useMemo(
    () =>
      fetchingLoadingState === LoadingStatus.ERROR ||
      (segmentsTotal === 0 && fetchingLoadingState === LoadingStatus.LOADED),
    [fetchingLoadingState, segmentsTotal]
  )

  const isLoading = React.useMemo(() => {
    return (
      fetchingLoadingState === LoadingStatus.LOADING || fetchingLoadingState === LoadingStatus.INIT
    )
  }, [fetchingLoadingState])
  const baseUrl = React.useMemo(
    () =>
      generateUrl('project_profiles', {
        activeTab: 'segments',
        companyId: project.companyId,
        projectId: project.id,
      }),
    [project]
  )
  React.useEffect(() => {
    dispatch(
      fetchSegmentList({
        page: currentPage,
        pageSize: NUMBER_OF_ENTITIES_PER_PAGE,
        search,
        sortDirection: sortDirectionParam,
        sortField: sortOrderByParam === 'displayName' ? 'displayName' : null,
      })
    )
  }, [dispatch, currentPage, search, sortDirectionParam, sortOrderByParam])

  // fetch attributes
  useEffect(() => {
    if (!attributesLoaded) {
      dispatch(fetchUnifiedCustomerDataSummary())
    }
  }, [attributesLoaded, dispatch])

  React.useEffect(() => {
    // redirect to previous page if no segments on the current page
    if (segments.size === 0 && currentPage > 1 && !isLoading) {
      setPaginationParam(currentPage - 1)
    }
  }, [segments, currentPage, setPaginationParam, isLoading])

  const onSearch = React.useCallback(
    (value: string) => {
      setPaginationParam(1)
      setSearch(value)
    },
    [setPaginationParam]
  )

  return (
    <React.Fragment>
      <Wrapper
        isEmpty={hasNoContent}
        isLoading={isLoading}
        isOverlayShown={fetchingLoadingState === LoadingStatus.ERROR || hasNoContent}
        overlayProps={
          fetchingLoadingState === LoadingStatus.ERROR
            ? GlobalErrorOverlayProps
            : emptyPageOverlayProps(baseUrl + '/new', userHasWritePermission)
        }
      >
        <Grid template="1fr auto 1px auto" gap={8} margin={[0, 0, 32, 0]}>
          <Title mb={0} overEmptyState>
            Segments
          </Title>
          <FilterSearch
            value={search}
            onChange={onSearch}
            disabled={hasNoContent}
            expandedMaxWidth={220}
            identifier={'Segments'}
          />
          <Separator />
          <Tooltip
            tooltip={`Impossible to create more than ${MAXIMUM_SEGMENT_LIMIT.toString()} segments.`}
            placement="bottom"
            isTooltipEmpty={segmentsTotal < MAXIMUM_SEGMENT_LIMIT}
          >
            <div>
              <PermissionButton
                intent="action"
                kind="primary"
                addOn="prefix"
                to="new"
                disabled={
                  (hasNoContent && fetchingLoadingState === LoadingStatus.LOADING) ||
                  segmentsTotal >= MAXIMUM_SEGMENT_LIMIT
                }
                isAllowed={userHasWritePermission}
              >
                <Icon icon="add" />
                New segment
              </PermissionButton>
            </div>
          </Tooltip>
        </Grid>
        <Box
          style={{
            overflow: 'hidden',
          }}
        >
          <Table template="minmax(100px, 1fr) 200px 180px 90px" rowHeight={52}>
            <Header />
            <Body
              templateSize={segments.size ? segments.size : NUMBER_OF_ENTITIES_PER_PAGE}
              segments={segments}
              emptyTemplate={<EmptyListTemplate />}
              baseUrl={baseUrl}
            />
            {(segmentsTotalMatching > 10 || (segmentsTotalMatching === 0 && isLoading)) && (
              <TableFooter>
                <Pager
                  forceLoading={isLoading}
                  page={currentPage}
                  total={segmentsTotalMatching}
                  nbPerPage={NUMBER_OF_ENTITIES_PER_PAGE}
                  selectPage={setPaginationParam}
                />
              </TableFooter>
            )}
          </Table>
        </Box>
      </Wrapper>
      <Outlet />
    </React.Fragment>
  )
}

// TODO: extract this to a shared component
const emptyPageOverlayProps = (
  newSegmentUrl: string,
  userHasWritePermission: boolean
): OverlayEmptyProps => {
  return {
    status: 'empty-page',
    title: 'No segments to display',
    description: (
      <div>
        <p style={{ marginBottom: 10 }}>
          You haven’t created any segments for this project for now. You can create your first one
          right now by filling out the following form.
        </p>
        <PermissionButton
          intent="action"
          kind="primary"
          to={newSegmentUrl}
          isAllowed={userHasWritePermission}
        >
          <Icon icon="add" style={{ paddingRight: '5px' }} />
          New segment
        </PermissionButton>
      </div>
    ),
  }
}
