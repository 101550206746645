import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'

import { type pushCampaignSendType, AttributeFactory } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { BoxedRadio, InputWrapper, Radio as BaseRadio } from 'components/form'
import {
  authorizedChannelsForRetargetingEvent,
  ClickedMessage,
  OpenedMessage,
  SentMessage,
} from 'com.batch.redux/attribute.api'
import { generateChannelLabel, sortChannels } from 'com.batch.common/utils'
import { colors } from 'components/styled/tokens'
import { generateOrchestrationUrl } from 'com.batch.common/router'
import styled from 'styled-components'

export type RetargetingOrchestrationMeta = {
  id: string
  name: string
  sendType: pushCampaignSendType
  channel: ChannelUntilCleanup
}
type ReplicationModalProps = {
  isOpened: boolean
  onClose: () => void
  orchestrationMeta: RetargetingOrchestrationMeta
}

const commonChannelRadioStyle = {
  paddingRight: 90,
  height: 'auto',
  minHeight: 92,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundPosition: 'center right',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '166px 92px',
}

const ALL_CHANNELS = Immutable.Set<ChannelUntilCleanup>(['sms', 'email', 'push'])

export const RetargetingModal = ({
  isOpened,
  onClose,
  orchestrationMeta,
}: ReplicationModalProps): React.ReactElement => {
  const { sendType, channel, id: orchestrationId } = orchestrationMeta
  const [selectedEventId, setSelectedEventId] = React.useState<string>(SentMessage.id)

  const currentProject = useSelector(currentProjectSelector)
  const hasSmsConfigured = currentProject.smsConfigured
  const hasPushConfigured = currentProject.anyPushConfigured
  const hasEmailConfigured = currentProject.emailConfigured

  const hasCurrentChannelConfigured = React.useCallback(
    (channel: ChannelUntilCleanup) => {
      switch (channel) {
        case 'sms':
          return hasSmsConfigured
        case 'email':
          return hasEmailConfigured
        case 'push':
          return hasPushConfigured
        default:
          return false
      }
    },
    [hasEmailConfigured, hasPushConfigured, hasSmsConfigured]
  )

  const [selectedChannel, setSelectedChannel] = React.useState<ChannelUntilCleanup>(
    hasCurrentChannelConfigured('email')
      ? 'email'
      : hasCurrentChannelConfigured('push')
        ? 'push'
        : 'sms'
  )

  const onEventRadioChange = React.useCallback(
    (event: string) => () => {
      setSelectedEventId(event)
    },
    []
  )

  const onChannelRadioChange = React.useCallback(
    (channel: ChannelUntilCleanup) => () => {
      setSelectedChannel(channel)
    },
    []
  )

  const onRetarget = React.useCallback(async () => {
    if (!selectedChannel) {
      return
    }

    const newCampaignUrl = generateOrchestrationUrl({
      sendType,
      companyId: currentProject.companyId,
      projectId: currentProject.id,
      token: 'new',
      channel: selectedChannel,
      page: 'form',
    })

    const newCampaignUrlWithRetargetingParams = `${newCampaignUrl}?retargetingEvent=${selectedEventId}&retargetingOrchestrationToken=${orchestrationId}`

    window.location.href = newCampaignUrlWithRetargetingParams
  }, [currentProject, orchestrationId, selectedChannel, selectedEventId, sendType])

  const authorizedEvents = React.useMemo(
    () =>
      authorizedChannelsForRetargetingEvent
        .filter(channels => channels.includes(channel))
        .keySeq()
        .map(id => {
          switch (id) {
            case SentMessage.id:
              return SentMessage
            case OpenedMessage.id:
              return OpenedMessage
            case ClickedMessage.id:
              return ClickedMessage
            default:
              return AttributeFactory()
          }
        }),
    [channel]
  )

  const buildChannelRadioStyle = React.useCallback(
    (channel: ChannelUntilCleanup, selected: boolean, channelDisabled: boolean) => {
      return {
        ...commonChannelRadioStyle,
        backgroundImage: `url(/medias/img/illustrations/${channel}-illustration${
          selected ? '-active' : channelDisabled ? '-disabled' : '-inactive'
        }.svg)`,
        cursor: channelDisabled ? 'not-allowed' : 'pointer',
      }
    },
    []
  )

  return (
    <Popin opened={isOpened} close={onClose}>
      <Box style={{ width: '820px' }}>
        <BoxHeader>
          <HeaderBoxTitle title="Retarget campaign" />
          <HeaderBoxActions>
            <Button onClick={onClose}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody style={{ padding: '28px' }}>
          <InputWrapper label="Event" style={{ width: '460px' }}>
            <Grid template="auto auto auto" gap={8}>
              {authorizedEvents.map(event => (
                <Radio
                  key={event.id}
                  label={event.label}
                  disabled={authorizedEvents.size === 1}
                  onChange={onEventRadioChange(event.id)}
                  checked={selectedEventId === event.id}
                  style={{ marginRight: '12px', fontWeight: 400, color: colors.text }}
                />
              ))}
            </Grid>
          </InputWrapper>
          <InputWrapper label="New campaign" style={{ marginTop: '28px' }}>
            <Grid template="auto auto auto" gap={12}>
              {sortChannels(ALL_CHANNELS).map(channel => (
                <BoxedRadio
                  checked={selectedChannel === channel}
                  title={generateChannelLabel(channel)}
                  onChange={onChannelRadioChange(channel)}
                  isDisabled={!hasCurrentChannelConfigured(channel)}
                  style={buildChannelRadioStyle(
                    channel,
                    selectedChannel === channel,
                    !hasCurrentChannelConfigured(channel)
                  )}
                />
              ))}
            </Grid>
          </InputWrapper>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" onClick={onClose}>
            Cancel
          </Button>
          <FooterBoxActions>
            <Button intent="action" kind="primary" onClick={onRetarget} disabled={!selectedChannel}>
              Create campaign
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}

// we don't want the label to appear disabled when the radio input is disabled
const Radio = styled(BaseRadio)`
  input:disabled ~ .styled-option-label {
    color: ${colors.text} !important;
  }
`
