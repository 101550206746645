import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { Checkbox } from 'components/form'

import { pluralize } from 'com.batch.common/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'
import { showToast } from 'com.batch.redux/toaster'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { MINIMUM_LABEL_LENGTH } from 'com.batch/labels/ui/pages/labels-list'
import { saveLabel } from 'com.batch/labels/usecases/save-label'
import {
  LabelCount,
  LabelDescription,
  LabelRow,
  LabelSearch,
  NewLabelRow,
  Scrollable,
  SearchContainer,
} from 'com.batch/orchestration/ui/components/label-association-popin.styles'
import { useSort } from 'com.batch/shared/hooks/use-sort'
import { LoadingStatus } from 'constants/common'

export interface LabelAssociationProps {
  close: () => void
  selectedLabels: List<LabelRecord>
  setSelectedLabels: (labels: List<LabelRecord>) => void
}

const MAXIMUM_ASSOCIATED_LABELS = 5

export const LabelAssociationPopin = ({
  close,
  selectedLabels,
  setSelectedLabels,
}: LabelAssociationProps): React.ReactElement => {
  const [checkedLabels, setCheckedLabels] = useState(
    Immutable.Set(selectedLabels.map(label => label.code))
  )
  const [search, setSearch] = React.useState('')
  const dispatch = useDispatch()
  const { sortEntitiesByKey } = useSort<LabelRecord>()
  const { loadingState, labels } = useSelector(labelsSelector)

  const labelsAndDeletedLabels = useMemo(() => {
    const deletedSelectedLabels = selectedLabels.filter(label => label.isDeleted)
    return labels.concat(deletedSelectedLabels)
  }, [labels, selectedLabels])

  const filteredAndSortedLabels = useMemo(
    () =>
      sortEntitiesByKey(
        sortEntitiesByKey(labelsAndDeletedLabels, 'description', 'asc'),
        'orchestrationCount',
        'dsc'
      ).filter(label => label.description.toLowerCase().includes(search.toLowerCase())),
    [labelsAndDeletedLabels, search, sortEntitiesByKey]
  )

  const onSearch = useCallback((searchQuery: string) => {
    setSearch(searchQuery)
  }, [])

  const createNewLabel = useCallback(() => {
    const newLabel = LabelFactory({
      description: search,
    })

    dispatch(saveLabel(newLabel)).then(createdLabel => {
      setSearch('')
      setCheckedLabels(checkedLabels.add(createdLabel.code))
    })
  }, [dispatch, search, checkedLabels])

  const isLabelSelected = useCallback(
    (label: LabelRecord) => checkedLabels.includes(label.code),
    [checkedLabels]
  )

  const onCheckboxChange = useCallback(
    (label: LabelRecord) => () => {
      setCheckedLabels(
        isLabelSelected(label) ? checkedLabels.remove(label.code) : checkedLabels.add(label.code)
      )
    },
    [checkedLabels, isLabelSelected]
  )

  const onCancel = useCallback(() => {
    setCheckedLabels(Immutable.Set())
    close()
  }, [close])

  const onSubmit = useCallback(() => {
    setSelectedLabels(
      labelsAndDeletedLabels.filter(label => checkedLabels.includes(label.code)).toList()
    )
    dispatch(showToast({ message: 'Label(s) updated', kind: 'success' }))
    close()
  }, [dispatch, checkedLabels, close, setSelectedLabels, labelsAndDeletedLabels])

  const isLoading = useMemo(() => {
    return loadingState === LoadingStatus.LOADING || loadingState === LoadingStatus.INIT
  }, [loadingState])

  const labelAlreadyExists = useMemo(() => {
    return labels.some(label => label.description === search)
  }, [labels, search])

  const isSubmitDisabled = useMemo(
    () => checkedLabels.size === 0 && selectedLabels.size === 0,
    [checkedLabels, selectedLabels]
  )

  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title={`Add up to ${MAXIMUM_ASSOCIATED_LABELS} labels`} />
        <HeaderBoxActions>
          <Button kind="inline" onClick={close}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        <SearchContainer>
          <FilterSearch
            value={search}
            onChange={onSearch}
            expandedMaxWidth={580}
            placeholder="Search or create label..."
            fullPlaceholder
            width={580}
          />
        </SearchContainer>
        <Loader overlay loading={isLoading}>
          <Scrollable sep>
            {filteredAndSortedLabels.size === 0 && !isLoading && !search && (
              <div style={{ textAlign: 'center', padding: '16px' }}>
                You haven’t created any labels for this project for now.
              </div>
            )}
            {!isLoading &&
              filteredAndSortedLabels.map(label => (
                <Checkbox
                  key={label.code}
                  size={16}
                  activeColor={'#0968AC'}
                  disabled={
                    checkedLabels.size >= MAXIMUM_ASSOCIATED_LABELS && !isLabelSelected(label)
                  }
                  style={{
                    marginBottom: '4px',
                    width: '100%',
                    padding: '8px 12px 8px 12px',
                    backgroundColor: `${isLabelSelected(label) ? 'rgba(0, 0, 0, 0.12)' : 'white'}`,
                    borderRadius: '6px',
                    color: 'black',
                  }}
                  onChange={onCheckboxChange(label)}
                  checked={isLabelSelected(label)}
                  label={
                    <Tooltip
                      minWidth={400}
                      delay={500}
                      isTooltipEmpty={!!label.description}
                      tooltip="This label has been deleted but is still associated to this orchestration. If you dissociate this label, you will no longer be able to reassociate it."
                    >
                      <LabelRow>
                        <LabelDescription>
                          {label.description || <s>{label.code}</s>}
                        </LabelDescription>
                        <LabelCount>
                          {label.orchestrationCount > 0 &&
                            pluralize('orchestration', label.orchestrationCount)}
                        </LabelCount>
                      </LabelRow>
                    </Tooltip>
                  }
                />
              ))}
            {search && !labelAlreadyExists && search.length >= MINIMUM_LABEL_LENGTH && (
              <NewLabelRow onClick={createNewLabel}>
                <Icon icon="add" style={{ paddingLeft: '2px', marginRight: '8px' }} />
                Create&nbsp;<LabelSearch>{search}</LabelSearch>
              </NewLabelRow>
            )}
          </Scrollable>
        </Loader>
      </BoxBody>
      <BoxFooter isEditable as="form">
        <Button kind="inline" intent="neutral" onClick={onCancel} type="button">
          Cancel
        </Button>
        <FooterBoxActions>
          <Button
            kind="primary"
            intent="action"
            onClick={onSubmit}
            disabled={isSubmitDisabled}
            type="submit"
          >
            {checkedLabels.size > 1 || !isSubmitDisabled
              ? `Associate ${pluralize('label', checkedLabels.size)}`
              : 'Associate label'}
          </Button>
        </FooterBoxActions>
      </BoxFooter>
    </Box>
  )
}
