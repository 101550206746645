import Immutable, { type List } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { buildAgeFromInputValue } from 'com.batch.common/utils'

import { singleQueryReducer, actions, api } from './query'
import {
  allOperators,
  QueryAttributeFactory,
  FunctionParamsFactory,
  ConditionInputFactory,
  allFunctions,
  DateInputFactory,
  type QueryRecord,
  type OperatorRecord,
  type ConditionRecord,
  type QueryAttributeRecord,
  type FunctionParamsRecord,
  type FunctionRecord,
  QueryFactory,
} from './query.records'
import { CountSinceFunction } from './query.records.functions'
import { ExistsOperator } from './query.records.operators'
import { getParentLogical } from './query.api'

type oursScope = 'native' | 'custom' | 'user'

type oursAttr = {
  type: 'tag' | 'attribute'
  scope: oursScope
  name: string
}

type oursArgs =
  | {
      name: 'lat' | 'lng'
      optional: boolean
      value: number
    }
  | {
      name: 'tag' // ajouté au passage à TS car il manquait mais je connais pas vraiment son type donc any
      scope: string
      value: any
    }
  | {
      name: 'attribute' // ajouté au passage à TS car il manquait mais je connais pas vraiment son type donc any
      scope: string
      value: {
        type: 'attribute'
        scope: 'custom'
        name: string
      }
    }
  | {
      name: 'period' // ajouté au passage à TS car il manquait mais je connais pas vraiment son type donc any
      value: any
    }
  | {
      name: 'radius'
      optional: boolean
      value: {
        name: 'value'
        valueType: 'distance'
        value: {
          length: number
          unit: 'm' | 'km'
        }
      }
    }
  | {
      name: 'expiration'
      optional: boolean
      value: {
        name: 'value'
        valueType: 'duration'
        value: {
          time: number
          unit: 'min'
        }
      }
    }
  | {
      name: 'campaign'
      optional: boolean
      value: string
    }
  | {
      name: 'date'
      optional: boolean
      value: oursAttr | oursFunction
    }
  | {
      name: 'event'
      optional: boolean
      value: {
        scope: oursScope
        name: string
        type: 'event'
        match: oursAndOperation
      }
    }
  | {
      name: 'att'
      optional: boolean
      value: oursAttr | oursFunction
    }

type oursFunction = {
  name: string
  type: 'function'
  args: Array<oursArgs | oursFunction>
}
type oursValue = {
  type: 'value'
  valueType: 'string' | 'number' | 'boolean' | 'bool' | 'long' | 'duration' | 'date' | 'double'
  value: any
}
type oursOperation = {
  type: 'operation'
  name: 'eq' | 'lt' | 'lteq' | 'gt' | 'gteq' | 'containsAny' | 'in'
  left: oursAttr | oursFunction
  right: oursValue | Array<oursValue>
}
type oursAndOperation = {
  type: 'operation'
  name: 'and'
  children: Array<
    | oursOperation
    | oursAndOperation
    | oursOrOperation
    | oursLogicalNot
    | oursExistsOperation
    | oursNotExistsOperation
  >
}
type oursOrOperation = {
  type: 'operation'
  name: 'or'
  children: Array<
    | oursOperation
    | oursAndOperation
    | oursAndOperation
    | oursLogicalNot
    | oursExistsOperation
    | oursNotExistsOperation
  >
}
type oursLogicalNot = {
  type: 'operation'
  name: 'not'
  child:
    | oursOperation
    | oursAndOperation
    | oursOrOperation
    | oursLogicalNot
    | oursExistsOperation
    | oursNotExistsOperation
}
type oursExistsOperation = {
  type: 'operation'
  name: 'exists'
  child: oursAttr
}
type oursNotExistsOperation = {
  type: 'operation'
  name: 'notExists'
  child: oursAttr
}

function oursOperatorToRecord(value: string): OperatorRecord {
  const match = allOperators.find(op => op.value === value)
  if (typeof match === 'undefined' && value !== 'notExists') {
    throw new Error(`Operator "${value}" is not recognized`)
  }
  // @ts-expect-error nécessite pas mal de changements
  return value === 'notExists' ? ExistsOperator : match
}

function buildAttributeName(oa: oursAttr, profileDataEnabled: boolean): string {
  // if profileEnabled, native and type tag
  if (profileDataEnabled && oa.type === 'tag' && oa.scope === 'native') {
    return `bt.${oa.name}`
  }

  if (profileDataEnabled) {
    return oa.type === 'tag' ? `t.${oa.name}` : oa.scope === 'native' ? `b.${oa.name}` : oa.name
  }

  if (oa.type === 'tag') {
    return `${oa.scope === 'custom' ? 't' : 'ut'}.${oa.name}`
  }

  return `${oa.scope === 'custom' ? 'c' : oa.scope === 'native' ? 'b' : 't'}.${oa.name}`
}

const queryId = 'unused in a single reducer context'

function getAttributeOrThrow(
  attributes: List<QueryAttributeRecord>,
  api: string
): QueryAttributeRecord {
  const attr = attributes.find(a => a.api === api)

  if (typeof attr === 'undefined') {
    throw new Error(`Attribute ${api} not recognized`)
  }
  return attr
}

function getFunctionsAndAttrRecursive(
  data: oursAttr | oursFunction,
  attributes: List<QueryAttributeRecord>,
  attribute: QueryAttributeRecord,
  functions: List<FunctionRecord>,
  functionParams: FunctionParamsRecord,
  profileDataEnabled: boolean
): {
  attribute: QueryAttributeRecord
  functions: List<FunctionRecord>
  functionParams: FunctionParamsRecord
  eventFilters?: List<ConditionRecord>
} {
  if (data.type === 'function') {
    if (data.name === 'eventLabel') {
      return {
        attribute: getAttributeOrThrow(attributes, 'eventLabel()'),
        functions,
        functionParams,
      }
    }
    if (data.name === 'eventTags') {
      return {
        attribute: getAttributeOrThrow(attributes, 'eventTags()'),
        functions,
        functionParams,
      }
    }
    if (data.name === 'eventAttr') {
      // @ts-expect-error je comprends pas comment ça fonctionne selon les types c'est impossible ça existe pas `.value.value` et je vois pas où il check que c'est un oursArg. je touche pas pour pas casser
      const attrributeName = data.args[0]?.value?.value
      return {
        attribute: getAttributeOrThrow(
          attributes,
          `eventAttr(attr: '${typeof attrributeName === 'string' ? attrributeName : ''}')`
        ),
        functions,
        functionParams,
      }
    }
    const func = allFunctions.find(f => f.value === data.name)
    if (typeof func === 'undefined') throw new Error(`Function "${data.name}" is not recognized`)
    functions = functions.push(func)
    const lockedArgs: Array<oursArgs | oursFunction> = data.args
    return lockedArgs.reduce(
      (accumulator, subArgOrFunction: oursArgs | oursFunction) => {
        if ('args' in subArgOrFunction) {
          const lockedAndRefined: oursFunction = subArgOrFunction
          const result = getFunctionsAndAttrRecursive(
            lockedAndRefined,
            attributes,
            attribute,
            functions,
            functionParams,
            profileDataEnabled
          )
          return {
            attribute: result.attribute.api !== 'unset' ? result.attribute : accumulator.attribute,
            functions: result.functions,
            functionParams: result.functionParams,
          }
        } else {
          switch (subArgOrFunction.name) {
            case 'event': {
              const event = getAttributeOrThrow(
                attributes,
                `${subArgOrFunction.value.scope === 'native' ? 'be' : 'e'}.${
                  subArgOrFunction.value.name
                }`
              )
              let eventFilters: List<ConditionRecord> = Immutable.List()
              if (subArgOrFunction.value.match) {
                const parsedSubQuery = recursiveParseOursql({
                  profileDataEnabled,
                  attributes: event.eventAttributes,
                  parsed: QueryFactory(),
                  data: subArgOrFunction.value.match,
                  position: 'root',
                  isRootNode: true,
                })
                eventFilters = parsedSubQuery.conditions.reduce(
                  (accumulator, condition, condId) => {
                    const papa = getParentLogical(parsedSubQuery.tree, condId)
                    const isRetargetingAttribute = ['b.orchestration_id', 'b.step_id'].includes(
                      condition?.attribute?.api ?? ''
                    )
                    // with retargeting, we must combine the two conditions into one and set the mode to InputOrchestration
                    if (isRetargetingAttribute) {
                      if (accumulator.size === 0) {
                        return accumulator.push(
                          condition
                            .setIn(['value', 'mode'], 'InputOrchestration')
                            .setIn(
                              ['value', 'retargetedOrchestration', 'orchestrationToken'],
                              condition.value.string
                            )
                        )
                      } else {
                        return accumulator.setIn(
                          [0, 'value', 'retargetedOrchestration', 'stepId'],
                          condition.value.string
                        )
                      }
                    }

                    return accumulator.push(
                      condition.set('isEventFilterNegated', papa && papa.value === 'not')
                    )
                  },
                  Immutable.List()
                )
              }
              return {
                ...accumulator,
                eventFilters,
                attribute: event,
              }
            }
            case 'tag':
            case 'attribute':
              // on the backend, tag is always a params for a count or last function
              // here, it can also be an attribute, when we don't already have one
              if (!attribute || attribute.api === 'unset') {
                return {
                  ...accumulator,
                  attribute: getAttributeOrThrow(
                    attributes,
                    subArgOrFunction.name === 'attribute'
                      ? subArgOrFunction.value.name === 'tags'
                        ? 'b.tags'
                        : subArgOrFunction.value.name
                      : `${subArgOrFunction.value.scope === 'user' ? 'ut' : 't'}.${
                          subArgOrFunction.value.name
                        }`
                  ),
                }
              }
              return accumulator
            case 'campaign':
              return {
                ...accumulator,
                functionParams: accumulator.functionParams.set(
                  'campaignToken',
                  typeof subArgOrFunction.value === 'string' ? subArgOrFunction.value : ''
                ),
              }

            case 'lat':
            case 'lng':
              return {
                ...accumulator,
                attribute: attributes.find(a => a.api === 'b.position') ?? QueryAttributeFactory(),
                functionParams: accumulator.functionParams.set(
                  subArgOrFunction.name,
                  typeof subArgOrFunction.value === 'number' ? subArgOrFunction.value : NaN
                ),
              }
            case 'radius':
              return {
                ...accumulator,
                functionParams: accumulator.functionParams.set(
                  'radius',
                  subArgOrFunction.value.value.length *
                    (subArgOrFunction.value.value.unit === 'm' ? 1 : 1000)
                ),
              }

            case 'date':
              if (subArgOrFunction.value.type === 'function') {
                return getFunctionsAndAttrRecursive(
                  subArgOrFunction.value,
                  attributes,
                  accumulator.attribute,
                  accumulator.functions,
                  accumulator.functionParams,
                  profileDataEnabled
                )
              } else {
                const lockedValueForFlow = subArgOrFunction.value
                return {
                  ...accumulator,
                  attribute: getAttributeOrThrow(
                    attributes,
                    buildAttributeName(lockedValueForFlow, profileDataEnabled)
                  ),
                }
              }
            case 'period': {
              return {
                ...accumulator,
                // if we have a count function with a period, we need to change it to countSince
                functions: functions.map(f => {
                  if (functions.first()?.value === 'count') return CountSinceFunction
                  return f
                }),
                functionParams: accumulator.functionParams.set(
                  'age',
                  buildAgeFromInputValue(
                    subArgOrFunction.value.value.time,
                    subArgOrFunction.value.value.unit,
                    -Infinity
                  )
                ),
              }
            }
            case 'expiration':
              return accumulator
            case 'att':
              return {
                ...accumulator,
                attribute: getFunctionsAndAttrRecursive(
                  subArgOrFunction.value,
                  attributes,
                  accumulator.attribute,
                  accumulator.functions,
                  accumulator.functionParams,
                  profileDataEnabled
                ).attribute,
              }
            default:
              throw 'unhanlded case : ' + JSON.stringify(subArgOrFunction)
          }
        }
      },
      { attribute, functions, functionParams }
    )
  }
  if (data.type === 'attribute' || data.type === 'tag') {
    const builtName = buildAttributeName(data, profileDataEnabled)

    attribute = getAttributeOrThrow(attributes, builtName)
  }
  return { attribute, functions, functionParams }
}

function buildConditionFromOursOperation(
  data: oursOperation | oursExistsOperation | oursNotExistsOperation,
  attributes: List<QueryAttributeRecord>,
  profileDataEnabled: boolean
): ConditionRecord {
  const operator = oursOperatorToRecord(data.name)
  let conditionInput = ConditionInputFactory()
  const { attribute, functions, functionParams, eventFilters } = getFunctionsAndAttrRecursive(
    data.name === 'exists' || data.name === 'notExists' ? data.child : data.left,
    attributes,
    QueryAttributeFactory({ api: 'unset', label: JSON.stringify(data) }),
    Immutable.List(),
    FunctionParamsFactory(),
    profileDataEnabled
  )

  if (data.name === 'exists' || data.name === 'notExists') {
    conditionInput = conditionInput
      .set('boolean', data.name === 'exists')
      .set('mode', 'InputBoolean')
  } else {
    if (Array.isArray(data.right)) {
      const lockedData = data.right
      // @todo on pourrait utiliser le valueType d'un des éléments du Array pour décider si stringList ou numberList
      if (attribute.api === 'bt.custom_audiences' || attribute.api === 'bt.segments') {
        conditionInput = conditionInput
          .set('stringList', Immutable.List(lockedData.map(ov => String(ov.value))))
          .set('mode', attribute.api === 'bt.custom_audiences' ? 'InputAudience' : 'InputSegment')
      } else {
        if (attribute.api === 'b.carrier_code' || attribute.api === 'b.city_code') {
          conditionInput = conditionInput
            .set('numberList', Immutable.List(lockedData.map(ov => parseInt(ov.value))))
            .set('mode', 'InputPrettyList')
        } else {
          conditionInput = conditionInput
            .set('stringList', Immutable.List(lockedData.map(ov => String(ov.value))))
            .set('mode', 'InputStringList')
        }
      }
    } else {
      const lockedData = data.right
      switch (lockedData.valueType) {
        case 'duration':
          conditionInput = conditionInput
            .set(
              'age',
              buildAgeFromInputValue(lockedData.value.time, lockedData.value.unit, -Infinity)
            )
            .set('mode', 'InputAge')
          break
        case 'number':
        case 'double':
        case 'long':
          conditionInput = conditionInput
            .set('number', Number(lockedData.value))
            .set('mode', lockedData.valueType === 'double' ? 'InputFloat' : 'InputInteger')
          break
        case 'bool':
        case 'boolean':
          conditionInput = conditionInput
            .set('boolean', Boolean(lockedData.value))
            .set('mode', 'InputBoolean')
          break
        case 'string':
          conditionInput = conditionInput
            .set('string', String(lockedData.value))
            .set('mode', 'InputString')
          break
        case 'date':
          conditionInput = conditionInput
            .set(
              'date',
              DateInputFactory({
                inputValue: dayjs.unix(lockedData.value).utc().format('DD/MM/YYYY'),
                value: dayjs.unix(lockedData.value).utc(),
              })
            )
            .set('mode', 'InputDate')
          break
      }
    }
  }
  return api
    .buildDefaultCondition(attribute)
    .set('operator', operator)
    .set('eventFilters', eventFilters ?? Immutable.List())
    .set(
      'functions', // dashboard needs a DATE() function, while it's just a type hint for oursql
      attribute.type === 'DATE' && functions.size === 0
        ? allFunctions.filter(f => f.value === 'date')
        : functions
    )
    .set('functionParams', functionParams)
    .set('value', conditionInput)
}

// ====================== PARSER

export function recursiveParseOursql({
  profileDataEnabled,
  attributes,
  parsed,
  data,
  position = 'root',
  isRootNode = false,
}: {
  profileDataEnabled: boolean
  parsed: QueryRecord
  attributes: List<QueryAttributeRecord>
  data:
    | oursAndOperation
    | oursOrOperation
    | oursOperation
    | oursLogicalNot
    | oursExistsOperation
    | oursNotExistsOperation
  position: string
  isRootNode?: boolean
}): QueryRecord {
  if (data.type === 'operation') {
    // ------- and / or à la racine : special case car on a tjrs un and en root dashboard side
    if ((data.name === 'and' || data.name === 'or') && isRootNode) {
      parsed = singleQueryReducer(
        parsed,
        actions.updateNode({ queryId, value: data.name, position: 'root' })
      )
    }
    // ------- and / or / not : on rajoute un Node (sauf and/or & root), & on boucle sur les enfants
    if (data.name === 'and' || data.name === 'or' || data.name === 'not') {
      const frozenDataForFlow: oursAndOperation | oursOrOperation | oursLogicalNot = data as
        | oursAndOperation
        | oursOrOperation
        | oursLogicalNot
      if (frozenDataForFlow.name === 'not' || !isRootNode) {
        const addNodeAction = actions.addNode({
          position,
          queryId,
          value: frozenDataForFlow.name,
        })
        parsed = singleQueryReducer(parsed, addNodeAction)
        position = addNodeAction.payload.value.id
      }
      if (frozenDataForFlow.name === 'not') {
        parsed = recursiveParseOursql({
          profileDataEnabled,
          data: frozenDataForFlow.child,
          parsed,
          position,
          attributes,
        })
      } else {
        frozenDataForFlow.children.forEach(oursPart => {
          parsed = recursiveParseOursql({
            profileDataEnabled,
            attributes,
            data: oursPart,
            parsed,
            position,
          })
        })
      }
      // ------- operation oursql "normale" : un opérator, avec left & right
    } else {
      const condition = buildConditionFromOursOperation(data, attributes, profileDataEnabled)
      parsed = singleQueryReducer(
        parsed,
        actions.addCondition({ queryId, parentId: position, condition })
      )
    }
  }
  return parsed
}
