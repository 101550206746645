import Immutable, { type List, type RecordOf, Record } from 'immutable'

import { LoadingStatus } from 'constants/common'

type ReachabilityCounterProps = {
  subscriptionsLastMonth: number
  unsubscriptionsLastMonth: number
}
export const ReachabilityCounterFactory = Record<ReachabilityCounterProps>({
  subscriptionsLastMonth: 0,
  unsubscriptionsLastMonth: 0,
} as ReachabilityCounterProps)
export type ReachabilityCounterRecord = RecordOf<ReachabilityCounterProps>

type ReachabilityTimeserieProps = {
  subscriptions: number
  unsubscriptions: number
  date: string
  period: string
}
export const ReachabilityTimeserieFactory = Record<ReachabilityTimeserieProps>({
  subscriptions: 0,
  unsubscriptions: 0,
  date: '',
  period: '',
} as ReachabilityTimeserieProps)
export type ReachabilityTimeserieRecord = RecordOf<ReachabilityTimeserieProps>

type ReachabilityTimeserieStateProps = {
  loadingState: LoadingStatus
  counter: ReachabilityCounterRecord
  timeserie: List<ReachabilityTimeserieRecord>
}
export const ReachabilityTimeserieStateFactory = Record<ReachabilityTimeserieStateProps>({
  loadingState: LoadingStatus.INIT,
  counter: ReachabilityCounterFactory(),
  timeserie: Immutable.List(),
} as ReachabilityTimeserieStateProps)
export type ReachabilityTimeserieStateRecord = RecordOf<ReachabilityTimeserieStateProps>

type ChannelsReachabilityStateProps = {
  email: ReachabilityTimeserieStateRecord
  push: ReachabilityTimeserieStateRecord
  sms: ReachabilityTimeserieStateRecord
  webpush: ReachabilityTimeserieStateRecord
}
export const ChannelsReachabilityStateFactory = Record<ChannelsReachabilityStateProps>({
  email: ReachabilityTimeserieStateFactory(),
  push: ReachabilityTimeserieStateFactory(),
  sms: ReachabilityTimeserieStateFactory(),
  webpush: ReachabilityTimeserieStateFactory(),
} as ChannelsReachabilityStateProps)
export type ChannelsReachabilityStateRecord = RecordOf<ChannelsReachabilityStateProps>
