import parseInt from 'core-js/es/number/parse-int'

import { type List, type Set } from 'immutable'

import { HEXtoRGB } from 'components/styled/tokens'

import { dayjs, type Dayjs } from 'com.batch.common/dayjs.custom'

import {
  AgeFactory,
  type AgeRecord,
  type CampaignRecord,
  type intervalUnit,
  TimeIntervalUnitLabels,
} from 'com.batch.redux/_records'
import { type ErrorRecord } from 'com.batch.redux/stat.records'
import type { availableIcons } from '../components/common/svg-icon'

export const convertArrayPlatformToPreviewPlatform = (
  platFormArr: Array<Platforms> | Array<ProjectPlatforms>
): Array<PreviewPlatform> => {
  // si on target que web =>
  if (platFormArr.length === 1 && platFormArr[0] === 'webpush')
    return ['webWin', 'webMac', 'webIos', 'webAndroid']
  // sinon
  return [
    ...(platFormArr.includes('ios') ? ['ios'] : []),
    ...(platFormArr.includes('android') ? ['android'] : []),
    ...(platFormArr.includes('webpush') ? ['webWin', 'webMac'] : []),
  ] as Array<PreviewPlatform>
}

function addCommas(nStr: string) {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const pluralize = (word: string, count: number): string => {
  return `${count} ${word}${count === 0 || count > 1 ? 's' : ''}`
}

export const singular = (word: string): string => {
  return word.replace(/s$/, '')
}

export const numberFormat = (number?: number | null): string => {
  if (typeof number === 'undefined' || number === null) return ''
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(parseInt(number))
}

export const formatPrice = (
  value: number,
  currency: 'eur' | 'usd',
  isCents: boolean = false
): string => {
  const divider = isCents ? 100 : 1
  if (typeof value === 'undefined' || value === null) return ''
  return `${currency === 'usd' ? '$' : ''}${new Intl.NumberFormat(
    currency === 'usd' ? 'en-US' : 'fr-FR',
    {
      maximumFractionDigits: 0,
    }
  ).format(parseInt(value / divider))}${currency === 'eur' ? ' €' : ''}`
}

export const formatNumberWithSpaces = (number: number): string =>
  number.toLocaleString('fr-FR', { useGrouping: true })

export const formatNumberWithCommas = (number: number): string =>
  number.toLocaleString('en-US', { useGrouping: true })

export const kformat = (
  numberOrString?: number | string | null,
  displaySign: boolean = false
): string => {
  if (typeof numberOrString === 'undefined' || numberOrString === null) {
    return '-'
  }
  let number: number = Number(numberOrString)
  number = parseInt(number)
  if (number === 0) {
    return '0'
  }
  let sign = number > 0 ? '+' : '-'
  sign = displaySign ? sign : ''
  number = Math.abs(number)

  // k - kilo, 10³
  // M - mega, 10⁶
  // G - giga, 10⁹
  // T - tera, 10¹²
  // P - peta, 10¹⁵

  const prefixes = [
    {
      symbol: 'P',
      min: 1000000000000,
      div: 1000000000000,
    },
    {
      symbol: 'T',
      min: 1000000000,
      div: 1000000000,
    },
    {
      symbol: 'M',
      min: 1000000,
      div: 1000000,
    },
    {
      symbol: 'k',
      min: 10000,
      div: 1000,
    },
  ]

  let found: { symbol: string; min: number; div: number } | undefined
  prefixes.forEach(p => {
    if (!found && number >= p.min) {
      found = p
    }
  })
  if (!found) {
    return addCommas(number.toFixed())
  } else {
    const [entier, decimal] = (number / found.div).toFixed(1).split('.')

    return sign + entier + (decimal !== '0' ? '.' + decimal : '') + found.symbol
  }
}

export const pluralizeAndKformat = (word: string, count: number): string => {
  return `${kformat(count)} ${word}${count === 0 || count > 1 ? 's' : ''}`
}

export const ucFirst = (str?: string | null): string =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : ''

export const percentage = (
  number?: number | null,
  decimals: number = 1,
  displaySign: boolean = false,
  displayPercent: boolean = true
): string => {
  if (typeof number === 'undefined' || number === null) {
    return ''
  }
  const sign = displaySign && number > 0 ? '+' : ''
  number = Math.abs(number) * 100
  return `${sign}${number === 0 ? 0 : number === 100 ? '100' : number.toFixed(decimals)}${
    displayPercent ? '%' : ''
  }`
}
export const formatPushToken = (
  value?: string | null,
  length: number = 4
): string | null | undefined => {
  if (typeof value === 'undefined' || !value || value.length < 15) {
    return value
  } else {
    return value.substring(0, length) + '....' + value.substring(value.length - length)
  }
}

export const computeRepeatWord = (
  unit: 'WEEKLY' | 'DAILY' | 'MONTHLY',
  frequency: number,
  start?: Dayjs | null
): string => {
  let txt = `${frequency > 1 ? frequency + ' ' : ''}`
  switch (unit) {
    case 'WEEKLY':
      txt += start ? start.format('dddd') : ''
      break
    case 'DAILY':
      txt += 'day'
      break
    case 'MONTHLY':
      txt += 'month'
      break
  }
  return `${txt}${frequency > 1 ? 's' : ''}`
}

export const durationLongerThanHours = (
  floatMinutes: number | null | undefined,
  thresholdHours: number
): boolean => {
  if (!floatMinutes) {
    return false
  }
  const hours = Math.ceil(floatMinutes / 60)
  return hours > thresholdHours
}

export const rateDuration = (floatMinutes?: number | null): string => {
  if (typeof floatMinutes === 'undefined' || floatMinutes === null || isNaN(floatMinutes)) {
    return '-'
  }
  const totalSeconds = Math.floor(floatMinutes * 60)
  const seconds = totalSeconds % 60
  const totalMinutes = Math.floor(totalSeconds / 60)
  if (totalMinutes > 24 * 60) {
    return 'more than a day'
  } else {
    const minutes = totalMinutes % 60
    const hours = Math.floor(totalMinutes / 60)
    const final: Array<string> = []
    if (hours > 0) {
      final.push(`${hours} hour${hours > 1 ? 's' : ''}`)
    }
    if (minutes > 0) {
      final.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
    }
    if (seconds < 15 && hours === 0 && minutes === 0) {
      return '~ 15 sec'
    }
    if (seconds > 0 && hours === 0 && (minutes === 0 || minutes < 3)) {
      final.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
    }
    return final.join(', ')
  }
}

export const getOS = (): string | null | undefined => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod']

  let os: string | null = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const slugify: (string: string) => string = string => {
  if (!string) {
    return ''
  }
  if (typeof String.prototype.normalize === 'function') {
    string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
  return string
    .toUpperCase()
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
    .replace(/^-+|-+$/g, '') // remove leading, trailing -
}

export const AdvertiserId = {
  '': '',
  mobile: '',
  ios: 'IDFA',
  android: 'GAID',
  windows: 'Advertising Id',
  webpush: 'Advertising Id',
}

export const formatIdentifier = (platform: Platforms, mode: lookupMode | 'install_id'): string => {
  switch (mode) {
    case 'token':
      return 'Push token'
    case 'custom_id':
      return 'Custom User ID'
    case 'installation_id':
    case 'install_id':
      return 'Installation ID'
    default:
      return AdvertiserId[platform]
  }
}

export const formatAudienceType = (
  mode: 'custom_ids' | 'advertising_ids' | 'install_ids'
): string => {
  switch (mode) {
    case 'custom_ids':
      return 'Custom user ID'
    case 'install_ids':
      return 'Installation ID'
    default:
      return 'Advertising ID'
  }
}

export function sumErrors(errs: List<ErrorRecord>): number {
  return errs.reduce((prev, current) => prev + current.count, 0)
}

const coordinateToDms = (coordinate: number) => {
  const absolute = Math.abs(coordinate)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = Math.round((minutesNotTruncated - minutes) * 60 * 10) / 10

  return `${degrees}°${minutes}'${seconds}"`
}

export const coordinatesToDms = (lat: number, lng: number): string => {
  const latitude = coordinateToDms(lat)
  const latitudeCardinal = lat >= 0 ? 'N' : 'S'

  const longitude = coordinateToDms(lng)
  const longitudeCardinal = lng >= 0 ? 'E' : 'W'

  return `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`
}

export const isDms = (str: string): boolean =>
  /^\d+°\d+'\d+(.\d+)?"[N|S] \d+°\d+'\d+(.\d+)?"[E|W]$/.test(str)

export const isEmail = (maybeEmail: string): boolean => {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,50}$/i
  return re.test(maybeEmail.toLowerCase())
}

export function convertSecondToMatchingUnit(
  value: number,
  allowedUnit: Array<intervalUnit> = ['s', 'm', 'h', 'd']
): {
  unit: intervalUnit
  value: number
  label: string
} {
  let unit: intervalUnit = 's'
  let divider = 1
  if (value % 60 === 0 && allowedUnit.indexOf('m') !== -1) {
    divider = 60
    unit = 'm'
  }
  if (value % 3600 === 0 && allowedUnit.indexOf('h') !== -1) {
    divider = 3600
    unit = 'h'
  }
  if (value % (24 * 3600) === 0 && allowedUnit.indexOf('d') !== -1) {
    divider = 24 * 3600
    unit = 'd'
  }

  if (unit === 's' && !allowedUnit.includes('s')) {
    throw new Error(`${value} not convertible in one of the allowed units`)
  }
  const convertedValue = value / divider
  return {
    unit,
    value: convertedValue,
    label: TimeIntervalUnitLabels[unit] + (convertedValue > 1 ? 's' : ''),
  }
}

const multiplier = {
  s: 1,
  m: 60,
  h: 60 * 60,
  d: 60 * 60 * 24,
}

export const buildAgeFromDuration = (
  duration: string,
  allowedUnits: Array<intervalUnit> = ['s', 'm', 'h', 'd']
): AgeRecord => {
  const unit = duration.slice(-1)
  const value = parseInt(duration.slice(0, -1))
  const coercedUnit: intervalUnit = unit === 'm' || unit === 'h' || unit === 'd' ? unit : 's'
  const seconds = value * multiplier[coercedUnit]
  return buildAgeFromSeconds(seconds, allowedUnits)
}

export function buildAgeFromInputValue(
  inputValue: string | number,
  defaultUnit: intervalUnit,
  min: number = 0,
  max: number = Infinity,
  allowedUnits: Array<intervalUnit> = ['s', 'm', 'h', 'd'],
  convertUnit: boolean = true
): AgeRecord {
  const seconds =
    (typeof inputValue === 'number' ? inputValue : parseInt(inputValue)) * multiplier[defaultUnit]

  if (isNaN(seconds)) {
    return AgeFactory({
      inputValue: inputValue.toString(),
      seconds: NaN,
      valid: false,
      unit: defaultUnit,
    })
  } else {
    try {
      if (!convertUnit) {
        return AgeFactory({
          inputValue: inputValue.toString(),
          seconds,
          unit: defaultUnit,
          label: `${TimeIntervalUnitLabels[defaultUnit]}${parseInt(inputValue) > 1 ? 's' : ''}`,
          value: parseInt(inputValue),
          valid: seconds >= min && seconds <= max,
          fullText: `${inputValue.toString()} ${TimeIntervalUnitLabels[defaultUnit]}${
            parseInt(inputValue) > 1 ? 's' : ''
          }`,
        })
      }

      const { unit, value, label } = convertSecondToMatchingUnit(seconds, allowedUnits)
      return AgeFactory({
        inputValue: inputValue.toString(),
        seconds,
        unit,
        label,
        value,
        valid: seconds >= min && seconds <= max,
        fullText: `${value} ${label}`,
      })
    } catch {
      return AgeFactory({
        valid: false,
        inputValue: inputValue.toString(),
        unit: defaultUnit,
      })
    }
  }
}

export function buildAgeFromSeconds(
  seconds: number,
  allowedUnits: Array<intervalUnit> = ['s', 'm', 'h', 'd'],
  min: number = 0,
  max: number = Infinity
): AgeRecord {
  try {
    const { unit, value, label } = convertSecondToMatchingUnit(seconds, allowedUnits)
    return AgeFactory({
      inputValue: value.toString(),
      seconds,
      unit,
      label,
      value,
      valid: seconds >= min && seconds <= max,
      fullText: `${value} ${label}`,
    })
  } catch {
    return AgeFactory({
      valid: false,
      inputValue: '',
      unit: allowedUnits[0],
    })
  }
}

export function validateUrl(txt: string): boolean {
  if (txt.substring(0, 16) === 'http://localhost' || txt.substring(0, 17) === 'https://localhost')
    return true
  let url
  try {
    url = new URL(txt)
  } catch {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

// example: 12 mins ago or 12 mins
export const humanizeDayjs = ({
  date,
  suffix = 'ago',
  timeframe = false,
}: {
  date: Dayjs
  suffix?: string
  timeframe?: boolean
}): string => {
  let lastUpdate = ''
  const yearsDiff: number = dayjs().diff(dayjs(date), 'year')
  const monthsDiff: number = dayjs().diff(dayjs(date), 'month')
  const weeksDiff: number = dayjs().diff(dayjs(date), 'week')
  const daysDiff: number = dayjs().diff(dayjs(date), 'day')
  const hoursDiff: number = dayjs().diff(dayjs(date), 'hour')
  const minutesDiff: number = dayjs().diff(dayjs(date), 'minute')
  const secondsDiff: number = dayjs().diff(dayjs(date), 'second')

  if (yearsDiff > 0) {
    lastUpdate = `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ${suffix}`
  } else if (monthsDiff > 0) {
    lastUpdate = `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ${suffix}`
  } else if (weeksDiff > 0) {
    lastUpdate = `${weeksDiff} week${weeksDiff > 1 ? 's' : ''} ${suffix}`
  } else if (timeframe && daysDiff === 1) {
    lastUpdate = 'Yesterday'
  } else if (daysDiff > 0) {
    lastUpdate = `${daysDiff} day${daysDiff > 1 ? 's' : ''} ${suffix}`
  } else if (hoursDiff > 0) {
    lastUpdate = `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ${suffix}`
  } else if (minutesDiff > 0) {
    lastUpdate = `${minutesDiff} min ${suffix}`
  } else if (secondsDiff > 0) {
    lastUpdate = `${secondsDiff} sec ${suffix}`
  } else if (timeframe) {
    lastUpdate = 'Today'
  }

  return lastUpdate
}

// example: 12y ago or 12y
export const humanizeDayjsShortFormat = ({
  date,
  suffix = 'ago',
}: {
  date: Dayjs
  suffix?: string
}): string => {
  const yearsDiff: number = dayjs().diff(dayjs(date), 'year')
  const monthsDiff: number = dayjs().diff(dayjs(date), 'month')
  const daysDiff: number = dayjs().diff(dayjs(date), 'day')
  const hoursDiff: number = dayjs().diff(dayjs(date), 'hour')
  const minutesDiff: number = dayjs().diff(dayjs(date), 'minute')
  const secondsDiff: number = dayjs().diff(dayjs(date), 'second')

  if (yearsDiff > 0) return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ${suffix}`
  if (monthsDiff > 0) return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ${suffix}`
  if (daysDiff > 0) return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ${suffix}`
  if (hoursDiff > 0) return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ${suffix}`
  if (minutesDiff > 0) return `${minutesDiff} min ${suffix}`
  if (secondsDiff > 0) return `${secondsDiff} sec ${suffix}`
  return ''
}

// N months
export const humanizeTimeRemainingDayjs = (date: Dayjs): string => {
  let lastUpdate = ''

  const yearsDiff: number = dayjs(date).diff(dayjs(), 'year')
  const monthsDiff: number = dayjs(date).diff(dayjs(), 'month')
  const daysDiff: number = dayjs(date).diff(dayjs(), 'day')
  const hoursDiff: number = dayjs(date).diff(dayjs(), 'hour')
  const minutesDiff: number = dayjs(date).diff(dayjs(), 'minute')
  const secondsDiff: number = dayjs(date).diff(dayjs(), 'second')

  if (yearsDiff > 0) {
    lastUpdate = `${yearsDiff} year${yearsDiff > 1 ? 's' : ''}`
  } else if (monthsDiff > 0) {
    lastUpdate = `${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`
  } else if (daysDiff > 0) {
    lastUpdate = `${daysDiff} day${daysDiff > 1 ? 's' : ''}`
  } else if (hoursDiff > 0) {
    lastUpdate = `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''}`
  } else if (minutesDiff > 0) {
    lastUpdate = `${minutesDiff} min`
  } else if (secondsDiff > 0) {
    lastUpdate = `${secondsDiff} sec`
  }

  return lastUpdate
}

// overkill but turfuproof
export const campaignStateFormatter = (state: campaignStateType, isAutomation: boolean): string => {
  switch (state) {
    case 'COMPLETED':
      return 'SENT'
    case 'RUNNING':
      return isAutomation ? 'RUNNING' : 'PLANNED'
    default:
      return state
  }
}

export const capitalize = (s?: string | null): string => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function isCampaignRunning(campaign: CampaignRecord): boolean {
  const isPush = campaign.type === 'push'
  const sendType = campaign.sendType
  const state = campaign.state
  const tzAware = campaign.tzAware

  if (state !== 'RUNNING') return false

  const now = tzAware ? dayjs() : dayjs.utc()

  if (!isPush || sendType === 'trigger') {
    return (
      (!campaign.start || campaign.start.isBefore(now)) &&
      (!campaign.end || campaign.end.isAfter(now))
    )
  }

  return true
}

export function devkitUUID(): string {
  // This isn't gonna be the best implementation ever, but it will do well

  // Designed according to
  // http://codingrepo.com/regular-expression/2015/11/23/javascript-generate-uuidguid-for-rfc-4122-version-4-compliant-with-regular-expression/

  // We need 31 random ints. Other are dashes and the magic 4
  const randomNumbers = new Array(31)

  // Populate the random numbers
  if (
    typeof window.crypto !== 'undefined' &&
    typeof window.crypto.getRandomValues !== 'undefined'
  ) {
    const prngRandomBytes = new Uint8Array(randomNumbers.length)
    window.crypto.getRandomValues(prngRandomBytes)
    for (let i = 0; i < randomNumbers.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      randomNumbers[i] = prngRandomBytes[i] % 16 | 0
    }
  } else {
    for (let i = 0; i < randomNumbers.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      randomNumbers[i] = (Math.random() * 16) | 0
    }
  }

  // The 16th number needs to be ‘8’, ‘9’, ‘A’, or ‘B’. We can bitmask that.
  // eslint-disable-next-line no-bitwise, no-mixed-operators
  randomNumbers[15] = (randomNumbers[15] & 0x3) | 0x8

  let uuidString = ''
  // Generate the uuid string. Don't forget to add the - and 4 at the right positions
  for (let i = 0; i < randomNumbers.length; i += 1) {
    uuidString += randomNumbers[i].toString(16)

    if (i === 7 || i === 14 || i === 18) {
      uuidString += '-'
    } else if (i === 11) {
      uuidString += '-4'
    }
  }

  return uuidString
}

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const TAG_REGEX = /{{[^}]*}}/g
const BLOC_REGEX = /{%[^}]*%}/g

export const textUsesTemplating = (text?: string | null): boolean => {
  return !!text && (!!text.match(TAG_REGEX) || !!text.match(BLOC_REGEX))
}

export const randomSize = (minSize: number, maxSize: number): number =>
  Math.floor(Math.random() * (maxSize - minSize)) + minSize

export const formatInstanceId = (text: string): string => {
  return text
    .replace('eventLabel(', 'Label')
    .replace('eventAttr(attr:', '')
    .replace(' ', '')
    .replace("'", '')
    .replace("'", '')
    .replace(')', '')
}

// FIXME: ne prend pas en compte flutter/cordova/react native
export const getPlatformDoc = (platform: Platforms): string => {
  let namePlatformOnDoc: string = platform
  if (platform === 'webpush') namePlatformOnDoc = 'web'
  return namePlatformOnDoc
}

export function cancellablePromise<T>(promise: Promise<T>): {
  cancel: () => void
  promise: Promise<T>
} {
  let isCancelled = false
  const wrappedPromise = new Promise(
    (res: (result: Promise<T> | T) => void, rej: (error?: any) => void) => {
      promise
        .then(d => {
          if (!isCancelled) res(d)
        })
        .catch(e => {
          if (!isCancelled) rej(e)
        })
    }
  )

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled = true
    },
  }
}

export const validateJSON = (text: string): boolean => {
  let valid = true
  if (text.trim() !== '') {
    try {
      JSON.parse(text.trim())
    } catch {
      valid = false
    }
  }
  return valid
}

export const convertRepeatUnit = (
  repeatUnit: 'DAILY' | 'MONTHLY' | 'WEEKLY'
): 'day' | 'month' | 'week' => {
  return repeatUnit === 'DAILY' ? 'day' : repeatUnit === 'MONTHLY' ? 'month' : 'week'
}

export const computeNextPushDate = ({
  start,
  end,
  repeatUnit,
  repeatFrequency,
  now = dayjs.utc(),
}: {
  start: Dayjs
  end: Dayjs | null | undefined
  repeatUnit: 'DAILY' | 'WEEKLY' | 'MONTHLY'
  repeatFrequency: number
  now?: Dayjs
}): Dayjs | null | undefined => {
  if (repeatFrequency < 1) return null
  let next = start
  const unit = convertRepeatUnit(repeatUnit)
  const last = end ? end : now
  if (start.isAfter(now)) return start
  while (next.isBefore(last) && next.isBefore(now)) {
    next = next.add(repeatFrequency, unit)
  }
  return next.isAfter(now) && (!end || next.isBefore(end)) ? next : null
}

function rgbToHsl(r: number, g: number, b: number): [number, number, number] {
  r /= 255
  g /= 255
  b /= 255
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h: number = 0,
    s: number
  const l: number = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h, s, l]
}

export function hexToHsl(hex: string): {
  h: number
  l: number
  s: number
} {
  const [r, g, b] = HEXtoRGB(hex)
  const [h, s, l] = rgbToHsl(r, g, b)
  return { h, s, l }
}

function toHex(n: number): string {
  n = parseInt(n, 10)
  if (isNaN(n)) return '00'
  n = Math.max(0, Math.min(n, 255))
  return '0123456789ABCDEF'.charAt((n - (n % 16)) / 16) + '0123456789ABCDEF'.charAt(n % 16)
}

export function shift(
  hsl: {
    h: number
    l: number
    s: number
  },
  sat: number,
  lum: number
): {
  h: number
  l: number
  s: number
} {
  return {
    h: hsl.h,
    s: hsl.s * sat,
    l: Math.min(hsl.l * lum, 1),
  }
}

export function hslToHex(hsl: { h: number; l: number; s: number }): string {
  const hue = hsl.h * 360
  const saturation = hsl.s
  const lightness = hsl.l

  if (hue === undefined) {
    return '#000000'
  }

  const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation
  let huePrime = hue / 60
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1))

  huePrime = Math.floor(huePrime)
  let red: number = 0
  let green: number = 0
  let blue: number = 0

  if (huePrime === 0) {
    red = chroma
    green = secondComponent
    blue = 0
  } else if (huePrime === 1) {
    red = secondComponent
    green = chroma
    blue = 0
  } else if (huePrime === 2) {
    red = 0
    green = chroma
    blue = secondComponent
  } else if (huePrime === 3) {
    red = 0
    green = secondComponent
    blue = chroma
  } else if (huePrime === 4) {
    red = secondComponent
    green = 0
    blue = chroma
  } else if (huePrime === 5) {
    red = chroma
    green = 0
    blue = secondComponent
  }

  const lightnessAdjustment = lightness - chroma / 2
  red += lightnessAdjustment
  green += lightnessAdjustment
  blue += lightnessAdjustment

  const rgb = [Math.round(red * 255), Math.round(green * 255), Math.round(blue * 255)]
  return `#${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`
}

export function shiftHex(hexCol: string, sat: number, lum: number): string {
  return hslToHex(shift(hexToHsl(hexCol), sat, lum))
}

export const getSchedulingType = (
  campaign:
    | CampaignRecord
    | {
        type: string
        repeatFrequency: number | null | undefined
      }
): schedulingType => {
  if (
    campaign.type === 'TRIGGER' ||
    campaign.type === 'LOCAL' ||
    (campaign.type.toUpperCase() === 'PUSH' && campaign.repeatFrequency !== null)
  ) {
    return 'automations'
  }
  return 'campaigns'
}

export const preventKeyPressNonInteger = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  const forbidden = ['.', ',', '-']
  if (forbidden.includes(evt.key)) {
    evt.preventDefault()
  }
}

export const getStableNumberFromTextEnd = (text: string, max: number = 12): number => {
  const asciiCode = text.charCodeAt(text.length - 1)
  return (asciiCode % max) + 1
}

export const convertNumberToDayOfWeek = (num: number): string => dayjs().day(num).format('dddd')

export const generateListOfDays = (daysOfWeek: Set<number>): string | undefined => {
  if (daysOfWeek.size === 0) return ''

  // sort days of week in ascending order, with Sunday at the end if present
  const allDaysSorted = daysOfWeek
    .sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b))
    .map(day => convertNumberToDayOfWeek(day))
    .toArray()
  const lastDay = allDaysSorted.pop()
  return allDaysSorted.length > 0 ? `${allDaysSorted.join(', ')} and ${lastDay}` : lastDay
}

export const incrementStringCode = (code: string, separator: string = '-'): string => {
  const lastSeparatorPosition = code.lastIndexOf(separator)
  const lastPart = code.substring(lastSeparatorPosition + 1)
  const lastPartInt = parseInt(lastPart)
  if (isNaN(lastPartInt)) {
    return code + separator + '1'
  } else {
    return code.substring(0, lastSeparatorPosition) + separator + (lastPartInt + 1)
  }
}

export const getVariantLabel = (id: number): string => {
  switch (id) {
    case 1:
      return 'A'
    case 2:
      return 'B'
    case 3:
      return 'C'
    default:
      return 'D'
  }
}

// A replacement for lodash.get
export const get = (obj: any, path: string, defValue?: any) => {
  if (!path) return undefined
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  const result = pathArray?.reduce((prevObj, key) => prevObj?.[key], obj)
  return result === undefined ? defValue : result
}

// A replacement for lodash.first (head
export const first = (arr: any[]) => arr[0]

// A replacement for lodash.max
export const max = (nums: number[]) => {
  if (nums.length) return Math.max(...nums)
}

export const sortChannels = (channels: Set<ChannelUntilCleanup>): Array<ChannelUntilCleanup> => {
  const channelWeight = {
    email: 1,
    push: 2,
    sms: 3,
  }
  return channels.toArray().sort((a, b) => (channelWeight[a] > channelWeight[b] ? 1 : -1))
}

export const generateChannelIcon = (channel: ChannelUntilCleanup): availableIcons => {
  switch (channel) {
    case 'email':
      return 'mail'
    case 'sms':
      return 'sms'
    case 'push':
      return 'push'
    default:
      return 'push'
  }
}

export const generateChannelLabel = (channel: ChannelUntilCleanup): string => {
  switch (channel) {
    case 'email':
      return 'Email'
    case 'sms':
      return 'SMS'
    case 'push':
      return 'Push'
    default:
      return 'Unknown'
  }
}
