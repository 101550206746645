import Immutable, { type Map, type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import styled from 'styled-components'

import { Button, ButtonLink } from 'components/common/button'
import NoData from 'components/common/nodata'
import { Icon } from 'components/common/svg-icon'
import {
  Form,
  InputWrapper,
  InputContainer,
  FormRow,
  Checkbox,
  Input,
  Select,
  selectBaseCountryProps,
} from 'components/form'
import * as colors from 'components/styled/colors'
import { LinkArrow } from 'components/styled/text'

import { ProcessingAgreement } from './processing-agreement'

import {
  Title,
  Header,
  AccountSection,
  SectionTitle,
  SectionInfo,
  SectionForm,
} from '../account.styles'
import { type RegionRecord, type GDPRRecord } from 'com.batch.redux/_records'
import { currentCompanySelector, saveGDPR } from 'com.batch.redux/company'

const basecategories = Immutable.OrderedSet([
  'Logs data (eg: last connection)',
  'Personal data (eg: identity)',
  'Geolocation data (eg: GPS coordinates)',
])

export const GDPR = (): React.ReactElement => {
  const dispatch = useDispatch()
  const company = useSelector(currentCompanySelector)
  const [data, updateData] = React.useState<GDPRRecord>(company.gdpr)
  const [categories, updateCategories] = React.useState<Set<string>>(
    company.gdpr.categories.union(basecategories)
  )
  const [newCategory, updateNewCategory] = React.useState('')
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const addCategory = React.useCallback(() => {
    if (typeof newCategory !== 'string' || !newCategory.trim()) {
      return
    }
    updateNewCategory('')
    updateCategories(categories.add(newCategory.trim()))
    updateData(data.set('categories', data.categories.add(newCategory.trim())))
  }, [newCategory, categories, data])

  const toggleDisabled = React.useCallback(() => {
    updateData(data.set('disabled', !data.disabled))
  }, [data])
  const toggleCategory = React.useCallback(
    (name: string) => {
      let selectedCategories = data.categories
      if (selectedCategories.has(name)) {
        selectedCategories = selectedCategories.delete(name)
      } else {
        selectedCategories = selectedCategories.add(name)
      }
      updateData(data.set('categories', selectedCategories))
    },
    [data]
  )
  const createToggleCategory = React.useCallback(
    (c: string) => () => toggleCategory(c),
    [toggleCategory]
  )

  const onUpdateNewCategory = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => updateNewCategory(evt.target.value),
    []
  )

  const onAddCategory = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        addCategory()
      }
    },
    [addCategory]
  )

  // refresh company - dirty hack to handle untouched state when the company is updated
  React.useEffect(() => {
    if (!company.loading) {
      updateData(company.gdpr)
    }
  }, [company])

  const onInputChange = React.useCallback(
    (
      field: 'name' | 'address' | 'address2' | 'zip' | 'city' | 'country' | 'dpoName' | 'dpoEmail'
    ) =>
      (evt: any) =>
        updateData(data.set(field, evt.target.value)),
    [updateData, data]
  )

  const onCountryChange = React.useCallback(
    (opt: RegionRecord | null | undefined) => {
      updateData(data.set('country', opt?.value ?? ''))
    },
    [updateData, data]
  )

  const onSubmit = React.useCallback(() => {
    const err: [string, string][] = []
    if (!data.disabled) {
      if (data.name.trim() === '') err.push(['name', 'Please enter a company name'])
      if (data.address.trim() === '') err.push(['address', 'Please enter an address'])
      if (data.city.trim() === '') err.push(['city', 'Please enter a city'])
      if (data.zip.trim() === '') err.push(['zip', 'Please enter a zip code'])
      if (data.country.trim() === '') err.push(['country', 'Please enter a country'])
    }
    const currentErrors = Immutable.Map(err)
    setErrors(currentErrors)
    if (currentErrors.size === 0) {
      dispatch(saveGDPR(company, data))
    }
  }, [company, data, dispatch])

  // scroll to error
  React.useEffect(() => {
    if (errors.size > 0) {
      window.location.hash = ''
      window.setTimeout(() => {
        window.location.hash = errors.keySeq().first()
      }, 3)
    }
  }, [errors])
  const untouched =
    data.name === company.gdpr.name &&
    data.address === company.gdpr.address &&
    data.address2 === company.gdpr.address2 &&
    data.city === company.gdpr.city &&
    data.zip === company.gdpr.zip &&
    data.country === company.gdpr.country &&
    data.dpoName === company.gdpr.dpoName &&
    data.dpoEmail === company.gdpr.dpoEmail &&
    data.categories === company.gdpr.categories &&
    data.disabled === company.gdpr.disabled
  return data.external ? (
    <NoData title="Account verified" icon="check">
      <p>
        Your company DPO or legal counsel has already filled a Data Processing Agreement and you
        have nothing additional to do at this time.
      </p>
      <p style={{ marginTop: 15 }}>
        <ButtonLink kind="primary" intent="neutral" href="/">
          Take me to the dashboard
        </ButtonLink>
      </p>
    </NoData>
  ) : (
    <Form onSubmit={onSubmit} horizontal>
      <Title style={{ display: 'flex', alignItems: 'center' }}>
        GDPR
        {company.gdpr.updated && (
          <LastUpdate>
            <Icon icon="success" style={{ marginRight: 9 }} />
            Last update on {company.gdpr.updated.format('DD/MM/YYYY')}
          </LastUpdate>
        )}
      </Title>
      <Header>
        <p>
          Batch takes customer privacy and security very seriously, which is why we offer a Data
          Processing Agreement for EU customers.
        </p>
        <LinkArrow
          intent="action"
          target="_blank"
          href="https://help.batch.com/en/articles/1957231-actionnable-features-for-gdpr-compliance"
          style={{ fontSize: 14 }}
        >
          Learn more about GDPR
        </LinkArrow>
        <br />
        <Checkbox
          label="I don't process any EU customers personal data and I am not subject to GDPR constraints"
          checked={data.disabled}
          onChange={toggleDisabled}
          style={{
            fontSize: 14,
            margin: '28px 0 -4px -3px',
          }}
        />
      </Header>
      {data.disabled ? null : (
        <React.Fragment>
          <AccountSection>
            <SectionInfo>
              <SectionTitle>Company Information</SectionTitle>
              <p>Some information about your company is required.</p>
            </SectionInfo>
            <SectionForm>
              <InputWrapper label="Full name" htmlFor="name" feedback={errors.get('name')}>
                <Input
                  type="text"
                  id="name"
                  placeholder="Company name"
                  value={data.name}
                  invalid={errors.has('name')}
                  onChange={onInputChange('name')}
                />
              </InputWrapper>
              <InputWrapper label="Address" feedback={errors.get('address')}>
                <InputContainer invalid={errors.has('address')} style={{ flexDirection: 'column' }}>
                  <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    value={data.address}
                    onChange={onInputChange('address')}
                    aria-label="Address line 1"
                  />
                  <hr />
                  <input
                    type="text"
                    id="address2"
                    placeholder="Line 2"
                    value={data.address2}
                    onChange={onInputChange('address2')}
                    aria-label="Address line 2"
                  />
                </InputContainer>
              </InputWrapper>
              <InputWrapper
                label="Zip code / City"
                feedback={errors.get('zip') || errors.get('city')}
              >
                <FormRow>
                  <Input
                    type="text"
                    id="zip"
                    placeholder="Zip code"
                    value={data.zip}
                    invalid={errors.has('zip')}
                    onChange={onInputChange('zip')}
                    aria-label="Zip code"
                  />
                  <Input
                    type="text"
                    placeholder="City"
                    value={data.city}
                    invalid={errors.has('city')}
                    onChange={onInputChange('city')}
                    aria-label="City"
                  />
                </FormRow>
              </InputWrapper>
              <InputWrapper label="Country" htmlFor="country" feedback={errors.get('country')}>
                <Select
                  {...selectBaseCountryProps}
                  id="country"
                  isSearchable
                  onChange={onCountryChange}
                  value={selectBaseCountryProps.options.find(opt => opt.value === data.country)}
                />
              </InputWrapper>
            </SectionForm>
          </AccountSection>
          <AccountSection>
            <SectionInfo>
              <SectionTitle>Data Protection Officer (DPO)</SectionTitle>
              <p>
                This is the person in your company responsible for all GDPR related requirements.
              </p>
            </SectionInfo>
            <SectionForm>
              <InputWrapper label="Name (optional)" htmlFor="dpoName">
                <Input
                  type="text"
                  id="dpoName"
                  placeholder="DPO Name"
                  value={data.dpoName}
                  onChange={onInputChange('dpoName')}
                />
              </InputWrapper>
              <InputWrapper label="Email (optional)" htmlFor="dpoEmail">
                <Input
                  type="email"
                  id="dpoEmail"
                  placeholder="DPO Email"
                  value={data.dpoEmail}
                  onChange={onInputChange('dpoEmail')}
                />
              </InputWrapper>
            </SectionForm>
          </AccountSection>
          <AccountSection>
            <SectionInfo>
              <SectionTitle>Types of data collected</SectionTitle>
              <p>What kind of data will you be collecting?</p>
            </SectionInfo>
            <SectionForm>
              <InputWrapper label="Collected data">
                {categories.map(c => (
                  <Checkbox
                    key={c}
                    label={c}
                    name="categories"
                    checked={data.categories.has(c)}
                    disabled={data.categories.has(c) && c === 'Logs data (eg: last connection)'}
                    style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '18px' }}
                    onChange={createToggleCategory(c)}
                  />
                ))}
                <Input
                  style={{ maxWidth: 260, marginTop: 28 }}
                  type="text"
                  placeholder="Add a new type..."
                  value={newCategory}
                  onChange={onUpdateNewCategory}
                  onKeyPress={onAddCategory}
                  suffix={{
                    kind: 'icon',
                    value: 'add',
                    handler: addCategory,
                  }}
                  aria-label="Add a new type"
                />
              </InputWrapper>
            </SectionForm>
          </AccountSection>
          <AccountSection>
            <SectionInfo>
              <SectionTitle>Data Processing Agreement</SectionTitle>
              <p>Please read the following agreement and confirm you understand it.</p>
            </SectionInfo>
            <SectionForm>
              <ProcessingAgreement />
              <Button
                intent="action"
                type="submit"
                kind="primary"
                disabled={untouched && !!company.gdpr.created}
                isLoading={company.loading}
                style={{ margin: '72px 0 0 0' }}
              >
                Accept and update my GDPR settings
              </Button>
            </SectionForm>
          </AccountSection>
        </React.Fragment>
      )}
      {data.disabled && (
        <Button
          disabled={company.gdpr.disabled}
          intent="action"
          kind="primary"
          type="submit"
          style={{ margin: '48px 0 0 24px' }}
        >
          Update my GDPR settings
        </Button>
      )}
    </Form>
  )
}

export const LastUpdate = styled.p`
  margin-left: 18px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.good.shade.s4};
  letter-spacing: 0;
`
