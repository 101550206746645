import { LoadingStatus } from 'constants/common'
import Immutable, { type RecordOf } from 'immutable'

type EstimateCount = {
  uniqueCount: number
  nonUniqueCount: number
  // push platform specific
  ios: number
  android: number
  webpush: number
}
export type EstimateCountRecord = RecordOf<EstimateCount>
export const EstimateCountFactory = Immutable.Record<EstimateCount>({
  uniqueCount: 0,
  nonUniqueCount: 0,
  ios: 0,
  android: 0,
  webpush: 0,
} as EstimateCount)
type EstimateByChannel = {
  profileCount: number
  reachableProfileCount: number
  email: EstimateCountRecord
  sms: EstimateCountRecord
  push: EstimateCountRecord
}
export type EstimateByChannelRecord = RecordOf<EstimateByChannel>
export const EstimateByChannelFactory = Immutable.Record<EstimateByChannel>({
  profileCount: 0,
  reachableProfileCount: 0,
  email: EstimateCountFactory(),
  sms: EstimateCountFactory(),
  push: EstimateCountFactory(),
} as EstimateByChannel)

type EstimateProfile = {
  loading: LoadingStatus
  total: EstimateByChannelRecord
  totalOptin: EstimateByChannelRecord
  matching: EstimateByChannelRecord
  matchingOptin: EstimateByChannelRecord
}
export type EstimateProfileRecord = RecordOf<EstimateProfile>
export const EstimateProfileFactory = Immutable.Record<EstimateProfile>({
  loading: LoadingStatus.INIT,
  total: EstimateByChannelFactory(),
  totalOptin: EstimateByChannelFactory(),
  matching: EstimateByChannelFactory(),
  matchingOptin: EstimateByChannelFactory(),
} as EstimateProfile)
