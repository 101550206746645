import { type SegmentRecord } from '../models/segment.records'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfileAttributeDefinition } from 'com.batch/shared/infra/export-service/export-service'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import dayjs from 'dayjs'

const PROFILE_LIMIT = 1000000

type ExportSegmentAction = ReduxAction<'EXPORT_SEGMENT', undefined>

export type ExportSegmentFailureAction = ReduxAction<'EXPORT_SEGMENT_FAILURE', undefined>

export type ExportSegmentSuccessAction = ReduxAction<'EXPORT_SEGMENT_SUCCESS', undefined>

export type ExportSegmentActionType =
  | ExportSegmentAction
  | ExportSegmentSuccessAction
  | ExportSegmentFailureAction

export const getSegmentsExport = ({
  segment,
}: {
  segment: SegmentRecord
}): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { exportService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    const definition: ProfileAttributeDefinition = {
      attributes: ['$phone_number', '$email_address'],
      identifiers: ['profile_id', 'custom_id'],
      jsonQuery: segment.query,
      limit: PROFILE_LIMIT,
    }

    const currentDate = dayjs.utc().format('YYYY-MM-DD')

    return promiseActionCreator({
      dispatch,
      promise: exportService.create({
        projectKey: project.projectKey,
        source: 'DASHBOARD',
        definition: {
          scope: 'PROFILE_ATTRIBUTES',
          profileAttribute: definition,
        },
        context: {
          metadata: {
            filename: `${currentDate}-${segment.name}-segment-export.csv`,
          },
        },
      }),
      actionName: 'EXPORT_SEGMENT',
    })
  }
}
