import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { Box } from 'components/common/box'
import { GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Wrapper } from 'components/common/empty-states/wrapper'
import {
  TableBody,
  TableCellHeader,
  TableCellOrder,
  TableHeader,
  TableTemplateCell,
} from 'components/common/table'
import { Table } from 'components/common/table/table'
import { colors } from 'components/styled/tokens'

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import { StatsBlocksRow } from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { StatsPerMailboxProviderRow } from 'com.batch/orchestration-analytics/ui/components/stats-per-mailbox-provider/stats-per-mailbox-provider-row'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { getStatsConfig } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { LoadingStatus } from 'constants/common'

type SortOptions = {
  order: 'asc' | 'dsc'
  sortBy:
    | 'sent'
    | 'delivered.value'
    | 'open.rate'
    | 'click.rate'
    | 'bounce.rate'
    | 'unsubscribe.rate'
}

const EmptyTemplate = (): React.ReactElement => (
  <React.Fragment>
    <TableTemplateCell template="1fr" />
    {Array(6)
      .fill(0)
      .map((_, i) => (
        <TableTemplateCell template="1fr" align="start" key={i} />
      ))}
  </React.Fragment>
)

export const StatsPerMailboxProvider = (): React.ReactNode => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { filters, dateRange } = useGetAnalyticsFilters(token)
  const stats = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      )
        .get('channels')
        .get('email').providers,
    [analyticsMap, token]
  )
  const providersLoadingState = React.useMemo(
    () =>
      token
        ? analyticsMap.get(token)?.providersLoadingState ?? LoadingStatus.INIT
        : LoadingStatus.ERROR,
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () =>
      providersLoadingState === LoadingStatus.LOADING ||
      providersLoadingState === LoadingStatus.INIT,
    [providersLoadingState]
  )
  const isOverlayShown = React.useMemo(
    () => providersLoadingState === LoadingStatus.ERROR,
    [providersLoadingState]
  )

  const [sortOptions, setSortOptions] = React.useState<SortOptions>({
    order: 'dsc',
    sortBy: 'sent',
  })

  const onSortBy = React.useCallback(
    (sortBy: SortOptions['sortBy']) => () => {
      if (sortOptions.sortBy === sortBy)
        setSortOptions(prev => ({
          ...prev,
          order: prev.order === 'asc' ? 'dsc' : 'asc',
        }))
      else
        setSortOptions({
          order: 'dsc',
          sortBy,
        })
    },
    [sortOptions]
  )

  React.useEffect(() => {
    if (token) {
      const tokens = Immutable.List([token])
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters,
          dateRange,
          config: getStatsConfig({ name: 'orchestrationStatsByProvider' }),
        })
      ).catch(() => {})
    }
  }, [token, dispatch, filters, dateRange])

  const sortedProviders = React.useMemo(
    () =>
      stats?.sort((a: EmailProviderRecord, b: EmailProviderRecord) => {
        let compare
        if (sortOptions.sortBy === 'sent') {
          compare = a.stats.sent > b.stats.sent
        } else {
          const [stat, unit] = sortOptions.sortBy.split('.')
          compare = a.stats[stat][unit] > b.stats[stat][unit]
        }

        if (sortOptions.order === 'asc') return compare ? 1 : -1
        return compare ? -1 : 1
      }),
    [stats, sortOptions]
  )

  const showProviders = React.useMemo(
    () => isLoading || (sortedProviders && sortedProviders.size > 0),
    [isLoading, sortedProviders]
  )

  return showProviders ? (
    <StatsBlocksRow $marginBottom>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <Box
          style={{
            width: 1148,
            overflowY: 'auto',
            maxHeight: 292,
          }}
        >
          <Table rowHeight={56} template="1fr repeat(5, 122px) 133px" style={{ marginBottom: 4 }}>
            <TableHeader
              style={{
                height: 52,
                backgroundColor: 'white',
                position: 'sticky',
                zIndex: 10,
                top: 0,
                paddingRight: 10,
              }}
            >
              <TableCellHeader style={{ color: colors.text }}>Mailbox provider</TableCellHeader>
              <TableCellOrder
                sort={sortOptions.sortBy === 'sent' ? sortOptions.order : false}
                onClick={onSortBy('sent')}
              >
                Sent
              </TableCellOrder>
              <TableCellOrder
                sort={sortOptions.sortBy === 'delivered.value' ? sortOptions.order : false}
                onClick={onSortBy('delivered.value')}
              >
                Delivered
              </TableCellOrder>
              <TableCellOrder
                sort={sortOptions.sortBy === 'open.rate' ? sortOptions.order : false}
                onClick={onSortBy('open.rate')}
              >
                Opened
              </TableCellOrder>
              <TableCellOrder
                sort={sortOptions.sortBy === 'click.rate' ? sortOptions.order : false}
                onClick={onSortBy('click.rate')}
              >
                Clicked
              </TableCellOrder>
              <TableCellOrder
                sort={sortOptions.sortBy === 'bounce.rate' ? sortOptions.order : false}
                onClick={onSortBy('bounce.rate')}
              >
                Bounced
              </TableCellOrder>
              <TableCellOrder
                sort={sortOptions.sortBy === 'unsubscribe.rate' ? sortOptions.order : false}
                onClick={onSortBy('unsubscribe.rate')}
              >
                Unsubscribed
              </TableCellOrder>
            </TableHeader>
            <TableBody emptyTemplate={<EmptyTemplate />}>
              {sortedProviders?.map((provider: EmailProviderRecord) => {
                return <StatsPerMailboxProviderRow provider={provider} key={provider.name} />
              })}
            </TableBody>
          </Table>
        </Box>
      </Wrapper>
    </StatsBlocksRow>
  ) : null
}
