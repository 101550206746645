import dayjs from 'dayjs'
import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { isEmail, textUsesTemplating } from 'com.batch.common/utils'

import {
  type JourneyNodeRecord,
  type JourneySettingsRecord,
  type QuietTimesRecord,
} from './journey.records'
import { countNodeType } from './tree.helpers'

import { messageStateSelector } from 'com.batch/message/store/message.selector'
import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import { getPushContentError } from 'com.batch/push/store/push.selector.helper'
import { type State } from 'com.batch.redux/_records'
import { removeInvalidConditions } from 'com.batch.redux/query/query.api'
import { getQueryRecordForIdSelector } from 'com.batch.redux/query/query.selector'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import {
  subscriptionStatusSelector,
  targetStateSelector,
} from 'com.batch.redux/target/target.selector'

import {
  type SmsContentRecord,
  type PushContentRecord,
  type PushMessageRecord,
  PushMessageFactory,
  type EmailContentRecord,
  EmailMessageFactory,
  SmsMessageFactory,
} from 'com.batch/message/models/message.records'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields, type SMS_ERROR_CODES } from 'com.batch/sms/usecases/validate-sms-fields'
import { type LoadingStatus } from 'constants/common'

type Extract<T> = (arg1: State) => T

const orchestrationStateSelector: Extract<OrchestrationStateRecord> = state => state.orchestration
export const copiedNodeIdSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.copiedNodeId
)
export const journeySettingsSelector: Extract<JourneySettingsRecord> = createSelector(
  orchestrationStateSelector,
  j => j.triggerSettings
)

export const quietTimesSelector: Extract<{
  hasQuietTimes: boolean
  hasQuietDays: boolean
  quietTimes: QuietTimesRecord
  hasQuietHoursTimePeriodDisabled: boolean
  quietDaysOfWeek: Set<number>
}> = createSelector(journeySettingsSelector, settings => {
  return {
    hasQuietTimes: settings.hasQuietTimes,
    hasQuietHoursTimePeriodDisabled: settings.quietTimes.quietHoursTimePeriodDisabled ?? false,
    quietTimes: settings.quietTimes,
    quietDaysOfWeek: settings.quietTimes.quietDaysOfWeek,
    hasQuietDays: settings.quietTimes.quietDaysOfWeek.size > 0,
  }
})

const orchestrationNameSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.name
)

export const journeyTreeSelector: Extract<{
  rootNodeId: string
  nodesMap: Map<string, JourneyNodeRecord>
}> = createSelector(orchestrationStateSelector, os => ({
  rootNodeId: os.triggerRootId,
  nodesMap: os.triggerNodes,
}))

export const journeyLoadingStateSelector: Extract<LoadingStatus> = createSelector(
  orchestrationStateSelector,
  s => s.loadingState
)

// incomplete, on a le droit de save
export const journeyIncompletestepMessageNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  ({ nodesMap }, messageState) => {
    const incompletesNodeIds: Array<string> = []
    nodesMap.forEach(node => {
      if (node.type !== 'MESSAGE') return
      if (
        node.messageConfig.channel === 'push' &&
        !isPushContentComplete(
          messageState.push
            .get(node.messageConfig.messageTypedId, PushMessageFactory())
            .get('localizedContent', Immutable.Map<string, PushContentRecord>())
        )
      )
        incompletesNodeIds.push(node.id)
      if (
        node.messageConfig.channel === 'email' &&
        !isEmailContentComplete(
          messageState.email
            .get(node.messageConfig.messageTypedId, EmailMessageFactory())
            .get('localizedContent', Immutable.Map<string, EmailContentRecord>())
        )
      )
        incompletesNodeIds.push(node.id)
      if (
        node.messageConfig.channel === 'sms' &&
        !isSmsContentComplete(
          messageState.sms
            .get(node.messageConfig.messageTypedId, SmsMessageFactory())
            .get('localizedContent', Immutable.Map<string, SmsContentRecord>())
        )
      )
        incompletesNodeIds.push(node.id)
    })
    return Immutable.Set(incompletesNodeIds)
  }
)

export const isPushMessageValid = (pushMessage: PushMessageRecord): boolean => {
  const { localizedContent } = pushMessage
  if (!isPushContentComplete(localizedContent)) return false
  return localizedContent.every(push => getPushContentError(push).length === 0)
}

const isReplyToValid = (replyTo?: string | null) => {
  return replyTo ? (textUsesTemplating(replyTo) ? true : isEmail(replyTo)) : true
}

export const isEmailContentValid = (
  emailContentForLangs: Map<string, EmailContentRecord>
): boolean => {
  return emailContentForLangs.every(email => {
    const isEmpty =
      !email.fromEmail && !email.name && !email.senderIdentityId && !email.subject && !email.html

    return isReplyToValid(email.replyTo) && !isEmpty
  })
}

export const isEmailContentComplete = (
  emailContentForLangs: Map<string, EmailContentRecord>
): boolean => {
  // au moins une trad, et chaque trad complète
  return (
    emailContentForLangs.size > 0 &&
    emailContentForLangs.every(email => {
      return (
        isReplyToValid(email.replyTo) &&
        Boolean(email.senderIdentityId) &&
        Boolean(email.subject) &&
        Boolean(email.html)
      )
    })
  )
}

export const isPushContentComplete = (
  pushContentForLangs: Map<string, PushContentRecord>
): boolean => {
  // au moins une trad, et chaque trad complète
  return (
    pushContentForLangs.size > 0 &&
    pushContentForLangs.every(push => {
      return Boolean(push.content.pushTitle) && Boolean(push.content.pushBody)
    })
  )
}

export const isSmsContentComplete = (
  smsContentForLangs: Map<string, SmsContentRecord>
): boolean => {
  return (
    smsContentForLangs.size > 0 &&
    smsContentForLangs.every(sms => {
      return Boolean(sms.smsMessage)
    })
  )
}

export const getSmsMessageErrors = (
  smsContent: SmsContentRecord,
  targetedUserbase: 'fullbase' | 'marketing'
): Set<SMS_ERROR_CODES> => {
  const message = smsContent.smsMessage
  const { parts } = countSmsMessage({ message, countStop: targetedUserbase === 'marketing' })

  const smsErrors = validateSmsFields({
    message,
    parts,
  })

  return smsErrors
}

export const isSmsMessageValid = (
  smsContentForLangs: Map<string, SmsContentRecord>,
  targetedUserbase: 'fullbase' | 'marketing'
): boolean =>
  smsContentForLangs.every(
    sms => getSmsMessageErrors(sms, targetedUserbase).delete('MSG_EMPTY').size === 0
  )

// ne check pas incomplete mais les erreurs empêchant de save
export const journeyInvalidMessageEmailNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  ({ nodesMap }, messageState) => {
    const invalidMessageNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (
        node.type === 'MESSAGE' &&
        node.messageConfig.channel === 'email' &&
        !isEmailContentValid(
          messageState.email
            .get(node.messageConfig.messageTypedId, EmailMessageFactory())
            .get('localizedContent', Immutable.Map<string, EmailContentRecord>())
        )
      )
        invalidMessageNodes.push(node.id)
    })
    return Immutable.Set(invalidMessageNodes)
  }
)

// ne check pas incomplete mais les erreurs empêchant de save
export const journeyInvalidMessagePushNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  ({ nodesMap }, messageState) => {
    const invalidMessageNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (
        node.type === 'MESSAGE' &&
        node.messageConfig.channel === 'push' &&
        !isPushMessageValid(
          messageState.push.get(node.messageConfig.messageTypedId, PushMessageFactory())
        )
      )
        invalidMessageNodes.push(node.id)
    })
    return Immutable.Set(invalidMessageNodes)
  }
)

// ne check pas incomplete mais les erreurs empêchant de save
export const journeyInvalidMessageSmsNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  subscriptionStatusSelector,
  ({ nodesMap }, messageState, targetedUserbase) => {
    const invalidMessageNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (
        node.type === 'MESSAGE' &&
        node.messageConfig.channel === 'sms' &&
        !isSmsMessageValid(
          messageState.sms
            .get(node.messageConfig.messageTypedId, SmsMessageFactory())
            .get('localizedContent', Immutable.Map<string, SmsContentRecord>()),
          targetedUserbase
        )
      )
        invalidMessageNodes.push(node.id)
    })
    return Immutable.Set(invalidMessageNodes)
  }
)

export const getNodeHasTargetingSelector: Extract<(arg1: string) => boolean> = createSelector(
  getQueryRecordForIdSelector,
  targetStateSelector,
  (getQuery, targets) => {
    return (nodeId: string) => {
      const query = removeInvalidConditions(getQuery(nodeId === 'default' ? 'targeting' : nodeId))
      const target = targets.get(nodeId, TargetStateFactory())
      return query.conditions.size + target.languages.size + target.regions.size > 0
    }
  }
)
// check for YESNO nodes without targeting
export const journeyYesNoWithoutTargetingNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  getNodeHasTargetingSelector,
  ({ nodesMap }, getNodeHasTargeting) => {
    const targetLessNodesIds: Array<string> = []
    nodesMap.forEach(node => {
      if (node.type === 'YESNO' && !getNodeHasTargeting(node.id)) targetLessNodesIds.push(node.id)
    })
    return Immutable.Set(targetLessNodesIds)
  }
)
// check for empty YESNO nodes
export const journeyEmptyBranchesIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const emptyBranchesNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('SPLIT_EMPTY_BRANCHES')) emptyBranchesNodes.push(node.id)
    })
    return Immutable.Set(emptyBranchesNodes)
  }
)

// check for invalid timers
export const journeyInvalidTimerIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const invalidTimers: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('INVALID_TIMER')) invalidTimers.push(node.id)
    })
    return Immutable.Set(invalidTimers)
  }
)

// check for distribution errors
export const journeyDistributionErrorIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const emptyBranchesNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('SPLIT_RANDOM_DISTRIBUTION')) emptyBranchesNodes.push(node.id)
    })
    return Immutable.Set(emptyBranchesNodes)
  }
)

export const orchestrationWeightRatioSelector: Extract<number> = createSelector(
  orchestrationStateSelector,
  os => os.maxWeightRatio
)
export const journeyErrorDraftSelector: Extract<List<string>> = createSelector(
  orchestrationNameSelector,
  orchestrationWeightRatioSelector,
  (name, ratio) => {
    const err: Array<string> = []
    if (!name) err.push('Enter a name to save.')
    if (ratio > 1)
      err.push(
        'The automation exceeds the allowed size limit, possibly due to large email content or translations.'
      )
    return Immutable.List(err)
  }
)
export const journeyErrorSelector: Extract<List<string>> = createSelector(
  journeyErrorDraftSelector,
  journeyIncompletestepMessageNodeIdsSelector,
  journeyInvalidMessageEmailNodeIdsSelector,
  journeyInvalidMessagePushNodeIdsSelector,
  journeyInvalidMessageSmsNodeIdsSelector,
  journeyDistributionErrorIdsSelector,
  journeyInvalidTimerIdsSelector,
  journeyYesNoWithoutTargetingNodeIdsSelector,
  journeyEmptyBranchesIdsSelector,
  journeyTreeSelector,
  journeySettingsSelector,
  (
    errors,
    incompletesNodeIds,
    invalidEmailMessageIds,
    invalidPushMessageIds,
    invalidSmsMessageIds,
    invalidRandomSplitIds,
    invalidTimerIds,
    targetLessYesNoNodes,
    emptySplitNodes,
    { nodesMap },
    settings
  ) => {
    const err = errors.toArray()
    if (settings.entryEvents.filter(ev => ev.name).size === 0) err.push('Entry event is required.')
    if (settings.hasInstanceId && !settings.instanceId)
      err.push('Parallel automations requires you pick a string event attribute')
    if (settings.hasStart) {
      if (!settings.start) err.push('Start date is missing.')
      else if (settings.start.isBefore(dayjs.utc())) err.push('Start date is in the past.')
    }
    if (settings.hasEnd) {
      if (!settings.end) err.push('End date is missing.')
      else if (settings.end?.isBefore(dayjs.utc())) err.push('End date is in the past.')
    }
    if (invalidEmailMessageIds.size > 0 && incompletesNodeIds.size === 0)
      err.push('Some email messages are invalid.')
    if (invalidPushMessageIds.size > 0 && incompletesNodeIds.size === 0)
      err.push('Some push messages are invalid.')
    if (invalidSmsMessageIds.size > 0 && incompletesNodeIds.size === 0)
      err.push('Some sms messages are invalid.')
    if (targetLessYesNoNodes.size > 0) err.push('Yes/No split steps must have a targeting.')
    if (invalidRandomSplitIds.size > 0) err.push('Random split distribution total must be 100.')
    if (invalidTimerIds.size > 0) err.push('Timer must be between 1 minute and 30 days.')
    if (emptySplitNodes.size > 0) err.push("Split steps can't have both branches empty.")
    if (countNodeType(nodesMap, 'MESSAGE') === 0) err.push('At least one message is required.')
    if (incompletesNodeIds.size > 0) err.push('Some steps are incomplete.')
    if (invalidEmailMessageIds.size > 0) err.push('Some steps are invalid.')
    return Immutable.List(err)
  }
)

export const getLabelForMessageIdSelector: Extract<(arg1: string) => string> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    return (messageId: string) => {
      const node = nodesMap.find(
        node => node.type === 'MESSAGE' && node.messageConfig.messageTypedId === messageId
      )
      return node && node.type === 'MESSAGE' ? node.label : ''
    }
  }
)

export const getEditingNodeId: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.editingNodeId
)
