import { type List } from 'immutable'
import * as React from 'react'
import { FixedSizeList } from 'react-window'

import { DropdownMenu, Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Checkbox } from 'components/form/fields/checkbox'

import { SelectNoOption, SelectOption } from './select.styles'
import { type UseComboboxGetItemPropsOptions } from 'downshift'

type SelectDropdownProps<T> = {
  getItemProps: (options: UseComboboxGetItemPropsOptions<T>) => {
    onClick: () => void
  }
  highlightedIndex: number
  innerRef: React.Ref<any>
  isOpen: boolean
  creatableOption?: T | null | undefined
  isOptionSelected: (arg1: T) => boolean
  isOptionDisabled?: (arg1: T) => boolean
  inMenuSearchInput?: React.ReactNode
  optionFormatter?: (
    arg1: T,
    arg2: {
      context: 'value' | 'menu'
    }
  ) => React.ReactNode
  optionMenuShownCount?: number
  optionMenuHeight?: number
  optionMenuStyle?: {
    [key: string]: string | number
  }
  menuStyle?: {
    [key: string]: string | number
  }
  options: List<T>
  optionToString: (arg1: T) => string
  setHighlightedIndex: (arg1: number) => void
  showCheckbox?: boolean
  noResultNode?: React.ReactNode
  style?: {
    [key: string]: string | number
  }
  width: number
  header?: React.ReactNode
  id: string
  ['aria-labelledby']: string
}

const MENU_SPACING = 4

export function SelectDropdown<T>({
  getItemProps,
  highlightedIndex,
  innerRef,
  isOpen,
  isOptionSelected,
  inMenuSearchInput,
  optionFormatter,
  creatableOption,
  optionMenuShownCount = 4,
  optionMenuHeight = 36,
  optionMenuStyle,
  menuStyle,
  options,
  optionToString,
  setHighlightedIndex,
  showCheckbox,
  noResultNode,
  width,
  header,
  id,
  'aria-labelledby': ariaLabelledBy,
  isOptionDisabled,
}: SelectDropdownProps<T>): React.ReactElement {
  /* 
    we need to compute 2 heights for this to work : 
    - the height of the windowed list (FixedSizedList) will be number options displayed * (optionMenuHeight + spacing)
    - the height of the dropdown will be the height of the windowed list + 
      - height of the search input if present
      - height of the loading indicator if present (removed)
      - height of the no results indicator if present
  */

  // we assume padding is 2px, might need to be dynamic
  const optionHeight = React.useMemo(() => optionMenuHeight + MENU_SPACING, [optionMenuHeight])
  // max height is
  const windowedListHeight = React.useMemo(() => {
    return Math.min(options.size, optionMenuShownCount) * optionHeight
  }, [options.size, optionMenuShownCount, optionHeight])
  const dropdownWillScroll = React.useMemo(
    () => options.size > optionMenuShownCount,
    [optionMenuShownCount, options.size]
  )
  const dropdownHeight = React.useMemo(() => {
    const searchInputHeight = inMenuSearchInput ? 36 : 0
    const noResultsHeight = noResultNode && windowedListHeight === 0 ? 76 : 0
    const explicitNewOption = creatableOption ? optionHeight : 0

    return (
      windowedListHeight +
      searchInputHeight +
      noResultsHeight +
      explicitNewOption +
      (dropdownWillScroll ? 2 : 6) +
      (header ? 37 : 0)
    )
  }, [
    inMenuSearchInput,
    noResultNode,
    windowedListHeight,
    creatableOption,
    optionHeight,
    dropdownWillScroll,
    header,
  ])

  const onDropdownMenuMouseLeave = React.useCallback(() => {
    setHighlightedIndex(-1)
  }, [setHighlightedIndex])

  const renderVirtualOption = React.useCallback(
    ({ style, index }) => {
      const item = options.get(index)
      return item ? (
        <SelectOption style={style} key={index} isActive={isOptionSelected(item)}>
          <Button
            {...getItemProps({
              index,
              key: optionToString(item),
              style: {
                ...optionMenuStyle,
                height: optionMenuHeight,
              },
              item,
            })}
            addOnGap={4}
            addOn={showCheckbox ? 'prefix' : undefined}
            kind="inline"
            isHover={highlightedIndex === index && !isOptionSelected(item)}
            isActive={isOptionSelected(item)}
            disabled={isOptionDisabled && isOptionDisabled(item)}
          >
            {showCheckbox && (
              <Checkbox
                size={18}
                checked={isOptionSelected(item)}
                style={{ pointerEvents: 'none', marginTop: 2, marginRight: '-4px' }}
                disabled={isOptionDisabled && isOptionDisabled(item)}
              />
            )}
            {optionFormatter ? optionFormatter(item, { context: 'menu' }) : optionToString(item)}
          </Button>
        </SelectOption>
      ) : null
    },
    [
      getItemProps,
      highlightedIndex,
      isOptionSelected,
      optionFormatter,
      optionMenuHeight,
      optionMenuStyle,
      optionToString,
      options,
      showCheckbox,
      isOptionDisabled,
    ]
  )

  return (
    <DropdownMenu
      alwaysInDom
      ref={innerRef}
      style={{
        ...menuStyle,
        padding: 0,
        borderRadius: '6px',
        height: options.size > 4 ? dropdownHeight + 26 : dropdownHeight,
        overflowY: 'hidden',
      }}
      forcedWidth={width}
      willScroll={dropdownWillScroll}
      onMouseLeave={onDropdownMenuMouseLeave}
      forcedHeight={options.size > optionMenuShownCount ? dropdownHeight + 26 : dropdownHeight}
      isOpen={
        isOpen &&
        (options.size > 0 ||
          Boolean(noResultNode) ||
          Boolean(inMenuSearchInput) ||
          Boolean(creatableOption))
      }
      id={id}
      aria-labelledby={ariaLabelledBy}
      role="listbox"
    >
      {inMenuSearchInput}
      {header}
      <FixedSizeList
        height={options.size > 4 ? windowedListHeight + 28 : windowedListHeight}
        width="100%"
        itemSize={optionHeight}
        itemCount={options.size}
        className="styled-windowed-list"
      >
        {renderVirtualOption}
      </FixedSizeList>
      {Boolean(noResultNode) && options.size === 0 && (
        <SelectNoOption>{noResultNode}</SelectNoOption>
      )}
      {!!creatableOption && (
        <div>
          <Button
            {...getItemProps({
              index: 0,
              style: {
                marginLeft: 4,
                height: optionMenuHeight,
                width: 'calc(100% - 8px)',
              },
              item: creatableOption,
            })}
            addOnGap={4}
            addOn="suffix"
            kind="inline"
            isHover={highlightedIndex === 0 && !isOptionSelected(creatableOption)}
            isActive={isOptionSelected(creatableOption)}
          >
            <span>
              <span style={{ opacity: 0.7 }}>Use&nbsp;</span>
              {optionToString(creatableOption)}
            </span>
            <Icon icon="add" />
          </Button>
        </div>
      )}
    </DropdownMenu>
  )
}
