import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type OrderedMap, type RecordOf, type Set } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

import { type CampaignRecord } from 'com.batch.redux/_records'
import { InAppContentFactory, type InAppContentRecord } from 'com.batch.redux/content.records'
import { type UserRecord } from 'com.batch.redux/user.records'
import { LoadingStatus } from 'constants/common'

export type FormatKind = 'universal' | 'banner' | 'modal' | 'image' | '' | 'webview'

export type StyleProperty =
  | 'fontSize'
  | 'color'
  | 'fontWeight'
  | 'backgroundColor'
  | 'backgroundOpacity'
  | 'backgroundSize'
  | 'padding'
  | 'margin'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'

export type FieldKind =
  | 'general'
  | 'image'
  | 'imageUrl'
  | 'header'
  | 'title'
  | 'text'
  | 'separator'
  | 'cta1'
  | 'cta2'
  | 'global'
  | 'close'
  | 'trackingId'
  | 'webviewUrl'
  | 'linkOpenTarget'
  | 'dismissIndicator'

export type PayloadProps = {
  kind: FormatKind | null | undefined
  flip_hero_h?: boolean
  flip_hero_v?: boolean
  attach_cta_bottom?: boolean
  stack_cta_h?: boolean
  auto_close?: number
  stretch_cta_h?: boolean
  cta_direction?: 'h' | 'v'
  fullscreen?: boolean
  hero_split_ratio?: number
  detached?: boolean
  banner_bottom?: boolean
  close: boolean
}

export const PayloadFactory = Immutable.Record<PayloadProps>({
  kind: null,
  flip_hero_h: false,
  flip_hero_v: false,
  auto_close: 10000,
  attach_cta_bottom: true,
  stack_cta_h: true,
  fullscreen: false,
  stretch_cta_h: true,
  cta_direction: 'h',
  hero_split_ratio: 0.4,
  detached: false,
  banner_bottom: true,
  close: true,
} as PayloadProps)

export type PayloadRecord = RecordOf<PayloadProps>

type FieldProps = {
  id: FieldKind
  icon?: availableIcons
  data: string
  configurable: boolean
  removable: boolean
  selected: boolean
  hidden: boolean
  label: string
  style: Map<StyleProperty, number | string | null | undefined>
  editableStyles: List<StyleProperty>
}

export const FieldFactory = Immutable.Record<FieldProps>({
  id: 'general',
  icon: undefined,
  data: '',
  configurable: false,
  removable: true,
  selected: false,
  hidden: true,
  label: '',
  style: Immutable.Map(),
  editableStyles: Immutable.List(['color']),
} as FieldProps)

export type FieldRecord = RecordOf<FieldProps>

type SampleDataProps = {
  name: string
  selected: boolean
  data: InAppContentRecord
}

export const SampleDataFactory = Immutable.Record<SampleDataProps>({
  name: '',
  selected: false,
  data: InAppContentFactory(),
} as SampleDataProps)

export type SampleDataRecord = RecordOf<SampleDataProps>

export type ThemeProps = {
  name: string
  id: number | 'new'
  loading: boolean
  linkedCampaignsLoaded: boolean
  code: string
  color: string
  payloadVars: PayloadRecord
  fields: Map<FieldKind, FieldRecord>
  dark: boolean
  statusBarMode: 'light' | 'dark' | 'auto'
  apps: Set<number>
  nbCampaignsRunning: number
  campaigns: Set<CampaignRecord>
  updated: Dayjs | null
  updatedBy: UserRecord | null
  fromDuplication: boolean
}
export const ThemeFactory = Immutable.Record<ThemeProps>({
  color: '#353860',
  dark: false,
  loading: false,
  linkedCampaignsLoaded: false,
  code: '',
  id: 'new',
  name: '',
  fields: Immutable.Map(),
  statusBarMode: 'light',
  apps: Immutable.Set(),
  payloadVars: PayloadFactory(),
  nbCampaignsRunning: 0,
  campaigns: Immutable.Set(),
  updated: null,
  updatedBy: null,
  fromDuplication: false,
} as ThemeProps)

export type ThemeRecord = RecordOf<ThemeProps>

export type AbTestedThemeProps = {
  a: ThemeRecord | null | undefined
  b: ThemeRecord | null | undefined
}

export const AbTestedThemeFactory = Immutable.Record<AbTestedThemeProps>({
  a: undefined,
  b: undefined,
} as AbTestedThemeProps)

export type AbTestedThemeRecord = RecordOf<AbTestedThemeProps>

type ThemeStateProps = {
  entities: OrderedMap<number | 'new', ThemeRecord>
  editing: number | 'new'
  loadingState: LoadingStatus
  selectedField: string
  sample: SampleDataRecord
}

export const sampleData: SampleDataRecord = SampleDataFactory({
  name: 'Travel',
  selected: true,
  data: InAppContentFactory({
    header: 'This is a header',
    title: 'This is a title',
    text: 'This is an in-app message content, it may be short or use multiple lines like this.',
    imageUrl: window.location.origin + '/medias/img/notif-preview/legacy/theme-sample-img.webp',
    mainButtonLabel: 'First button',
    secondaryButtonLabel: 'Second button',
  }),
})

export const ThemeStateFactory = Immutable.Record<ThemeStateProps>({
  entities: Immutable.OrderedMap([['new', ThemeFactory()]]),
  editing: 'new',
  loadingState: LoadingStatus.INIT,
  selectedField: 'general',
  sample: sampleData,
} as ThemeStateProps)

export type ThemeStateRecord = RecordOf<ThemeStateProps>
