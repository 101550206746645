import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Title } from 'components/styled/text'

import {
  eventsLoadingStateSelector,
  profileDataStateSelector,
  profileLoadingStateSelector,
} from 'com.batch/profile/store/profile.selector'

import { type IdentifierKind } from 'com.batch/profile/constants/identifier-kind'
import { AttributesBlock } from 'com.batch/profile/ui/components/attributes-block/attributes-block'
import { EventsBlock } from 'com.batch/profile/ui/components/events-block/events-block'
import { ProfileHero } from 'com.batch/profile/ui/components/profile-hero/profile-hero'
import { ProfileMultiResultsBanner } from 'com.batch/profile/ui/components/profile-multi-results-banner/profile-multi-results-banner'
import { ProfileSearch } from 'com.batch/profile/ui/components/profile-search/profile-search'
import { ProfileBody, ProfileContainer } from 'com.batch/profile/ui/pages/profile.styles'
import { searchProfileByIdentifier } from 'com.batch/profile/usecases/search-profile-by-identifier'
import { LoadingStatus } from 'constants/common'

export const Profile = (): React.ReactElement => {
  const dispatch = useDispatch()
  const profileLoadingState = useSelector(profileLoadingStateSelector)
  const eventsLoadingState = useSelector(eventsLoadingStateSelector)
  const profileData = useSelector(profileDataStateSelector)
  const [feedback, setFeedback] = React.useState<'NOT_FOUND' | 'NONE'>('NONE')
  const [isRefresh, setIsRefresh] = React.useState(false)

  const identifierNotFound = React.useMemo(() => feedback === 'NOT_FOUND', [feedback])
  const isLoading = React.useMemo(
    () => profileLoadingState === LoadingStatus.LOADING,
    [profileLoadingState]
  )
  const isEventsLoading = React.useMemo(
    () => eventsLoadingState === LoadingStatus.LOADING,
    [eventsLoadingState]
  )
  const dataLoaded = React.useMemo(
    () => profileLoadingState === LoadingStatus.LOADED,
    [profileLoadingState]
  )
  const hasError = React.useMemo(
    () => profileLoadingState === LoadingStatus.ERROR,
    [profileLoadingState]
  )
  const isEmpty = React.useMemo(
    () => !dataLoaded || identifierNotFound,
    [dataLoaded, identifierNotFound]
  )

  const handleOnSearch = React.useCallback(
    (identifier: string, identifierKind: IdentifierKind, isRefresh: boolean) => {
      setIsRefresh(isRefresh)
      dispatch(searchProfileByIdentifier({ identifier, identifierKind }))
        .then(() => setFeedback('NONE'))
        .catch(() => setFeedback('NOT_FOUND'))
    },
    [dispatch]
  )

  return (
    <ProfileContainer>
      <Grid template="200px 1fr" alignItems="start">
        <Title>Profiles</Title>
        <ProfileSearch
          onSearch={handleOnSearch}
          isLoading={isLoading}
          dataLoaded={dataLoaded}
          hasError={hasError}
        />
      </Grid>
      <Wrapper
        isEmpty={isEmpty}
        isLoading={profileLoadingState === LoadingStatus.LOADING}
        isOverlayShown={isEmpty && !isLoading}
        overlayProps={
          identifierNotFound
            ? {
                status: 'empty-page',
                subtitle: (
                  <span>
                    No matching profile found: <br /> please check the identifier and try again.
                  </span>
                ),
                content: (
                  <img
                    src="/medias/img/empty-state/profile/illustration_profile_notfound.png"
                    width="406"
                    height="173"
                    alt="Looking for profile illustration"
                  />
                ),
              }
            : {
                status: 'empty-page',
                subtitle: (
                  <span style={{ position: 'relative' }}>
                    Explore user interactions in real-time:
                    <br />
                    provide a valid identifier & hit search.{' '}
                    <img
                      src="/medias/img/empty-state/profile/arrow_profile_empty.png"
                      alt="arrow_empty_profile"
                      width="64"
                      height="80"
                      style={{ position: 'absolute', top: '-20px', right: '-75px' }}
                    />
                  </span>
                ),
                content: (
                  <img
                    src="/medias/img/empty-state/profile/illustration_profile_empty.png"
                    alt="illustration_profile_empty"
                    width="406"
                    height="173"
                  />
                ),
              }
        }
      >
        <ProfileMultiResultsBanner isLoading={isLoading || isEmpty} />

        <ProfileHero
          identity={profileData.identity}
          isLoading={isLoading || isEmpty}
          dataLoaded={dataLoaded}
          hasError={hasError}
          profileId={profileData.id}
          isRefresh={isRefresh}
        />

        <ProfileBody template="repeat(2, minmax(224px, 1fr))" alignItems="start" gap={28}>
          <AttributesBlock
            attributes={profileData.attributes}
            isLoading={isLoading}
            isEmpty={isEmpty}
          />
          <EventsBlock
            events={profileData.events}
            isLoading={isLoading || isEventsLoading || isEmpty}
          />
        </ProfileBody>
      </Wrapper>
    </ProfileContainer>
  )
}
