import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useNavigate } from 'react-router-dom'

import { useUserHasPermission, useToggle, useQuery } from 'components/_hooks'
import { Box, BoxBody } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Wrapper, GlobalErrorOverlayProps, SettingsEmptyIcon } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Table, TableCellOrder, TableHeader, TableFooter, TableBody } from 'components/common/table'
import { FilterSearch } from 'components/filter'
import { Title } from 'components/styled/text'

import { getPlatformDoc } from 'com.batch.common/utils'

import { Separator } from './../custom-data/custom-data.styles'
import { AudienceEditor } from './audience-editor'
import { AudienceEmptyList } from './audience-empty-list'
import { AudienceUpload } from './audience-upload'

import {
  CustomAudienceFactory,
  type AppRecord,
  type CustomAudienceRecord,
} from 'com.batch.redux/_records'
import {
  fetchCustomAudiences,
  saveCustomAudience,
  createCustomAudience,
  deleteCustomAudience,
  type CreateCustomAudienceType,
} from 'com.batch.redux/audience'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'
import { LoadingStatus } from 'constants/common'

const NB_PER_PAGE = 10

type AudiencesListProps = {
  app: AppRecord
}

const coerceOrder = (value?: string | null) => (value === 'asc' || value === 'dsc' ? value : 'dsc')

export const AudienceList: React.ComponentType<AudiencesListProps> = React.memo<AudiencesListProps>(
  ({ app }: AudiencesListProps): React.ReactElement => {
    const navigate = useNavigate()
    const [editLine, setEditLine] = React.useState<number | null>(null)
    const [pagerLoading, setPagerLoading] = React.useState<boolean>(false)

    const uploaderState = useToggle()

    // ====================== REDUX
    const dispatch = useDispatch()
    const { customAudienceLoadingState, creating, entities, idsPerPage, count } = useSelector(
      state => state.audience
    )

    const user = useSelector(currentUserSelector)

    // check if features is activated for this app
    const featureActivated = app.features.has('custom-audience')

    // current query string param, with defaults
    const query = useQuery()

    const qPage = parseInt(query.get('page') ?? '1') || 1
    const qSortBy = query.get('sortBy') || 'date'
    const qSortOrder = coerceOrder(query.get('sortOrder'))
    const qSearch = query.get('search') || ''

    const audiences = React.useMemo(
      () =>
        idsPerPage
          .get(qPage, Immutable.List())
          .map((id: string) => entities.get(id, CustomAudienceFactory())),
      [entities, idsPerPage, qPage]
    )

    const minTableHeight = React.useMemo(
      () =>
        Math.ceil(count / 10) === qPage && audiences.size !== 0
          ? Math.ceil(audiences.size * 44.9)
          : 449,
      [audiences.size, count, qPage]
    )

    const userAllowedToWrite = useUserHasPermission(user, ['app', 'custom-audiences:write'])

    // Check when is empty and set disabled
    const isEmpty = React.useMemo(
      () =>
        customAudienceLoadingState === LoadingStatus.ERROR ||
        (count === 0 && customAudienceLoadingState === LoadingStatus.LOADED && !qSearch) ||
        !featureActivated,
      [customAudienceLoadingState, count, featureActivated, qSearch]
    )

    const isLoading = React.useMemo(
      () =>
        customAudienceLoadingState === LoadingStatus.INIT ||
        customAudienceLoadingState === LoadingStatus.LOADING,
      [customAudienceLoadingState]
    )

    const isError = React.useMemo(
      () => customAudienceLoadingState === LoadingStatus.ERROR,
      [customAudienceLoadingState]
    )

    // ====================== CALLBACKS
    // build query string based on partial params & current query string

    const onLineChange = React.useCallback((line: number | null) => () => setEditLine(line), [])
    const buildSearch = React.useCallback(
      ({
        page,
        sortBy,
        sortOrder,
        search,
      }: {
        page?: number
        sortBy?: 'name' | 'code' | 'type' | 'date' | 'ids'
        sortOrder?: 'dsc' | 'asc'
        search?: string
      }) => {
        const cleanPage = typeof page === 'number' ? page : qPage
        const cleanSortBy = typeof sortBy === 'string' ? sortBy : qSortBy
        const cleanSortOrder: 'dsc' | 'asc' = typeof sortOrder === 'string' ? sortOrder : qSortOrder
        const cleanSearch = typeof search === 'string' ? search : qSearch
        return `page=${cleanPage}&sortBy=${cleanSortBy}&sortOrder=${cleanSortOrder}&search=${encodeURIComponent(
          cleanSearch
        )}`
      },
      [qPage, qSearch, qSortBy, qSortOrder]
    )

    const createCustomAudienceBound = React.useCallback(
      (customAudience: CreateCustomAudienceType) => dispatch(createCustomAudience(customAudience)),
      [dispatch]
    )

    const searchingAndFetching = React.useCallback(
      (search: string) => {
        setPagerLoading(true)
        navigate({ search: buildSearch({ page: 1, search }) })
        dispatch(fetchCustomAudiences(app.id, { forceRefresh: true }))
      },
      [app.id, buildSearch, dispatch, navigate]
    )

    const save = React.useCallback(
      (audience: CustomAudienceRecord): Promise<any> => {
        return dispatch(saveCustomAudience(app.id, audience))
      },
      [app.id, dispatch]
    )

    const remove = React.useCallback(
      (audience: CustomAudienceRecord) => {
        confirm({
          sensitive: true,
          message: (
            <article>
              <p>
                This will delete custom audience{' '}
                <ConfirmHighlight>{audience.name}</ConfirmHighlight> app.
              </p>
              <p>Campaigns already targeting this audience won't reach anyone.</p>
            </article>
          ),
          title: 'Delete this audience?',
        }).then(
          () => dispatch(deleteCustomAudience(app.id, audience)),
          () => {}
        )
      },
      [app.id, dispatch]
    )

    const onSortChange = React.useCallback(
      (field: 'name' | 'type' | 'code' | 'date' | 'ids') => () => {
        const direction: 'asc' | 'dsc' =
          qSortBy === field ? (qSortOrder === 'asc' ? 'dsc' : 'asc') : 'asc'

        setEditLine(null)

        navigate({ search: buildSearch({ page: 1, sortBy: field, sortOrder: direction }) })
        dispatch(
          fetchCustomAudiences(app.id, {
            page: 1,
            forceRefresh: true,
          })
        )
      },
      [app.id, buildSearch, dispatch, navigate, qSortOrder, qSortBy]
    )

    const selectPage = React.useCallback(
      (page: number) => {
        setPagerLoading(false)
        navigate({ search: buildSearch({ page }) })
        dispatch(fetchCustomAudiences(app.id, { forceRefresh: true }))
      },
      [dispatch, navigate, app.id, buildSearch]
    )

    // ====================== USE EFFECTS
    React.useEffect(() => {
      setEditLine(null)
      dispatch(fetchCustomAudiences(app.id, { page: qPage }))
    }, [app.id, qPage, qSearch, dispatch])

    // Force pager loeding if any changes on search
    React.useEffect(() => setPagerLoading(true), [qSearch])

    // ====================== RENDER
    return (
      <Wrapper
        isEmpty={isEmpty}
        isLoading={isLoading}
        isOverlayShown={!isLoading && (isError || !featureActivated || isEmpty)}
        overlayProps={
          !featureActivated
            ? {
                status: 'upgrade-page',
                companyId: app.companyId ?? 0,
                title: 'Activate this feature',
                description:
                  'Custom user data allows you to assign tags and custom attributes to your users, thus enabling better targeting.',
              }
            : isError
              ? GlobalErrorOverlayProps
              : {
                  status: 'empty-page',
                  title: 'No custom audience saved yet',
                  description: (
                    <div>
                      <p style={{ marginBottom: 10 }}>
                        Our custom audience feature lets you send static lists of user or device IDs
                        to Batch and then target those with push or in-app campaigns.
                      </p>
                      <PermissionButton
                        intent="action"
                        kind="primary"
                        onClick={uploaderState.open}
                        disabled={isLoading}
                        isAllowed={userAllowedToWrite}
                      >
                        Add audience
                      </PermissionButton>
                    </div>
                  ),
                  content: <SettingsEmptyIcon />,
                  links: [
                    {
                      name: 'Custom audiences',
                      href: 'https://doc.batch.com/dashboard/push/user-targeting#custom-audiences',
                    },
                    {
                      name: 'Install the SDK',
                      href: `https://doc.batch.com/${getPlatformDoc(
                        app.platform
                      )}/getting-started/prerequisites`,
                    },
                  ],
                }
        }
      >
        <Grid
          template={`1fr auto 9px ${!userAllowedToWrite ? '150px' : 'auto'}`}
          gap={8}
          margin={[0, 0, 38, 0]}
        >
          <Title overEmptyState mb={0}>
            Custom audiences
          </Title>

          <FilterSearch
            identifier="custom audiences"
            value={qSearch}
            onChange={searchingAndFetching}
            // c'est faux mais je sais pas ce qu'on voulait faire
            disabled={isEmpty && !qSearch && !isLoading}
            expandedMaxWidth={280}
          />

          <Separator />

          <PermissionButton
            intent="action"
            kind="primary"
            addOn="prefix"
            onClick={uploaderState.open}
            // c'est faux mais je sais pas ce qu'on voulait faire
            disabled={isEmpty && !qSearch && !isLoading}
            isAllowed={userAllowedToWrite}
          >
            <Icon icon="upload" />
            Add audience
          </PermissionButton>
        </Grid>

        <Box style={{ overflow: 'hidden' }}>
          <BoxBody>
            <Table template="minmax(100px, 1fr) 190px 150px 140px 140px 126px" rowHeight={52}>
              <TableHeader style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                <TableCellOrder
                  sort={qSortBy === 'name' ? qSortOrder : undefined}
                  onClick={onSortChange('name')}
                >
                  Display name
                </TableCellOrder>

                <TableCellOrder
                  sort={qSortBy === 'code' ? qSortOrder : undefined}
                  onClick={onSortChange('code')}
                >
                  Name
                </TableCellOrder>

                <TableCellOrder
                  sort={qSortBy === 'type' ? qSortOrder : undefined}
                  onClick={onSortChange('type')}
                >
                  Type
                </TableCellOrder>

                <TableCellOrder
                  align="right"
                  sort={qSortBy === 'ids' ? qSortOrder : undefined}
                  onClick={onSortChange('ids')}
                  style={{ marginRight: '16px' }}
                >
                  Tokens / IDs
                </TableCellOrder>

                <TableCellOrder
                  sort={qSortBy === 'date' ? qSortOrder : undefined}
                  onClick={onSortChange('date')}
                >
                  Last update
                </TableCellOrder>

                <div />
              </TableHeader>
              <TableBody emptyTemplate={<AudienceEmptyList />}>
                <NoResultWrapper
                  isEmpty={audiences.size === 0 && !!qSearch}
                  entityName="audience"
                  height={minTableHeight}
                >
                  <div style={{ minHeight: minTableHeight }}>
                    {audiences.map((audience: CustomAudienceRecord, index: number) => {
                      const line = index + 1
                      const isEditing = line === editLine

                      return (
                        <AudienceEditor
                          key={audience.name + index}
                          aud={audience}
                          isEditing={isEditing}
                          save={save}
                          remove={remove}
                          setEditing={onLineChange(line)}
                          closeEditing={onLineChange(null)}
                          index={index}
                        />
                      )
                    })}
                  </div>
                </NoResultWrapper>
              </TableBody>
              {(count > 10 || (count === 0 && isLoading && !qSearch)) && (
                <TableFooter>
                  <Pager
                    forceLoading={isLoading && pagerLoading}
                    page={qPage}
                    total={count}
                    nbPerPage={NB_PER_PAGE}
                    selectPage={selectPage}
                  />
                </TableFooter>
              )}
            </Table>
          </BoxBody>
        </Box>
        <Popin
          opened={uploaderState.value}
          close={uploaderState.close}
          style={{ width: 880, margin: 0, padding: 0 }}
        >
          <AudienceUpload
            app={app}
            close={uploaderState.close}
            createCustomAudience={createCustomAudienceBound}
            creating={creating}
          />
        </Popin>
      </Wrapper>
    )
  }
)
