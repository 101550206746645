import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import S3Upload from 'react-s3-uploader/s3upload'

import { useToggle } from 'components/_hooks/use-toggle'

type useImageUploaderProps = {
  onImageUploadSuccess: (arg1: string) => void
  onImageUploadError: (arg1: string) => void
  s3signingUrl: string
  clickToOpen?: boolean
  imageMinWidth?: number
  imageMinHeight?: number
  imageRequiredRatio?: number
}

export function useImageUploader({
  onImageUploadSuccess,
  onImageUploadError,
  s3signingUrl,
  clickToOpen = false,
  imageMinWidth = 0,
  imageMinHeight = 0,
  imageRequiredRatio = 0,
}: useImageUploaderProps): {
  getUploaderRootProps: (props?: Record<any, any> | null | undefined) => Record<any, any>
  uploaderInputField: React.ReactNode
  openUploader: () => void
  draggingState: draggingState
} {
  const uploadingState = useToggle()
  const s3Config = React.useMemo(() => {
    return {
      accept: 'image/*',
      onFinishS3Put: (file: any) => {
        uploadingState.close()
        onImageUploadSuccess(file.publicUrl)
      },
      onError: err => {
        console.log(err)
        onImageUploadError(err)
        uploadingState.close()
      },
      uploadRequestHeaders: {},
      signingUrl: s3signingUrl,
    }
  }, [onImageUploadError, onImageUploadSuccess, s3signingUrl, uploadingState])

  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) => {
      const file = acceptedFiles[0]
      if (!file) {
        onImageUploadError('No file selected')
        return
      }
      const sizeMo = typeof file.size === 'number' ? file.size / 1024 / 1024 : 0
      if (sizeMo > 5) {
        onImageUploadError('Image must be less than 5 Mo')
        return
      }
      const dataURL: string = window.URL.createObjectURL(file)
      const img = new Image()
      img.onload = () => {
        if (imageMinWidth && img.width < imageMinWidth) {
          onImageUploadError(`Image does not meet the required minimum width of ${imageMinWidth}px`)
        } else if (imageMinHeight && img.height < imageMinHeight) {
          onImageUploadError(
            `Image does not meet the required minimum height of ${imageMinHeight}px`
          )
        } else if (imageRequiredRatio && img.width / img.height !== imageRequiredRatio) {
          onImageUploadError(`Image does not meet the required ratio of ${imageRequiredRatio}`)
        } else {
          uploadingState.open()
          const options = {
            ...s3Config,
            files: [file],
          }
          new S3Upload(options)
        }
        window.URL.revokeObjectURL(dataURL)
      }

      img.src = dataURL
    },
    [
      onImageUploadError,
      imageMinWidth,
      imageMinHeight,
      imageRequiredRatio,
      uploadingState,
      s3Config,
    ]
  )
  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
    },
    noClick: !clickToOpen,
    noKeyboard: true,
  })
  return {
    getUploaderRootProps: getRootProps,
    uploaderInputField: <input {...getInputProps()} />,
    openUploader: open,
    draggingState: uploadingState.value
      ? 'UPLOADING'
      : isDragAccept
        ? 'ACCEPTED'
        : isDragReject
          ? 'REJECTED'
          : 'NONE',
  }
}
