import { get } from 'lodash-es'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, Input } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import * as api from './api'
import { RegWizard } from './register-containers'
import { RegisterStep, RegisterStepFooter, RegisterLogOut } from './register.styles'

import { Title, Text } from '../auth.styles'

export const RegisterCompany = ({ isInvite }: { isInvite: boolean }): React.ReactElement => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [name, setName] = React.useState('')
  const [error, setError] = React.useState('')
  const navigate = useNavigate()
  const onSubmit = React.useCallback(() => {
    setError('')
    if (name.trim().length === 0) {
      setError('Please provide a name for your company.')
    } else {
      setLoading(true)
      api
        .saveCompany(name)
        .then(() => {
          navigate('/register/congrats', { replace: true })
        })
        .catch(errorArray => {
          setLoading(false)
          setError(errorArray[0].message)
        })
    }
  }, [name, navigate])

  const onCompanyNameChange = React.useCallback(evt => setName(evt.target.value), [setName])

  return (
    <React.Fragment>
      <RegisterLogOut>
        <ButtonLink
          kind="inline"
          addOn="prefix"
          href={generateUrl('logout', {
            _csrf_token: get(document.querySelector('head > meta[name=account-csrf]'), 'content'),
          })}
        >
          <Icon icon="logout" />
          Log out
        </ButtonLink>
      </RegisterLogOut>
      <RegWizard total={!isInvite ? 5 : 3} step={!isInvite ? 5 : 3} animate>
        <RegisterStep>
          <Title>Your company</Title>
          <Text>You’re almost done! It’s time to create your company.</Text>
          <Form onSubmit={onSubmit}>
            <InputWrapper feedback={error}>
              <Input
                placeholder="Company name"
                value={name}
                invalid={!!error}
                onChange={onCompanyNameChange}
                aria-label="Company name"
              />
            </InputWrapper>

            <RegisterStepFooter>
              <Button kind="primary" intent="action" type="submit" isLoading={loading}>
                Finish
              </Button>
            </RegisterStepFooter>
          </Form>
        </RegisterStep>
      </RegWizard>
    </React.Fragment>
  )
}
