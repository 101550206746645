import { type List } from 'immutable'

import { type PartialOrchestrationRecord } from '../models/partial-orchestration.records'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type fetchCampaignsResponse = {
  count: number
  countTotal: number
  entities: List<PartialOrchestrationRecord>
}

type SearchOrchestrationsListAction = ReduxAction<'SEARCH_ORCHESTRATIONS_LIST', null>

export type SearchOrchestrationsListSuccessAction = ReduxAction<
  'SEARCH_ORCHESTRATIONS_LIST_SUCCESS',
  fetchCampaignsResponse
>

export type SearchOrchestrationsListFailureAction = ReduxAction<
  'SEARCH_ORCHESTRATIONS_LIST_FAILURE',
  string
>

export type SearchOrchestrationsListActions =
  | SearchOrchestrationsListAction
  | SearchOrchestrationsListSuccessAction
  | SearchOrchestrationsListFailureAction

let abortSearchCampaignsController: AbortController = new AbortController()

export const searchOrchestrationsList = ({
  search,
  mode,
}: {
  search: string
  mode: schedulingType
}): DispatchExtraBoundFn<Promise<fetchCampaignsResponse>> => {
  return (dispatch, getState, { orchestrationService }) => {
    abortSearchCampaignsController.abort()
    abortSearchCampaignsController = new AbortController()
    const state = getState()
    const project = currentProjectSelector(state)

    const promise = orchestrationService.fetchOrchestrations({
      project,
      mode: mode,
      search: search,
      orderDirection: 'asc',
      orderBy: 'name',
      statuses: [],
      pushPlatforms: [],
      filterType: [],
      channels: [],
      labels: [],
      dateRange: null,
      offset: 0,
      pageSize: 20,
      count: null,
      countTotal: null,
      abortSignal: abortSearchCampaignsController.signal,
      segments: [],
      trashCache: false,
    })

    return promiseActionCreator<fetchCampaignsResponse>({
      dispatch,
      promise,
      actionName: 'SEARCH_ORCHESTRATIONS_LIST',
    })
  }
}
