import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useNavigate } from 'react-router-dom'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Button, ButtonNavLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form'
import { BlurValidation } from 'components/form/blur-validation'
import {
  ButtonContainer,
  HeaderContainer,
  HeaderContent,
  HeaderTitleContainer,
  HeaderActions,
  LabelList,
  LabelTag,
} from 'components/project/campaign/common/fake-omni-header.styles'

import { generateOrchestrationUrl } from 'com.batch.common/router'
import { campaignStateFormatter, pluralize } from 'com.batch.common/utils'
import { requiredString } from 'com.batch.common/validators'

import {
  orchestrationLabelSelector,
  orchestrationMetaSelector,
  orchestrationStateSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'
import { currentCompanySelector } from 'com.batch.redux/company'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import {
  estimateProfileGetterSelector,
  subscriptionStatusSelector,
} from 'com.batch.redux/target/target.selector'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'
import { LabelAssociationPopin } from 'com.batch/orchestration/ui/components/label-association-popin'
import { setOrchestrationLabelCodes } from 'com.batch/orchestration/usecases/set-orchestration-label-codes'
import { updateName } from 'com.batch/orchestration/usecases/update-name'
import { NodeIconWrapper } from 'com.batch/orchestration-journey/ui/components/node-icon-wrapper'
import { OrchestrationMenu } from 'com.batch/orchestration-list/ui/components/orchestration-menu'
import { ReplicationModal } from 'com.batch/orchestration-list/ui/components/replication-modal'
import { CopyableWhenOverflow } from 'com.batch/shared/ui/component/copyable-when-overflow'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'
import { type ProjectMode } from 'com.batch/orchestration-list/ui/components/confirm-wording'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { RetargetingModal } from 'com.batch/orchestration-list/ui/components/retargeting-modal'
import { LoadingStatus } from 'constants/common'

const convertChannelToCampaignLabelName = (channel?: ChannelUntilCleanup | null): string => {
  switch (channel) {
    case 'email':
      return 'Email Campaign name'
    case 'sms':
      return 'SMS Campaign name'
    case 'push':
      return 'Push Campaign name'
    default:
      return 'Campaign name'
  }
}

const convertChannelToAutomationLabelName = (channel?: ChannelUntilCleanup | null): string => {
  switch (channel) {
    case 'email':
      return 'Recurring Email name'
    case 'sms':
      return 'Recurring SMS name'
    case 'push':
      return 'Recurring Push name'
    default:
      return 'Recurring name'
  }
}

// small wrapper to not retrieve data when we are not showing the menu
const CampaignMenuWithData = ({ schedulingType }: { schedulingType: schedulingType }) => {
  const orchestration = useSelector(orchestrationStateSelector)
  const estimateGetter = useSelector(estimateProfileGetterSelector)
  const targetingMode = useSelector(subscriptionStatusSelector)
  const estimate = estimateGetter('default')
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const data: ProjectMode = React.useMemo(
    () => ({
      orchestration,
      estimate,
      isTransactional: targetingMode === 'fullbase',
      channel: orchestration.campaign.sendType === 'trigger' ? null : 'email',
    }),
    [orchestration, estimate, targetingMode]
  )
  const replicateModalState = useToggle()
  const retargetingModalState = useToggle()
  return (
    <React.Fragment>
      {replicateModalState.value && (
        <ReplicationModal
          isOpened
          dismiss={replicateModalState.close}
          orchestrationMeta={{
            id: orchestration.id,
            name: orchestration.name,
            sendType: orchestration.campaign.sendType,
            channels:
              orchestration.campaign.sendType === 'trigger'
                ? orchestration.triggerNodes.reduce((acc, node) => {
                    if (node.type !== 'MESSAGE') {
                      return acc
                    }
                    return acc.add(node.messageConfig.channel)
                  }, Immutable.Set())
                : orchestration.channels,
          }}
        />
      )}
      {retargetingModalState.value && (
        <RetargetingModal
          isOpened
          onClose={retargetingModalState.close}
          orchestrationMeta={{
            id: orchestration.id,
            name: orchestration.name,
            sendType: orchestration.campaign.sendType,
            channel: orchestration.channels.first(),
          }}
        />
      )}
      <OrchestrationMenu
        schedulingType={schedulingType}
        data={data}
        userHasWritePermission={userHasWritePermission}
        openReplication={replicateModalState.open}
        openRetargeting={retargetingModalState.open}
      />
    </React.Fragment>
  )
}

type OrchestrationHeaderProps = {
  channel: ChannelUntilCleanup | null | undefined
  forceDisabled?: boolean
}

export const OrchestrationHeader: React.ComponentType<OrchestrationHeaderProps> = React.memo(
  ({ channel, forceDisabled }: OrchestrationHeaderProps) => {
    const { state, sendType, loadingState, id, name, schedulingType, version, savedVersion } =
      useSelector(orchestrationMetaSelector)
    const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
    const company = useSelector(currentCompanySelector)
    const project = useSelector(currentProjectSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const analyticsUrl = React.useMemo(
      () =>
        generateOrchestrationUrl({
          sendType,
          companyId: company.id,
          projectId: project.id,
          token: id,
          channel: channel ?? null,
          page: 'analytics',
        }),
      [company.id, id, project.id, sendType, channel]
    )
    const isPristine = React.useMemo(() => version === savedVersion, [version, savedVersion])
    const { confirmBeforeAction } = useLeaveConfirmation({ isPristine })
    const onAnalyticsClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        confirmBeforeAction().then(
          ok => ok && navigate(analyticsUrl),
          () => {}
        )
      },
      [analyticsUrl, confirmBeforeAction, navigate]
    )
    const isDisabled = React.useMemo(
      () =>
        !userHasWritePermission ||
        forceDisabled ||
        loadingState === LoadingStatus.LOADING ||
        loadingState === LoadingStatus.ERROR ||
        state === 'COMPLETED',
      [state, forceDisabled, loadingState, userHasWritePermission]
    )
    const showNav = React.useMemo(() => state !== 'DRAFT', [state])
    const showMenu = React.useMemo(() => state !== 'NEW' && id, [id, state])
    const onCampaignNameChange = React.useCallback(
      evt => {
        dispatch(updateName(evt.target.value))
      },
      [dispatch]
    )
    const toggleLabelPopin = useToggle()

    const selectedLabels = useSelector(orchestrationLabelSelector)

    const onChangeLabels = React.useCallback(
      (labels: List<LabelRecord>) => {
        dispatch(setOrchestrationLabelCodes(Immutable.Set(labels.map(label => label.code))))
      },
      [dispatch]
    )
    const campaignWord = React.useMemo(() => {
      return schedulingType === 'automations' ? 'automation' : 'campaign'
    }, [schedulingType])
    return (
      <HeaderContainer>
        <HeaderContent>
          <Grid
            template={`${channel ? '45px ' : '32px'} 1fr ${showNav ? 'auto' : ''} ${
              showMenu ? 'auto' : ''
            }`}
          >
            {channel ? (
              <div style={{ position: 'relative' }}>
                <NodeIconWrapper kind={channel} size={32} />
                <div style={{ position: 'absolute', bottom: -12, left: 20 }}>
                  <Tooltip
                    tooltip={
                      <React.Fragment>
                        This {campaignWord} is {campaignStateFormatter(state, true)}
                      </React.Fragment>
                    }
                  >
                    <CampaignStateIcon state={state} size={18} schedulingType={schedulingType} />
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div>
                <Tooltip
                  tooltip={
                    <React.Fragment>
                      This {campaignWord} is {campaignStateFormatter(state, true)}
                    </React.Fragment>
                  }
                >
                  <CampaignStateIcon state={state} size={21} schedulingType={schedulingType} />
                </Tooltip>
              </div>
            )}
            {isDisabled ? (
              <HeaderTitleContainer>
                <CopyableWhenOverflow className="styled-nameInput">{name}</CopyableWhenOverflow>
                {selectedLabels.size > 0 && (
                  <Tooltip
                    tooltip={selectedLabels.map(selectedLabel => (
                      <LabelList key={selectedLabel.code}>
                        {selectedLabel.description || <s>{selectedLabel.code}</s>}
                        <br />
                      </LabelList>
                    ))}
                    isTooltipEmpty={!selectedLabels.size}
                    maxWidth={600}
                    placement="bottom"
                  >
                    <LabelTag icon="label" type="label">
                      <div style={{ marginLeft: '-4px' }}>
                        {pluralize('label', selectedLabels.size)}
                      </div>
                    </LabelTag>
                  </Tooltip>
                )}
              </HeaderTitleContainer>
            ) : (
              <HeaderTitleContainer>
                <ButtonContainer>
                  <BlurValidation validator={requiredString} value={name}>
                    {({ value, invalid, onBlur }) => (
                      <Input
                        value={value}
                        autoFocus
                        disabled={isDisabled}
                        onChange={onCampaignNameChange}
                        onBlur={onBlur}
                        invalid={invalid}
                        data-testid="input_orchestration_name"
                        placeholder={
                          sendType === 'trigger'
                            ? 'Automation name'
                            : sendType === 'recurring'
                              ? `${convertChannelToAutomationLabelName(channel)}`
                              : `${convertChannelToCampaignLabelName(channel)}`
                        }
                        className="styled-nameInput"
                        style={{ borderRadius: 6, width: '100%' }}
                        // disable safari autocomplete
                        // https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7
                        name="notASearchField"
                      />
                    )}
                  </BlurValidation>
                  <Button
                    onClick={toggleLabelPopin.open}
                    style={{ position: 'absolute', right: '5px', top: '4px', height: '32px' }}
                    intent={selectedLabels.size ? 'action' : 'neutral'}
                  >
                    <Icon
                      icon="label"
                      style={{ paddingRight: '8px', paddingBottom: '1px', color: '' }}
                    />
                    {selectedLabels.size ? pluralize('label', selectedLabels.size) : 'Add labels'}
                  </Button>
                </ButtonContainer>
              </HeaderTitleContainer>
            )}
            {showNav && (
              <HeaderActions>
                <Separator style={{ margin: 'auto 20px auto 10px', height: '24px' }} />
                <ButtonNavLink
                  to={generateOrchestrationUrl({
                    sendType,
                    companyId: company.id,
                    projectId: project.id,
                    token: id,
                    channel: channel ?? null,
                    page: 'form',
                  })}
                  style={{
                    height: 36,
                    marginRight: 8,
                  }}
                  end
                >
                  Builder
                </ButtonNavLink>
                <ButtonNavLink
                  onClick={onAnalyticsClick}
                  to={analyticsUrl}
                  style={{
                    height: 36,
                  }}
                >
                  Analytics
                </ButtonNavLink>
              </HeaderActions>
            )}
            {showMenu && <CampaignMenuWithData schedulingType={schedulingType} />}
          </Grid>
        </HeaderContent>
        <Popin
          opened={toggleLabelPopin.value}
          close={React.useCallback(() => {
            onChangeLabels(selectedLabels)
            toggleLabelPopin.close()
          }, [selectedLabels, toggleLabelPopin, onChangeLabels])}
        >
          <LabelAssociationPopin
            close={toggleLabelPopin.close}
            selectedLabels={selectedLabels}
            setSelectedLabels={onChangeLabels}
          />
        </Popin>
      </HeaderContainer>
    )
  }
)

OrchestrationHeader.displayName = 'OrchestrationHeader'
