import * as React from 'react'
import { Link } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { ButtonNavLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions, TableTemplateCell } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { Timezone } from 'components/styled/text'

import { generateOrchestrationUrl } from 'com.batch.common/router/url-generator-helper'
import { campaignStateFormatter, kformat, percentage } from 'com.batch.common/utils'

import { type ReplicationOrchestrationMeta } from './replication-modal'
import { OrchestrationChannelFlags, OrchestrationNameWithFlagAndChannels } from './row.styles'

import { type ProjectRecord } from 'com.batch.redux/project.records'

import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { OrchestrationMenu } from 'com.batch/orchestration-list/ui/components/orchestration-menu'
import { When } from 'com.batch/orchestration-list/ui/components/when'
import { generateChannelIcon, generateChannelLabel, sortChannels } from '../../../../common/utils'
import { type RetargetingOrchestrationMeta } from './retargeting-modal'
import { dayjs, type Dayjs } from 'com.batch.common/dayjs.custom'
import { TrendContainer } from 'com.batch/shared/ui/component/trend/trend.styles'
import { LoadingStatus } from 'constants/common'

const humanizeDayjsCustom = (date: Dayjs): string => {
  const daysDiff: number = dayjs().diff(dayjs(date), 'day')
  const hoursDiff: number = dayjs().diff(dayjs(date), 'hour')
  const minutesDiff: number = dayjs().diff(dayjs(date), 'minute')
  const secondsDiff: number = dayjs().diff(dayjs(date), 'second')

  if (daysDiff > 1) {
    return date.format('DD/MM/YY')
  } else if (daysDiff > 0) {
    return 'yesterday'
  } else if (hoursDiff > 0) {
    return `${hoursDiff}h ago`
  } else if (minutesDiff > 0) {
    return `${minutesDiff}m ago`
  } else {
    return `${secondsDiff}s ago`
  }
}

type RowProps = {
  partialOrchestration: PartialOrchestrationRecord
  project: ProjectRecord
  multipleChannelsSupported: boolean
  schedulingType: schedulingType
  data: OrchestrationListStatsStateRecord
  openReplicationModalFor: (arg1: ReplicationOrchestrationMeta) => void
  openRetargetingModalFor: (arg1: RetargetingOrchestrationMeta) => void
}

export const Row = ({
  partialOrchestration,
  schedulingType,
  data,
  project,
  multipleChannelsSupported,
  openReplicationModalFor,
  openRetargetingModalFor,
}: RowProps): React.ReactElement => {
  const campaignWord = React.useMemo(() => {
    return schedulingType === 'automations' ? 'automation' : 'campaign'
  }, [schedulingType])
  const userIsAllowed = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const formUrl = React.useMemo(
    () =>
      generateOrchestrationUrl({
        channel: partialOrchestration.channels.first() ?? 'email',
        page: 'form',
        sendType: partialOrchestration.sendType,
        companyId: project.companyId,
        projectId: project.id,
        token: partialOrchestration.token,
      }),
    [partialOrchestration, project]
  )
  const analyticsUrl = React.useMemo(
    () =>
      generateOrchestrationUrl({
        channel: partialOrchestration.channels.first() ?? 'email',
        page: 'analytics',
        sendType: partialOrchestration.sendType,
        companyId: project.companyId,
        projectId: project.id,
        token: partialOrchestration.token,
      }),
    [partialOrchestration, project]
  )
  const onOpenReplicationModal = React.useCallback(() => {
    openReplicationModalFor({
      id: partialOrchestration.token,
      name: partialOrchestration.name,
      sendType: partialOrchestration.sendType,
      channels: partialOrchestration.channels,
    })
  }, [
    openReplicationModalFor,
    partialOrchestration.token,
    partialOrchestration.name,
    partialOrchestration.sendType,
    partialOrchestration.channels,
  ])

  const onOpenRetargetingModal = React.useCallback(() => {
    openRetargetingModalFor({
      id: partialOrchestration.token,
      name: partialOrchestration.name,
      channel: partialOrchestration.channels.first(),
      sendType: partialOrchestration.sendType,
    })
  }, [openRetargetingModalFor, partialOrchestration])

  const menuData = React.useMemo(() => {
    return { partialOrchestration }
  }, [partialOrchestration])

  const pushPlatformWording = React.useMemo(() => {
    if (partialOrchestration.pushPlatforms.size === 0) return ''
    const text: Array<string> = []
    if (
      partialOrchestration.pushPlatforms.includes('ios') ||
      partialOrchestration.pushPlatforms.includes('android')
    )
      text.push('App')
    if (partialOrchestration.pushPlatforms.includes('webpush')) text.push('Web')
    return text.join(' & ')
  }, [partialOrchestration.pushPlatforms])

  return (
    <TableRow
      key={partialOrchestration.token}
      style={{ height: multipleChannelsSupported ? 60 : 52 }}
    >
      <TableCell align="center" style={{ overflow: 'visible' }}>
        <Tooltip
          tooltip={
            <React.Fragment>
              This {campaignWord} is {campaignStateFormatter(partialOrchestration.state, true)}
            </React.Fragment>
          }
        >
          <CampaignStateIcon state={partialOrchestration.state} schedulingType={schedulingType} />
        </Tooltip>
      </TableCell>
      <TableCell>
        <OrchestrationNameWithFlagAndChannels $withAPIFlag={partialOrchestration.createdByApi}>
          <Tooltip
            tooltip={partialOrchestration.name}
            minWidth={Math.min(500, Math.max(50, partialOrchestration.name.length * 10))}
          >
            <Link
              style={{
                fontWeight: 500,
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              to={formUrl}
            >
              {partialOrchestration.name}
            </Link>
          </Tooltip>
          {partialOrchestration.createdByApi && <Timezone>API</Timezone>}
          {partialOrchestration.channels.size > 0 && multipleChannelsSupported && (
            <OrchestrationChannelFlags>
              {sortChannels(partialOrchestration.channels).map(channel => {
                return (
                  <li key={channel}>
                    <Icon icon={generateChannelIcon(channel)} />
                    <span>
                      {generateChannelLabel(channel)} {channel === 'push' && pushPlatformWording}
                    </span>
                  </li>
                )
              })}
            </OrchestrationChannelFlags>
          )}
        </OrchestrationNameWithFlagAndChannels>
      </TableCell>
      {!multipleChannelsSupported && (
        <TableCell>
          <Icon
            icon={generateChannelIcon(partialOrchestration.channels.first())}
            style={{ marginRight: '8px', marginTop: -2 }}
          />
          {generateChannelLabel(partialOrchestration.channels.first())}
          {pushPlatformWording ? ' ' : ''}
          {pushPlatformWording}
        </TableCell>
      )}
      <TableCell style={{ paddingLeft: 22, overflow: 'initial' }}>
        <When partialOrchestration={partialOrchestration} />
      </TableCell>
      {[LoadingStatus.INIT, LoadingStatus.LOADING].includes(data.loadingState) ? (
        <React.Fragment>
          <TableTemplateCell template="1fr" forceLoading />
          {schedulingType === 'automations' && <TableTemplateCell template="1fr" forceLoading />}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Tooltip
            minWidth={196}
            isTooltipEmpty={
              schedulingType !== 'automations' || (data.trend === 0 && data.sent === 0)
            }
            tooltip={
              <div>
                <div
                  style={{
                    borderBottom: '1px solid #565656',
                    padding: '4px 0 8px 0',
                    marginBottom: 6,
                    fontSize: '14px',
                    textAlign: 'left',
                    fontWeight: 500,
                  }}
                >
                  <strong>
                    <Icon icon="send" style={{ marginRight: 8 }} />
                    Sent&nbsp;
                  </strong>
                  {data.trend > 0 ? '+' : '-'}
                  {Math.abs(data.trend) === Infinity ? '∞%' : percentage(data.trend)}
                </div>
                <div style={{ color: '#d2d2d2', fontSize: '11px', textAlign: 'left' }}>
                  Past 7 days vs. previous 7 days
                </div>
              </div>
            }
          >
            <TableCell align="right">
              {data.sent ? kformat(data.sent) : '0'}
              {schedulingType === 'automations' && (
                <TrendContainer
                  style={{ marginLeft: 6 }}
                  $color={data.trend > 0 ? 'success' : data.trend < 0 ? 'danger' : 'neutral'}
                >
                  <Icon icon={data.trend >= 0 ? 'trending-up' : 'trending-down'} size={14} />
                </TrendContainer>
              )}
            </TableCell>
          </Tooltip>
          {schedulingType === 'automations' && (
            <TableCell align="right">
              {data.lastSent ? humanizeDayjsCustom(data.lastSent) : '—'}
            </TableCell>
          )}
        </React.Fragment>
      )}
      <TableCellActions>
        <Tooltip tooltip="Analytics" placement="top" minWidth={48}>
          <ButtonNavLink disabled={partialOrchestration.state === 'DRAFT'} to={analyticsUrl}>
            <Icon icon="analytics" />
          </ButtonNavLink>
        </Tooltip>
        <OrchestrationMenu
          userHasWritePermission={userIsAllowed}
          data={menuData}
          schedulingType={schedulingType}
          openReplication={onOpenReplicationModal}
          openRetargeting={onOpenRetargetingModal}
        />
      </TableCellActions>
    </TableRow>
  )
}
