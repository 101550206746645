import styled from 'styled-components'
import { colors } from 'components/styled/tokens'

export const FeaturesSubNav = styled.div`
  display: flex;
  gap: 20px;
  font-weight: 500;
  /* margin-bottom: 10px; */

  div {
    color: ${colors.textLight};
    cursor: pointer;
    padding-bottom: 5px;

    &:hover {
      color: ${colors.text};
    }

    &.active {
      color: ${colors.text};
      border-bottom: 2px solid ${colors.fillAction};
    }
  }
`
