import { type Dayjs } from 'dayjs'
import * as Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import type { RecordOf, List, Set, Map, OrderedSet, OrderedMap } from 'immutable' // eslint-disable-line

type CardProps = {
  brand: string
  expiration: string
  card: string
  loading: boolean
  found: boolean
}

export const CardFactory = Immutable.Record<CardProps>({
  brand: '',
  expiration: '',
  card: '',
  loading: false,
  found: false,
} as CardProps)

export type CardRecord = RecordOf<CardProps>

type PriceProps = {
  monthly: number
  monthlyStripePlanId: string
  yearly: number
  yearlyStripePlanId: string
}
export const PriceFactory = Immutable.Record<PriceProps>({
  monthly: 0,
  yearly: 0,
  monthlyStripePlanId: '',
  yearlyStripePlanId: '',
} as PriceProps)

export type PriceRecord = RecordOf<PriceProps>

type PlanProps = {
  code: string
  seats: number
  name: string
  weight: number
  descr: string
  legacy: boolean
  usd: PriceRecord | null | undefined
  eur: PriceRecord | null | undefined
}

export const PlanFactory = Immutable.Record<PlanProps>({
  code: '',
  seats: 1,
  name: '',
  weight: 0,
  descr: '',
  legacy: false,
  usd: null,
  eur: null,
} as PlanProps)

export type PlanRecord = RecordOf<PlanProps>
export type SubscriptionStatusEnum =
  | 'trialing'
  | 'active'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'none'

export type BillingProps = {
  address: string
  address2: string
  city: string
  companyName: string
  country: string
  cycle: 'monthly' | 'yearly' | null
  overcharge: boolean
  currency: 'eur' | 'usd'
  firstname: string
  lastname: string
  vatNumber: string
  vatRate: number
  zip: string
  plan: PlanRecord
  trial: PlanRecord | null | undefined
  trialUntil: Dayjs | null | undefined
  trialIsActive: boolean
  nextInvoice: Dayjs | null | undefined
  loading: boolean
  subscriptionStatus: SubscriptionStatusEnum
  subscriptionId: string | null | undefined
  timestamp: number
  cancelUnpaidAt: Dayjs | null | undefined
  autorenew: boolean
  downgradingTo: PlanRecord | null | undefined
  downgradingCycle: 'monthly' | 'yearly'
}

export const BillingFactory = Immutable.Record<BillingProps>({
  address: '',
  address2: '',
  city: '',
  overcharge: false,
  companyName: '',
  currency: 'eur',
  country: '',
  cycle: null,
  firstname: '',
  lastname: '',
  vatNumber: '',
  vatRate: 0,
  zip: '',
  plan: PlanFactory(),
  trial: null,
  trialUntil: null,
  trialIsActive: false,
  nextInvoice: null,
  loading: false,
  subscriptionStatus: 'none',
  subscriptionId: null,
  timestamp: 0,
  autorenew: true,
  cancelUnpaidAt: null,
  downgradingTo: null,
  downgradingCycle: 'monthly',
} as BillingProps)

export type BillingRecord = RecordOf<BillingProps>

type InvoiceProps = {
  id: string
  paidDate: Dayjs | null | undefined
  periodFrom: Dayjs
  periodTo: Dayjs
  varNumber: string
  total: number
  currency: 'eur' | 'usd'
  paid: boolean
}

export type InvoiceRecord = RecordOf<InvoiceProps>

export const InvoiceFactory = Immutable.Record<InvoiceProps>({
  id: '',
  paidDate: null,
  periodFrom: dayjs(),
  periodTo: dayjs(),
  varNumber: '',
  total: 0,
  currency: 'usd',
  paid: false,
} as InvoiceProps)
