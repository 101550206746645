import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import { type ProfilebaseType } from 'com.batch/profilebase/infra/types/profilebase.type'
import { LoadingStatus } from 'constants/common'

type extract<T> = (arg1: State) => T

const profilebaseSelector = (state: State): ProfilebaseType => {
  return state.profilebase
}

export const profilebaseIsLoadedSelector: extract<boolean> = createSelector(
  profilebaseSelector,
  profilebase =>
    profilebase.profilesDistribution.loadingState === LoadingStatus.LOADED &&
    profilebase.channelsDistribution.email.loadingState === LoadingStatus.LOADED &&
    profilebase.channelsDistribution.sms.loadingState === LoadingStatus.LOADED &&
    profilebase.channelsReachability.email.loadingState === LoadingStatus.LOADED &&
    profilebase.channelsReachability.sms.loadingState === LoadingStatus.LOADED &&
    (profilebase.channelsDistribution.push.loadingState === LoadingStatus.LOADED ||
      profilebase.channelsDistribution.webpush.loadingState === LoadingStatus.LOADED) &&
    (profilebase.channelsReachability.push.loadingState === LoadingStatus.LOADED ||
      profilebase.channelsReachability.webpush.loadingState === LoadingStatus.LOADED)
)

export const profilebaseHasErrorSelector: extract<boolean> = createSelector(
  profilebaseSelector,
  profilebase =>
    profilebase.profilesDistribution.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsDistribution.email.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsDistribution.push.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsDistribution.sms.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsDistribution.webpush.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsReachability.email.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsReachability.push.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsReachability.sms.loadingState === LoadingStatus.ERROR ||
    profilebase.channelsReachability.webpush.loadingState === LoadingStatus.ERROR
)
