// @TODO - à rewrite également, les empty states sont imbittables

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { Box } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { Wrapper } from 'components/common/empty-states'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Popin } from 'components/common/popin/popin'
import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'
import { cancellablePromise } from 'com.batch.common/utils'

import { AddPressureCategory } from './add-pressure-category'
import { PressureCategory, EmptyCategoryRow } from './pressure-category'
import { PressureInApp } from './pressure-inapp'

import { type AppRecord, type CappingCategoryRecord } from 'com.batch.redux/_records'
import { fetchInAppCappings, saveInAppCappings } from 'com.batch.redux/app.api'
import { type InAppCappingRecord } from 'com.batch.redux/capping.records'
import { type OverlayLink } from 'components/common/empty-states/overlay'
import { LoadingStatus } from 'constants/common'

type blockState = 'UPGRADE' | 'EMPTY' | 'NORMAL'
// TOPBLOCK references the placement on page, and is an aggregation of GLOBAL and INAPP
type blockKind = 'GLOBAL' | 'PAGE' | 'LABELS' | 'INAPP' | 'TOPBLOCK'

type PressureProps = {
  showToast: (arg1: string, arg2: boolean) => void
  hasCappingLabels: boolean
  hasCappingGlobal: boolean
  sdkLevelLoading: boolean
  getIntallRateMatchingApiLvl: (arg1: number) => number
  fetchAttributesAndValues: () => void
  hasInAppCapping: boolean
  app: AppRecord
  categories: List<CappingCategoryRecord>
}

export const Pressure = ({
  showToast,
  hasCappingLabels,
  fetchAttributesAndValues,
  sdkLevelLoading,
  getIntallRateMatchingApiLvl,
  hasCappingGlobal,
  hasInAppCapping,
  categories,
  app,
}: // fetchCampaignsLabelsCount
PressureProps): React.ReactElement => {
  // ------ LOCAL STATE -----
  const [editingCategoryCode, setEditingCategoryCode] = React.useState<string | null>(null)
  const addCategorieModalState = useToggle()
  const [inAppLoadingState, setInAppLoadingState] = React.useState<LoadingStatus>(
    LoadingStatus.INIT
  )
  const [inAppData, setInAppData] = React.useState<List<InAppCappingRecord>>(Immutable.List())
  const inAppEditingState = useToggle()
  const cleanupOnUnMount = React.useRef<() => void>(() => undefined)

  // ------ DERIVED STATE -----
  const installRate = React.useMemo(
    () => getIntallRateMatchingApiLvl(50),
    [getIntallRateMatchingApiLvl]
  )

  const internals = React.useMemo(() => categories.filter(cat => cat.internal), [categories])

  const labels = React.useMemo(
    () => categories.filter(cat => cat.id !== 'new' && !cat.internal),
    [categories]
  )

  const labelsShown = React.useMemo(
    () => labels.filter(cat => cat.dirty || cat.cappings.size > 0),
    [labels]
  )

  const labelsNoShown = React.useMemo(
    () => labels.filter(cat => !cat.dirty && cat.cappings.size === 0),
    [labels]
  )

  const blockMode: Partial<Record<blockKind, blockState>> = React.useMemo(() => {
    const partial: Partial<Record<blockKind, blockState>> = {
      INAPP: hasInAppCapping ? (inAppData.size === 0 ? 'EMPTY' : 'NORMAL') : 'UPGRADE',
      GLOBAL: hasCappingGlobal ? 'NORMAL' : 'UPGRADE',
      LABELS: hasCappingLabels ? (labelsShown.size === 0 ? 'EMPTY' : 'NORMAL') : 'UPGRADE',
    }

    return {
      ...partial,
      TOPBLOCK: partial.GLOBAL === 'UPGRADE' && partial.INAPP === 'UPGRADE' ? 'UPGRADE' : 'NORMAL',
      PAGE:
        partial.GLOBAL === 'UPGRADE' && partial.INAPP === 'UPGRADE' && partial.LABELS === 'UPGRADE'
          ? 'UPGRADE'
          : 'NORMAL',
    }
  }, [hasCappingGlobal, hasCappingLabels, hasInAppCapping, inAppData.size, labelsShown.size])

  const inAppEmptyState = React.useMemo(() => {
    const mode = blockMode.INAPP
    return (
      mode !== 'NORMAL' && (
        <Wrapper
          isEmpty={mode === 'EMPTY'}
          isLoading={
            inAppLoadingState === LoadingStatus.LOADING || inAppLoadingState === LoadingStatus.INIT
          }
          isOverlayShown={
            inAppLoadingState !== LoadingStatus.LOADING && (mode === 'EMPTY' || mode === 'UPGRADE')
          }
          overlayProps={
            mode === 'UPGRADE'
              ? {
                  status: 'upgrade',
                  title: 'Activate In-app frequency cappings',
                  description:
                    'In-app cappings allow you to limit the number of in-app messages a device will display.',
                  links: [
                    {
                      name: 'Upgrade your plan',
                      href: generateUrl('company_billing', {
                        companyId: app.companyId,
                      }),
                      intent: 'action',
                    },
                  ],
                }
              : {
                  status: 'empty',
                  title:
                    inAppLoadingState === LoadingStatus.ERROR
                      ? 'Data load failed.'
                      : 'No rules added yet.',
                }
          }
        >
          {Array(mode === 'UPGRADE' ? 4 : 2)
            .fill(undefined)
            .map((a, i) => (
              <EmptyCategoryRow key={i} />
            ))}
        </Wrapper>
      )
    )
  }, [app.companyId, blockMode.INAPP, inAppLoadingState])

  // ------ USE EFFECT ---

  React.useEffect(() => {
    if (blockMode.INAPP !== 'UPGRADE' && inAppLoadingState === LoadingStatus.INIT) {
      setInAppLoadingState(LoadingStatus.LOADING)
      fetchAttributesAndValues()
      // we don't need to fetch if company does not have the in-app capping feature
      const { cancel, promise } = cancellablePromise(fetchInAppCappings({ app }))
      cleanupOnUnMount.current = cancel
      promise.then(
        data => {
          setInAppLoadingState(LoadingStatus.LOADED)
          setInAppData(data)
        },
        err => {
          setInAppLoadingState(LoadingStatus.ERROR)
          showToast(err, false)
        }
      )
    }
  }, [app, blockMode.INAPP, fetchAttributesAndValues, inAppLoadingState, showToast])
  React.useEffect(() => cleanupOnUnMount.current, [])
  // ------ CALLBACKS ---
  const saveInAppPressure = React.useCallback(
    localData => {
      setInAppLoadingState(LoadingStatus.LOADING)
      saveInAppCappings({ app, pressure: localData }).then(
        () => {
          inAppEditingState.close()
          setInAppData(localData)
          setInAppLoadingState(LoadingStatus.LOADED)
          showToast('In-app cappings saved!', true)
        },
        err => {
          showToast(err?.body?.errors[0]?.message ?? '', false)
          setInAppLoadingState(LoadingStatus.LOADED)
        }
      )
    },
    [app, inAppEditingState, showToast]
  )

  const editCategory = React.useCallback((code: string | null) => setEditingCategoryCode(code), [])
  const clearEditCategory = React.useCallback(() => editCategory(null), [editCategory])

  const upgradePlanLink: OverlayLink = React.useMemo(
    () => ({
      name: 'Upgrade your plan',
      href: generateUrl('company_billing', {
        companyId: app.companyId,
      }),
      intent: 'action',
    }),
    [app.companyId]
  )

  const inAppProps = React.useMemo(
    () => ({
      clearOtherEdit: clearEditCategory,
      data: inAppData,
      installRate: installRate,
      hideToggle: blockMode.INAPP === 'UPGRADE',
      editingState: inAppEditingState,
      loadingState: inAppLoadingState,
      save: saveInAppPressure,
      otherEdit: editingCategoryCode,
      wrappedEmptyState: inAppEmptyState,
      sdkLevelLoading,
    }),
    [
      clearEditCategory,
      inAppData,
      installRate,
      blockMode.INAPP,
      inAppEditingState,
      inAppLoadingState,
      saveInAppPressure,
      sdkLevelLoading,
      editingCategoryCode,
      inAppEmptyState,
    ]
  )

  const isAllowedToAddCapping = useIsCurrentUserAllowedTo(['app', 'cappings:write'])
  // ------ render ---
  return (
    <div style={{ minHeight: 400 }}>
      {/* the above div is required cause WrapperPage absolute pos does not work   */}
      <Title>Global frequency cappings</Title>
      {blockMode.GLOBAL !== 'NORMAL' &&
        blockMode.PAGE === 'NORMAL' &&
        app.platform !== 'webpush' && <PressureInApp {...inAppProps} />}
      <Wrapper
        isEmpty={blockMode.TOPBLOCK === 'UPGRADE'}
        isLoading={false}
        isOverlayShown={blockMode.TOPBLOCK === 'UPGRADE'}
        overlayProps={{
          status: 'upgrade-page',
          companyId: app.companyId ?? 0,
          title: 'Activate this feature',
          description:
            'Capping allows you to limit the number of notifications a device can receive in a customisable time frame.',
        }}
      >
        <Box
          style={{
            border: blockMode.TOPBLOCK !== 'NORMAL' ? 'inherit' : 'none',
            backgroundColor: blockMode.TOPBLOCK !== 'NORMAL' ? 'inherit' : 'transparent',
            boxShadow: blockMode.TOPBLOCK !== 'NORMAL' ? 'inherit' : 'none',
          }}
        >
          <Wrapper
            isEmpty={blockMode.GLOBAL !== 'NORMAL'}
            isLoading={false}
            isOverlayShown={blockMode.GLOBAL !== 'NORMAL'}
            overlayProps={{
              status: 'upgrade',
              title: 'Activate global frequency cappings',
              description:
                'Global cappings allows you to limit the number of notifications a device can receive on all your channels.',
              links: [upgradePlanLink],
            }}
          >
            {internals.map((cat, index) => (
              <React.Fragment key={index}>
                <PressureCategory
                  hideToggle={blockMode.GLOBAL === 'UPGRADE'}
                  category={cat}
                  isEditing={cat.code === editingCategoryCode}
                  editCategory={editCategory}
                />
                {blockMode.GLOBAL === 'NORMAL' &&
                  cat.code === '_campaign' &&
                  app.platform !== 'webpush' && <PressureInApp {...inAppProps} />}
              </React.Fragment>
            ))}
          </Wrapper>
        </Box>

        {blockMode.PAGE === 'NORMAL' && (
          <ThemeProvider
            theme={{
              isEmpty: blockMode.LABELS !== 'NORMAL',
            }}
          >
            <React.Fragment>
              <FlexLine style={{ margin: '68px 0 32px 0' }}>
                <FlexLineItem grow={1}>
                  <Title mb={0}>Labels frequency cappings</Title>
                </FlexLineItem>
                <FlexLineItem>
                  <PermissionButton
                    type="button"
                    kind="primary"
                    intent="action"
                    disabled={labels.size === 0 || !hasCappingLabels}
                    onClick={addCategorieModalState.open}
                    isAllowed={isAllowedToAddCapping}
                  >
                    Add new capping
                  </PermissionButton>
                </FlexLineItem>
              </FlexLine>

              <Box
                style={{
                  overflow:
                    hasCappingLabels && (labels.size === 0 || labelsShown.size === 0)
                      ? 'hidden'
                      : 'inherit',
                  border:
                    hasCappingLabels && (labels.size === 0 || labelsShown.size === 0)
                      ? `1px solid ${colors.stroke}`
                      : 'none',
                  height:
                    !hasCappingLabels || labels.size === 0 || labelsShown.size === 0
                      ? '180px'
                      : 'inherit',
                  boxShadow:
                    hasCappingLabels && (labels.size === 0 || labelsShown.size === 0)
                      ? 'initial'
                      : 'none',
                  backgroundColor:
                    hasCappingLabels && (labels.size === 0 || labelsShown.size === 0)
                      ? 'inherit'
                      : 'transparent',
                }}
              >
                <Wrapper
                  isEmpty={blockMode.LABELS !== 'NORMAL'}
                  isOverlayShown={blockMode.LABELS !== 'NORMAL'}
                  isLoading={false}
                  overlayProps={
                    blockMode.LABELS === 'UPGRADE'
                      ? {
                          status: 'upgrade',
                          title: 'Activate labels frequency cappings',
                          description:
                            'Labels cappings allows you to limit the number of notifications a device can receive on a specific label.',
                          links: [upgradePlanLink],
                        }
                      : blockMode.LABELS === 'EMPTY'
                        ? {
                            status: 'empty',
                            title: 'No labels frequency capping yet',
                            links: [
                              {
                                name: 'Manage labels',
                                href: `/${app.companyId || ''}/apps/${app.id}/settings/labels`,
                              },
                            ],
                          }
                        : {
                            status: 'empty',
                            title: 'No rules added yet',
                          }
                  }
                >
                  {blockMode.LABELS !== 'NORMAL'
                    ? Array(4)
                        .fill(undefined)
                        .map((a, i) => <EmptyCategoryRow key={i} />)
                    : labelsShown.map(cat => (
                        <PressureCategory
                          key={cat.code}
                          category={cat}
                          isEditing={cat.code === editingCategoryCode}
                          editCategory={editCategory}
                        />
                      ))}
                </Wrapper>
              </Box>
            </React.Fragment>
          </ThemeProvider>
        )}
      </Wrapper>

      <Popin
        opened={addCategorieModalState.value}
        close={addCategorieModalState.close}
        style={{ width: 486 }}
      >
        <AddPressureCategory
          categories={labelsNoShown}
          close={addCategorieModalState.close}
          editCategory={editCategory}
        />
      </Popin>
    </div>
  )
}
