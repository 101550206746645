import dayjs from 'dayjs'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import { NavLink } from 'react-router-dom'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { generateOrchestrationUrl } from 'com.batch.common/router'

import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type MessagingEventRecord } from 'com.batch/profile/models/event.records'
import {
  ChannelBadge,
  statusConfig,
} from 'com.batch/profile/ui/components/events-block/event-row/channel-badge'
import {
  MessagingEventName,
  MessagingEventRowBox,
  MessagingEventRowContainer,
  MessagingEventText,
  MessagingEventTooltip,
} from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'
import { Time } from 'com.batch/profile/ui/components/events-block/timeline/time'
import { Skeleton } from 'components/common/empty-states'
import { EventIllustration } from 'com.batch/profile/ui/components/events-block/event-row/event-illustration'
import { profileLoadingStateSelector } from 'com.batch/profile/store/profile.selector'
import { LoadingStatus } from 'constants/common'

type Props = {
  event: MessagingEventRecord
  nextEvent?: MessagingEventRecord
}

export const MessagingEventRow = ({ event, nextEvent }: Props): React.ReactElement => {
  const profileLoadingState = useSelector(profileLoadingStateSelector)
  const isLoading = React.useMemo(
    () => profileLoadingState === LoadingStatus.LOADING,
    [profileLoadingState]
  )
  const project = useSelector(currentProjectSelector)
  const sendDateUtc = React.useMemo(() => {
    return dayjs.unix(event.eventDate).utc()
  }, [event.eventDate])

  const eventDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])

  const hasStep = React.useMemo(
    () => Boolean(event.orchestrationMetadata.stepName),
    [event.orchestrationMetadata.stepName]
  )

  const isAutomation = React.useMemo(() => {
    return (
      Boolean(event.orchestrationMetadata.orchestrationType) &&
      (Boolean(event.orchestrationMetadata.stepID) ||
        event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_RECURRENT' ||
        event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_TRIGGER')
    )
  }, [event.orchestrationMetadata.orchestrationType, event.orchestrationMetadata.stepID])

  const sendType = React.useMemo(() => {
    if (isAutomation) {
      return event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_RECURRENT'
        ? 'recurring'
        : 'trigger'
    }
    return 'now'
  }, [event.orchestrationMetadata.orchestrationType, isAutomation])

  const url = React.useMemo(() => {
    return generateOrchestrationUrl({
      channel: event.channel,
      page: 'form',
      sendType: sendType,
      companyId: project.companyId,
      projectId: project.id,
      token: event.orchestrationMetadata.orchestrationID,
    })
  }, [
    sendType,
    event.channel,
    event.orchestrationMetadata.orchestrationID,
    project.companyId,
    project.id,
  ])

  return (
    <MessagingEventRowContainer
      $variant={nextEvent ? statusConfig[nextEvent.metric]?.variant : undefined}
    >
      <Time date={eventDate} />
      <MessagingEventRowBox>
        <div>
          <Skeleton w={103} h={20}>
            <ChannelBadge event={event} />
          </Skeleton>
          <MessagingEventText>
            <Skeleton w={150} h={14}>
              <Tooltip
                tooltip={
                  <MessagingEventTooltip>
                    <div>{event.orchestrationMetadata.orchestrationName}</div>
                    <HorizontalDivider color={colors.strokeContrast} />
                    <div>
                      {isAutomation ? 'Click to access automation' : 'Click to access campaign'}
                    </div>
                  </MessagingEventTooltip>
                }
                placement="bottom"
                maxWidth={294}
                minWidth={220}
              >
                <MessagingEventName $twoLines={!hasStep}>
                  <Ellipsis>
                    <NavLink target="_blank" to={url}>
                      {event.orchestrationMetadata.orchestrationName}
                    </NavLink>
                  </Ellipsis>
                  <Icon icon="arrow-long-right" />
                </MessagingEventName>
              </Tooltip>
            </Skeleton>

            {event.orchestrationMetadata.stepName && (
              <Skeleton w={100} h={14}>
                <Tooltip
                  tooltip={
                    <MessagingEventTooltip>
                      <div>{event.orchestrationMetadata.stepName}</div>
                      <HorizontalDivider color={colors.strokeContrast} />
                      <div>Click to access automation step</div>
                    </MessagingEventTooltip>
                  }
                  placement="bottom"
                  maxWidth={294}
                  minWidth={240}
                >
                  <MessagingEventName>
                    <Ellipsis>
                      <NavLink
                        target="_blank"
                        to={`${url}?stepId=${event.orchestrationMetadata.stepID}`}
                      >
                        {event.orchestrationMetadata.stepName}
                      </NavLink>
                    </Ellipsis>
                    <Icon icon="arrow-long-right" />
                  </MessagingEventName>
                </Tooltip>
              </Skeleton>
            )}
          </MessagingEventText>
        </div>
        {!isLoading && <EventIllustration channel={event.channel} />}
      </MessagingEventRowBox>
    </MessagingEventRowContainer>
  )
}
